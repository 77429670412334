package xim.resource.table

import xim.poc.browser.DatLoader
import xim.resource.StringTableParser

class StringTable(val resourceName: String, val bitMask: Byte = 0): LoadableResource {

    private lateinit var names: List<List<String>>
    private var preloaded = false

    override fun preload() {
        if (preloaded) { return }
        preloaded = true
        loadTable()
    }

    override fun isFullyLoaded() : Boolean {
        return this::names.isInitialized
    }

    fun getAllFirst(): List<String> {
        return names.map { it.first() }
    }

    fun first(id: Int) = getById(id).first()

    operator fun get(id: Int) = getById(id)

    private fun getById(id: Int) : List<String> {
        return names.getOrElse(id) { emptyList() }
    }

    private fun loadTable() {
        DatLoader.load(resourceName).onReady { names = StringTableParser.read(bitMask = bitMask, byteReader = it.getAsBytes()) }
    }

}