package xim.resource.table

import js.typedarrays.Uint8Array
import xim.poc.RaceGenderConfig
import xim.poc.browser.DatLoader
import xim.resource.ByteReader

object MainDll: LoadableResource {

    private lateinit var dll: ByteReader

    private const val battleAnimationFileTableOffset = 0x34AF4
    private const val dualWieldMainHandFileTableOffset = 0x34B64
    private const val dualWieldOffHandFileTableOffset = 0x34B2C
    private const val emoteAnimationOffset = 0x34B9C

    private const val equipmentLookupTableOffset = 0x34C18

    private const val raceConfigLookupTableOffset = 0x367B0
    private const val weaponSkillAnimationFileTableOffset = 0x3685C
    private const val danceSkillAnimationFileTableOffset = 0x36894

    private const val actionAnimationFileTableOffset = 0x3694C

    private const val zoneDecryptTable1Offset = 0x4DCB0
    private const val zoneDecryptTable2Offset = 0x4DFD8

    private const val zoneMapTableOffset = 0x593D0

    override fun preload() {
        DatLoader.load("FFXiMain.dll").onReady { dll = it.getAsBytes() }
    }

    override fun isFullyLoaded(): Boolean {
        return this::dll.isInitialized
    }

    fun getZoneDecryptTable1(): Uint8Array {
        return getBytes(offset = zoneDecryptTable1Offset, size = 0x100)
    }

    fun getZoneDecryptTable2(): Uint8Array {
        return getBytes(offset = zoneDecryptTable2Offset, size = 0x100)
    }

    fun getZoneMapTableReader(): ByteReader {
        return getByteReader(offset = zoneMapTableOffset, size = 0x2D64)
    }

    fun getEquipmentLookupTable(): ByteReader {
        return getByteReader(offset = equipmentLookupTableOffset, size = 0x1B0 * 0x10)
    }

    fun getBaseBattleAnimationIndex(raceGenderConfig: RaceGenderConfig): Int {
        return dll.read16(battleAnimationFileTableOffset + raceGenderConfig.index * 2)
    }

    fun getBaseWeaponSkillAnimationIndex(raceGenderConfig: RaceGenderConfig): Int {
        return dll.read16(weaponSkillAnimationFileTableOffset + raceGenderConfig.index * 2)
    }

    fun getBaseRaceConfigIndex(raceGenderConfig: RaceGenderConfig): Int {
        return dll.read16(raceConfigLookupTableOffset + raceGenderConfig.index * 2)
    }

    fun getBaseDanceSkillAnimationIndex(raceGenderConfig: RaceGenderConfig): Int {
        return dll.read16(danceSkillAnimationFileTableOffset + raceGenderConfig.index * 2)
    }

    fun getBaseDualWieldMainHandAnimationIndex(raceGenderConfig: RaceGenderConfig): Int {
        return dll.read16(dualWieldMainHandFileTableOffset + raceGenderConfig.index * 2)
    }

    fun getBaseDualWieldOffHandAnimationIndex(raceGenderConfig: RaceGenderConfig): Int {
        return dll.read16(dualWieldOffHandFileTableOffset + raceGenderConfig.index * 2)
    }

    fun getBaseEmoteAnimationIndex(raceGenderConfig: RaceGenderConfig): Int {
        return dll.read16(emoteAnimationOffset + raceGenderConfig.index * 2)
    }

    fun getActionAnimationIndex(raceGenderConfig: RaceGenderConfig): Int {
        return dll.read16(actionAnimationFileTableOffset + raceGenderConfig.index * 2)
    }

    private fun getBytes(offset: Int, size: Int): Uint8Array {
        return dll.subBuffer(offset = offset, size = size)
    }

    private fun getByteReader(offset: Int, size: Int): ByteReader {
        return ByteReader(getBytes(offset, size))
    }

}