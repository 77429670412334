package xim.poc.game.event

import xim.poc.ActionTargetFilter
import xim.poc.ActorId
import xim.poc.ActorManager
import xim.poc.game.*
import xim.poc.game.configuration.MonsterDefinitions
import xim.poc.ui.ChatLog
import xim.poc.ui.ChatLogColor

class ActorDefeatedEvent(
    val defeated: ActorId,
    val defeatedBy: ActorId?,
    val actionContext: AttackContext? = null,
): Event {

    override fun apply(): List<Event> {
        val events = ArrayList<Event>()

        val defeatedState = ActorStateManager[defeated] ?: return emptyList()
        events += BattleDisengageEvent(defeated)
        events += GameEngine.releaseDependents(defeatedState)

        actionContext?.composeCallback { displayDeath() } ?: displayDeath()

        val defeatedByState = ActorStateManager[defeatedBy]
        if (defeatedByState != null) {
            actionContext?.composeCallback { ChatLog("${defeatedByState.name} defeats ${defeatedState.name}.", ChatLogColor.Action) }
        } else {
            ChatLog("${defeatedState.name} falls to the ground.", ChatLogColor.Action)
        }

        val creditedActor = defeatedByState ?: ActorStateManager[defeatedState.targetState.targetId] ?: return events
        if (!ActionTargetFilter.areEnemies(defeatedState, creditedActor)) { return events }

        val creditedActors = PartyManager[creditedActor.id].getAllState().filter { it.type == ActorType.Pc }
        if (creditedActors.any { it.isPlayer() }) {
            generateDropItems(defeatedState)
            events += transferInventory(defeatedState, ActorStateManager.player())
        }

        val pointsGainActors = creditedActors.filter { !it.isDead() }
        events += pointsGainActors.map { ActorGainExpEvent(it.id, defeated, actionContext = actionContext) }
        events += pointsGainActors.map { ActorGainRpEvent(it.id, defeated, actionContext = actionContext) }

        return events
    }

    private fun displayDeath() {
        ActorManager[defeated]?.onDisplayDeath()
    }

    private fun generateDropItems(monsterState: ActorState) {
        val monsterId = monsterState.monsterId ?: return
        val monsterDefinition = MonsterDefinitions[monsterId]

        val monsterInventory = monsterState.inventory

        for (entry in monsterDefinition.dropTable) {
            val dropDefinition = entry.getRandom() ?: continue
            val item = GameState.getGameMode().generateItem(
                itemId = dropDefinition.itemId,
                quantity = dropDefinition.quantity,
                quality = dropDefinition.quality,
                augmentRank = dropDefinition.augmentRank,
            )
            monsterInventory.addItem(item, stack = false)
        }
    }

    private fun transferInventory(source: ActorState, destination: ActorState): List<Event> {
        return source.inventory.inventoryItems.map {
            InventoryItemTransferEvent(
                sourceId = source.id,
                destinationId = destination.id,
                inventoryItemId = it.internalId,
                actionContext = actionContext,
            )
        }
    }

}