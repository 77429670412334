package xim.poc.game

import xim.poc.ActorId

fun interface EffectCallback {
    fun invoke()

    companion object {
        val noop = EffectCallback {  }

        fun compose(first: EffectCallback, second: EffectCallback): EffectCallback {
            return EffectCallback { first.invoke(); second.invoke() }
        }

    }

}

class AttackContexts(private val byTarget: Map<ActorId, AttackContext>) {

    companion object {
        fun single(targetId: ActorId, attackContext: AttackContext): AttackContexts {
            return AttackContexts(mapOf(targetId to attackContext))
        }

        fun noop(): AttackContexts {
            return AttackContexts(emptyMap())
        }
    }

    operator fun get(actorId: ActorId) = byTarget[actorId] ?: AttackContext.noop()

    fun getAll(): Collection<AttackContext> {
        return byTarget.values
    }

}

class AttackContext(val hitFlag: Int = 0, val retaliationFlag: Int = 0, var rollValueFlag: Int = 0, var rollSumFlag: Int = 0, var magicBurst: Boolean = false, var effectCallback: EffectCallback) {

    companion object {
        fun from(attacker: ActorState, defender: ActorState, effectCallback: EffectCallback = EffectCallback.noop): AttackContext {
            val hitFlag = if (attacker.hasStatusEffect(94)) { 1 } else if (attacker.hasStatusEffect(95)) { 2 } else { 0 }
            val retaliationFlag = if (defender.hasStatusEffect(34)) { 1 } else if (defender.hasStatusEffect(35)) { 2 } else { 0 }
            return AttackContext(hitFlag = hitFlag, retaliationFlag = retaliationFlag, effectCallback = effectCallback)
        }

        fun fromRoll(rollValue: Int, rollSum: Int, effectCallback: EffectCallback): AttackContext {
            return AttackContext(rollValueFlag = rollValue, rollSumFlag = rollSum, effectCallback = effectCallback)
        }

        fun noop(): AttackContext {
            return AttackContext(effectCallback = {})
        }

    }

    fun composeCallback(next: EffectCallback) {
        effectCallback = EffectCallback.compose(effectCallback, next)
    }

}