package xim.poc.game.event

import xim.poc.ActorId
import xim.poc.ActorManager
import xim.poc.game.ActorStateManager
import xim.poc.game.CastingState
import xim.resource.DatId

class CastInterruptedEvent(
    val sourceId: ActorId
): Event {

    override fun apply(): List<Event> {
        val actorState = ActorStateManager[sourceId] ?: return emptyList()

        val current = actorState.getCastingState() ?: return emptyList()
        current.onExecute()

        val animId = getInterruptAnimation(current) ?: return emptyList()
        ActorManager[sourceId]?.enqueueModelRoutine(animId)

        return emptyList()
    }

    private fun getInterruptAnimation(castingState: CastingState): DatId? {
        return if (castingState.rangedAttack) {
            DatId("splg")
        } else if (castingState.spellInfo != null) {
            DatId.stopCastId(castingState.spellInfo)
        } else if (castingState.itemInfo != null) {
            DatId("spit")
        } else if (castingState.abilityInfo != null || castingState.mobSkillInfo != null) {
            DatId("spte")
        } else {
            throw IllegalStateException()
        }
    }

}