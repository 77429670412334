package xim.poc.ui

import xim.math.Vector2f
import xim.poc.UiElementHelper
import xim.poc.game.ActorStateManager
import xim.poc.game.UiState
import xim.poc.game.UiStateHelper
import xim.resource.UiMenuCursorKey

object StatusEffectUi {

    private const val elementsPerRow = 9

    fun isValid(): Boolean {
        val player = ActorStateManager.player()
        val statuses = player.getStatusEffects()
        return statuses.isNotEmpty()
    }

    fun draw(uiState: UiState) {
        val menu = uiState.latestMenu ?: return
        val position = uiState.latestPosition ?: return

        val player = ActorStateManager.player()
        val statuses = player.getStatusEffects()

        for (i in statuses.indices) {
            val element = menu.elements[i]
            val status = statuses[i]

            status.info.icon.texture ?: continue
            UiElementHelper.drawStatusEffect(status, position + element.offset + Vector2f(6f, 6f))
        }
    }

    fun getSelectedStatusEffectId(uiState: UiState): Int? {
        val player = ActorStateManager.player()
        val statuses = player.getStatusEffects()
        return statuses.getOrNull(uiState.cursorIndex)?.info?.id
    }

    fun navigateCursor(uiState: UiState): Boolean {
        val key = UiStateHelper.getDirectionalInput() ?: return false

        val player = ActorStateManager.player()
        val statuses = player.getStatusEffects()

        if (key == UiMenuCursorKey.Left) {
            uiState.cursorIndex -= 1
        } else if (key == UiMenuCursorKey.Right) {
            uiState.cursorIndex += 1
        } else if (key == UiMenuCursorKey.Up && statuses.size > elementsPerRow) {
            uiState.cursorIndex -= elementsPerRow
        } else if (key == UiMenuCursorKey.Down && statuses.size > elementsPerRow) {
            uiState.cursorIndex += elementsPerRow
        }

        if (uiState.cursorIndex < 0) { uiState.cursorIndex = statuses.size - 1 }
        if (uiState.cursorIndex >= statuses.size) { uiState.cursorIndex = 0 }

        return true
    }

}