package xim.poc.browser

import onStart
import web.dom.Element
import web.dom.document
import web.html.HTMLElement
import web.performance.performance
import web.uievents.KeyboardEvent
import web.uievents.MouseEvent
import web.uievents.Touch
import web.uievents.TouchEvent
import web.window.window
import xim.poc.browser.Keyboard.Key
import xim.poc.browser.Keyboard.KeyState
import xim.poc.ui.ChatLog
import kotlin.math.abs

class JsKeyboard : Keyboard {

    companion object {
        private const val mouseEventId = -1

        private val KEY_MAP = mutableMapOf(
            "tab" to Key.TAB,
            "enter" to Key.ENTER,
            "shift" to Key.SHIFT,
            "escape" to Key.ESC,
            " " to Key.SPACE,
            "pageup" to Key.ZOOM_IN,
            "pagedown" to Key.ZOOM_OUT,
            "arrowleft" to Key.LEFT,
            "arrowright" to Key.RIGHT,
            "arrowup" to Key.UP,
            "arrowdown" to Key.DOWN,
            "a" to Key.A,
            "c" to Key.C,
            "d" to Key.D,
            "f" to Key.F,
            "h" to Key.H,
            "k" to Key.K,
            "m" to Key.M,
            "p" to Key.P,
            "r" to Key.R,
            "s" to Key.S,
            "w" to Key.W,
            "z" to Key.Z,
            "f1" to Key.F1,
            "f2" to Key.F2,
            "f3" to Key.F3,
            "f4" to Key.F4,
            "f5" to Key.F5,
            "f6" to Key.F6,
            "-" to Key.MINUS,
        )
    }

    private val keyStates = mutableMapOf<Key, KeyState>()
    private val bufferedKeyUp = HashMap<Key, KeyboardEvent<*>>()
    private val bufferedKeyDown = HashMap<Key, KeyboardEvent<*>>()
    private val bufferedClicks = ArrayList<ClickEvent>()

    private val touches = HashMap<Int, TouchData>()
    private val clicks = ArrayList<ClickEvent>()

    init {
        window.onkeyup = this::onKeyUp
        window.onkeydown = this::onKeyDown

        val canvas = document.getElementById("canvas") as HTMLElement

        canvas.onmousedown = { onMouseDown(it as MouseEvent<Element>) }
        canvas.onmousemove = { onMouseMove(it as MouseEvent<Element>) }
        canvas.onmouseleave = { onMouseEnd(it as MouseEvent<Element>) }
        canvas.onmouseup = { onMouseEnd(it as MouseEvent<Element>) }

        canvas.ontouchstart = { onTouchStart(it as TouchEvent<Element>) }
        canvas.ontouchmove = { onTouchMove(it as TouchEvent<Element>) }
        canvas.ontouchend = { onTouchEnd(it as TouchEvent<Element>) }
        canvas.ontouchcancel = { onTouchEnd(it as TouchEvent<Element>) }
    }

    private fun onKeyUp(event: KeyboardEvent<*>) {
        val targetId = (event.target as HTMLElement).id
        if (targetId == "canvas" || targetId == "") {
            event.stopPropagation()
            event.preventDefault()
        } else {
            return
        }

        val key = KEY_MAP[event.key.lowercase()] ?: return
        bufferedKeyUp[key] = event
    }

    private fun onKeyDown(event: KeyboardEvent<*>) {
        onStart()

        val targetId = (event.target as HTMLElement).id
        if (targetId == "canvas" || targetId == "") {
            event.stopPropagation()
            event.preventDefault()
        } else {
            return
        }

        val key = KEY_MAP[event.key.lowercase()] ?: return
        bufferedKeyDown[key] = event
    }


    private fun onMouseDown(mouseEvent: MouseEvent<Element>) {
        val data = TouchData()
        touches[mouseEventId] = data
        data.startTime = performance.now()

        val (x, y) = getNormalizedMousePosition(mouseEvent)

        data.startingX = x
        data.startingY = y
    }

    private fun onMouseMove(mouseEvent: MouseEvent<Element>) {
        val data = touches[mouseEventId] ?: return

        val (x, y) = getNormalizedMousePosition(mouseEvent)

        data.dX = (x - data.startingX)
        data.dY = (y - data.startingY)
    }

    private fun onMouseEnd(mouseEvent: MouseEvent<Element>) {
        val data = touches[mouseEventId] ?: return

        if (abs(data.dX) < 0.005 && abs(data.dY) < 0.005) {
            bufferedClicks += ClickEvent(performance.now() - data.startTime, data.startingX.toFloat(), data.startingY.toFloat())
        }

        touches.remove(mouseEventId)
    }

    private fun getNormalizedMousePosition(mouseEvent: MouseEvent<Element>): Pair<Double, Double> {
        val target = mouseEvent.currentTarget
        val rect = target.getBoundingClientRect()

        val x = (mouseEvent.clientX - rect.left) / rect.width
        val y = (mouseEvent.clientY - rect.top) / rect.height

        return Pair(x, y)
    }

    private fun onTouchStart(event: TouchEvent<Element>) {
        event.preventDefault()

        for (touch in event.touches) {
            if (touches.containsKey(touch.identifier)) { continue }
            val data = TouchData()
            data.startTime = performance.now()

            val (x, y) = getNormalizedTouchPosition(touch, event.currentTarget)
            data.startingX = x
            data.startingY = y
            touches[touch.identifier] = data
        }
    }

    private fun onTouchMove(event: TouchEvent<Element>) {
        event.preventDefault()

        for (touch in event.touches) {
            val data = touches[touch.identifier] ?: continue
            val (x, y) = getNormalizedTouchPosition(touch, event.currentTarget)
            data.dX = x - data.startingX
            data.dY = y - data.startingY
        }
    }

    private fun onTouchEnd(event: TouchEvent<Element>) {
        event.preventDefault()

        for (touch in event.changedTouches) {
            val data = touches.remove(touch.identifier) ?: continue

            if (abs(data.dX) < 0.05 && abs(data.dY) < 0.05) {
                val now = performance.now()
                bufferedClicks += ClickEvent(now - data.startTime, data.startingX.toFloat(), data.startingY.toFloat())
            }
        }
    }

    private fun getNormalizedTouchPosition(touch: Touch, target: Element): Pair<Double, Double> {
        val rect = target.getBoundingClientRect()

        val x = (touch.clientX - rect.left) / rect.width
        val y = (touch.clientY - rect.top) / rect.height

        return Pair(x, y)
    }

    override fun poll() {
        clicks.clear()
        clicks += bufferedClicks
        bufferedClicks.clear()

        val itr = keyStates.iterator()
        while (itr.hasNext()) {
            val next = itr.next()

            if (next.value == KeyState.RELEASED) {
                itr.remove()
            } else if (bufferedKeyUp.containsKey(next.key)) {
                keyStates[next.key] = KeyState.RELEASED
            } else if (bufferedKeyDown.containsKey(next.key)) {
                keyStates[next.key] = KeyState.REPEATED
            }

            bufferedKeyUp.remove(next.key)
            bufferedKeyDown.remove(next.key)
        }

        keyStates.putAll(bufferedKeyDown.mapValues { KeyState.PRESSED })
    }

    override fun getClickEvents(): List<ClickEvent> {
        return clicks
    }

    override fun clear() {
        keyStates.clear()
    }

    override fun getKeyState(key: Key): KeyState {
        return keyStates.getOrElse(key) { KeyState.NONE }
    }

    override fun isKeyPressed(key: Key): Boolean {
        return getKeyState(key) == KeyState.PRESSED
    }

    override fun isKeyPressedOrRepeated(key: Key): Boolean {
        return isKeyPressed(key) || isKeyRepeated(key)
    }

    override fun isKeyReleased(key: Key): Boolean {
        return getKeyState(key) == KeyState.RELEASED
    }

    override fun isKeyRepeated(key: Key): Boolean {
        return getKeyState(key) == KeyState.REPEATED
    }

    override fun getTouchData(): Collection<TouchData> {
        return touches.values
    }

}
