package xim.poc.game.event

import xim.poc.ActorId
import xim.poc.game.ActorStateManager

class InventorySortEvent(
    val sourceId: ActorId
): Event {

    override fun apply(): List<Event> {
        val source = ActorStateManager[sourceId] ?: return emptyList()
        source.inventory.sort()
        return emptyList()
    }

}