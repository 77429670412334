package xim.poc.game.configuration.v0

import xim.poc.game.ActorState
import xim.poc.game.GameEngine
import xim.poc.game.configuration.*
import xim.poc.game.event.*
import xim.resource.AbilityCost
import xim.resource.AbilityCostType
import xim.resource.AbilityInfo
import kotlin.math.roundToInt

object V0AbilityDefinitions {

    private val abilityCosts = HashMap<Int, AbilityCost>()

    fun register() {
        // Fast Blade
        SkillAppliers += SkillApplier(skillId = 32, skillType = SkillType.Ability, validEvaluator = CommonSkillAppliers.weaponSkillValidator, targetEvaluator = applyFastBlade())
        abilityCosts[32] = AbilityCost(AbilityCostType.Tp, 240)

        // Burning Blade
        SkillAppliers += SkillApplier(skillId = 33, skillType = SkillType.Ability, validEvaluator = CommonSkillAppliers.weaponSkillValidator, targetEvaluator = applyBurningBlade())
        abilityCosts[33] = AbilityCost(AbilityCostType.Tp, 240)

        // Red Lotus Blade
        SkillAppliers += SkillApplier(skillId = 34, skillType = SkillType.Ability, validEvaluator = CommonSkillAppliers.weaponSkillValidator, targetEvaluator = applyRedLotusBlade())
        abilityCosts[34] = AbilityCost(AbilityCostType.Tp, 480)

        // Provoke
        SkillAppliers += SkillApplier(skillId = 547, skillType = SkillType.Ability,
            targetEvaluator = { listOf(BattleEngageEvent(it.targetState.id, it.sourceState.id)) }
        )
    }

    fun getCost(abilityInfo: AbilityInfo): AbilityCost {
        return abilityCosts[abilityInfo.index] ?: AbilityCost(AbilityCostType.Tp, 0)
    }

    private fun applyFastBlade(): SkillApplierHelper.TargetEvaluator {
        return SkillApplierHelper.TargetEvaluator {
            val hits = rollHits(it.sourceState, it.targetState, rollCount = 2)
            val tpGain = hits.first().tpGained

            val damage = DamageCalculator.applyWeaponSkillBonus(it.sourceState, hits.sumOf { hit -> hit.damageDone })

            if (it.targetState.id == it.primaryTargetState.id) {
                it.sourceState.gainTp(tpGain)
                it.sourceState.consumeTp(GameEngine.getAbilityCost(it.skillInfo.id).value)
            }

            listOf(
                ActorDamagedEvent(sourceId = it.sourceState.id, targetId = it.targetState.id, amount = damage, it.context),
                ActorWeaponSkillEvent(abilityId = it.skillInfo.id, sourceId = it.sourceState.id, targetId = it.targetState.id, context = it.context, damageDone = damage)
            )
        }
    }

    private fun applyBurningBlade(): SkillApplierHelper.TargetEvaluator {
        return SkillApplierHelper.TargetEvaluator {
            val hits = rollHits(it.sourceState, it.targetState, rollCount = 1)
            val tpGain = hits.first().tpGained

            val baseDamage = DamageCalculator.applyWeaponSkillBonus(it.sourceState, hits.sumOf { hit -> hit.damageDone })
            val damage = (baseDamage * DamageCalculator.computeIntRatio(it.sourceState, it.targetState)).roundToInt()

            if (it.targetState.id == it.primaryTargetState.id) {
                it.sourceState.gainTp(tpGain)
                it.sourceState.consumeTp(GameEngine.getAbilityCost(it.skillInfo.id).value)
            }

            listOf(
                ActorDamagedEvent(sourceId = it.sourceState.id, targetId = it.targetState.id, amount = damage, it.context),
                ActorWeaponSkillEvent(abilityId = it.skillInfo.id, sourceId = it.sourceState.id, targetId = it.targetState.id, context = it.context, damageDone = damage)
            )
        }
    }

    private fun applyRedLotusBlade(): SkillApplierHelper.TargetEvaluator {
        return SkillApplierHelper.TargetEvaluator {
            val hits = rollHits(it.sourceState, it.targetState, rollCount = 1)
            val tpGain = hits.first().tpGained

            val ftp = it.sourceState.getTp() / 240f
            val ftpHit = hits.first().damageDone * ftp
            val remainingHits = hits.drop(1).sumOf { hit -> hit.damageDone }

            val baseDamage = DamageCalculator.applyWeaponSkillBonus(it.sourceState, (ftpHit + remainingHits).roundToInt())
            val damage = (baseDamage * DamageCalculator.computeIntRatio(it.sourceState, it.targetState)).roundToInt()

            if (it.targetState.id == it.primaryTargetState.id) {
                it.sourceState.setTp(tpGain)
            }

            listOf(
                ActorDamagedEvent(sourceId = it.sourceState.id, targetId = it.targetState.id, amount = damage, it.context),
                ActorWeaponSkillEvent(abilityId = it.skillInfo.id, sourceId = it.sourceState.id, targetId = it.targetState.id, context = it.context, damageDone = damage)
            )
        }
    }

    private fun rollHits(attacker: ActorState, defender: ActorState, rollCount: Int): List<AutoAttackResult> {
        val hits = ArrayList<AutoAttackResult>()

        for (i in 0 until rollCount) {
            hits += DamageCalculator.getAutoAttackTypeResult(attacker, defender, AutoAttackType.Main)
        }

        if (attacker.isDualWield()) {
            hits += DamageCalculator.getAutoAttackTypeResult(attacker, defender, AutoAttackType.Sub)
        }

        return hits.take(8)
    }

}