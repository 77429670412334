package xim.poc.game.configuration.v0

import xim.poc.game.CombatStats

class TrustWeapon(
    val damage: Int,
    val delay: Int,
)

class TrustDefinition(
    val id: Int,
    val chrScaling: CombatStats,
    val weapon: TrustWeapon,
)

object TrustDefinitions {

    val definitionsById: Map<Int, TrustDefinition>

    operator fun get(trustId: Int?): TrustDefinition? {
        trustId ?: return null
        return get(trustId)
    }

    operator fun get(trustId: Int): TrustDefinition {
        return definitionsById[trustId] ?: throw IllegalStateException("[$trustId] Undefined trust")
    }

    init {
        val definitions = ArrayList<TrustDefinition>()

        // Shantotto
        definitions += TrustDefinition(
            id = 896,
            chrScaling = CombatStats(maxHp = 60, maxMp = 140, str = 40, vit = 100, dex = 40, agi = 40, int = 140, mnd = 40, chr = 100),
            weapon = TrustWeapon(damage = 1, delay = 240),
        )

        // Kupipi
        definitions += TrustDefinition(
            id = 898,
            chrScaling = CombatStats(maxHp = 60, maxMp = 140, str = 40, vit = 100, dex = 40, agi = 40, int = 40, mnd = 140, chr = 100),
            weapon = TrustWeapon(damage = 3, delay = 240),
        )

        // Curilla
        definitions += TrustDefinition(
            id = 902,
            chrScaling = CombatStats(maxHp = 120, maxMp = 80, str = 70, vit = 130, dex = 60, agi = 60, int = 60, mnd = 120, chr = 100),
            weapon = TrustWeapon(damage = 4, delay = 240),
        )

        definitionsById = definitions.associateBy { it.id }
    }

}