package xim.poc.game.event

import xim.poc.ActorId
import xim.poc.game.*
import xim.poc.ui.ChatLog
import xim.poc.ui.ChatLogColor
import xim.poc.ui.ShiftJis

class InventoryItemTransferEvent(
    val sourceId: ActorId,
    val destinationId: ActorId,
    val inventoryItemId: InternalItemId,
    val actionContext: AttackContext? = null,
) : Event {

    override fun apply(): List<Event> {
        val source = ActorStateManager[sourceId] ?: return emptyList()
        val destination = ActorStateManager[destinationId] ?: return emptyList()

        val inventoryItem = source.inventory.getByInternalId(inventoryItemId) ?: return emptyList()

        source.inventory.discardItem(inventoryItemId)
        destination.inventory.addItem(inventoryItem)

        if (destination.isPlayer()) {
            AttackContext.compose(actionContext) {
                ChatLog.addLine("${destination.name} obtains ${getItemString(inventoryItem)}${ShiftJis.colorInfo}.", ChatLogColor.Info)
            }
        }

        return emptyList()
    }

    private fun getItemString(inventoryItem: InventoryItem): String {
        val info = inventoryItem.info()
        val logName = if (inventoryItem.quantity > 1) { "${inventoryItem.quantity} ${info.logNamePlural}" } else { "a ${info.logName}" }

        val qualityColor = AugmentHelper.getQualityColorDisplay(inventoryItem)
        return "${qualityColor}${logName}"
    }

}