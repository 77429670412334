package xim.poc.game.event

import xim.poc.ActorId
import xim.poc.ActorManager
import xim.poc.game.ActorStateManager
import xim.poc.game.CastingState
import xim.poc.game.GameEngine
import xim.poc.game.GameState
import xim.resource.table.SpellInfoTable

class CastSpellStart(
    val sourceId: ActorId,
    val targetId: ActorId,
    val spellId: Int,
) : Event {

    override fun apply(): List<Event> {
        val actorState = ActorStateManager[sourceId] ?: return emptyList()
        if (!actorState.isIdleOrEngaged()) { return emptyList() }

        val spellInfo = SpellInfoTable[spellId]

        if (!GameEngine.canBeginCastSpell(sourceId, spellInfo)) { return emptyList() }

        val current = actorState.getCastingState()
        if (current != null && !current.isComplete()) { return emptyList() }

        val castTime = GameState.getGameMode().getSpellCastTime(actorState, spellId)

        val castingState = CastingState.spell(castTime = castTime, sourceId = sourceId, targetId = targetId, spellInfo = spellInfo)
        actorState.setCastingState(castingState)

        ActorManager[sourceId]?.startCasting(spellInfo, targetId)

        return emptyList()
    }

}