package xim.poc.game.configuration

import xim.poc.game.configuration.SkillApplierHelper.makeApplier
import xim.poc.game.configuration.StandardSkillEvaluators.basicPhysicalDamage
import xim.poc.game.configuration.StandardSkillEvaluators.sourceHpPercentDamage
import xim.resource.AoeType

class MobSkill(val id: Int, val rangeInfo: SkillRangeInfo, val applier: SkillApplier) {
    init { MobSkills.definitions += this }
}

object MobSkills {

    val definitions = ArrayList<MobSkill>()
    val definitionsById: Map<Int, MobSkill>

    init {
        // Mandragora: Leaf Dagger
        MobSkill(id =  49, rangeInfo = SkillRangeInfo(16f, 0f, AoeType.None), applier = makeApplier(
            targetEvaluator = basicPhysicalDamage(PotencyConfiguration(potency = 1f)),
        ))

        // Vulture: Hell Dive
        MobSkill(id = 366, rangeInfo = SkillRangeInfo(16f, 0f, AoeType.None), applier = makeApplier(
            targetEvaluator = basicPhysicalDamage(PotencyConfiguration(potency = 1f)),
        ))

        // Vulture: Wing Cutter
        MobSkill(id = 367, rangeInfo = SkillRangeInfo( 4f, 4f, AoeType.Cone), applier = makeApplier(
            targetEvaluator = basicPhysicalDamage(PotencyConfiguration(potency = 2f)),
        ))

        // Goblin: Normal bomb toss
        MobSkill(id = 335, rangeInfo = SkillRangeInfo(16f, 8f, AoeType.Target), applier = makeApplier(
            targetEvaluator = basicPhysicalDamage(PotencyConfiguration(potency = 1f)),
        ))

        // Goblin: dropped bomb toss
        MobSkill(id = 336, rangeInfo = SkillRangeInfo( 8f, 8f, AoeType.Source), applier = makeApplier(
            targetEvaluator = sourceHpPercentDamage(PotencyConfiguration(potency = 0.5f)),
            additionalSelfEvaluator = sourceHpPercentDamage(PotencyConfiguration(potency = 1f)),
        ))

        definitionsById = definitions.associateBy { it.id }
    }

}