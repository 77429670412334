package xim.poc.game

import xim.math.Vector3f
import xim.poc.Actor
import xim.poc.ActorId
import xim.poc.ActorManager
import xim.poc.audio.AudioManager
import xim.poc.audio.SystemSound
import xim.poc.tools.ZoneNpcTool

fun interface TargetFilter {
    fun isValidTarget(sourceId: ActorId, targetId: ActorId): Boolean
}

object PlayerTargetSelector {

    private val targetStack = ArrayList<Actor>()
    private val subTargetStack = ArrayList<Actor>()
    private val tabTargetFilter = TargetFilter { a, b -> tabTargetFilter(a, b) }

    fun updateTarget() {
        val playerActor = ActorManager.player()
        val targetId = playerActor.target ?: return

        val targetActor = ActorManager[targetId]
        val visible = ActorManager.getVisibleActors().contains(targetActor)

        if (visible && targetActor != null && steadyStateFilter(playerActor.id, targetActor.id)) {
            return
        }

        if (!playerActor.isFullyOutOfCombat()) {
            GameEngine.onPlayerDisengage()
        } else {
            GameEngine.submitClearTarget(playerActor.id)
            targetStack.clear()
        }
    }

    fun clearTarget() {
        val playerActor = ActorManager.player()
        if (playerActor.isTargetLocked()) { return }

        GameEngine.submitClearTarget(playerActor.id)
        targetStack.clear()
    }

    fun targetPartyMember(index: Int) {
        val playerActor = ActorManager.player()
        val party = PartyManager[playerActor]
        val target = party.getByIndex(index) ?: return

        val subTargetMode = UiStateHelper.isSubTargetMode()
        if (playerActor.isTargetLocked() && !subTargetMode) { return }

        val pendingFilter = UiStateHelper.getPendingActionTargetFilter()
        if (pendingFilter != null && !pendingFilter.isValidTarget(playerActor.id, target.id)) { return }

        if (subTargetMode) {
            playerActor.subTarget = target.id
            subTargetStack.clear()
        } else {
            GameEngine.submitTargetUpdate(playerActor.id, target.id)
            targetStack.clear()
        }

        AudioManager.playSystemSoundEffect(SystemSound.TargetCycle)
    }

    fun targetCycle(playSound: Boolean = true, subTarget: Boolean = false, targetFilter: TargetFilter = tabTargetFilter): Boolean {
        val playerActor = ActorManager.player()
        if (playerActor.isTargetLocked() && !subTarget) { return false }

        val stack = if (subTarget) { subTargetStack } else { targetStack }
        var nextTarget: Actor

        while (true) {
            if (stack.isEmpty()) {
                val allActors = getTargetableActors(targetFilter)
                if (allActors.isEmpty()) { return false }
                stack.addAll(allActors)
            }

            nextTarget = stack.removeFirst()
            if (targetFilter.isValidTarget(playerActor.id, nextTarget.id)) { break }
        }

        if (subTarget) {
            playerActor.subTarget = nextTarget.id
        } else {
            GameEngine.submitTargetUpdate(playerActor.id, nextTarget.id)
            ZoneNpcTool.printTargetInfo()
        }

        if (playSound) { AudioManager.playSystemSoundEffect(SystemSound.TargetCycle) }
        return true
    }

    fun onStartSelectingSubTarget(targetFilter: TargetFilter): Actor? {
        val player = ActorManager.player()

        val allTargets = getTargetableActors(targetFilter)
        if (allTargets.isEmpty()) { return null }

        val target = ActorStateManager[player.target]

        val shouldPreferPlayer = target == null || (target.isEnemy() && targetFilter.isValidTarget(player.id, player.id))
        val preferredTarget = if (shouldPreferPlayer) { player.id } else { target?.id }

        if (preferredTarget != null && targetFilter.isValidTarget(player.id, preferredTarget)) {
            val headList = allTargets.takeWhile { it.id != preferredTarget }
            allTargets.removeAll(headList)
        }

        player.subTarget = allTargets.removeFirst().id
        subTargetStack.addAll(allTargets)

        return ActorManager[player.subTarget]
    }

    fun updateSubTarget(targetFilter: TargetFilter): Boolean {
        val player = ActorManager.player()

        val currentTarget = ActorManager[player.subTarget]
        if (currentTarget == null || !targetFilter.isValidTarget(player.id, currentTarget.id)) {
            player.subTarget = null
            return false
        }

        return true
    }

    fun onFinishedSelectingSubTarget() {
        subTargetStack.clear()
    }

    private fun getTargetableActors(targetFilter: TargetFilter) : MutableList<Actor> {
        val player = ActorManager.player()
        val playerPosition = player.displayPosition
        val party = PartyManager[player]

        return ActorManager.getVisibleActors().asSequence()
            .filter { targetFilter.isValidTarget(player.id, it.id) }
            .filter { !ZoneNpcTool.isForceHidden(it.id) }
            .sortedBy { Vector3f.distance(playerPosition, it.displayPosition) }
            .sortedBy { if (party.contains(it) || it.getState().owner == player.id) { 1 } else { 0 } }
            .toMutableList()
    }

    private fun tabTargetFilter(sourceId: ActorId, targetId: ActorId): Boolean  {
        if (sourceId == targetId) {
            return false
        }

        return steadyStateFilter(sourceId, targetId)
    }

    private fun steadyStateFilter(sourceId: ActorId, targetId: ActorId): Boolean {
        val sourceState = ActorStateManager[sourceId] ?: return false

        val target = ActorManager[targetId] ?: return false
        val targetState = ActorStateManager[targetId] ?: return false

        if (targetState.isMount) {
            return false
        }

        if (targetState.isDead() && target.isDisplayInvisible()) {
            return false
        }

        if (targetState.isDead() && targetState.isGatheringNode()) {
            return false
        }

        if (target.isDisplayedDead() && (targetState.isEnemy() || targetState.isDependent())) {
            return false
        }

        if (targetState.type == ActorType.Effect && targetState.name.isBlank()) {
            return false
        }

        if (Vector3f.distance(sourceState.position, targetState.position) > 50f) {
            return false
        }

        return true
    }

    fun tryDirectlyTarget(actor: Actor?): Boolean {
        if (UiStateHelper.hasActiveUi()) { return false }

        val playerActor = ActorManager.player()
        if (playerActor.isTargetLocked()) {
            return false
        }

        if (actor == null) {
            if (playerActor.target != null) {
                GameEngine.submitClearTarget(playerActor.id)
                return true
            }
            return false
        }

        if (!steadyStateFilter(playerActor.id, actor.id)) {
            return false
        }

        if (playerActor.target == actor.id) {
            UiStateHelper.handleTargetConfirm()
        } else {
            GameEngine.submitTargetUpdate(playerActor.id, actor.id)
            targetStack.clear()
        }

        AudioManager.playSystemSoundEffect(SystemSound.TargetCycle)
        return true
    }

}