package xim.poc

import xim.math.Vector3f
import xim.poc.audio.AudioManager
import xim.poc.browser.DatLoader
import xim.poc.game.*
import xim.poc.gl.ByteColor
import xim.poc.tools.PlayerLookTool
import xim.resource.*
import xim.resource.table.FileTableManager
import xim.resource.table.MainDll
import xim.resource.table.NpcInfo
import xim.resource.table.NpcTable
import xim.util.OnceLogger.warn
import kotlin.math.PI
import kotlin.math.abs
import kotlin.math.sign
import kotlin.time.Duration.Companion.seconds

data class ActorId(val id: Int)

private enum class EngageAnimationState {
    NotEngaged,
    Engaged,
    Engaging,
    Disengaging,
}

enum class Direction {
    None,
    Forward,
    Left,
    Right,
    Backward,
}


fun interface ReadyToDrawAction {
    fun invoke(actor: Actor)
}

class RenderState {
    var effectColor: ByteColor = ByteColor.half
    val wrapEffect = ActorWrapEffect()
}

class Actor private constructor(val id: ActorId) {

    companion object {
        fun createFrom(actorState: ActorState): Actor {
            return Actor(actorState.id).also { it.syncFromState() }
        }
    }

    private var displayDead = false
    private var displayDoorOpen = false
    private var displayResting = false

    private var synthesisAnimationState: SynthesisAnimationStateMachine? = null

    private val readyToDrawActions = ArrayList<ReadyToDrawAction>()
    private val routineQueue = ActorRoutineQueue(this)

    var displayFacingDir: Float = 0f
        private set

    private var turnAmount: Float = 0f

    val displayPosition = Vector3f()
    val displayPositionOffset = Vector3f()

    val currentVelocity: Vector3f = Vector3f()
    var stoppedMoving = false

    val target: ActorId?
        get() = getState().targetState.targetId

    var subTarget: ActorId? = null

    private var engageAnimationState = EngageAnimationState.NotEngaged

    val renderState = RenderState()

    private var displayLook = ModelLook.blank()
    var actorModel: ActorModel? = null
        private set

    private val boundingBoxRef = BoundingBoxRef()

    private val skeletonBoundingBox = ArrayList<BoundingBox>()

    private val hasDftIdle = DatLink<SkeletonAnimationResource>(DatId("dft0"))

    init {
        onReadyToDraw { startAutoRunParticles() }
    }

    fun syncFromState() {
        val state = getState()
        displayFacingDir = state.rotation
        displayPosition.copyFrom(state.position)

        update(0f)
        // TODO - dead, resting, synthesis, etc
    }

    fun update(elapsedFrames: Float) {
        val state = getState()

        updateVelocity(state.velocity)
        updateDestinationFacingDir(state.rotation)
        displayPosition.copyFrom(state.position + displayPositionOffset)

        if (readyToDrawActions.isNotEmpty() && isReadyToDraw()) {
            readyToDrawActions.forEach { it.invoke(this) }
            readyToDrawActions.clear()
        }

        routineQueue.update(elapsedFrames)

        updateModelDisplay()

        updateRestingDisplay(state)

        updateEngageDisplay(state)

        updateDoorDisplay(state)

        updateSynthesisAnimationState(synthesisAnimationState)

        updateFacingDir(elapsedFrames)
    }

    fun getBoundingBox(): BoundingBox {
        if (boundingBoxRef.referencePosition != displayPosition) {
            boundingBoxRef.boundingBox = BoundingBox.scaled(Scene.collisionBoxSize, Vector3f().copyFrom(displayPosition))
            boundingBoxRef.referencePosition.copyFrom(displayPosition)
        }

        return boundingBoxRef.boundingBox
    }

    fun getSkeletonBoundingBox(index: Int = 0): BoundingBox? {
        return skeletonBoundingBox.getOrNull(index)
    }

    fun updateSkeletonBoundingBoxes(box: List<BoundingBox>) {
        skeletonBoundingBox.clear()
        skeletonBoundingBox.addAll(box)
    }

    fun isPlayer(): Boolean {
        return id == ActorStateManager.playerId
    }

    fun getNpcInfo(): NpcInfo? {
        return getState().getNpcInfo()
    }

    fun isDead(): Boolean {
        return getState().isDead()
    }

    fun isDisplayedDead(): Boolean {
        return displayDead
    }

    fun startRangedAttack(targetId: ActorId) {
        enqueueModelRoutine(DatId("calg"), options = RoutineOptions(highPriority = true))
    }

    fun startCasting(spellInfo: SpellInfo, targetId: ActorId) {
        val animationId = DatId.castId(spellInfo) ?: return
        enqueueModelRoutine(animationId, options = RoutineOptions(highPriority = true))
    }

    fun startUsingItem(inventoryItemInfo: InventoryItemInfo, targetId: ActorId) {
        enqueueModelRoutine(DatId.castId(inventoryItemInfo), options = RoutineOptions(highPriority = true))
    }

    fun readySkill() {
        enqueueModelRoutine(DatId("cate"), options = RoutineOptions(highPriority = true))
    }

    fun isCasting(): Boolean {
        return getState().isCasting()
    }

    fun transitionToIdle(transitionTime: Float) {
        actorModel?.forceTransitionToIdle(idleId = getIdleAnimationId(), transitionTime = transitionTime, animationDirs = getAllAnimationDirectories())
    }

    fun getIdleAnimationId(): DatId {
        val model = actorModel?.model
        val isChocobo = model is PcModel && model.raceGenderConfig.chocobo
        if (isChocobo || getState().isMount) { return DatId("chi?") }

        val mountDef = getState().mountedState?.getInfo()
        if (mountDef != null) { return DatId("${mountDef.poseType}un?") }

        if (isStaticNpc() && hasDftIdle()) {
            return DatId("dft?")
        }

        return if (engageAnimationState == EngageAnimationState.Engaged || engageAnimationState == EngageAnimationState.Disengaging) {
            DatId("btl?")
        } else {
            DatId("idl?")
        }
    }

    private fun hasDftIdle(): Boolean {
        if (!isReadyToDraw()) { return false }
        hasDftIdle.getOrPut { getAllAnimationDirectories().firstNotNullOfOrNull { d -> d.getNullableChildRecursivelyAs(it, SkeletonAnimationResource::class) } }
        return hasDftIdle.getIfPresent() != null
    }

    fun startSynthesis(crystalType: SynthesisType) {
        synthesisAnimationState = SynthesisAnimationStateMachine(this, crystalType)
    }

    fun endSynthesis(result: SynthesisState.SynthesisResult) {
        synthesisAnimationState?.transitionToComplete(result)
    }

    private fun updateSynthesisAnimationState(currentStateMachine: SynthesisAnimationStateMachine?) {
        currentStateMachine?.update()
        if (currentStateMachine?.isComplete() == true) { synthesisAnimationState = null }
    }

    private fun engage() {
        engageAnimationState = EngageAnimationState.Engaged
    }

    private fun disengage() {
        engageAnimationState = EngageAnimationState.NotEngaged
    }

    fun fadeAway() {
        playRoutine(DatId.disappear) { renderState.effectColor = ByteColor.zero }
    }

    fun onRevive() {
        displayDead = false
        actorModel?.clearAnimations()
        transitionToIdle(0f)
    }

    fun isFullyOutOfCombat(): Boolean {
        return engageAnimationState == EngageAnimationState.NotEngaged
    }

    fun isDisplayEngaged(): Boolean {
        return engageAnimationState == EngageAnimationState.Engaged
    }

    fun isDisplayEngagedOrEngaging(): Boolean {
        return engageAnimationState == EngageAnimationState.Engaged || engageAnimationState == EngageAnimationState.Engaging
    }

    fun getAllAnimationDirectories() : List<DirectoryResource> {
        val model = actorModel?.model ?: return emptyList()

        if (isFullyOutOfCombat()) { return model.getAnimationDirectories() }

        val main = model.getMainBattleAnimationDirectory()
        val sub = model.getSubBattleAnimationDirectory()

        return listOfNotNull(main, sub) + model.getAnimationDirectories()
    }

    fun isReadyToDraw() : Boolean {
        val model = actorModel?.model ?: return false

        val mountReady = ActorManager[getMount()?.id]?.isReadyToDraw() ?: true
        if (!mountReady) { return false }

        if (isFullyOutOfCombat()) { return model.isReadyToDraw() }

        if (model.getMainBattleAnimationDirectory() == null) { return false }
        if (isDualWield() && model.getSubBattleAnimationDirectory() == null) { return false }

        return true
    }

    fun getMovementDirection(): Direction {
        if (!isTargetLocked()) { return Direction.None }
        val targetDirection = getState().getTargetDirectionVector() ?: return Direction.None

        val movementVelocity = Vector3f().copyFrom(currentVelocity).also { it.y = 0f }
        if (movementVelocity.magnitudeSquare() <= 1e-5) {
            return Direction.None
        }

        val normalizedVelocity = movementVelocity.normalizeInPlace()
        val cosAngle = normalizedVelocity.dot(targetDirection)

        // Prefer to run forward > horizontal > backward
        return if (cosAngle >= 0.25f ) {
            return Direction.Forward
        } else if (cosAngle >= -0.75f) {
            val right = targetDirection.cross(Vector3f.UP).normalizeInPlace()
            val horizontalCosAngle = normalizedVelocity.dot(right)
            if (horizontalCosAngle >= 0f) {
                Direction.Right
            } else {
                Direction.Left
            }
        } else {
            Direction.Backward
        }
    }

    fun getMovementAnimation(): DatId {
        return when (getMovementDirection()) {
            Direction.None, Direction.Forward -> DatId("run?")
            Direction.Left -> DatId("mvl?")
            Direction.Right -> DatId("mvr?")
            Direction.Backward -> DatId("mvb?")
        }
    }

    fun getJointPosition(index: Int): Vector3f {
        return actorModel?.getJointPosition(index) ?: Vector3f.ZERO
    }

    fun getWorldSpaceJointPosition(index: Int): Vector3f {
        return displayPosition + getJointPosition(index)
    }

    private fun updateDestinationFacingDir(dest: Float) {
        turnAmount = dest - displayFacingDir
        if (abs(turnAmount) > PI ) {
            turnAmount = if (sign(turnAmount) == -1f ) {
                turnAmount + 2 * PI.toFloat()
            } else {
                turnAmount - 2 * PI.toFloat()
            }
        }
    }

    private fun updateFacingDir(elapsedFrames: Float) {
        if (abs(turnAmount) == 0f) { return }

        val stepAmount = sign(turnAmount) * elapsedFrames * PI.toFloat() / 20f
        if (abs(turnAmount) < abs(stepAmount) ) {
            displayFacingDir += turnAmount
            turnAmount = 0f
            return
        }

        displayFacingDir += stepAmount
        turnAmount -= stepAmount

        if (displayFacingDir < -PI) {
            displayFacingDir += 2 * PI.toFloat()
        } else if (displayFacingDir > PI) {
            displayFacingDir -= 2 * PI.toFloat()
        }
    }

    fun isDualWield(): Boolean {
        return getState().isDualWield()
    }

    fun isHandToHand(): Boolean {
        return getState().isHandToHand()
    }

    private fun updateModelDisplay() {
        val state = getState()
        val stateLook = state.getCurrentLook()

        val needsNewModel = displayLook.type != stateLook.type || displayLook.modelId != stateLook.modelId

        displayLook = stateLook.copy()
        val currentModel = actorModel?.model

        if (needsNewModel) {
            if (currentModel is PcModel) { currentModel.updateEquipment(EquipmentLook()) } // Clear equipment effects
            actorModel = createActorModel()
            onReadyToDraw { transitionToIdle(0f) }
        } else if (state.type == ActorType.Pc && currentModel is PcModel) {
            val equipmentLook = PlayerLookTool.getDebugOverride(this) ?: stateLook.equipment
            currentModel.updateEquipment(equipmentLook)
        }
    }

    private fun createActorModel(): ActorModel? {
        return when (displayLook.type) {
            0 -> {
                val additionalAnimationId = FileTableManager.getFilePath(NpcTable.getAdditionalAnimationId(displayLook))
                val npcModel = NpcModel.fromNpcLook(displayLook, listOfNotNull(additionalAnimationId)) ?: return null
                ActorModel(this, npcModel)
            }
            1 -> {
                displayLook.race ?: return null
                ActorModel(this, PcModel(displayLook, this))
            }
            2, 3 -> {
                val datId = getNpcInfo()?.datId ?: return null
                val scene = SceneManager.getCurrentScene()
                ActorModel(this, ZoneObjectModel(datId, scene))
            }
            ModelLook.fileTableIndexType -> {
                val npcModel = NpcModel.fromNpcLook(displayLook) ?: return null
                ActorModel(this, npcModel)
            }
            else -> {
                null
            }
        }
    }

    fun getEquipment(equipSlot: EquipSlot) : InventoryItem? {
        return getState().getEquipment(equipSlot)
    }

    fun enqueueRoutine(context: ActorContext, routine: EffectRoutineResource, options: RoutineOptions = RoutineOptions()) {
        routineQueue.enqueueRoutine(context, options, routine)
    }

    fun enqueueModelRoutine(routineId: DatId, actorContext: ActorContext = ActorContext(id), options: RoutineOptions = RoutineOptions()) {
        routineQueue.enqueueRoutine(actorContext, options) { findAnimationRoutine(routineId) }
    }

    fun enqueueModelRoutineIfReady(routineId: DatId, actorContext: ActorContext = ActorContext(id), options: RoutineOptions = RoutineOptions()) {
        routineQueue.enqueueRoutine(actorContext, options, findAnimationRoutine(routineId))
    }

    fun hasEnqueuedRoutines(): Boolean {
        return routineQueue.hasEnqueuedRoutines()
    }

    fun playRoutine(routineId: DatId, routineCompleteCallback: RoutineCompleteCallback? = null): EffectRoutineInstance? {
        val routine = findAnimationRoutine(routineId)

        return if (routine == null) {
            routineCompleteCallback?.onComplete()
            null
        } else {
            playRoutine(routine).also { it.onComplete = routineCompleteCallback }
        }
    }

    private fun playRoutine(routine: EffectRoutineResource): EffectRoutineInstance {
        return EffectManager.registerActorRoutine(this, ActorContext(this.id), routine)
    }

    fun loopRoutine(routine: DatId): EffectRoutineInstance? {
        val output = playRoutine(routine)
        output?.repeatOnSequencesCompleted = true
        return output
    }

    fun playEmote(mainId: Int, subId: Int) {
        val raceGenderConfig = displayLook.race ?: return
        val fileIndexId = MainDll.getBaseEmoteAnimationIndex(raceGenderConfig) + mainId

        val filePath = FileTableManager.getFilePath(fileIndexId)
        if (filePath == null) {
            warn("Couldn't resolve emote: $mainId/$subId")
            return
        }

        DatLoader.load(filePath).onReady {
            val emoteId = DatId("em0${subId}")
            val routine = it.getAsResource().getNullableChildRecursivelyAs(emoteId, EffectRoutineResource::class)

            if (routine != null) {
                EffectManager.registerActorRoutine(this, ActorContext(this.id), routine)
            }
        }
    }

    private fun findAnimationRoutine(routineId: DatId): EffectRoutineResource? {
        return actorModel?.model?.getAnimationDirectories()?.firstNotNullOfOrNull { it.getNullableChildAs(routineId, EffectRoutineResource::class) }
            ?: GlobalDirectory.directoryResource.getNullableChildRecursivelyAs(routineId, EffectRoutineResource::class)
    }

    fun onReadyToDraw(readyToDrawAction: ReadyToDrawAction) {
        readyToDrawActions += readyToDrawAction
    }

    private fun playSoundPrefixed(prefix: String) {
        val randomMatch = getAllAnimationDirectories()
            .flatMap { it.collectByType(SoundPointerResource::class) }
            .filter { it.id.id.startsWith(prefix) }
            .randomOrNull() ?: return

        AudioManager.playSoundEffect(randomMatch, ActorAssociation(this, ActorContext(id)), positionFn = { displayPosition })
    }

    fun getPetId() = getState().pet

    fun getMount() = getState().mountedState

    private fun startAutoRunParticles() {
        val model = actorModel?.model
        if (model !is NpcModel) { return }
        if (getState().owner != null) { return }

        val association = ActorAssociation(this, context = ActorContext(id))
        model.resource.getAsResource().collectByTypeRecursive(EffectResource::class)
            .filter { pg -> pg.particleGenerator.autoRun }
            .forEach { pg -> EffectManager.registerEffect(association, pg) }
    }

    fun isStaticNpc(): Boolean {
        return getState().isStaticNpc()
    }

    fun isDoor(): Boolean {
        return getState().isDoor()
    }

    fun isElevator(): Boolean {
        return getState().isElevator()
    }

    private fun updateRestingDisplay(state: ActorState) {
        if (displayResting && !state.isResting()) {
            displayResting = false
            enqueueModelRoutineIfReady(DatId.stopResting)
        } else if (!displayResting && state.isResting()) {
            displayResting = true
            enqueueModelRoutineIfReady(DatId.startResting)
        }
    }

    private fun updateDoorDisplay(state: ActorState) {
        if (displayDoorOpen && !state.doorState.open) {
            displayDoorOpen = false
            enqueueModelRoutineIfReady(DatId.close)
        } else if (!displayDoorOpen && state.doorState.open) {
            displayDoorOpen = true
            enqueueModelRoutineIfReady(DatId.open)
        }
    }

    private fun updateEngageDisplay(state: ActorState) {
        if (state.isEngaged() && engageAnimationState == EngageAnimationState.NotEngaged) {
            onEngage()
        } else if (!state.isEngaged() && engageAnimationState == EngageAnimationState.Engaged) {
            onDisengage()
        }
    }

    private fun onEngage() {
        engageAnimationState = EngageAnimationState.Engaging

        if (getState().isEnemy()) {
            transitionToIdle(7.5f)
            playSoundPrefixed("idl")
        }

        routineQueue.enqueueItemModelRoutine(ItemModelSlot.Main, DatId("!w00"))
        routineQueue.enqueueItemModelRoutine(ItemModelSlot.Sub, DatId("!w10"))

        val mainWeaponInfo = actorModel?.model?.getMainWeaponInfo()
        if (mainWeaponInfo != null) {
            routineQueue.enqueueMainBattleRoutine(DatId("in ${mainWeaponInfo.weaponAnimationSubType}")) { engage() }
        } else {
            engage()
        }

        routineQueue.enqueueItemModelRoutine(ItemModelSlot.Main, DatId("!w01"))
        routineQueue.enqueueItemModelRoutine(ItemModelSlot.Sub, DatId("!w11"))
    }

    private fun onDisengage() {
        engageAnimationState = EngageAnimationState.Disengaging

        routineQueue.enqueueItemModelRoutine(ItemModelSlot.Main, DatId("!w02"))
        routineQueue.enqueueItemModelRoutine(ItemModelSlot.Sub, DatId("!w12"))

        if (isDead()) {
            disengage()
            return
        }

        if (isPlayer()) { UiStateHelper.popActionContext() }

        val mainWeaponInfo = actorModel?.model?.getMainWeaponInfo()
        val onComplete = { disengage() }

        if (mainWeaponInfo != null) {
            routineQueue.enqueueMainBattleRoutine(DatId("out${mainWeaponInfo.weaponAnimationSubType}"), callback = onComplete)
        } else {
            onComplete.invoke()
        }
    }

    fun onDisplayDeath() {
        displayDead = true
        val deadRoutine = if (getState().owner != null) { DatId("sdep") } else { DatId("dead") }
        enqueueModelRoutineIfReady(deadRoutine)
    }

    fun onAttackMainHand(attackContext: AttackContext) {
        val routine = when(getMovementDirection()) {
            Direction.None -> actorModel?.model?.getMainAttackIds()?.randomOrNull() ?: DatId("ati0")
            Direction.Forward -> DatId("atf0")
            Direction.Left -> DatId("atl0")
            Direction.Right -> DatId("atr0")
            Direction.Backward -> DatId("atb0")
        }

        val options = RoutineOptions(blocking = false, expiryDuration = 2.seconds)
        routineQueue.enqueueMainBattleRoutine(DatId("atk0"), attackContext, options)
        routineQueue.enqueueMainBattleRoutine(routine, attackContext, options)
    }

    fun onAttackSubHand(attackContext: AttackContext) {
        val subRoutine = when(getMovementDirection()) {
            Direction.None -> actorModel?.model?.getSubAttackIds()?.randomOrNull() ?: DatId("bti0")
            Direction.Forward -> DatId("btf0")
            Direction.Left -> DatId("btl0")
            Direction.Right -> DatId("btr0")
            Direction.Backward -> DatId("btb0")
        }

        val options = RoutineOptions(blocking = false, expiryDuration = 2.seconds)
        routineQueue.enqueueMainBattleRoutine(DatId("atk0"), attackContext, options)
        routineQueue.enqueueSubBattleRoutine(subRoutine, attackContext, options)
    }

    fun onAttackH2H(attackContext: AttackContext) {
        // Left-punch, Right-kick, Left-kick
        val subAttack = listOf(DatId("bti0"), DatId("cti0"), DatId("dti0")).random()

        val options = RoutineOptions(blocking = false, expiryDuration = 2.seconds)
        routineQueue.enqueueMainBattleRoutine(DatId("atk0"), attackContext, options)
        routineQueue.enqueueMainBattleRoutine(subAttack, attackContext, options)
    }

    fun getState(): ActorState {
        return ActorStateManager[this.id]!!
    }

    private fun updateVelocity(newVelocity: Vector3f) {
        stoppedMoving = currentVelocity.magnitudeSquare() > 0f && newVelocity.magnitudeSquare() == 0f
        currentVelocity.copyFrom(newVelocity)
    }

    fun isTargetLocked(): Boolean {
        return getState().targetState.locked
    }

    fun isDisplayInvisible(): Boolean {
        return renderState.effectColor.a == 0
    }

    fun isMovementOrAnimationLocked(): Boolean {
        return actorModel?.isMovementLocked() == true || actorModel?.isAnimationLocked() == true
    }

}
