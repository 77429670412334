package xim.poc.game

import xim.poc.ActorId
import xim.poc.game.event.InitialActorState

object ActorStateManager {

    private var idCounter = 0
    val playerId = nextId()

    private val actorStates = HashMap<ActorId, ActorState>()

    fun getAll(): Collection<ActorId> {
        return actorStates.keys
    }

    fun filter(fn: (ActorState) -> Boolean): Collection<ActorState> {
        return actorStates.values.filter { fn.invoke(it) }
    }

    fun create(initialActorState: InitialActorState): ActorState {
        val actorId = initialActorState.presetId ?: nextId()
        val state = ActorState(actorId, initialActorState)
        actorStates[actorId] = state
        return state
    }

    fun delete(actorId: ActorId) {
        actorStates.remove(actorId)
    }

    operator fun get(actorId: ActorId?): ActorState? {
        return actorStates[actorId]
    }

    fun clear() {
        val player = player()
        val preserveIds = setOfNotNull(player.id, player.bubble, player.mountedState?.id)
        actorStates.entries.removeAll { !preserveIds.contains(it.key) }
    }

    fun player(): ActorState {
        return this[playerId] ?: throw IllegalStateException("Player wasn't created?")
    }

    fun playerTarget(): ActorState? {
        return get(player().targetState.targetId)
    }

    fun nextId(): ActorId {
        val id = ActorId(idCounter)
        idCounter += 1
        return id
    }

}