package xim.resource.table

import xim.resource.SpellInfo

object TrustTable {

    fun getModelId(spellInfo: SpellInfo): Int {
        val offsetId = spellInfo.index - 896

        return 0xBB8 + when {
            offsetId <= 51 -> offsetId
            offsetId == 52 -> 0x35
            offsetId == 53 -> 0x36
            offsetId == 54 -> 0x37
            offsetId == 55 -> 0x38
            offsetId == 56 -> 0x39
            offsetId == 57 -> 0x3A
            offsetId == 58 -> 0x3C
            offsetId == 59 -> 0x3D
            offsetId == 60 -> 0x3E
            offsetId == 61 -> 0x3B
            offsetId == 62 -> 0x43
            offsetId == 63 -> 0x44
            offsetId == 64 -> 0x45
            offsetId == 65 -> 0x46
            offsetId == 66 -> 0x47
            offsetId == 67 -> 0x48
            offsetId == 68 -> 0x49
            offsetId == 69 -> 0x4A
            offsetId == 70 -> 0x4B
            offsetId == 71 -> 0x4C
            offsetId == 72 -> 0x4D
            offsetId == 73 -> 0x4E
            offsetId == 74 -> 0x4F
            offsetId == 75 -> 0x50
            offsetId == 76 -> 0x57
            offsetId == 77 -> 0x58
            offsetId == 78 -> 0x59
            offsetId == 79 -> 0x5A
            offsetId == 80 -> 0x5B
            offsetId == 81 -> 0x5C
            offsetId == 82 -> 0x5D
            offsetId == 83 -> 0x5E
            offsetId == 84 -> 0x5F
            offsetId == 85 -> 0x60
            offsetId == 86 -> 0x62
            offsetId == 87 -> 0x63
            offsetId == 88 -> 0x64
            offsetId == 89 -> 0x65
            offsetId == 90 -> 0x67
            offsetId == 91 -> 0x69
            offsetId == 92 -> 0x6A
            offsetId == 93 -> 0x6B
            offsetId == 94 -> 0x6C
            offsetId == 95 -> 0x6D
            offsetId == 96 -> 0x71
            offsetId == 97 -> 0x72
            offsetId == 98 -> 0X73
            offsetId == 99 -> 0x74
            offsetId == 100 -> 0x75
            offsetId == 101 -> 0x6F
            offsetId == 102 -> 0x76
            offsetId == 103 -> 0x78
            offsetId == 106 -> 0x77
            offsetId == 107 -> 0x79
            offsetId == 108 -> 0x34
            offsetId == 109 -> 0x3F
            offsetId == 110 -> 0x40
            offsetId == 111 -> 0x41
            offsetId == 112 -> 0x42
            offsetId == 113 -> 0x51
            offsetId == 114 -> 0x56
            offsetId == 115 -> 0x52
            offsetId == 116 -> 0x53
            offsetId == 117 -> 0x54
            offsetId == 118 -> 0x61
            offsetId == 119 -> 0x68
            offsetId == 120 -> 0x66
            offsetId == 121 -> 0x55
            offsetId == 122 -> 0x70
            offsetId == 123 -> 0x6E
            else -> throw IllegalStateException("Unknown trust: $spellInfo")
        }
    }
}