package xim.poc.game

enum class AugmentId(val id: Int) {
    HP(9),
    MP(10),
    STR(11),
    DEX(12),
    VIT(13),
    AGI(14),
    INT(15),
    MND(16),
    CHR(17),

    CriticalHitRate(26),
    EnemyCriticalHitRate(27),

    Haste(32),

    PhysicalDamageTaken(37),

    Regen(137),
    Refresh(138),

    FastCast(140),
    StoreTp(142),
    DoubleAttack(143),
    DualWield(146),

    WeaponSkillDamage(327),
    CriticalHitDamage(328),

    AlterEgo(2042),
}

enum class StatusEffect(val id: Int) {
    Stun(10),

    Haste(33),
    BlazeSpikes(34),
    IceSpikes(35),

    Enfire(94),
    Enblizzard(95),

    Costume(127),

    Dia(134),

    Encumbrance(259),

    Visitant(285),

    DoubleUpChance(308),
    Bust(309),
    FightersRoll(310),

    Indicolure(612),
}

enum class AbilityId(val id: Int) {
    FastBlade(32),
    BurningBlade(33),
    RedLotusBlade(34),

    Provoke(547),
    BloodPactRage(603),
    PhantomRoll(609),
    FightersRoll(610),
    DoubleUp(635),
    QuickDraw(636),
    BloodPactWard(684),
    Samba(694),
    Waltz(695),
    Step(710),
    FlourishI(711),
    Jig(712),
    FlourishII(725),
    FlourishIII(775),

    AutoRegen(1545),
    AutoRefresh(1546),
    FastCast(1548),
    StoreTp(1550),
    DoubleAttack(1551),
    DualWield(1554),
}

enum class ItemIds(val id: Int) {
    IcarusWing(4213),
    CopseCandy(6008),
}