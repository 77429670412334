package xim.poc.game.event

import xim.poc.ActorId
import xim.poc.game.ActorStateManager
import xim.poc.game.AttackContext
import xim.poc.game.GameEngine
import xim.poc.game.SynthesisState
import xim.poc.ui.ChatLog
import xim.poc.ui.ShiftJis

class ActorDamagedEvent(
    val sourceId: ActorId,
    val targetId: ActorId,
    val amount: Int,
    val actionContext: AttackContext? = null,
): Event {

    override fun apply(): List<Event> {
        val sourceState = ActorStateManager[sourceId] ?: return emptyList()

        val targetState = ActorStateManager[targetId] ?: return emptyList()
        if (targetState.isDead()) { return emptyList() }

        targetState.consumeHp(amount)
        val defeatedTarget = targetState.isDead()

        val outEvents = ArrayList<Event>()
        outEvents += RestingEndEvent(targetId)

        if (targetState.getSynthesisState()?.canInterrupt() == true) {
            outEvents += SynthesisCompleteEvent(targetId, SynthesisState.SynthesisResult.Break)
        }

        if (defeatedTarget) { outEvents += ActorDefeatedEvent(defeated = targetId, defeatedBy = sourceId, actionContext = actionContext) }

        if (!defeatedTarget) {
            sourceState.pet?.let { outEvents += BattleEngageEvent(it, targetId) }
            GameEngine.getTrusts(sourceId).forEach { outEvents += BattleEngageEvent(it.id, targetId) }
        }

        if (targetState.isEnemy() && !targetState.isEngaged()) {
            outEvents += ActorDismountEvent(sourceId)
            outEvents += BattleEngageEvent(targetId, sourceId)
        } else if (!targetState.isEnemy() && targetState.targetState.targetId == null) {
            outEvents += ActorTargetEvent(targetId, sourceId)
        }

        actionContext?.composeCallback {
            var line = "${sourceState.name}${ShiftJis.rightArrow}${targetState.name} $amount damage"
            line += if (actionContext.criticalHit()) { "!" } else { "." }
            if (actionContext.magicBurst) { line = "[Magic Burst!] $line"}
            ChatLog.addLine(line)
        }

        return outEvents
    }

}