package xim.poc.game.configuration.zones

import xim.math.Vector3f
import xim.poc.game.configuration.*

data class SpawnArea(val position: Vector3f, val size: Vector3f, val chestPosition: Vector3f? = null)

class FloorDefinition(
    val gatheringPoints: List<GatheringConfiguration>,
    val easyMobTypes: List<MonsterDefinition>,
    val easyMobLocations: List<SpawnArea>,
) {

    fun toZoneLogic(): ZoneLogic {
        val spawners = ArrayList<MonsterSpawnerDefinition>()

        val easyMobs = easyMobTypes.shuffled().toMutableList()
        for (loc in easyMobLocations) {
            val easyMob = easyMobs.removeLastOrNull() ?: break
            spawners += MonsterSpawnerDefinition(
                spawnArea = loc,
                table = toTable(easyMob),
                maxMonsters = 8,
                spawnDelay = 0f,
                maxSpawnCount = 8,
            )
        }

        return ZoneLogic(spawners, gatheringPoints)
    }

    private fun toTable(mobDefinition: MonsterDefinition): MonsterTable {
        return MonsterTable(listOf(WeightedMonsterDef(mobDefinition, 1.0)))
    }

}