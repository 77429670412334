package xim.poc.game.event

import xim.math.Vector3f
import xim.poc.ActorId
import xim.poc.ActorManager
import xim.poc.SceneManager
import xim.poc.game.ActorStateManager
import xim.poc.game.GameEngine
import xim.poc.game.UiStateHelper
import xim.poc.tools.DestinationZoneConfig
import xim.poc.tools.ZoneChanger

class ChangeZoneEvent(
    val sourceId: ActorId,
    val destination: DestinationZoneConfig
): Event {

    override fun apply(): List<Event> {
        val events = ArrayList<Event>()

        val state = ActorStateManager[sourceId] ?: return emptyList()
        events += GameEngine.releaseDependents(state)

        val currentZoneConfig = SceneManager.getCurrentScene().config

        if (currentZoneConfig.zoneId == destination.zoneConfig.zoneId) {
            ZoneChanger.onZoneIn()
        } else {
            state.zone = ZoneSettings(destination.zoneConfig.zoneId, subAreaId = null)
        }

        if (destination.options.fullyRevive) {
            state.setHp(state.getMaxMp())
            state.setMp(state.getMaxMp())
            ActorManager[sourceId]?.onRevive()
        }

        UiStateHelper.clear()

        return events
    }

}