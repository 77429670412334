package xim.poc.game.configuration.zones

import xim.poc.ModelLook
import xim.poc.NoOpActorController
import xim.poc.game.StatusEffect
import xim.poc.game.configuration.ActorBehaviors
import xim.poc.game.configuration.MonsterDefinition

val mandyDef = MonsterDefinition(id = 0x001, "Mandy", ModelLook.npc(0x012C), mobSkills = listOf(44, 45, 46, 48, 49, 50))

val crawlyDef = MonsterDefinition(id = 0x003, "Crawler", ModelLook.npc(0x018C), mobSkills = listOf(88, 89, 90))

val gobbieDef = MonsterDefinition(id = 0x004, "Gobbie", ModelLook.npc(0x01E5), mobSkills = listOf(334, 335, 336))
val yaguDef = MonsterDefinition(id = 0x005, "Yagudo", ModelLook.npc(0x0246), mobSkills = listOf(361, 362, 363, 364))
val birdDef = MonsterDefinition(id = 0x006, "Bird", ModelLook.npc(0x01BD), mobSkills = listOf(366, 367))
val dhalDef = MonsterDefinition(id = 0x007, "Dhalmel", ModelLook.npc(0x014C), mobSkills = listOf(24, 25, 28, 29, 30, 31))

val fireElem = MonsterDefinition(id = 0x008, "Fire", ModelLook.npc(0x01B4))  { it.getState().gainStatusEffect(StatusEffect.BlazeSpikes.id); it.getState().gainStatusEffect(StatusEffect.Enfire.id) }
val iceElem = MonsterDefinition(id = 0x009, "Ice", ModelLook.npc(0x01B5)) { it.getState().gainStatusEffect(StatusEffect.IceSpikes.id); it.getState().gainStatusEffect(StatusEffect.Enblizzard.id) }
val earthElem = MonsterDefinition(id = 0x00A, "Earth", ModelLook.npc(0x01B7))

val dummyBox = MonsterDefinition(id = 0x00B, "Dummy", ModelLook.npc(0x091B), behaviorId = ActorBehaviors.NoAction, movementController = NoOpActorController()) {
    it.getState().setMaxHp(999)
    it.getState().setHp(999)
}