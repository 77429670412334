package xim.poc.game.configuration

import xim.math.Vector3f
import xim.poc.ActorId
import xim.poc.ModelLook
import xim.poc.game.ActorStateManager
import xim.poc.game.configuration.zones.CustomSaru
import xim.poc.game.configuration.zones.EastSaru
import xim.resource.DatId
import xim.resource.table.Npc
import xim.resource.table.NpcInfo
import xim.resource.table.ZoneNpcList
import xim.resource.table.ZoneSettings

fun makeZonalId(zoneId: Int, id: Int): Int {
    if (id > 0x1000) { throw IllegalArgumentException("Zonal Id must be less than 0x1000") }
    return 0x01000000 or (zoneId shl 12) or (id)
}

fun basicNpc(zoneId: Int, npcId: Int, name: String, npcLook: Int, position: Vector3f, rotation: Float = 0f, spawnAnimations: List<DatId>? = null): Npc {
    val id = makeZonalId(zoneId, npcId)

    val info = NpcInfo(id,
        rotation = rotation,
        position = position,
        flag = 0,
        nameVis = 0,
        status = 0,
        entityFlags = 0,
        look = ModelLook.npc(npcLook),
        datId = null,
        spawnAnimations = spawnAnimations,
    )

    return Npc(id,name,info)
}

fun playerDistanceCheck(maxDistance: Float, id: ActorId): Boolean {
    val state = ActorStateManager[id] ?: return false
    val player = ActorStateManager.player()
    return Vector3f.distance(state.position, player.position) < maxDistance
}

fun interface NpcInteraction {
    fun onInteraction(npcId: ActorId)
}

data class CustomZoneDefinition(
    val zoneId: Int,
    val zoneMapId: Int? = null,
    val displayName: String? = null,
    val zoneResourcePath: String? = null,
    val zoneSettings: ZoneSettings? = null,
    val staticNpcList: ZoneNpcList? = null,
    val onLoad: () -> Unit = {},
)

object ZoneDefinitionManager {

    private val zones = mapOf(
        EastSaru.zoneId to EastSaru,
        CustomSaru.zoneId to CustomSaru,
    )

    init {
        zones.forEach { validateDefinition(it.value) }
    }

    operator fun get(zoneId: Int): CustomZoneDefinition? {
        return zones[zoneId]
    }

    private fun validateDefinition(definition: CustomZoneDefinition) {
        if (definition.staticNpcList != null) {
            val uniqueIds = definition.staticNpcList.npcs.map { it.id }.toSet()
            if (uniqueIds.size != definition.staticNpcList.npcs.size) { throw IllegalStateException("[${definition.zoneId}][${definition.displayName}] Npcs don't have unique IDs") }
        }
    }

}

class ZoneLogic(monsterSpawnerDefinitions: List<MonsterSpawnerDefinition>, gatheringPoints: List<GatheringConfiguration>) {

    private val monsterSpawners = monsterSpawnerDefinitions.map { MonsterSpawnerInstance(it) }
    private val gatheringPointSpawners = gatheringPoints.map { GatheringNodeSpawner(it) }

    fun setup() {
    }

    fun update(elapsed: Float) {
        gatheringPointSpawners.forEach { it.update(elapsed) }
        monsterSpawners.forEach { it.update(elapsed) }
    }

    fun cleanUp() {
        monsterSpawners.forEach { it.clear() }
        gatheringPointSpawners.forEach { it.clear() }
    }

}
