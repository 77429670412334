package xim.poc.game.event

import xim.poc.ActorId
import xim.poc.game.ActorStateManager

class RestingEndEvent(
    val sourceId: ActorId
): Event {

    override fun apply(): List<Event> {
        val actorState = ActorStateManager[sourceId] ?: return emptyList()
        actorState.getRestingState().stopResting()
        return emptyList()
    }

}