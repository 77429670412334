package xim.poc.game.event

import xim.poc.ActorId
import xim.poc.game.ActorStateManager
import xim.poc.ui.ChatLog
import xim.poc.ui.ShiftJis
import xim.resource.InventoryItems

class InventoryGainEvent(
    val sourceId: ActorId,
    val itemId: Int,
): Event {

    override fun apply(): List<Event> {
        val source = ActorStateManager[sourceId] ?: return emptyList()
        source.inventory.addItem(itemId)

        if (source.isPlayer()) {
            val inventoryItemInfo = InventoryItems[itemId]
            ChatLog.addLine("${source.name} obtains a ${ShiftJis.colorItem}${inventoryItemInfo.logName}${ShiftJis.colorClear}.")
        }

        return emptyList()
    }

}