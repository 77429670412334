package xim.poc.game.configuration

import xim.poc.SynthesisType

typealias RecipeId = Int

data class SynthesisRecipe(val id: RecipeId, val synthesisType: SynthesisType, val output: Int, val input: List<Int>) {
    init { SynthesisRecipes.register(id, this) }
}

object SynthesisRecipes {

    private val recipes = HashMap<RecipeId, SynthesisRecipe>()

    init {
        /*Brass Ingot*/ SynthesisRecipe(0x0001, SynthesisType.Fire, output = 650, input = listOf(642, 640, 640, 640))
        /*Brass Ring*/ SynthesisRecipe(0x0002, SynthesisType.Fire, output = 13465, input = listOf(650))
        /*Sardonyx Ring*/ SynthesisRecipe(0x0003, SynthesisType.Fire, output = 13444, input = listOf(13465))
        /*Orange Juice*/ SynthesisRecipe(0x0004, SynthesisType.Water, output = 4422, input = listOf(4392, 4392, 4392, 4392))
    }

    fun getAvailableRecipes(filter: SynthesisType): List<SynthesisRecipe> {
        return recipes.values.filter { it.synthesisType == filter }
    }

    operator fun get(recipeId: RecipeId): SynthesisRecipe? {
        return recipes[recipeId]
    }

    fun register(recipeId: RecipeId, recipe: SynthesisRecipe) {
        if (recipes.containsKey(recipeId)) { throw IllegalStateException("Recipe $recipeId is already defined by ${recipes[recipeId]}") }
        recipes[recipeId] = recipe
    }

}