package xim.poc.game.configuration

import xim.poc.ActionTargetFilter
import xim.poc.ActorId
import xim.poc.game.ActorState
import xim.poc.game.AttackContext
import xim.poc.game.AttackContexts
import xim.poc.game.configuration.SkillApplierHelper.SkillUsableEvaluator
import xim.poc.game.configuration.SkillApplierHelper.TargetEvaluator
import xim.poc.game.configuration.SkillApplierHelper.makeApplier
import xim.poc.game.event.Event
import xim.poc.ui.ChatLog
import xim.poc.ui.ChatLogColor
import xim.resource.AbilityInfo
import xim.resource.AoeType
import xim.resource.SpellInfo
import xim.resource.table.MobSkillInfo

enum class SkillFailureReason {
    OutOfRange,
    NotEngaged,
    NotEnoughTp,
    GenericFailure,
}

class ApplierResult(val events: List<Event>, val allTargetIds: List<ActorId>, val contexts: AttackContexts, val success: Boolean = true, val failureReason: SkillFailureReason? = null) {
    companion object {
        fun failure(reason: SkillFailureReason? = null): ApplierResult {
            return ApplierResult(events = emptyList(), allTargetIds = emptyList(), contexts = AttackContexts(emptyMap()), success = false, failureReason = reason)
        }
    }
}

enum class SkillType {
    Spell,
    MobSkill,
    Ability,
}

class SkillRangeInfo(val maxTargetDistance: Float, val effectRadius: Float, val type: AoeType)

class SkillInfo(val id: Int, val type: SkillType, val targetFlags: Int) {
    val targetFilter = ActionTargetFilter(targetFlags)
}

class SkillApplier(
    val skillId: Int,
    val skillType: SkillType,
    val targetEvaluator: TargetEvaluator,
    val validEvaluator: SkillUsableEvaluator = SkillUsableEvaluator.noop,
    val primaryTargetEvaluator: TargetEvaluator? = null,
    val additionalSelfEvaluator: TargetEvaluator? = null,
    val postEvaluation: TargetEvaluator? = null,
)

object SkillAppliers {

    private val mobSkillAppliers = HashMap<Int, SkillApplier>()
    private val spellSkillAppliers = HashMap<Int, SkillApplier>()
    private val abilitySkillAppliers = HashMap<Int, SkillApplier>()

    fun register(skillApplier: SkillApplier) {
        when (skillApplier.skillType) {
            SkillType.Spell -> spellSkillAppliers[skillApplier.skillId] = skillApplier
            SkillType.MobSkill -> mobSkillAppliers[skillApplier.skillId] = skillApplier
            SkillType.Ability -> abilitySkillAppliers[skillApplier.skillId] = skillApplier
        }
    }

    operator fun plusAssign(skillApplier: SkillApplier) = register(skillApplier)

    fun invoke(sourceState: ActorState, targetState: ActorState, abilityInfo: AbilityInfo): ApplierResult {
        val applier = abilitySkillAppliers[abilityInfo.index] ?: makeApplier(abilityInfo, TargetEvaluator.noop())
        val skillInfo = SkillInfo(abilityInfo.index, SkillType.Ability, abilityInfo.targetFlags)
        return invoke(sourceState, targetState, applier, skillInfo)
    }

    fun invoke(sourceState: ActorState, targetState: ActorState, spellInfo: SpellInfo): ApplierResult {
        val applier = spellSkillAppliers[spellInfo.index] ?: makeApplier(spellInfo, TargetEvaluator.noop())
        val skillInfo = getSkillInfo(spellInfo)
        return invoke(sourceState, targetState, applier, skillInfo)
    }

    fun invoke(sourceState: ActorState, targetState: ActorState, mobSkillInfo: MobSkillInfo): ApplierResult {
        val applier = mobSkillAppliers[mobSkillInfo.id]
        val skillInfo = getSkillInfo(mobSkillInfo)
        return invoke(sourceState, targetState, applier, skillInfo)
    }

    private fun getSkillInfo(mobSkillInfo: MobSkillInfo?): SkillInfo? {
        mobSkillInfo ?: return null
        return SkillInfo(mobSkillInfo.id, SkillType.MobSkill, mobSkillInfo.targetFlag)
    }

    private fun getSkillInfo(spellInfo: SpellInfo): SkillInfo {
        return SkillInfo(spellInfo.index, SkillType.Spell, spellInfo.targetFlags)
    }

    private fun invoke(sourceState: ActorState, targetState: ActorState, applier: SkillApplier?, info: SkillInfo?): ApplierResult {
        return if (applier != null && info != null) {
            val targets = SkillTargetEvaluator.evaluateTargets(info, sourceState, targetState)
            if (targets.failureReason != null) { return ApplierResult.failure(targets.failureReason) }
            SkillApplierRunner.applySkill(info, applier, sourceState, targets)
        } else {
            val context = AttackContext.noop()
            ApplierResult(events = emptyList(), allTargetIds = listOf(targetState.id), contexts = AttackContexts.single(targetState.id, context))
        }
    }

    fun writeFailureReason(source: ActorState, target: ActorState, skillName: String, skillFailureReason: SkillFailureReason?) {
        skillFailureReason ?: return

        val string = when (skillFailureReason) {
            SkillFailureReason.OutOfRange -> "${target.name} is out of range."
            SkillFailureReason.NotEngaged -> "${source.name} must be engaged to use $skillName."
            SkillFailureReason.NotEnoughTp -> "${source.name} does not have enough TP to use $skillName."
            SkillFailureReason.GenericFailure -> "${source.name} failed to use $skillName."
        }

        ChatLog(string, ChatLogColor.Info)
    }

}

object SkillApplierHelper {

    class TargetEvaluatorContext(
        val skillInfo: SkillInfo,
        val sourceState: ActorState,
        val targetState: ActorState,
        val primaryTargetState: ActorState,
        val context: AttackContext,
    )

    fun interface TargetEvaluator {
        fun getEvents(context: TargetEvaluatorContext): List<Event>

        companion object {
            fun compose(vararg evaluators: TargetEvaluator): TargetEvaluator {
                return TargetEvaluator { c -> evaluators.flatMap { it.getEvents(c) } }
            }

            fun noop(): TargetEvaluator {
                return TargetEvaluator { emptyList() }
            }

        }
    }

    class SkillUsableEvaluatorContext(
        val skillInfo: SkillInfo,
        val sourceState: ActorState,
    )

    fun interface SkillUsableEvaluator {
        fun isUsable(context: SkillUsableEvaluatorContext): SkillFailureReason?

        companion object {
            val noop = SkillUsableEvaluator { null }
        }
    }

    fun makeApplier(id: Int, type: SkillType, targetEvaluator: TargetEvaluator): SkillApplier {
        return SkillApplier(skillId = id, skillType = type, targetEvaluator = targetEvaluator)
    }

    fun makeApplier(spellInfo: SpellInfo, targetEvaluator: TargetEvaluator) = makeApplier(spellInfo.index, SkillType.Spell, targetEvaluator)

    fun makeApplier(abilityInfo: AbilityInfo, targetEvaluator: TargetEvaluator) = makeApplier(abilityInfo.index, SkillType.Ability, targetEvaluator)

}

