package xim.poc.game.configuration

import xim.math.Vector3f
import xim.poc.SceneManager
import xim.poc.game.*
import xim.poc.game.configuration.v0.SpawnerChestDefinition
import xim.poc.game.event.InitialActorState
import xim.poc.gl.ByteColor
import xim.resource.DatId
import xim.util.PI_f
import xim.util.RandHelper.rand

data class SpawnArea(val position: Vector3f, val size: Vector3f)

data class MonsterSpawnerDefinition(
    val spawnArea: SpawnArea,
    val table: WeightedTable<MonsterDefinition>,
    val maxMonsters: Int,
    val spawnDelay: Float,
    val maxSpawnCount: Int? = null,
    val chestDefinition: SpawnerChestDefinition? = null,
)

class MonsterSlot {
    var spawnDelay = 0f
    var monster: MonsterInstance? = null
}

class MonsterSpawnerInstance(val definition: MonsterSpawnerDefinition) {

    private val slots = Array(definition.maxMonsters) { MonsterSlot() }

    private var totalSpawned = 0
    private var totalDefeated = 0

    fun update(elapsed: Float) {
        refreshMonsters(elapsed)
    }

    fun clear() {
        slots.mapNotNull { it.monster }.forEach { GameEngine.submitDeleteActor(it.actorId) }
        slots.forEach { it.monster = null }
    }

    fun getTotalDefeated(): Int {
        return totalDefeated
    }

    fun consumeProgress(amount: Int) {
        totalDefeated -= amount
        totalDefeated = totalDefeated.coerceAtLeast(0)
    }

    fun getProgress(): Pair<Int, Int>? {
        if (definition.maxSpawnCount == null) { return null }
        return Pair(totalDefeated, definition.maxSpawnCount)
    }

    fun hasDefeatedAllMonsters(): Boolean {
        val progress = getProgress() ?: return false
        return progress.first == progress.second
    }

    private fun refreshMonsters(elapsedFrames: Float) {
        for (slot in slots) {
            val currentMonster = slot.monster

            // Empty slot - waiting for spawn delay
            if (currentMonster == null) {
                slot.spawnDelay -= elapsedFrames
                if (slot.spawnDelay < 0f && slot.monster == null) { spawnMonster(slot) }
                continue
            }

            // Active monster
            val monsterState = ActorStateManager[currentMonster.actorId]
            if (monsterState != null && !monsterState.isDead()) { continue }

            // Defeat/deleted monster - reset for respawn
            totalDefeated += 1
            slot.monster = null
            slot.spawnDelay = definition.spawnDelay
        }
    }

    private fun spawnMonster(slot: MonsterSlot) {
        if (definition.maxSpawnCount != null && totalSpawned >= definition.maxSpawnCount) { return }
        totalSpawned += 1

        val monsterDefinition = definition.table.getRandom()

        val size = definition.spawnArea.size
        val position = definition.spawnArea.position + Vector3f(size.x * rand(), size.y * rand() - 5f, size.z * rand())
        val snappedPosition = SceneManager.getCurrentScene().getNearestFloor(position) ?: position

        GameEngine.submitCreateActorState(
            InitialActorState(
                monsterId = monsterDefinition.id,
                name = monsterDefinition.name,
                type = ActorType.Enemy,
                position = snappedPosition,
                modelLook = monsterDefinition.look,
                rotation = 2 * PI_f * rand(),
                movementController = monsterDefinition.movementController,
                behaviorController = monsterDefinition.behaviorId,
            )
        ) {
            it.renderState.effectColor = ByteColor.zero

            it.onReadyToDraw {
                it.transitionToIdle(0f)
                it.playRoutine(DatId.pop)
                it.actorModel?.customModelSettings = monsterDefinition.customModelSettings
            }

            slot.monster = MonsterInstance(monsterDefinition, it.id)
            monsterDefinition.onSpawn?.invoke(it)
        }

    }

}