package xim.poc.game.event

import xim.math.Vector3f
import xim.poc.*
import xim.poc.game.*
import xim.poc.game.configuration.ActorBehaviors
import xim.poc.game.configuration.BehaviorId
import xim.poc.game.configuration.GatheringConfiguration
import xim.poc.game.configuration.MonsterId
import xim.resource.table.NpcInfo

data class MountSettings(
    val index: Int,
    val riderId: ActorId,
)

data class TrustSettings(
    val ownerId: ActorId
)

data class PetSettings(
    val ownerId: ActorId
)

data class BubbleSettings(
    val ownerId: ActorId
)

data class JobSettings(
    val mainJob: Job,
    val subJob: Job?,
)

data class ZoneSettings(
    val zoneId: Int,
    val subAreaId: Int?,
)

data class InitialActorState(
    val name: String,
    val position: Vector3f,
    val modelLook: ModelLook,
    val type: ActorType,
    val rotation: Float = 0f,
    val zoneSettings: ZoneSettings? = null,
    val behaviorController: BehaviorId = ActorBehaviors.AutoAttackOnly,
    val movementController: ActorController = NoOpActorController(),
    val presetId: ActorId? = null,
    val equipment: Equipment? = null,
    val mountSettings: MountSettings? = null,
    val trustSettings: TrustSettings? = null,
    val petSettings: PetSettings? = null,
    val bubbleSettings: BubbleSettings? = null,
    val jobSettings: JobSettings? = null,
    val jobLevels: JobLevels? = null,
    val inventory: Inventory? = null,
    val gatheringConfiguration: GatheringConfiguration? = null,
    val npcInfo: NpcInfo? = null,
    val monsterId: MonsterId? = null,
)

fun interface CreateDisplayCallback {
    fun onCreated(actor: Actor)
}

class ActorCreateEvent(
    val initialActorState: InitialActorState,
    val createDisplayCallback: CreateDisplayCallback? = null
): Event {

    override fun apply(): List<Event> {
        val state = ActorStateManager.create(initialActorState)

        val outputEvents = ArrayList<Event>()

        if (initialActorState.mountSettings != null) {
            val rider = ActorStateManager[initialActorState.mountSettings.riderId] ?: return emptyList()
            rider.mountedState = Mount(initialActorState.mountSettings.index, state.id)
        } else if (initialActorState.trustSettings != null) {
            val party = PartyManager[initialActorState.trustSettings.ownerId]
            party.addMember(state.id)
        } else if (initialActorState.petSettings != null) {
            val owner = ActorStateManager[state.owner]
            owner?.pet = state.id
        } else if (initialActorState.bubbleSettings != null) {
            val owner = ActorStateManager[initialActorState.bubbleSettings.ownerId]
            owner?.bubble = state.id
        }

        if (initialActorState.equipment != null) {
            outputEvents += ActorEquipEvent(state.id, initialActorState.equipment.getAllItems())
        }

        if (initialActorState.jobSettings != null) {
            state.jobState.mainJob = initialActorState.jobSettings.mainJob
            state.jobState.subJob = initialActorState.jobSettings.subJob
        }

        if (initialActorState.jobLevels != null) {
            state.jobLevels.copyFrom(initialActorState.jobLevels)
        }

        if (initialActorState.inventory != null) {
            state.inventory.copyFrom(initialActorState.inventory)
        }

        if (initialActorState.gatheringConfiguration != null) {
            state.setMaxHp(3)
        }

        outputEvents += ActorUpdateBaseLookEvent(state.id, initialActorState.modelLook)

        if (createDisplayCallback != null) {
            val actor = ActorManager.getOrCreate(state)
            createDisplayCallback.onCreated(actor)
        }

        return outputEvents
    }

}