package xim.poc.game.event

import xim.poc.ActorId
import xim.poc.ActorManager
import xim.poc.game.ActorState
import xim.poc.game.ActorStateManager
import xim.poc.game.GameEngine
import xim.poc.game.SynthesisState
import xim.poc.game.configuration.SynthesisRecipe
import xim.poc.game.configuration.SynthesisRecipes
import xim.poc.ui.ChatLog
import xim.poc.ui.ChatLogColor
import xim.poc.ui.ShiftJis
import xim.resource.InventoryItems
import kotlin.math.min

class SynthesisCompleteEvent(
    val sourceId: ActorId,
    val result: SynthesisState.SynthesisResult,
): Event {

    override fun apply(): List<Event> {
        val actorState = ActorStateManager[sourceId] ?: return emptyList()
        val synthesisState = actorState.getSynthesisState() ?: return emptyList()
        val recipe = SynthesisRecipes[synthesisState.recipeId] ?: return emptyList()

        var finalResult = result

        val currentMax = GameEngine.getMaximumCraftable(sourceId, recipe)
        if (currentMax < synthesisState.quantity) { finalResult = SynthesisState.SynthesisResult.Break }

        consumeItems(actorState, synthesisState, recipe)

        if (finalResult != SynthesisState.SynthesisResult.Break) {
            actorState.inventory.addItem(recipe.output, synthesisState.quantity)
            val outputInfo = InventoryItems[recipe.output]
            ChatLog("${actorState.name} synthesized a ${ShiftJis.colorItem}${outputInfo.logName}${ShiftJis.colorClear}.", ChatLogColor.Info)
        }

        synthesisState.setResult(finalResult)

        val actor = ActorManager[sourceId]
        actor?.endSynthesis(finalResult)

        return emptyList()
    }

    private fun consumeItems(actorState: ActorState, synthesisState: SynthesisState, recipe: SynthesisRecipe) {
        val inventory = actorState.inventory

        for (inputId in recipe.input) {
            var remainingToRemove = synthesisState.quantity
            val matching = inventory.inventoryItems.filter { it.id == inputId }

            for (match in matching) {
                if (actorState.isEquipped(match)) { continue }

                val toRemove = min(match.quantity, remainingToRemove)
                match.quantity -= toRemove
                if (match.quantity == 0) { inventory.discardItem(match.internalId) }

                remainingToRemove -= toRemove
                if (remainingToRemove == 0) { break }
            }
        }
    }

}