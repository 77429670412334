package xim.poc.game.configuration

import xim.poc.game.StatusEffect
import xim.poc.game.configuration.SkillApplierHelper.makeApplier
import xim.poc.game.configuration.StandardSkillEvaluators.basicElementDamage
import xim.poc.game.configuration.StandardSkillEvaluators.basicEnhancingMagicStatusEffect
import xim.poc.game.configuration.StandardSkillEvaluators.basicHealingMagic
import xim.poc.game.configuration.StandardSkillEvaluators.sourceHpPercentDamage
import xim.poc.game.configuration.StandardSkillEvaluators.summonBubble
import xim.poc.game.configuration.StandardSkillEvaluators.summonLuopan
import xim.poc.game.configuration.StandardSkillEvaluators.summonPet
import xim.poc.game.configuration.StandardSkillEvaluators.summonTrust
import xim.resource.MagicType
import xim.resource.table.SpellInfoTable
import xim.resource.table.SpellNameTable
import kotlin.time.Duration.Companion.seconds

class SpellSkill(val id: Int, val applier: SkillApplier) {
    init { SpellSkills.definitions += this }
}

object SpellSkills {

    val definitions = ArrayList<SpellSkill>()
    
    init {
        // Cure
        SpellSkill(id = 1, applier = makeApplier(
            targetEvaluator = basicHealingMagic(PotencyConfiguration(potency = 2f)),
        ))

        // Cure II
        SpellSkill(id = 2, applier = makeApplier(
            targetEvaluator = basicHealingMagic(PotencyConfiguration(potency = 4f)),
        ))

        // Curaga
        SpellSkill(id = 7, applier = makeApplier(
            targetEvaluator = basicHealingMagic(PotencyConfiguration(potency = 2f)),
        ))

        // Enfire
        SpellSkill(id = 100, applier = makeApplier(
            targetEvaluator = basicEnhancingMagicStatusEffect(status = StatusEffect.Enfire, baseDuration = 30.seconds),
        ))

        // Enblizzard
        SpellSkill(id = 101, applier = makeApplier(
            targetEvaluator = basicEnhancingMagicStatusEffect(status = StatusEffect.Enblizzard, baseDuration = 30.seconds),
        ))

        // Fire
        SpellSkill(id = 144, applier = makeApplier(
            targetEvaluator = basicElementDamage(PotencyConfiguration(potency = 1f)),
        ))

        // Blizzard
        SpellSkill(id = 149, applier = makeApplier(
            targetEvaluator = basicElementDamage(PotencyConfiguration(potency = 20f)),
        ))

        // Firaga
        SpellSkill(id = 174, applier = makeApplier(
            targetEvaluator = basicElementDamage(PotencyConfiguration(potency = 1f)),
        ))

        // Blizzaga
        SpellSkill(id = 179, applier = makeApplier(
            targetEvaluator = basicElementDamage(PotencyConfiguration(potency = 20f)),
        ))

        // Blaze Spikes
        SpellSkill(id = 249, applier = makeApplier(
            targetEvaluator = basicEnhancingMagicStatusEffect(status = StatusEffect.BlazeSpikes, baseDuration = 30.seconds),
        ))

        // Ice Spikes
        SpellSkill(id = 250, applier = makeApplier(
            targetEvaluator = basicEnhancingMagicStatusEffect(status = StatusEffect.IceSpikes, baseDuration = 30.seconds),
        ))

        // Fire Spirit
        SpellSkill(id = 288, applier = makeApplier(
            targetEvaluator = summonPet(lookId = 0x08),
        ))

        // Ice Spirit
        SpellSkill(id = 289, applier = makeApplier(
            targetEvaluator = summonPet(lookId = 0x09),
        ))

        // Air Spirit
        SpellSkill(id = 290, applier = makeApplier(
            targetEvaluator = summonPet(lookId = 0x0A),
        ))

        // Earth Spirit
        SpellSkill(id = 291, applier = makeApplier(
            targetEvaluator = summonPet(lookId = 0x0B),
        ))

        // Thunder Spirit
        SpellSkill(id = 292, applier = makeApplier(
            targetEvaluator = summonPet(lookId = 0x0D),
        ))

        // Water Spirit
        SpellSkill(id = 293, applier = makeApplier(
            targetEvaluator = summonPet(lookId = 0x0C),
        ))

        // Light Spirit
        SpellSkill(id = 294, applier = makeApplier(
            targetEvaluator = summonPet(lookId = 0x0E),
        ))

        // Dark Spirit
        SpellSkill(id = 295, applier = makeApplier(
            targetEvaluator = summonPet(lookId = 0x0F),
        ))

        // Carbuncle
        SpellSkill(id = 296, applier = makeApplier(
            targetEvaluator = summonPet(lookId = 0x10),
        ))

        // Fenrir
        SpellSkill(id = 297, applier = makeApplier(
            targetEvaluator = summonPet(lookId = 0x11),
        ))

        // Ifrit
        SpellSkill(id = 298, applier = makeApplier(
            targetEvaluator = summonPet(lookId = 0x12),
        ))

        // Titan
        SpellSkill(id = 299, applier = makeApplier(
            targetEvaluator = summonPet(lookId = 0x13),
        ))

        // Leviathan
        SpellSkill(id = 300, applier = makeApplier(
            targetEvaluator = summonPet(lookId = 0x14),
        ))

        // Garuda
        SpellSkill(id = 301, applier = makeApplier(
            targetEvaluator = summonPet(lookId = 0x15),
        ))

        // Shiva
        SpellSkill(id = 302, applier = makeApplier(
            targetEvaluator = summonPet(lookId = 0x16),
        ))

        // Ramuh
        SpellSkill(id = 303, applier = makeApplier(
            targetEvaluator = summonPet(lookId = 0x17),
        ))

        // Diabolos
        SpellSkill(id = 304, applier = makeApplier(
            targetEvaluator = summonPet(lookId = 0x19),
        ))

        // Odin
        SpellSkill(id = 305, applier = makeApplier(
            targetEvaluator = summonPet(lookId = 0x1A),
        ))

        // Alexander
        SpellSkill(id = 306, applier = makeApplier(
            targetEvaluator = summonPet(lookId = 0x1B),
        ))

        // Cait Sith
        SpellSkill(id = 307, applier = makeApplier(
            targetEvaluator = summonPet(lookId = 0x1C),
        ))

        // Siren
        SpellSkill(id = 355, applier = makeApplier(
            targetEvaluator = summonPet(lookId = 0x1E),
        ))

        // Self Destruct
        SpellSkill(id = 533, applier = makeApplier(
            targetEvaluator = sourceHpPercentDamage(PotencyConfiguration(potency = 0.5f)),
            additionalSelfEvaluator = sourceHpPercentDamage(PotencyConfiguration(potency = 1f)),
        ))

        // Atomos
        SpellSkill(id = 847, applier = makeApplier(
            targetEvaluator = summonPet(lookId = 0x1D),
        ))

        // All Geo spells (debug)
        (0 until 1024)
            .map { SpellInfoTable[it] }
            .filter { it.magicType == MagicType.Geomancy }
            .filter { SpellNameTable.first(it.index).startsWith("Geo") }
            .map { SpellSkill(id = it.index, applier = makeApplier(targetEvaluator = summonLuopan(it))) }

        // All Indi spells (debug)
        (0 until 1024)
            .map { SpellInfoTable[it] }
            .filter { it.magicType == MagicType.Geomancy }
            .filter { SpellNameTable.first(it.index).startsWith("Indi") }
            .map { SpellSkill(id = it.index, applier = makeApplier(targetEvaluator = summonBubble(it))) }

        // Trust Spells (debug)
        (0 until 1024)
            .map { SpellInfoTable[it] }
            .filter { it.magicType == MagicType.Trust }
            .map { SpellSkill(id = it.index, applier = makeApplier(targetEvaluator = summonTrust(it))) }
    }
    
}