package xim.poc.game.configuration.v0.behaviors

import xim.poc.game.ActorState
import xim.poc.game.configuration.ActorBehaviorController
import xim.poc.game.configuration.AutoAttackController
import xim.poc.game.configuration.SkillApplier
import xim.poc.game.configuration.SkillType
import xim.poc.game.configuration.v0.DamageCalculator
import xim.poc.game.event.ActorDamagedEvent
import xim.poc.game.event.CastMobSkillStart
import xim.poc.game.event.Event
import xim.resource.table.MobSkillInfoTable
import kotlin.math.roundToInt

object MobClusterBehavior {

    fun selfDestruct(id: Int, destState: Int?): SkillApplier {
        return SkillApplier(skillId = id, skillType = SkillType.MobSkill,
            additionalSelfEvaluator = {
                if (destState != null) { it.sourceState.appearanceState = destState }
                val dmg = if (destState == null) { it.sourceState.getHp() } else { (it.sourceState.getHp() / 3f).roundToInt() }
                listOf(ActorDamagedEvent(it.sourceState.id, it.sourceState.id, dmg, it.context))
            },
            targetEvaluator = {
                val baseDmg = if (destState == null) { it.sourceState.getHp() } else { (it.sourceState.getHp() / 3f).roundToInt() }
                val statRatio = DamageCalculator.computeIntRatio(it.sourceState, it.targetState)
                val dmg = (baseDmg * statRatio).roundToInt()
                listOf(ActorDamagedEvent(it.sourceState.id, it.targetState.id, dmg, it.context))
            },
            postEvaluation = {
                it.sourceState.setTp(0)
                emptyList()
            }
        )
    }

}

class MobClusterController(val actorState: ActorState): ActorBehaviorController {

    private val autoAttackDelegate = AutoAttackController(actorState)

    override fun update(elapsedFrames: Float): List<Event> {
        if (eligibleToUseSkill()) {
            val results = useSkill()
            if (results.isNotEmpty()) { return results }
        }

        return autoAttackDelegate.update(elapsedFrames)
    }

    private fun eligibleToUseSkill(): Boolean {
        return actorState.isIdleOrEngaged() && actorState.getTp() > 120
    }

    private fun getValidSkills(): List<Int> {
        val skills = ArrayList<Int>()

//        skills += 313
//        if (actorState.appearanceState < 2) { skills += listOf(311, 312, 314) }

        if (actorState.appearanceState == 0) {
            if (actorState.getHpp() < 0.66f) { skills += 315 }
            if (actorState.getHpp() < 0.20f) { skills += 316 }
        }

        if (actorState.appearanceState == 1) {
            if (actorState.getHpp() < 0.33f) { skills += 317 }
            if (actorState.getHpp() < 0.20f) { skills += 318 }
        }

        if (actorState.appearanceState == 2) {
            if (actorState.getHpp() < 0.20f) { skills += 319 }
        }

        return skills
    }

    private fun useSkill(): List<Event> {
        val randomSkillId = getValidSkills().randomOrNull() ?: return emptyList()
        val mobSkillInfo = MobSkillInfoTable[randomSkillId] ?: return emptyList()

        val targetFilter = mobSkillInfo.targetFilter
        val targetId = (if (targetFilter.targetFilter(actorState.id, actorState.id)) {
            actorState.id
        } else if (targetFilter.targetFilter(actorState.id, actorState.targetState.targetId)) {
            actorState.targetState.targetId
        } else {
            null
        }) ?: return emptyList()

        return listOf(CastMobSkillStart(actorState.id, targetId, randomSkillId))
    }

}
