package xim.poc.game.configuration

import xim.poc.game.StatusEffect
import xim.poc.game.configuration.AbilityCostType.Tp
import xim.poc.game.configuration.SkillApplierHelper.makeApplier
import xim.poc.game.configuration.StandardSkillEvaluators.consumesAllTpWeaponSkill
import xim.poc.game.configuration.StandardSkillEvaluators.fixedCostWeaponSkill
import xim.poc.game.configuration.StandardSkillEvaluators.releasePet
import xim.poc.game.configuration.StandardSkillEvaluators.sourceHpPercentDamage
import xim.poc.game.event.*

enum class AbilityCostType {
    Tp,
}

class AbilityCost(val type: AbilityCostType, val value: Int)

class AbilitySkill(val id: Int, val abilityCost: AbilityCost? = null, val applier: SkillApplier) {
    init { AbilitySkills.definitions += this }
}

object AbilitySkills {

    val definitions = ArrayList<AbilitySkill>()
    val definitionsById: Map<Int, AbilitySkill>

    init {

        // Fast Blade
        AbilitySkill(id = 32, abilityCost = AbilityCost(Tp, 240), applier = fixedCostWeaponSkill())

        // Fast Blade
        AbilitySkill(id = 34, abilityCost = AbilityCost(Tp, 480), applier = consumesAllTpWeaponSkill())

        // Mijin Gakure
        AbilitySkill(id = 540, applier = makeApplier(
            targetEvaluator = sourceHpPercentDamage(PotencyConfiguration(potency = 0.5f)),
            additionalSelfEvaluator = sourceHpPercentDamage(PotencyConfiguration(potency = 1f)),
        ))

        // Provoke
        AbilitySkill(id = 547, applier = SkillApplier(
            targetEvaluator = { listOf(BattleEngageEvent(it.targetState.id, it.sourceState.id)) }
        ))

        // Call Wyvern
        AbilitySkill(id = 573, applier = makeApplier {
            listOf(PetReleaseEvent(it.sourceState.id), PetSummonEvent(it.sourceState.id, name = "Wyvern", lookId = 0x18))
        })

        // Assault
        AbilitySkill(600, applier = makeApplier {
            val petId = it.sourceState.pet
            if (petId != null) { listOf(BattleEngageEvent(petId, it.targetState.id)) } else { emptyList() }
        })

        // Retreat
        AbilitySkill(601, applier = makeApplier {
            val petId = it.sourceState.pet
            if (petId != null) { listOf(BattleDisengageEvent(petId)) } else { emptyList() }
        })

        // Release
        AbilitySkill(602, applier = makeApplier(releasePet()))

        // Fighter's Roll
        AbilitySkill(id = 610, applier = makeCorsairRoll(StatusEffect.FightersRoll))

        // Double-up
        AbilitySkill(id = 635, applier = makeCorsairDoubleUp())

        definitionsById = definitions.associateBy { it.id }
    }

    private fun makeCorsairRoll(statusEffect: StatusEffect): SkillApplier {
        return SkillApplier(
            validEvaluator = {
                if (it.sourceState.getStatusEffect(StatusEffect.DoubleUpChance) != null) { "Double-Up is active!" } else { null }
            },
            primaryTargetEvaluator = {
                listOf(CorsairRollEvent(abilityId = it.skillInfo.id, statusId = statusEffect.id, sourceId = it.sourceState.id, context = it.context)) },
            targetEvaluator = {
                listOf(CorsairRollTargetEvent(statusId = statusEffect.id, rollerId = it.sourceState.id, targetId = it.targetState.id, context = it.context))
            })
    }

    private fun makeCorsairDoubleUp(): SkillApplier {
        return SkillApplier(
            validEvaluator = {
                if (it.sourceState.getStatusEffect(StatusEffect.DoubleUpChance) == null) { "No active roll!" } else { null }
            },
            primaryTargetEvaluator = {
                val doubleUpState = it.sourceState.getStatusEffect(StatusEffect.DoubleUpChance) ?: return@SkillApplier emptyList()
                listOf(CorsairRollEvent(abilityId = it.skillInfo.id, statusId = doubleUpState.linkedStatusId, sourceId = it.sourceState.id, context = it.context)) },
            targetEvaluator = {
                val doubleUpState = it.sourceState.getStatusEffect(StatusEffect.DoubleUpChance) ?: return@SkillApplier emptyList()
                listOf(CorsairRollTargetEvent(statusId = doubleUpState.linkedStatusId, rollerId = it.sourceState.id, targetId = it.targetState.id, context = it.context))
            })
    }

}