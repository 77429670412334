package xim.poc.game.configuration.zones

import xim.math.Vector3f
import xim.poc.*
import xim.poc.game.ActorStateManager
import xim.poc.game.GameState
import xim.poc.game.QueryMenuOption
import xim.poc.game.StatusEffect.*
import xim.poc.game.UiStateHelper
import xim.poc.game.configuration.*
import xim.poc.gl.ByteColor
import xim.resource.table.ZoneNpcList
import xim.resource.table.ZoneSettings
import xim.util.Fps
import kotlin.time.Duration.Companion.hours

private const val zoneId = 0x1001


//private val gate0 = basicNpc(zoneId, npcId = 0x002, name = "", npcLook = 0x0964, position = Vector3f(x=-81.37f, y=-3.49f, z=-568.05f), rotation = PI_f/2f, spawnAnimations = emptyList())
//private val gate1 = basicNpc(zoneId, npcId = 0x003, name = "", npcLook = 0x0964, position = Vector3f(x=-74.16f, y=-3.82f, z=-568.26f), rotation = PI_f/2f, spawnAnimations = emptyList())

//private val maw = basicNpc(zoneId, npcId = 0x004, name = "Cavernous Maw", npcLook = 0x0913, position = Vector3f(x=-39.42f,y=4.0f,z=-679.26f), rotation = PI_f)

private val entrance = basicNpc(zoneId, npcId = 0x001, name = "Entrance", npcLook = 0x0975, position = Vector3f(x=-155.40f,y=4.02f,z=-684.68f))

private val conflux00 = basicNpc(zoneId, npcId = 0x005, name = "Conflux #00", npcLook = 0x0962, position = Vector3f(x=-87.57f,y=-4.85f,z=-559.39f))
private val conflux01 = basicNpc(zoneId, npcId = 0x006, name = "Conflux #01", npcLook = 0x0962, position = Vector3f(x=-127.94f,y=-4.94f,z=-438.77f))

private val staticNpcs = listOf(entrance, conflux00, conflux01)

private val staticNpcList = ZoneNpcList(
    resourceId = "",
    npcs = staticNpcs,
    npcsByDatId = emptyMap(),
)

private val miningPoint = GatheringConfiguration(
    type = GatheringType.Mining,
    positions = listOf(
        Vector3f(x=-82.80f, y=0.09f, z=-612.00f),
        Vector3f(x=-90.55f, y=1.84f, z=-628.33f),
        Vector3f(x=-78.45f, y=3.07f, z=-636.91f),
        Vector3f(x=-90.63f, y=2.85f, z=-641.63f),
        Vector3f(x=-87.98f, y=2.91f, z=-639.02f),
    ),
    items = listOf(
        GatheringNodeItem(itemId = 640, weight = 1.0),
        GatheringNodeItem(itemId = 641, weight = 1.0),
        GatheringNodeItem(itemId = 642, weight = 1.0),
    )
)

private val loggingPoint = GatheringConfiguration(
    type = GatheringType.Logging,
    positions = listOf(
        Vector3f(x=-69.53f, y=2.65f, z=-677.63f),
        Vector3f(x=-73.78f, y=3.18f, z=-685.42f),
        Vector3f(x=-81.13f, y=3.00f, z=-679.50f),
        Vector3f(x=-110.00f, y=3.36f, z=-649.98f),
    ),
    items = listOf(
        GatheringNodeItem(itemId = 688, weight = 1.0),
        GatheringNodeItem(itemId = 689, weight = 1.0),
        GatheringNodeItem(itemId = 698, weight = 1.0),
    )
)

private val harvestingPoint = GatheringConfiguration(
    type = GatheringType.Harvesting,
    positions = listOf(
        Vector3f(x=-95.58f, y=3.04f, z=-642.17f),
        Vector3f(x=-92.67f, y=3.41f, z=-636.59f),
        Vector3f(x=-103.63f, y=2.97f, z=-643.53f),
        Vector3f(x=-96.43f, y=2.10f, z=-627.20f),
    ),
    items = listOf(
        GatheringNodeItem(itemId = 833, weight = 1.0),
        GatheringNodeItem(itemId = 834, weight = 1.0),
        GatheringNodeItem(itemId = 835, weight = 1.0),
    )
)

private val floor0Definition = FloorDefinition(
    gatheringPoints = listOf(loggingPoint, miningPoint, harvestingPoint),
    easyMobTypes = listOf(mandyDef),
    easyMobLocations = listOf(
        SpawnArea(position = Vector3f(-76f, -5f, -520f), size = Vector3f(20f, 0f, 20f), chestPosition = Vector3f(x=-95.68f,y=-4.96f,z=-536.21f))
    ),
)

private fun setupZoneLogic(floorDefinition: FloorDefinition) {
    GameState.setZoneLogic(floorDefinition.toZoneLogic())
}

private object EntranceInteraction: NpcInteraction {

    override fun onInteraction(npcId: ActorId) {
        if (!playerDistanceCheck(3f, npcId)) { return }
        UiStateHelper.openQueryMode("What will you do?", getOptions(), callback = this::handleQueryResponse)
    }

    private fun getOptions(): List<QueryMenuOption> {
        val options = ArrayList<QueryMenuOption>()
        options += QueryMenuOption("Do not use.", value = -1)
        options += QueryMenuOption("Enter!", value = 1)
        return options
    }

    private fun handleQueryResponse(response: QueryMenuOption?) {
        if (response == null || response.value < 0) { return }

        val playerAssociation = ActorAssociation(ActorManager.player())

        val destination = Vector3f(conflux00.info.position)
        destination.z -= 1f

        val script = EventScript(listOf(
            EffectRoutineEventItem(fileTableIndex = 0x113c8, playerAssociation, eagerlyComplete = true),
            WaitRoutine(Fps.secondsToFrames(1.9f)),
            WarpSameZoneEventItem(destination) {
                setupZoneLogic(floor0Definition)
                ActorStateManager.player().gainStatusEffect(Visitant, duration = 1.hours)
                ActorManager.player().renderState.effectColor = ByteColor.half
            },
        ))

        EventScriptRunner.runScript(script)
    }
}

private object ConfluxInteraction: NpcInteraction {

    override fun onInteraction(npcId: ActorId) {
        if (!playerDistanceCheck(3f, npcId)) { return }
        UiStateHelper.openQueryMode("What will you do?", getOptions(npcId), callback = this::handleQueryResponse)
    }

    private fun getOptions(npcId: ActorId): List<QueryMenuOption> {
        val options = ArrayList<QueryMenuOption>()
        options += QueryMenuOption("Do not use.", value = -1)
        if (npcId != conflux00.actorId) { options += QueryMenuOption("Warp to Conflux #00.", value = 0) }
        if (npcId != conflux01.actorId) { options += QueryMenuOption("Warp to Conflux #01.", value = 1) }

        return options
    }

    private fun handleQueryResponse(response: QueryMenuOption?) {
        if (response == null || response.value < 0) { return }

        val playerAssociation = ActorAssociation(ActorManager.player())

        val conflux = listOf(conflux00, conflux01)

        val destination = Vector3f(conflux[response.value].info.position)
        destination.z -= 1f

        val script = EventScript(listOf(
            EffectRoutineEventItem(fileTableIndex = 0x11372, playerAssociation, eagerlyComplete = true),
            WaitRoutine(Fps.secondsToFrames(1.33f)),
            WarpSameZoneEventItem(destination),
            EffectRoutineEventItem(fileTableIndex = 0x11373, playerAssociation, eagerlyComplete = true),
            WaitRoutine(Fps.secondsToFrames(1.33f)),
        ))

        EventScriptRunner.runScript(script)
    }
}

private val npcInteractions = mapOf(
    entrance.actorId to EntranceInteraction,
    conflux00.actorId to ConfluxInteraction,
    conflux01.actorId to ConfluxInteraction,
)

val CustomSaru = CustomZoneDefinition(
    zoneId = zoneId,
    zoneMapId = 116,
    displayName = "Custom Sarutabaruta",
    zoneResourcePath = "ROM/1/Custom.DAT",
    zoneSettings = ZoneSettings(zoneId, musicId = 51, battleSoloMusicId = 51, battlePartyMusicId = 51),
    staticNpcList = staticNpcList,
) {
    setupZoneLogic(floor0Definition)
    GameState.registerInteractions(npcInteractions)
}