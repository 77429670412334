package xim.poc.game.event

import xim.math.Matrix4f
import xim.math.Vector3f
import xim.poc.ActorId
import xim.poc.ModelLook
import xim.poc.NoOpActorController
import xim.poc.PetController
import xim.poc.game.ActorStateManager
import xim.poc.game.ActorType
import xim.poc.gl.ByteColor
import xim.resource.DatId

class PetSummonEvent(
    val ownerId: ActorId,
    val lookId: Int,
    val name: String,
    val entranceAnimation: DatId = DatId("spop"),
    val stationary: Boolean = false,
): Event {

    override fun apply(): List<Event> {
        val ownerState = ActorStateManager[ownerId] ?: return emptyList()

        val position = Vector3f().copyFrom(ownerState.position)
        position += Matrix4f().rotateYInPlace(ownerState.rotation).transformInPlace(Vector3f(2f, 0f, 0f))

        val npcLook = ModelLook.npc(lookId)
        val controller = if (stationary) { NoOpActorController() } else { PetController() }

        val initialActorState = InitialActorState(
            name = name,
            type = ActorType.Npc,
            position = position,
            modelLook = npcLook,
            movementController = controller,
            petSettings = PetSettings(ownerState.id)
        )

        val event = ActorCreateEvent(initialActorState) {
            it.renderState.effectColor = ByteColor.zero

            it.onReadyToDraw {
                it.transitionToIdle(0f)
                it.playRoutine(entranceAnimation)
            }
        }

        return listOf(event)
    }

}