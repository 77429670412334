package xim.poc.game

import xim.poc.*
import xim.poc.browser.DatLoader
import xim.resource.DatId
import xim.resource.EffectRoutineResource
import xim.resource.table.FileTableManager
import xim.util.OnceLogger

object MiscEffects {

    enum class Effect(val id: Int) {
        LevelDown(0xCED),
        LevelUp(0xCEE),
        NewChallenge(0xE86),
        JobPoint(0xE90),
        MasterLevelUp(0xEE8),
        MasterLevelDown(0xEE9),
        ChangeJobs(0x13AE),
    }

    private val skillChainAnimations = mapOf(
        SkillChainAttribute.Compression to 0xCC3,
        SkillChainAttribute.Impaction to 0xCC4,
        SkillChainAttribute.Liquefaction to 0xCC5,
        SkillChainAttribute.Detonation to 0xCC6,
        SkillChainAttribute.Reverberation to 0xCC7,
        SkillChainAttribute.Transfixion to 0xCC8,
        SkillChainAttribute.Scission to 0xCC9,
        SkillChainAttribute.Induration to 0xCCA,

        SkillChainAttribute.Gravitation to 0xCCB,
        SkillChainAttribute.Fragmentation to 0xCCC,
        SkillChainAttribute.Distortion to 0xCCD,
        SkillChainAttribute.Fusion to 0xCCE,

        SkillChainAttribute.Light to 0xCCF,
        SkillChainAttribute.Darkness to 0xCD0,

        SkillChainAttribute.Radiance to 0xEE5,
        SkillChainAttribute.Umbra to 0xEE6,
    )

    fun playSkillChain(source: ActorId?, target: ActorId? = source, attribute: SkillChainAttribute) {
        val sourceActor = ActorManager[source] ?: return
        val targetActor = ActorManager[target] ?: return
        val animation = skillChainAnimations[attribute] ?: return
        playEffectMain(sourceActor, targetActor, animation)
    }

    fun playEffect(source: Actor?, target: Actor? = source, effect: Effect) {
        if (source == null) { return }
        playEffectMain(source, target ?: source, effect.id)
    }

    private fun playEffectMain(actor: Actor, target: Actor, fileTableIndex: Int) {
        val path = FileTableManager.getFilePath(fileTableIndex) ?: return
        playEffectMain(actor, target, path)
    }

    private fun playEffectMain(actor: Actor, target: Actor, resourceName: String) {
        DatLoader.load(resourceName).onReady {
            val resource = it.getAsResource().getNullableChildRecursivelyAs(DatId.main, EffectRoutineResource::class)
            if (resource != null) {
                EffectManager.registerActorRoutine(actor, ActorContext(actor.id, primaryTargetId = target.id), resource)
            } else {
                OnceLogger.warn("Couldn't find [main] in $resourceName")
            }

        }
    }

}