package xim.poc.game.event

import xim.poc.ActorId
import xim.poc.game.ActorState
import xim.poc.game.ActorStateManager
import xim.poc.game.AttackContext
import xim.poc.game.GameState
import xim.poc.ui.ChatLog
import xim.poc.ui.ChatLogColor

class ActorGainExpEvent(
    val actorId: ActorId,
    val defeatedTargetId: ActorId,
    val actionContext: AttackContext? = null
): Event {

    override fun apply(): List<Event> {
        val actor = ActorStateManager[actorId] ?: return emptyList()
        val target = ActorStateManager[defeatedTargetId] ?: return emptyList()

        val expAmount = GameState.getGameMode().getExperiencePointGain(actor, target)
        if (expAmount <= 0) { return emptyList() }

        actionContext?.composeCallback {
            if (actor.isPlayer()) { ChatLog.addLine("Gained $expAmount EXP!", ChatLogColor.Info) }
        }

        val levelUp = gainExp(actor, expAmount)
        return if (levelUp) {
            listOf(ActorLevelUpEvent(actorId, actionContext = actionContext))
        } else {
            emptyList()
        }
    }

    private fun gainExp(actorState: ActorState, expAmount: Int): Boolean {
        val current = actorState.jobLevels[actorState.jobState.mainJob] ?: return false
        val beforeLevel = current.level

        current.gainExp(expAmount)
        val afterLevel = current.level

        return afterLevel > beforeLevel
    }

}