package xim.poc.game.configuration.v0

import xim.poc.ActorId
import xim.poc.game.*
import xim.poc.game.configuration.NpcInteraction
import xim.resource.EquipSlot

object AugmentDebugger: NpcInteraction {

    override fun onInteraction(npcId: ActorId) {
        UiStateHelper.openQueryMode(prompt = "Which slot?", options = getSlotOptions(), callback = this::handleSlotResponse)
    }

    private fun getSlotOptions(): List<QueryMenuOption> {
        return EquipSlot.values().map { QueryMenuOption(it.name, it.ordinal) }
    }

    private fun handleSlotResponse(option: QueryMenuOption?): QueryMenuResponse {
        if (option == null || option.value < 0) { return QueryMenuResponse.pop }

        val slot = EquipSlot.values()[option.value]

        UiStateHelper.openQueryMode(prompt = "Add which augment?", options = getOptions(slot), callback = { handleResponse(it, slot) })
        return QueryMenuResponse.noop
    }

    private fun getOptions(slot: EquipSlot): List<QueryMenuOption> {
        val options = ArrayList<QueryMenuOption>()
        val equip = ActorStateManager.player().getEquipment(slot) ?: return emptyList()

        for ((key, value) in ItemAugmentDefinitions.definitions) {
            val bonus = value.valueFn.calculate(equip)
            options += QueryMenuOption("[$key]${value.attribute.toDescription(bonus)}", key)
        }

        return options
    }

    private fun handleResponse(option: QueryMenuOption?, equipSlot: EquipSlot): QueryMenuResponse {
        if (option == null || option.value < 0) { return QueryMenuResponse.pop }

        val player = ActorStateManager.player()
        val body = player.getEquipment(equipSlot) ?: return QueryMenuResponse.pop

        val current = body.augments ?: ItemAugment()

        if (current.augmentIds.size >= 3) {
            current.augmentIds[0] = option.value
        } else {
            current.augmentIds += option.value
        }

        body.augments = current
        return QueryMenuResponse.noop
    }

}