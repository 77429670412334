package xim.poc.game.event

import xim.poc.ActorId
import xim.poc.game.*
import xim.poc.ui.ChatLog
import kotlin.random.Random

class CorsairRollEvent(
    val abilityId: Int,
    val statusId: Int,
    val sourceId: ActorId,
    val context: AttackContext,
): Event {

    override fun apply(): List<Event> {
        val sourceState = ActorStateManager[sourceId] ?: return emptyList()

        if (abilityId == AbilityId.DoubleUp.id) {
            doubleUp(sourceState)
        } else {
            roll(sourceState)
        }

        return emptyList()
    }

    private fun roll(sourceState: ActorState) {
        if (sourceState.hasStatusEffect(StatusEffect.DoubleUpChance.id)) { return }

        sourceState.gainStatusEffect(statusId)

        val doubleUpChance = sourceState.gainStatusEffect(StatusEffect.DoubleUpChance.id)
        doubleUpChance.linkedAbilityId = abilityId
        doubleUpChance.linkedStatusId = statusId

        doubleUp(sourceState)
    }

    private fun doubleUp(sourceState: ActorState) {
        val doubleUpStatus = sourceState.getStatusEffect(StatusEffect.DoubleUpChance.id) ?: return
        val linkedStatus = sourceState.getStatusEffect(doubleUpStatus.linkedStatusId) ?: return

        var rollValue = 1 + Random.nextInt(0, 6)

        // For testing, always hit 11 before busting
        if (GameState.isDebugMode() && linkedStatus.counter < 11 && linkedStatus.counter + rollValue > 11) {
            rollValue = 11 - linkedStatus.counter
        }

        val rollSum = (linkedStatus.counter + rollValue).coerceIn(1, 12)
        linkedStatus.counter = rollSum

        val chatLogCallback = if (rollSum < 12) {
            EffectCallback { ChatLog.addLine("Roll Value: $rollSum") }
        } else {
            sourceState.expireStatusEffect(StatusEffect.DoubleUpChance.id)
            sourceState.expireStatusEffect(doubleUpStatus.linkedStatusId)
            sourceState.gainStatusEffect(StatusEffect.Bust.id)
            EffectCallback { ChatLog.addLine("Roll Value: bust!") }
        }

        context.rollSumFlag = rollSum
        context.rollValueFlag = rollValue
        context.composeCallback(chatLogCallback)
    }

}