package xim.poc.game.configuration.v0

import xim.poc.game.*
import xim.util.multiplyInPlace

data class CombatBonusAggregate(
    var additiveStats: CombatStatBuilder = CombatStatBuilder(),
    var multiplicativeStats: MutableMap<CombatStat, Float> = HashMap(),
    var regen: Int = 0,
    var refresh: Int = 0,
    var criticalHitRate: Int = 0,
    var enemyCriticalHitRate: Int = 0,
    var haste: Int = 0,
    var physicalDamageTaken: Int = 0,
    var fastCast: Int = 0,
    var storeTp: Int = 0,
    var doubleAttack: Int = 0,
    var dualWield: Int = 0,
    var weaponSkillDamage: Int = 0,
    var criticalHitDamage: Int = 0,
    var canDualWield: Boolean = false,
) {

    fun toPercentBonus(bonus: Int): Float {
        return 1f + (bonus/100f)
    }

    fun aggregate(augmentId: AugmentId, value: Int) {
        when (augmentId) {
            AugmentId.HP -> additiveStats.maxHp += value
            AugmentId.MP -> additiveStats.maxMp += value
            AugmentId.STR -> additiveStats.str += value
            AugmentId.DEX -> additiveStats.dex += value
            AugmentId.VIT -> additiveStats.vit += value
            AugmentId.AGI -> additiveStats.agi += value
            AugmentId.INT -> additiveStats.int += value
            AugmentId.MND -> additiveStats.mnd += value
            AugmentId.CHR -> additiveStats.chr += value
            AugmentId.CriticalHitRate -> criticalHitRate += value
            AugmentId.EnemyCriticalHitRate -> enemyCriticalHitRate += value
            AugmentId.Haste -> haste += value
            AugmentId.PhysicalDamageTaken -> physicalDamageTaken += value
            AugmentId.Regen -> regen += value
            AugmentId.Refresh -> refresh += value
            AugmentId.FastCast -> fastCast += value
            AugmentId.StoreTp -> storeTp += value
            AugmentId.DoubleAttack -> doubleAttack += value
            AugmentId.DualWield -> dualWield += value
            AugmentId.WeaponSkillDamage -> weaponSkillDamage += value
            AugmentId.CriticalHitDamage -> criticalHitDamage += value
            AugmentId.AlterEgo -> Unit
        }
    }

    fun aggregate(abilityId: AbilityId, value: Int) {
        when (abilityId) {
            AbilityId.AutoRegen -> regen += value
            AbilityId.AutoRefresh -> refresh += value
            AbilityId.FastCast -> fastCast += value
            AbilityId.StoreTp -> storeTp += value
            AbilityId.DoubleAttack -> doubleAttack += value
            AbilityId.DualWield -> { dualWield += value; canDualWield = true }
            else -> Unit
        }
    }

    fun aggregate(combatStats: CombatStats) {
        additiveStats.add(combatStats)
    }

    fun aggregate(statusEffectState: StatusEffectState) {
        when (statusEffectState.statusEffectId) {
            StatusEffect.Dia.id -> handleDia(statusEffectState)
            StatusEffect.Haste.id -> haste += statusEffectState.potency
        }
    }

    private fun handleDia(statusEffectState: StatusEffectState) {
        regen -= 1
        multiplicativeStats.multiplyInPlace(CombatStat.vit, 0.9f, defaultValue = 1f)
    }

}