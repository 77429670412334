package xim.poc.game.event

import xim.poc.ActorId
import xim.poc.game.ActorStateManager
import xim.poc.game.InternalItemId

class InventoryDiscardEvent(
    val sourceId: ActorId,
    val internalItemId: InternalItemId,
): Event {

    override fun apply(): List<Event> {
        val state = ActorStateManager[sourceId] ?: return emptyList()

        val item = state.inventory.getByInternalId(internalItemId) ?: return emptyList()
        if (state.isEquipped(item)) { return emptyList() }

        state.inventory.discardItem(internalItemId)
        return emptyList()
    }

}