package xim.poc.browser

import onStart
import web.dom.Element
import web.dom.document
import web.html.HTMLElement
import web.keyboard.Key.Digit0
import web.keyboard.Key.Digit1
import web.keyboard.Key.Digit2
import web.keyboard.Key.Digit3
import web.keyboard.Key.Digit4
import web.keyboard.Key.Digit5
import web.keyboard.Key.Digit6
import web.keyboard.Key.Digit7
import web.keyboard.Key.Digit8
import web.keyboard.Key.Digit9
import web.performance.performance
import web.uievents.KeyboardEvent
import web.uievents.MouseEvent
import web.uievents.Touch
import web.uievents.TouchEvent
import web.window.window
import xim.math.Vector2f
import xim.poc.browser.Keyboard.*
import kotlin.math.abs

private class JsKeyState(val keyState: KeyState, val modifiers: List<KeyModifier> = emptyList())

class JsKeyboard : Keyboard {

    companion object {
        private const val mouseEventId = -1

        private val KEY_MAP = mutableMapOf(
            "tab" to Key.TAB,
            "enter" to Key.ENTER,
            "shift" to Key.SHIFT,
            "escape" to Key.ESC,
            " " to Key.SPACE,
            "pageup" to Key.ZOOM_IN,
            "pagedown" to Key.ZOOM_OUT,
            "arrowleft" to Key.LEFT,
            "arrowright" to Key.RIGHT,
            "arrowup" to Key.UP,
            "arrowdown" to Key.DOWN,
            "0" to Key.N0,
            "1" to Key.N1,
            "2" to Key.N2,
            "3" to Key.N3,
            "4" to Key.N4,
            "5" to Key.N5,
            "6" to Key.N6,
            "7" to Key.N7,
            "8" to Key.N8,
            "9" to Key.N9,
            "a" to Key.A,
            "c" to Key.C,
            "d" to Key.D,
            "f" to Key.F,
            "h" to Key.H,
            "k" to Key.K,
            "m" to Key.M,
            "p" to Key.P,
            "r" to Key.R,
            "s" to Key.S,
            "w" to Key.W,
            "z" to Key.Z,
            "f1" to Key.F1,
            "f2" to Key.F2,
            "f3" to Key.F3,
            "f4" to Key.F4,
            "f5" to Key.F5,
            "f6" to Key.F6,
            "-" to Key.MINUS,
        )
    }

    private val keyStates = mutableMapOf<Key, JsKeyState>()
    private val bufferedKeyUp = HashMap<Key, KeyboardEvent<*>>()
    private val bufferedKeyDown = HashMap<Key, KeyboardEvent<*>>()
    private val bufferedClicks = ArrayList<ClickEvent>()

    private val touches = HashMap<Int, TouchData>()
    private val clicks = ArrayList<ClickEvent>()

    init {
        window.onkeyup = this::onKeyUp
        window.onkeydown = this::onKeyDown

        val canvas = document.getElementById("canvas") as HTMLElement

        canvas.onmousedown = { onMouseDown(it as MouseEvent<Element>) }
        canvas.onmousemove = { onMouseMove(it as MouseEvent<Element>) }
        canvas.onmouseleave = { onMouseEnd(it as MouseEvent<Element>) }
        canvas.onmouseup = { onMouseEnd(it as MouseEvent<Element>) }

        canvas.ontouchstart = { onTouchStart(it as TouchEvent<Element>) }
        canvas.ontouchmove = { onTouchMove(it as TouchEvent<Element>) }
        canvas.ontouchend = { onTouchEnd(it as TouchEvent<Element>) }
        canvas.ontouchcancel = { onTouchEnd(it as TouchEvent<Element>) }
    }

    private fun onKeyUp(event: KeyboardEvent<*>) {
        val targetId = (event.target as HTMLElement).id
        if (targetId == "canvas" || targetId == "") {
            event.stopPropagation()
            event.preventDefault()
        } else {
            return
        }

        val eventKey = mapKeyCode(event)
        val key = KEY_MAP[eventKey.lowercase()] ?: return
        bufferedKeyUp[key] = event
    }

    private fun onKeyDown(event: KeyboardEvent<*>) {
        onStart()

        val targetId = (event.target as HTMLElement).id
        if (targetId == "canvas" || targetId == "") {
            event.stopPropagation()
            event.preventDefault()
        } else {
            return
        }

        val eventKey = mapKeyCode(event)
        val key = KEY_MAP[eventKey] ?: return
        bufferedKeyDown[key] = event
    }

    private fun mapKeyCode(event: KeyboardEvent<*>): String {
        // Used to capture shift+number
        return when (event.code) {
            Digit0 -> "0"
            Digit1 -> "1"
            Digit2 -> "2"
            Digit3 -> "3"
            Digit4 -> "4"
            Digit5 -> "5"
            Digit6 -> "6"
            Digit7 -> "7"
            Digit8 -> "8"
            Digit9 -> "9"
            else -> event.key.lowercase()
        }
    }

    private fun onMouseDown(mouseEvent: MouseEvent<Element>) {
        val data = TouchData()
        touches[mouseEventId] = data
        data.startTime = performance.now()

        val (x, y) = getMousePosition(mouseEvent)
        data.startingX = x
        data.startingY = y

        val (nX, nY) = getNormalizedMousePosition(mouseEvent)
        data.normalizedStartingX = nX
        data.normalizedStartingY = nY
    }

    private fun onMouseMove(mouseEvent: MouseEvent<Element>) {
        val data = touches[mouseEventId] ?: return

        val (x, y) = getNormalizedMousePosition(mouseEvent)

        data.dX = (x - data.normalizedStartingX)
        data.dY = (y - data.normalizedStartingY)
    }

    private fun onMouseEnd(mouseEvent: MouseEvent<Element>) {
        val data = touches[mouseEventId] ?: return

        if (abs(data.dX) < 0.005 && abs(data.dY) < 0.005) {
            val pos = Vector2f(data.startingX.toFloat(), data.startingY.toFloat())
            val nPos = Vector2f(data.normalizedStartingX.toFloat(), data.normalizedStartingY.toFloat())
            bufferedClicks += ClickEvent(clickTime = performance.now() - data.startTime, screenPosition = pos, normalizedScreenPosition = nPos)
        }

        touches.remove(mouseEventId)
    }

    private fun getMousePosition(mouseEvent: MouseEvent<Element>): Pair<Double, Double> {
        val target = mouseEvent.currentTarget
        val rect = target.getBoundingClientRect()

        val x = (mouseEvent.clientX - rect.left)
        val y = (mouseEvent.clientY - rect.top)

        return Pair(x, y)
    }

    private fun getNormalizedMousePosition(mouseEvent: MouseEvent<Element>): Pair<Double, Double> {
        val target = mouseEvent.currentTarget
        val rect = target.getBoundingClientRect()

        val x = (mouseEvent.clientX - rect.left) / rect.width
        val y = (mouseEvent.clientY - rect.top) / rect.height

        return Pair(x, y)
    }

    private fun onTouchStart(event: TouchEvent<Element>) {
        event.preventDefault()

        for (touch in event.touches) {
            if (touches.containsKey(touch.identifier)) { continue }
            val data = TouchData()
            data.startTime = performance.now()

            val (x, y) = getNormalizedTouchPosition(touch, event.currentTarget)
            data.normalizedStartingX = x
            data.normalizedStartingY = y
            touches[touch.identifier] = data
        }
    }

    private fun onTouchMove(event: TouchEvent<Element>) {
        event.preventDefault()

        for (touch in event.touches) {
            val data = touches[touch.identifier] ?: continue
            val (x, y) = getNormalizedTouchPosition(touch, event.currentTarget)
            data.dX = x - data.normalizedStartingX
            data.dY = y - data.normalizedStartingY
        }
    }

    private fun onTouchEnd(event: TouchEvent<Element>) {
        event.preventDefault()

        for (touch in event.changedTouches) {
            val data = touches.remove(touch.identifier) ?: continue

            if (abs(data.dX) < 0.05 && abs(data.dY) < 0.05) {
                val pos = Vector2f(data.startingX.toFloat(), data.startingY.toFloat())
                val nPos = Vector2f(data.normalizedStartingX.toFloat(), data.normalizedStartingY.toFloat())
                bufferedClicks += ClickEvent(clickTime = performance.now() - data.startTime, screenPosition = pos, normalizedScreenPosition = nPos)
            }
        }
    }

    private fun getNormalizedTouchPosition(touch: Touch, target: Element): Pair<Double, Double> {
        val rect = target.getBoundingClientRect()

        val x = (touch.clientX - rect.left) / rect.width
        val y = (touch.clientY - rect.top) / rect.height

        return Pair(x, y)
    }

    override fun poll() {
        clicks.clear()
        clicks += bufferedClicks
        bufferedClicks.clear()

        val itr = keyStates.iterator()
        while (itr.hasNext()) {
            val next = itr.next()
            val state = next.value.keyState

            if (state == KeyState.RELEASED) {
                itr.remove()
            } else if (bufferedKeyUp.containsKey(next.key)) {
                keyStates[next.key] = JsKeyState(KeyState.RELEASED)
            } else if (bufferedKeyDown.containsKey(next.key)) {
                val event = bufferedKeyDown[next.key]!!
                keyStates[next.key] = JsKeyState(KeyState.REPEATED, toModifiers(event))
            }

            bufferedKeyUp.remove(next.key)
            bufferedKeyDown.remove(next.key)
        }

        for ((key, event) in bufferedKeyDown) {
            keyStates[key] = JsKeyState(KeyState.PRESSED, toModifiers(event))
        }
    }

    override fun getClickEvents(): List<ClickEvent> {
        return clicks
    }

    override fun clear() {
        keyStates.clear()
    }

    override fun getKeyState(key: Key): KeyState {
        return keyStates[key]?.keyState ?: KeyState.NONE
    }

    override fun isKeyPressed(key: Key): Boolean {
        return getKeyState(key) == KeyState.PRESSED
    }

    override fun isKeyPressedOrRepeated(key: Key): Boolean {
        return isKeyPressed(key) || isKeyRepeated(key)
    }

    override fun isKeyReleased(key: Key): Boolean {
        return getKeyState(key) == KeyState.RELEASED
    }

    override fun isKeyRepeated(key: Key): Boolean {
        return getKeyState(key) == KeyState.REPEATED
    }

    override fun getKeyModifiers(key: Key): List<KeyModifier> {
        return keyStates[key]?.modifiers ?: emptyList()
    }

    override fun getTouchData(): Collection<TouchData> {
        return touches.values
    }

    private fun toModifiers(event: KeyboardEvent<*>): List<KeyModifier> {
        val events = ArrayList<KeyModifier>()
        if (event.altKey) { events += KeyModifier.Alt }
        if (event.ctrlKey) { events += KeyModifier.Ctrl }
        if (event.shiftKey) { events += KeyModifier.Shift }
        return events
    }

}
