package xim.poc.game.event

import xim.poc.ActorId
import xim.poc.ModelLook
import xim.poc.NoOpActorController
import xim.poc.game.ActorStateManager
import xim.poc.game.ActorType

class ActorMountEvent(
    val sourceId: ActorId,
    val index: Int
): Event {
    override fun apply(): List<Event> {
        val source = ActorStateManager[sourceId] ?: return emptyList()
        if (!source.isIdle()) { return emptyList() }

        if (source.mountedState?.index == index) { return emptyList() }

        val outputEvents = ArrayList<Event>()
        if (source.mountedState != null) { outputEvents += ActorDismountEvent(sourceId) }

        val initialActorState = InitialActorState(
            name = "",
            type = ActorType.Npc,
            position = source.position,
            modelLook = ModelLook.fileTableIndex(0x019131 + index),
            rotation = source.rotation,
            movementController = NoOpActorController(),
            mountSettings = MountSettings(index, source.id)
        )

        outputEvents += ActorCreateEvent(initialActorState) {
            it.onReadyToDraw {
                it.transitionToIdle(0f)
            }
        }

        return outputEvents
    }
}