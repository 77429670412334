package xim.poc.game.configuration.v0

import xim.poc.CustomModelSettings
import xim.poc.ModelLook
import xim.poc.game.CombatStats
import xim.poc.game.configuration.*
import xim.poc.game.configuration.v0.behaviors.MobClusterController
import xim.poc.gl.ByteColor

object V0MonsterDefinitions {

    val definitions: List<MonsterDefinition>

    init {
        definitions = ArrayList()

        definitions += MonsterDefinition(id = 0x001, "Tiny Mandragora", ModelLook.npc(0x012C), mobSkills = listOf(49),
            baseLevel = 1,
            baseCombatStats = CombatStats(maxHp = 16, maxMp = 16, str = 4, vit = 4, int = 4, mnd = 4, agi = 4, dex = 4, chr = 1),
        )

        definitions += MonsterDefinition(id = 0x002, "Tom Tit Tat", ModelLook.npc(0x012C),
            mobSkills = listOf(44, 45, 46, 48, 49, 50),
            baseCombatStats = CombatStats(maxHp = 200, maxMp = 20, str = 15, vit = 10, int = 4, mnd = 4, agi = 5, dex = 4, chr = 1),
            customModelSettings = CustomModelSettings(blurConfig = standardBlurConfig(ByteColor(0x80, 0x00, 0x00, 0x20))),
            dropTable = listOf(
                WeightedTable.uniform(ItemDropSlot(9084, quantity = 5)),
                WeightedTable.uniform(null, ItemDropSlot(9084, quantity = 5)),
                WeightedTable.uniform(Floor1DropTables.bronzeEquipmentQ1),
            ),
            notoriousMonster = true,
        )

        definitions += MonsterDefinition(id = 0x003, "Bumblebee", ModelLook.npc(0x0110), mobSkills = listOf(78, 79),
            baseDamage = 3,
            baseDelay = 160,
            baseLevel = 2,
            baseCombatStats = CombatStats(maxHp = 32, maxMp = 32, str = 8, vit = 8, int = 4, mnd = 4, agi = 4, dex = 4, chr = 1),
        )

        definitions += MonsterDefinition(id = 0x004, "Carrion Crow", ModelLook.npc(0x01BD), mobSkills = listOf(366, 367),
            baseDamage = 6,
            baseDelay = 210,
            baseLevel = 3,
            baseCombatStats = CombatStats(maxHp = 40, maxMp = 40, str = 10, vit = 10, int = 8, mnd = 8, agi = 8, dex = 8, chr = 1),
        )

        val clusterBehaviorId = ActorBehaviors.register(0x005) { MobClusterController(it) }
        definitions += MonsterDefinition(id = 0x005, "Cluster", ModelLook.npc(0x0123), mobSkills = listOf(311, 312, 313, 314, 315, 316, 317, 318, 319),
            behaviorId = clusterBehaviorId,
            baseDamage = 8,
            baseDelay = 210,
            baseLevel = 4,
            baseCombatStats = CombatStats(maxHp = 80, maxMp = 40, str = 18, vit = 15, int = 10, mnd = 10, agi = 12, dex = 12, chr = 1),
        )

    }

}