package xim.poc.game.configuration.assetviewer

import xim.poc.game.StatusEffect
import xim.poc.game.configuration.SkillApplier
import xim.poc.game.configuration.SkillAppliers
import xim.poc.game.configuration.SkillType
import xim.poc.game.configuration.assetviewer.AssetViewerSkillApplierHelpers.basicElementDamage
import xim.poc.game.configuration.assetviewer.AssetViewerSkillApplierHelpers.basicEnhancingMagicStatusEffect
import xim.poc.game.configuration.assetviewer.AssetViewerSkillApplierHelpers.basicHealingMagic
import xim.poc.game.configuration.assetviewer.AssetViewerSkillApplierHelpers.sourceHpPercentDamage
import xim.poc.game.configuration.assetviewer.AssetViewerSkillApplierHelpers.summonBubble
import xim.poc.game.configuration.assetviewer.AssetViewerSkillApplierHelpers.summonLuopan
import xim.poc.game.configuration.assetviewer.AssetViewerSkillApplierHelpers.summonPet
import xim.resource.MagicType
import xim.resource.table.SpellInfoTable
import xim.resource.table.SpellNameTable
import kotlin.time.Duration.Companion.seconds

object AssetViewerSpellAppliers {
    
    fun register() {
        // Cure
        SkillAppliers += SkillApplier(skillId = 1, skillType = SkillType.Spell, 
            targetEvaluator = basicHealingMagic(PotencyConfiguration(potency = 2f))
        )

        // Cure II
        SkillAppliers += SkillApplier(skillId = 2, skillType = SkillType.Spell, 
            targetEvaluator = basicHealingMagic(PotencyConfiguration(potency = 4f)),
        )

        // Curaga
        SkillAppliers += SkillApplier(skillId = 7, skillType = SkillType.Spell, 
            targetEvaluator = basicHealingMagic(PotencyConfiguration(potency = 2f)),
        )

        // Enfire
        SkillAppliers += SkillApplier(skillId = 100, skillType = SkillType.Spell, 
            targetEvaluator = basicEnhancingMagicStatusEffect(status = StatusEffect.Enfire, baseDuration = 30.seconds),
        )

        // Enblizzard
        SkillAppliers += SkillApplier(skillId = 101, skillType = SkillType.Spell, 
            targetEvaluator = basicEnhancingMagicStatusEffect(status = StatusEffect.Enblizzard, baseDuration = 30.seconds),
        )

        // Fire
        SkillAppliers += SkillApplier(skillId = 144, skillType = SkillType.Spell, 
            targetEvaluator = basicElementDamage(PotencyConfiguration(potency = 1f)),
        )

        // Blizzard
        SkillAppliers += SkillApplier(skillId = 149, skillType = SkillType.Spell, 
            targetEvaluator = basicElementDamage(PotencyConfiguration(potency = 20f)),
        )

        // Firaga
        SkillAppliers += SkillApplier(skillId = 174, skillType = SkillType.Spell, 
            targetEvaluator = basicElementDamage(PotencyConfiguration(potency = 1f)),
        )

        // Blizzaga
        SkillAppliers += SkillApplier(skillId = 179, skillType = SkillType.Spell, 
            targetEvaluator = basicElementDamage(PotencyConfiguration(potency = 20f)),
        )

        // Blaze Spikes
        SkillAppliers += SkillApplier(skillId = 249, skillType = SkillType.Spell, 
            targetEvaluator = basicEnhancingMagicStatusEffect(status = StatusEffect.BlazeSpikes, baseDuration = 30.seconds),
        )

        // Ice Spikes
        SkillAppliers += SkillApplier(skillId = 250, skillType = SkillType.Spell, 
            targetEvaluator = basicEnhancingMagicStatusEffect(status = StatusEffect.IceSpikes, baseDuration = 30.seconds),
        )

        // Fire Spirit
        SkillAppliers += SkillApplier(skillId = 288, skillType = SkillType.Spell, 
            targetEvaluator = summonPet(lookId = 0x08),
        )

        // Ice Spirit
        SkillAppliers += SkillApplier(skillId = 289, skillType = SkillType.Spell, 
            targetEvaluator = summonPet(lookId = 0x09),
        )

        // Air Spirit
        SkillAppliers += SkillApplier(skillId = 290, skillType = SkillType.Spell, 
            targetEvaluator = summonPet(lookId = 0x0A),
        )

        // Earth Spirit
        SkillAppliers += SkillApplier(skillId = 291, skillType = SkillType.Spell, 
            targetEvaluator = summonPet(lookId = 0x0B),
        )

        // Thunder Spirit
        SkillAppliers += SkillApplier(skillId = 292, skillType = SkillType.Spell, 
            targetEvaluator = summonPet(lookId = 0x0D),
        )

        // Water Spirit
        SkillAppliers += SkillApplier(skillId = 293, skillType = SkillType.Spell, 
            targetEvaluator = summonPet(lookId = 0x0C),
        )

        // Light Spirit
        SkillAppliers += SkillApplier(skillId = 294, skillType = SkillType.Spell, 
            targetEvaluator = summonPet(lookId = 0x0E),
        )

        // Dark Spirit
        SkillAppliers += SkillApplier(skillId = 295, skillType = SkillType.Spell, 
            targetEvaluator = summonPet(lookId = 0x0F),
        )

        // Carbuncle
        SkillAppliers += SkillApplier(skillId = 296, skillType = SkillType.Spell, 
            targetEvaluator = summonPet(lookId = 0x10),
        )

        // Fenrir
        SkillAppliers += SkillApplier(skillId = 297, skillType = SkillType.Spell, 
            targetEvaluator = summonPet(lookId = 0x11),
        )

        // Ifrit
        SkillAppliers += SkillApplier(skillId = 298, skillType = SkillType.Spell, 
            targetEvaluator = summonPet(lookId = 0x12),
        )

        // Titan
        SkillAppliers += SkillApplier(skillId = 299, skillType = SkillType.Spell, 
            targetEvaluator = summonPet(lookId = 0x13),
        )

        // Leviathan
        SkillAppliers += SkillApplier(skillId = 300, skillType = SkillType.Spell, 
            targetEvaluator = summonPet(lookId = 0x14),
        )

        // Garuda
        SkillAppliers += SkillApplier(skillId = 301, skillType = SkillType.Spell, 
            targetEvaluator = summonPet(lookId = 0x15),
        )

        // Shiva
        SkillAppliers += SkillApplier(skillId = 302, skillType = SkillType.Spell, 
            targetEvaluator = summonPet(lookId = 0x16),
        )

        // Ramuh
        SkillAppliers += SkillApplier(skillId = 303, skillType = SkillType.Spell, 
            targetEvaluator = summonPet(lookId = 0x17),
        )

        // Diabolos
        SkillAppliers += SkillApplier(skillId = 304, skillType = SkillType.Spell, 
            targetEvaluator = summonPet(lookId = 0x19),
        )

        // Odin
        SkillAppliers += SkillApplier(skillId = 305, skillType = SkillType.Spell, 
            targetEvaluator = summonPet(lookId = 0x1A),
        )

        // Alexander
        SkillAppliers += SkillApplier(skillId = 306, skillType = SkillType.Spell, 
            targetEvaluator = summonPet(lookId = 0x1B),
        )

        // Cait Sith
        SkillAppliers += SkillApplier(skillId = 307, skillType = SkillType.Spell, 
            targetEvaluator = summonPet(lookId = 0x1C),
        )

        // Siren
        SkillAppliers += SkillApplier(skillId = 355, skillType = SkillType.Spell, 
            targetEvaluator = summonPet(lookId = 0x1E),
        )

        // Self Destruct
        SkillAppliers += SkillApplier(skillId = 533, skillType = SkillType.Spell, 
            targetEvaluator = sourceHpPercentDamage(PotencyConfiguration(potency = 0.5f)),
            additionalSelfEvaluator = sourceHpPercentDamage(PotencyConfiguration(potency = 1f)),
        )

        // Atomos
        SkillAppliers += SkillApplier(skillId = 847, skillType = SkillType.Spell, 
            targetEvaluator = summonPet(lookId = 0x1D),
        )

        // All Geo spells (debug)
        (0 until 1024)
            .map { SpellInfoTable[it] }
            .filter { it.magicType == MagicType.Geomancy }
            .filter { SpellNameTable.first(it.index).startsWith("Geo") }
            .map { SkillAppliers += SkillApplier(skillId = it.index, skillType = SkillType.Spell, targetEvaluator = summonLuopan(it)) }

        // All Indi spells (debug)
        (0 until 1024)
            .map { SpellInfoTable[it] }
            .filter { it.magicType == MagicType.Geomancy }
            .filter { SpellNameTable.first(it.index).startsWith("Indi") }
            .map { SkillAppliers += SkillApplier(skillId = it.index, skillType = SkillType.Spell, targetEvaluator = summonBubble(it)) }
    }
    
}