package xim.resource.table

import xim.poc.browser.DatLoader
import xim.resource.*

val SpellNameTable = StringTable("ROM/181/73.DAT")

// https://github.com/LandSandBoat/server/blob/base/sql/spell_list.sql
object SpellAnimationTable: LoadableResource {

    const val fileTableOffset = 0xAF0

    private lateinit var table: ByteReader
    private var preloaded = false

    override fun preload() {
        if (preloaded) { return }
        preloaded = true
        loadTable()
    }

    override fun isFullyLoaded() : Boolean {
        return this::table.isInitialized
    }

    operator fun get(spellInfo: SpellInfo): Int {
        if (spellInfo.magicType == MagicType.Trust) { return 0xe9b }
        return get(spellInfo.index)
    }

    operator fun get(spellId: Int): Int {
        table.position = spellId * 2
        return fileTableOffset + table.next16()
    }

    private fun loadTable() {
        DatLoader.load("landsandboat/SpellAnimationTable.DAT").onReady { table = it.getAsBytes() }
    }

}

object SpellInfoTable: LoadableResource {

    private lateinit var spellListResource: SpellListResource
    private var preloaded = false

    override fun preload() {
        if (preloaded) { return }
        preloaded = true
        loadTable()
    }

    override fun isFullyLoaded() : Boolean {
        return this::spellListResource.isInitialized
    }

    fun hasInfo(spellId: Int): Boolean {
        return spellListResource.spells[spellId] != null
    }

    fun getSpellInfo(spellId: Int) : SpellInfo {
        return spellListResource.spells[spellId] ?: throw IllegalStateException("No info for: $spellId")
    }

    operator fun get(spellId: Int) = getSpellInfo(spellId)

    private fun loadTable() {
        DatLoader.load("ROM/118/114.DAT").onReady {
            spellListResource = it.getAsResource().getOnlyChildByType(SpellListResource::class)
        }
    }

}