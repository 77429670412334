package xim.poc.game.configuration.v0

import xim.poc.ActorId
import xim.poc.game.UiStateHelper
import xim.poc.game.configuration.NpcInteraction
import xim.poc.ui.ChatLog
import xim.poc.ui.ShiftJis

object ItemReinforcementInteraction: NpcInteraction {

    private var describedSelf = false

    override fun onInteraction(npcId: ActorId) {
        if (!describedSelf) {
            describedSelf = true
            ChatLog("Upgrade materials can be applied to equipment in order to increase its ${ShiftJis.colorAug}rank${ShiftJis.colorClear}.")
        }

        UiStateHelper.openItemReinforcementContext()
    }

}