package xim.poc.tools

import xim.math.Vector3f
import xim.poc.FadeParameters
import xim.poc.SceneManager
import xim.poc.UiElementHelper
import xim.poc.game.ActorStateManager
import xim.poc.game.GameEngine
import xim.poc.game.configuration.CustomZoneDefinition
import xim.poc.game.configuration.ZoneDefinitionManager
import xim.poc.game.configuration.zones.CustomSaru
import xim.resource.DatId
import xim.resource.ZoneInteraction
import xim.util.Fps.secondsToFrames
import xim.util.OnceLogger

data class ZoneConfig(val zoneId: Int, val entryId: DatId? = null, val startPosition: Vector3f? = null, val customDefinition: CustomZoneDefinition? = null)

enum class CustomZoneConfig(val config: ZoneConfig) {
    Game(ZoneConfig(CustomSaru.zoneId, startPosition = Vector3f(-116f, -4f, -520f))),
    MogGarden(ZoneConfig(280, startPosition = Vector3f(0f, 0f, 0f))),
    PsoXja_Elevator(ZoneConfig(9, startPosition = Vector3f(300f, 0f, -50f))),
    Valkurm_Cave(ZoneConfig(103, startPosition = Vector3f(718f, -8f, -180f))),
    Valkurm_Beach(ZoneConfig(103, startPosition = Vector3f(377f, 4.3f, -188f))),
    Beaucedine(ZoneConfig(111, startPosition = Vector3f(-12.5f, -60f, -86f))),
    Uleguerand(ZoneConfig(5, startPosition = Vector3f(-328f, -176f, -40.6f))),
    EastAdoulin(ZoneConfig(257, startPosition = Vector3f(-66f, -1f, -21f))),
    Sarutabartua_West(ZoneConfig(115, startPosition = Vector3f(320f, -4f, -36f))),
    Sarutabartua_East(ZoneConfig(116, startPosition = Vector3f(-116f, -4f, -520f))),
    EmpParadox(ZoneConfig(36, startPosition = Vector3f(500f, 0f, 500f))),
}

class ZoneChangeOptions(val fullyRevive: Boolean = false)
class DestinationZoneConfig(val zoneConfig: ZoneConfig, val options: ZoneChangeOptions = ZoneChangeOptions())

object ZoneChanger {

    private var fadeOutParams: FadeParameters? = null
    private var fadeInParams: FadeParameters? = null
    private var destinationZoneConfig: DestinationZoneConfig? = null

    fun update(elapsedFrames: Float) {
        val completedFadingOut = fadeOutParams?.update(elapsedFrames)
        if (completedFadingOut == true) {
            GameEngine.requestZoneChange(destinationZoneConfig!!)
        }

        val completedFadingIn = fadeInParams?.update(elapsedFrames)
        if (completedFadingIn == true) {
            destinationZoneConfig = null
            fadeInParams = null
        }
    }

    fun drawScreenFade() {
        val params = fadeOutParams ?: fadeInParams ?: return
        val opacity = params.getOpacity()
        UiElementHelper.drawBlackScreenCover(1f - opacity)
    }

    fun isChangingZones(): Boolean {
        return destinationZoneConfig != null
    }

    fun isFadingIn(): Boolean {
        return fadeInParams != null
    }

    fun onZoneIn() {
        movePlayerToStartingPosition()
        fadeOutParams = null
        fadeInParams = FadeParameters.fadeIn(secondsToFrames(1f))
    }

    fun returnToHomePoint(restore: Boolean) {
        beginChangeZone(DatId("z381"), options = ZoneChangeOptions(fullyRevive = restore))
    }

    fun beginChangeZone(interaction: ZoneInteraction, options: ZoneChangeOptions = ZoneChangeOptions()) {
        val destinationZoneLine = interaction.destId!!
        return beginChangeZone(destinationZoneLine, options)
    }

    private fun beginChangeZone(destinationZoneLine: DatId, options: ZoneChangeOptions = ZoneChangeOptions()) {
        if (isChangingZones()) { return }

        val zoneId = destinationZoneLine.toZoneId()

        val zoneInteractions = SceneManager.getCurrentScene().getZoneInteractions()
        val intraZoneInteraction = zoneInteractions.firstOrNull { it.sourceId == destinationZoneLine }

        val zoneConfig = if (intraZoneInteraction != null) {
            val currentZoneId = SceneManager.getCurrentScene().getMainArea().id
            ZoneConfig(zoneId = currentZoneId, entryId = destinationZoneLine)
        } else {
            ZoneConfig(zoneId = zoneId, entryId = destinationZoneLine)
        }

        println("Changing zones to: $destinationZoneConfig via [${destinationZoneLine}]")
        beginChangeZone(zoneConfig, options)
    }

    fun beginChangeZone(toZone: ZoneConfig, options: ZoneChangeOptions = ZoneChangeOptions()) {
        if (isChangingZones()) { return }

        val destination = toZone.copy(customDefinition = ZoneDefinitionManager[toZone.zoneId])
        destinationZoneConfig = DestinationZoneConfig(destination, options)
        fadeOutParams = FadeParameters.fadeOut(secondsToFrames(1f))
    }

    private fun movePlayerToStartingPosition() {
        val zoneConfig = destinationZoneConfig?.zoneConfig ?: return

        val startingPosition = if (zoneConfig.startPosition != null) {
            zoneConfig.startPosition
        } else if (zoneConfig.entryId != null) {
            val destEntry = SceneManager.getCurrentScene().getZoneInteractions()
                .firstOrNull { it.sourceId == zoneConfig.entryId }

            if (destEntry != null) {
                destEntry.position + Vector3f(0f, destEntry.size.y/2f, 0f)
            } else {
                OnceLogger.warn("[${zoneConfig.entryId}] Couldn't find entry");
                Vector3f()
            }
        } else {
            val firstEntry = SceneManager.getCurrentScene().getZoneInteractions()
                .firstOrNull { it.isZoneEntrance() }

            if (firstEntry != null) {
                firstEntry.position + Vector3f(0f, firstEntry.size.y/2f, 0f)
            } else {
                OnceLogger.warn("[${zoneConfig.zoneId}] Didn't find any entry-points")
                getFallbackStartingPosition() ?: Vector3f(0f, -50f, 0f)
            }
        }

        ActorStateManager.player().position.copyFrom(startingPosition)
    }

    private fun getFallbackStartingPosition(): Vector3f? {
        val scene = SceneManager.getCurrentScene()

        val npcNames = listOf("Home Point", "Cavernous Maw", "Dimensional Portal", "Spatial Displacement", "Undulating Confluence", "Somnial Threshold", "Goblin Footprint", "Planar Rift", "Moogle")
        val zoneNpcs = scene.getNpcs().npcs

        for (npcName in npcNames) {
            val first = zoneNpcs.firstOrNull { it.name.contains(npcName) } ?: continue
            return first.info.position
        }

        return null
    }

}