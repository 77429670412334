package xim.poc.game.event

import xim.poc.ActorId
import xim.poc.game.ActorStateManager
import xim.poc.game.AttackContext
import xim.poc.game.GameEngine
import xim.poc.game.PartyManager

class ActorDefeatedEvent(
    val defeated: ActorId,
    val defeatedBy: ActorId,
    val actionContext: AttackContext? = null,
): Event {

    override fun apply(): List<Event> {
        val events = ArrayList<Event>()

        val gainedExp = PartyManager[defeatedBy].getAllState()
            .filter { !it.isDead() && !it.isDependent() }

        val defeatedState = ActorStateManager[defeated] ?: return emptyList()
        events += GameEngine.releaseDependents(defeatedState)

        actionContext?.composeCallback { GameEngine.onDisplayDeath(defeated) }

        events += gainedExp.map { ActorGainExpEvent(it.id, amount = 400, actionContext = actionContext) }
        return events
    }

}