package xim.poc.ui

import xim.poc.ActorManager
import xim.poc.game.*
import xim.poc.game.event.ActorReinforceItemEvent
import xim.resource.DatId

object EquipmentFilter: InventoryFilter {
    override fun apply(inventoryItem: InventoryItem): Boolean {
        val augments = inventoryItem.augments ?: return false
        return !AugmentHelper.isMaxRank(augments)
    }
}

class UpgradeItemFilter(val validUpgradeMaterials: Set<Int>): InventoryFilter {
    override fun apply(inventoryItem: InventoryItem): Boolean {
        return validUpgradeMaterials.contains(inventoryItem.id)
    }
}

object ItemReinforcementUi {

    val quantityInput = QuantityInputController {
        getSelectedUpgradeMaterial()?.quantity ?: 0
    }

    fun drawItemControl(uiState: UiState) {
        quantityInput.draw(uiState)
    }

    fun getSelectedUpgradeItem(): InventoryItem? {
        return InventoryUi.getSelectedItem(UiStateHelper.rankItemSelectContext, filter = EquipmentFilter)
    }

    fun getSelectedUpgradeMaterial(): InventoryItem? {
        return InventoryUi.getSelectedItem(UiStateHelper.rankUpgradeItemSelectContext, filter = getUpgradeItemFilter())
    }

    fun getUpgradeItemFilter(): InventoryFilter {
        val item = getSelectedUpgradeItem() ?: return InventoryFilter { false }
        val validMaterials = GameState.getGameMode().getItemReinforcementValues(item)
        return UpgradeItemFilter(validMaterials.keys)
    }

    fun isSelectedItemMaxRank(): Boolean {
        val selectedItem = getSelectedUpgradeItem() ?: return true
        val augment = selectedItem.augments ?: return true
        return AugmentHelper.isMaxRank(augment)
    }

    fun submitUpgrade() {
        val itemToUpgrade = getSelectedUpgradeItem() ?: return
        val upgradeMaterial = getSelectedUpgradeMaterial() ?: return
        val quantity = quantityInput.value

        GameEngine.submitEvent(ActorReinforceItemEvent(ActorStateManager.playerId, itemToUpgrade.internalId, upgradeMaterial.internalId, quantity))

        val player = ActorManager.player()
        MiscEffects.playEffect(player, player, 0x1346, DatId.synthesisNq)
    }

}