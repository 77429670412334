package xim.resource.table

import xim.poc.tools.ZoneConfig

object ZoneIdToResourceId {

    fun map(zoneConfig: ZoneConfig): String? {
        val custom = zoneConfig.customDefinition?.zoneResourcePath
        if (custom != null) { return custom }

        val zoneId = zoneConfig.zoneId

        val fileTableIndex = if (zoneId < 0x100) {
            0x64 + zoneId
        } else {
            0x147B3 + (zoneId - 0x100)
        }

        return FileTableManager.getFilePath(fileTableIndex)
    }
}

object ZoneNameTable: LoadableResource {

    private val zoneNameTable = StringTable(resourceName = "ROM/165/84.DAT", bitMask = -1)

    override fun preload() {
        zoneNameTable.preload()
    }

    override fun isFullyLoaded(): Boolean {
        return zoneNameTable.isFullyLoaded()
    }

    fun getAllFirst(): List<String> {
        return zoneNameTable.getAllFirst()
    }

    fun first(zoneConfig: ZoneConfig): String {
        return zoneConfig.customDefinition?.displayName ?: zoneNameTable.first(zoneConfig.zoneId)
    }

}

