package xim.poc.game.event

import xim.poc.ActorId
import xim.poc.ActorManager
import xim.poc.game.ActorStateManager
import xim.poc.game.AttackContext
import xim.poc.game.GameEngine
import xim.resource.EquipSlot

class AutoAttackEvent(
    val sourceId: ActorId,
): Event {

    override fun apply(): List<Event> {
        val sourceState = ActorStateManager[sourceId] ?: return emptyList()
        if (!sourceState.isEngaged() || sourceState.isDead()) { return emptyList() }

        val targetId = sourceState.targetState.targetId ?: return emptyList()
        val targetState = ActorStateManager[targetId] ?: return emptyList()
        if (targetState.isDead()) { return emptyList() }

        val actor = ActorManager[sourceId]
        val outputEvents = ArrayList<Event>()

        val targetCastingState = targetState.getCastingState()
        if (targetCastingState?.spellInfo != null) { outputEvents += CastInterruptedEvent(targetId) }

        val mainContext = AttackContext.from(sourceState, targetState)
        outputEvents += ActorDamagedEvent(sourceId, targetId, sourceState.getMainHandDamage(), mainContext)
        actor?.onAttackMainHand(mainContext)
        sourceState.gainTp(GameEngine.getTpGained(sourceState, targetState, EquipSlot.Main))

        if (sourceState.isDualWield()) {
            val subContext = AttackContext.from(sourceState, targetState)
            outputEvents += ActorDamagedEvent(sourceId, targetId, sourceState.getSubHandDamage(), subContext)
            actor?.onAttackSubHand(subContext)
            sourceState.gainTp(GameEngine.getTpGained(sourceState, targetState, EquipSlot.Sub))
        } else if (sourceState.isHandToHand()) {
            val h2hContext = AttackContext.from(sourceState, targetState)
            outputEvents += ActorDamagedEvent(sourceId, targetId, sourceState.getMainHandDamage(), h2hContext)
            actor?.onAttackH2H(h2hContext)
        }

        return outputEvents
    }

}