package xim.poc.browser;

class TouchData {
    var startTime = 0.0

    var startingX = 0.0
    var startingY = 0.0

    var dX = 0.0
    var dY = 0.0

    fun isControlTouch(): Boolean {
        return startingX > 0.5
    }

}

data class ClickEvent(val clickTime: Double, val normalizedScreenX: Float, val normalizedScreenY: Float) {
    var consumed = false

    fun isLongClick(): Boolean {
        return clickTime > 250
    }

}

interface Keyboard {

    enum class KeyState {
        PRESSED,
        RELEASED,
        REPEATED,
        NONE;
    }

    enum class Key {
        LEFT,
        RIGHT,
        UP,
        DOWN,

        W,
        A,
        S,
        D,
        C,
        H,
        K,
        M,
        F,
        P,
        Z,

        R,
        ZOOM_IN,
        ZOOM_OUT,
        SPACE,
        SHIFT,

        TAB,
        ESC,
        ENTER,

        MINUS,

        F1,
        F2,
        F3,
        F4,
        F5,
        F6,
        ;
    }

    fun poll()

    fun clear()

    fun getKeyState(key: Key): KeyState

    fun isKeyPressed(key: Key): Boolean

    fun isKeyPressedOrRepeated(key: Key): Boolean

    fun isKeyReleased(key: Key): Boolean

    fun isKeyRepeated(key: Key): Boolean

    fun getTouchData(): Collection<TouchData>

    fun getClickEvents(): List<ClickEvent>

}
