package xim.poc.game.configuration.v0

import xim.poc.ActorId
import xim.poc.ActorManager
import xim.poc.game.*
import xim.poc.game.configuration.NpcInteraction
import xim.poc.game.event.InventoryItemQualityUpgradeEvent
import xim.poc.ui.ChatLog
import xim.poc.ui.InventoryFilter
import xim.poc.ui.InventoryUi
import xim.poc.ui.ShiftJis
import xim.resource.DatId

private object QualityUpgradeItemFilter: InventoryFilter {
    override fun apply(inventoryItem: InventoryItem): Boolean {
        val augment = inventoryItem.augments ?: return false
        return AugmentHelper.isMaxRank(augment) && inventoryItem.internalQuality < 3
    }
}

object QualityUpgradeInteraction: NpcInteraction {

    private var describedSelf = false

    override fun onInteraction(npcId: ActorId) {
        if (!describedSelf) {
            describedSelf = true
            ChatLog("Any equipment that has reached its ${ShiftJis.colorAug}maximum rank${ShiftJis.colorClear} is eligible for a quality upgrade in exchange for resetting its ${ShiftJis.colorAug}rank${ShiftJis.colorClear}.")
        }

        UiStateHelper.openInventorySelectMode(QualityUpgradeItemFilter, this::handleSelectedItem)
    }

    private fun handleSelectedItem(inventoryItem: InventoryItem?): QueryMenuResponse {
        if (inventoryItem == null) { return QueryMenuResponse.popAll }

        val options = ArrayList<QueryMenuOption>()
        options += QueryMenuOption(text = "Yes.", 0)
        options += QueryMenuOption(text = "No.", -1)

        UiStateHelper.openQueryMode(
            prompt = "Improve item quality and reset rank to 1?",
            options = options,
            drawFn = { drawSelectedItem(inventoryItem) }
        ) { handleConfirmation(it, inventoryItem) }
        return QueryMenuResponse.noop
    }

    private fun drawSelectedItem(inventoryItem: InventoryItem) {
        InventoryUi.drawSelectedInventoryItem(inventoryItem)
    }

    private fun handleConfirmation(queryMenuOption: QueryMenuOption?, inventoryItem: InventoryItem): QueryMenuResponse {
        if (queryMenuOption == null || queryMenuOption.value < 0)  { return QueryMenuResponse.pop }

        GameEngine.submitEvent(InventoryItemQualityUpgradeEvent(ActorStateManager.playerId, inventoryItem.internalId))

        val player = ActorManager.player()
        MiscEffects.playEffect(player, player, 0x1346, DatId.synthesisHq)

        return QueryMenuResponse.popAll
    }

}
