package xim.poc.game.event

import xim.poc.ActorId
import xim.poc.game.AttackContext
import xim.poc.game.MiscEffects
import xim.poc.game.SkillChain
import xim.poc.ui.ChatLog
import kotlin.math.roundToInt

class ActorSkillChainEvent(
    val sourceId: ActorId,
    val targetId: ActorId,
    val skillChain: SkillChain,
    val closingWeaponSkillDamage: Int,
    val context: AttackContext,
) : Event {

    override fun apply(): List<Event> {

        val damageMultiplier = (0.5f * skillChain.attribute.level) * (1.1f * skillChain.numSteps)
        val damage = (closingWeaponSkillDamage * damageMultiplier).roundToInt()

        val outputEvents = ArrayList<Event>()
        outputEvents += ActorDamagedEvent(sourceId, targetId, damage, context)

        context.composeCallback {
            ChatLog("Skillchain! ${skillChain.attribute} [${skillChain.numSteps}]")
            MiscEffects.playSkillChain(sourceId, targetId, skillChain.attribute)
        }

        return outputEvents
    }

}