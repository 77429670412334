package xim.poc.game.event

import xim.poc.ActorId
import xim.poc.game.ActorStateManager
import xim.poc.game.InternalItemId
import xim.resource.EquipSlot

class ActorEquipEvent(
    val sourceId: ActorId,
    val equipment: Map<EquipSlot, InternalItemId?>,
): Event {

    override fun apply(): List<Event> {
        val actorState = ActorStateManager[sourceId] ?: return emptyList()
        if (actorState.isDead()) { return emptyList() }

        for ((equipSlot, internalItemId) in equipment) {
            if (internalItemId == null) {
                actorState.unequip(equipSlot)
                continue
            }

            val item = actorState.inventory.getByInternalId(internalItemId) ?: continue
            actorState.equip(equipSlot, item)
        }

        return emptyList()
    }

}