package xim.poc

import xim.math.Vector2f
import xim.math.Vector3f
import xim.poc.audio.AudioManager
import xim.poc.browser.DatLoader
import xim.poc.browser.Keyboard
import xim.poc.browser.LocalStorage
import xim.poc.browser.PlatformDependencies
import xim.poc.camera.CameraReference
import xim.poc.camera.PolarCamera
import xim.poc.game.*
import xim.poc.game.configuration.ZoneDefinitionManager
import xim.poc.gl.Drawer
import xim.poc.gl.FrameBufferManager
import xim.poc.tools.*
import xim.poc.ui.*
import xim.resource.DatId
import xim.resource.DirectoryResource
import xim.resource.InventoryItems
import xim.resource.TextureLink
import xim.resource.table.*
import xim.util.Fps.millisToFrames
import xim.util.OncePerFrame
import xim.util.Timer
import kotlin.math.min

object GlobalDirectory {
    lateinit var directoryResource: DirectoryResource
    lateinit var shadowResource: DirectoryResource
    val shadowTexture by lazy { TextureLink("system  kage_tex", shadowResource) }
}

object MainTool {

    lateinit var platformDependencies: PlatformDependencies
    lateinit var drawer: Drawer

    private var resourceDependenciesAreLoaded = false
    private var setupZone = false
    private var welcomed = false

    fun run(platformDependencies: PlatformDependencies) {
        this.platformDependencies = platformDependencies

        val viewExecutor = platformDependencies.viewExecutor
        drawer = platformDependencies.drawer

        CameraReference.setInstance(PolarCamera(Vector3f(), Vector3f(0f, -1.5f, 0f), MainTool.platformDependencies.screenSettingsSupplier))

        viewExecutor.beginDrawing {
            try {
                Timer.time("Loop") { loop(it) }
                Timer.report()
            } catch (t: Throwable) {
                t.printStackTrace()
                throw t
            }
        }
    }

    private fun loop(elapsedTimeInMs: Double) {
        platformDependencies.keyboard.poll()

        val currentLogicalFrameIncrement = millisToFrames(elapsedTimeInMs)
        val throttledFrameIncrement = min(3f, currentLogicalFrameIncrement.toFloat())

        val gameFrameIncrement = if (isCheckBox("pause")) {
            0f
        } else {
            if (!isCheckBox("todOverride")) { EnvironmentManager.advanceTime(currentLogicalFrameIncrement) }
            throttledFrameIncrement * GameState.gameSpeed
        }

        OncePerFrame.onNewFrame()
        internalLoop(gameFrameIncrement, throttledFrameIncrement)
    }

    fun internalLoop(elapsedFrames: Float, trueElapsedFrames: Float) {
        if (!resourceDependenciesLoaded()) {
            return drawDownloadingScreenCover(elapsedFrames)
        }

        refreshCurrentSceneIfNeeded()
        if (!SceneManager.isFullyLoaded()) {
            return drawDownloadingScreenCover(elapsedFrames)
        }

        // "Game" logic
        ZoneChanger.update(elapsedFrames)
        setupZoneGameLogicIfNeeded()

        Timer.time("gameLogic") { GameEngine.tick(elapsedFrames) }

        // Update entities
        val currentScene = SceneManager.getCurrentScene()
        currentScene.update(elapsedFrames)

        if (!ZoneChanger.isChangingZones()) {
            val zoneInteraction = Timer.time("checkInteractions") { currentScene.checkInteractions() }
            if (zoneInteraction != null) { ZoneChanger.beginChangeZone(zoneInteraction) }
        }

        handleKeyEvents()
        ClickHandler.handleClickEvents()
        PlayerTargetSelector.updateTarget()

        ActorManager.updateAll(elapsedFrames)
        CameraReference.getInstance().update(platformDependencies.keyboard, trueElapsedFrames)

        val playerActor = ActorStateManager.player()
        val playerActorCollision = playerActor.lastCollisionResult
        val zoneObjectsByArea = Timer.time("cullZoneObjects") { currentScene.getVisibleZoneObjects(CameraReference.getInstance(), CullContext(shadowMode = false), playerActor) }

        Timer.time("updateEffects") { EffectManager.update(elapsedFrames) }
        val lightingParticles = EffectManager.getLightingParticles()

        EnvironmentManager.update(elapsedFrames)

        val (playerEnvironmentArea, playerEnvironmentId) = playerActorCollision.getEnvironment()
        EnvironmentManager.updateWeatherAudio(currentScene.getMainArea(), playerEnvironmentId)
        val drawDistance = EnvironmentManager.getDrawDistance(playerEnvironmentArea, playerEnvironmentId)

        UiStateHelper.update(trueElapsedFrames)

        Timer.time("updateAudio") { AudioManager.update(elapsedFrames) }

        AoeIndicators.update()

        // Begin 3D effects
        FrameBufferManager.releaseClaimedBuffers()
        FrameBufferManager.bindAndClearScreenBuffer()

        // The sky
        drawer.setupXim(CameraReference.getInstance())
        EnvironmentManager.drawSkyBox(drawer)

        // The zone
        Timer.time("drawZone") { zoneObjectsByArea.forEach { ZoneDrawer.drawZoneObjects(it.key, it.value, drawDistance, CameraReference.getInstance(), lightingParticles) } }

        // Actor models
        ActorDrawer.update(elapsedFrames)

        drawer.setupXimSkinned(CameraReference.getInstance())
        for (actor in ActorManager.getVisibleActors()) {
            val hasDebugOverride = ZoneObjectTool.drawActor(actor)
            if (hasDebugOverride) { continue }

            Timer.time("drawActor") { ActorDrawer.drawActor(actor, lightingParticles) }
            Timer.time("emitFootSteps") { ZoneDrawer.emitFootSteps(actor, GlobalDirectory.directoryResource, currentScene.getMainAreaRootDirectory()) }
        }

        // Particles & Effects
        val postEffects = Timer.time("drawParticles") { ParticleDrawer.drawAllParticlesAndGetDecals(lightingParticles) }

        Timer.time("drawDecal") { ZoneDrawer.drawDecalEffects(postEffects.decalEffects, drawDistance) }
        Timer.time("drawLensFlare") { ZoneDrawer.drawLensFlares(postEffects.lensFlareEffects) }

        // 3D Debug tool
        Timer.time("debugTools3d") {
            LineDrawingTool.renderLines(drawer)
            BoxDrawingTool.render(drawer)
            SphereDrawingTool.render(drawer)
        }

        // 3D effects are done, so output the screen-buffer
        FrameBufferManager.unbind()
        drawer.drawScreenBuffer(FrameBufferManager.getCurrentScreenBuffer())

        // 2D & UI
        drawer.setupXimUi()
        ScreenFlasher.draw()

        if (GameState.gameSpeed == 0f) {
            UiElementHelper.drawBlackScreenCover(0.3f)
        } else if (GameState.gameSpeed < 1f) {
            UiElementHelper.drawBlackScreenCover(0.1f)
        }

        Timer.time("updateUi") { UiElementHelper.update(trueElapsedFrames) }

        MapDrawer.draw(playerActorCollision)

        if (!isCheckBox("UiDisabled")) {
            Timer.time("drawUi") {
                PartyUi.draw()

                UiElementTool.drawPreviewElement()

                UiStateHelper.draw()
                CastTimeUi.draw()
            }
        }

        ZoneChanger.drawScreenFade()

        // 2D & misc Debug tools
        Timer.time("debugTools") {
            PlayerPositionTracker.update()
            PlayerAnimationTrackerTool.update()
            EnvironmentTool.update()
            AudioVolumeTool.update()
            RoutineViewer.update()
            TexturePreviewer.draw()
            ParticleGenTool.update()
            PlayerLookTool.update()
            LocalStorage.persistPlayerState()
        }

        drawer.finish()
    }

    private fun resourceDependenciesLoaded(): Boolean {
        if (resourceDependenciesAreLoaded) { return true }

        if (!DatLoader.isReady()) { return false }

        val systemEffects = DatLoader.load("ROM/0/0.DAT").onReady { GlobalDirectory.directoryResource = it.getAsResource() }
        DatLoader.load("ROM/27/81.DAT").onReady { GlobalDirectory.shadowResource = it.getAsResource() }
        FontMojiHelper.register()

        val tables = listOf(MainDll, FileTableManager, ItemModelTable, SpellAnimationTable, SpellNameTable, SpellInfoTable, ZoneNameTable, ZoneSettingsTable, AbilityInfoTable, AbilityNameTable, AbilityDescriptionTable, AbilityTable, StatusEffectNameTable, StatusEffectIcons, NpcTable, MountNameTable, ItemAnimationTable, MobSkillNameTable, MobSkillInfoTable, InventoryItems)
        tables.forEach { it.preload() }
        if (tables.any { !it.isFullyLoaded() }) { return false }

        UiResourceManager.prefetch()
        PcModelLoader.preload()
        FrameBufferManager.setup()
        if (!FrameBufferManager.isReady() || !systemEffects.isReady() || !PcModelLoader.isFullyLoaded()) { return false }

        GameEngine.setup()

        resourceDependenciesAreLoaded = true
        return true
    }

    private fun refreshCurrentSceneIfNeeded() {
        val player = ActorStateManager.player()
        val playerZoneSettings = player.zone ?: throw IllegalStateException("Player starting position was not configured")
        val playerZoneConfig = ZoneConfig(zoneId = playerZoneSettings.zoneId, startPosition = Vector3f(player.position), customDefinition = ZoneDefinitionManager[playerZoneSettings.zoneId])

        val currentScene = SceneManager.getNullableCurrentScene()
        if (currentScene?.config?.zoneId == playerZoneConfig.zoneId) { return }

        SceneManager.unloadScene()
        setupZone = false

        ActorStateManager.clear()
        ActorManager.clear()

        MapDrawer.clear()
        FrameCoherence.clear()
        EffectManager.clearAllEffects()

        ParticleGenTool.clear()
        RoutineViewer.clear()

        SceneManager.loadScene(playerZoneConfig, playerZoneSettings.subAreaId)
    }

    private fun drawDownloadingScreenCover(elapsedFrames: Float) {
        drawer.setupXimUi()
        UiElementHelper.drawBlackScreenCover(opacity = 1f)
        UiElementHelper.update(elapsedFrames)
        UiElementHelper.drawDownloadingDataElement(Vector2f(620f, 550f).scale(UiElementHelper.offsetScaling))
    }

    private fun setupZoneGameLogicIfNeeded() {
        if (setupZone) { return }
        setupZone = true

        emitWelcomeMessage()

        val playerActor = ActorManager.player()
        playerActor.onReadyToDraw { it.playRoutine(DatId.pop) }
        CameraReference.getInstance().setTarget(playerActor.displayPosition)

        GameState.setZoneLogic(null)
        val zoneConfig = SceneManager.getCurrentScene().config
        zoneConfig.customDefinition?.onLoad?.invoke()

        ZoneChanger.onZoneIn()

        EnvironmentManager.setScene(SceneManager.getCurrentScene())

        val zoneName = ZoneNameTable.first(zoneConfig)
        ChatLog.addLine("=== Area: $zoneName ===")
        ChatLog.addLine("The maximum number of people are already participating in Unity chat. Please try again later.", chatLogColor = ChatLogColor.Info)

        setupDebugTools()
    }

    private fun emitWelcomeMessage() {
        if (welcomed) { return }
        welcomed = true
        ChatLog.addLine("<<< Welcome to Xim! >>>", ChatLogColor.SystemMessage)

        ChatLog.addLine("${ShiftJis.solidSquare} Assist Channel added for New Players and Returnees!", ChatLogColor.SystemMessage)
        ChatLog.addLine("${ShiftJis.solidSquare} Jeuno, Back in the Spotlight Yet Again ${ShiftJis.leftBracket}until February 12 at 6:59 (PST) / 14:59 (GMT)${ShiftJis.rightBracket}", ChatLogColor.SystemMessage)
    }

    private fun setupDebugTools() {
        FrameTool.setup()
        ScreenSettingsTool.setup()
        AudioVolumeTool.setup()
        EnvironmentTool.setup()
        ZoneObjectTool.setup(SceneManager.getCurrentScene().getMainAreaRootDirectory())
        ZoneNpcTool.setup()
        NpcSpawningTool.setup()
        FurnitureSpawningTool.setup()
        RoutineViewer.setup(SceneManager.getCurrentScene().getMainArea())
        SpellHelper.setup()
        PlayerCustomizer.setup()
        InventoryTool.setup()
        UiElementTool.setup()
        ZoneChangeTool.setup()
    }

    private fun handleKeyEvents() {
        if (ZoneChanger.isChangingZones()) { return }
        val player = ActorStateManager.player()

        if (platformDependencies.keyboard.isKeyPressed(Keyboard.Key.H)) {
            if (player.targetState.targetId != null) {
                GameEngine.toggleTargetLock(player.id)
            }  else {
                GameEngine.toggleResting(player.id)
            }
        }
    }

}
