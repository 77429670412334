package xim.poc.ui

import xim.math.Vector2f
import xim.math.Vector3f
import xim.poc.*
import xim.poc.browser.DatLoader
import xim.poc.browser.DatWrapper
import xim.poc.browser.Keyboard
import xim.poc.game.ActorCollision
import xim.poc.gl.Color.Companion.HALF_ALPHA
import xim.resource.DirectoryResource
import xim.resource.UiElementResource
import xim.resource.table.FileTableManager
import xim.resource.table.ZoneMap
import xim.resource.table.ZoneMapTable
import kotlin.math.floor
import kotlin.math.roundToInt

object MapDrawer {

    private val loaded = HashMap<String, DatWrapper>()
    private var drawMap = false
    private var mostRecentMap: ZoneMap? = null

    fun draw(playerCollision: ActorCollision) {
        toggleOnKeyPress()
        val firstCollision = getCollision(playerCollision) ?: return

        val mapId = firstCollision.collisionObject.transformInfo.mapId
        val zone = SceneManager.getCurrentScene().config

        val zoneMap = ZoneMapTable[zone, mapId]
        mostRecentMap = zoneMap
        if (zoneMap == null) { return }

        val fileName = FileTableManager.getFilePath(zoneMap.fileId) ?: throw IllegalStateException("Map not found?")

        val wrapper = DatLoader.load(fileName)
        loaded[fileName] = wrapper

        val resource = wrapper.getAsResourceIfReady()
        if (resource != null) { draw(zoneMap, resource) }
    }

    fun getPlayerMapCoordinates(): Pair<String, String> {
        val zoneMap = mostRecentMap ?: return Pair("?", "?")

        val mapPosition = getPlayerMapPosition(ActorManager.player().displayPosition, zoneMap) + zoneMap.offset * -1f

        val mapPosX = floor(15f * mapPosition.x / 512f).roundToInt()
        val mapPosY = floor(15f * mapPosition.y / 512f).roundToInt()

        return Pair(('A' + mapPosX).toString(), (1 + mapPosY).toString())
    }

    fun clear() {
        loaded.entries.forEach {
            it.value.getAsResourceIfReady()?.release()
            DatLoader.release(it.key)
        }
        loaded.clear()
        mostRecentMap = null
    }

    private fun toggleOnKeyPress() {
        val pressed = MainTool.platformDependencies.keyboard.isKeyPressed(Keyboard.Key.M)
        if (!pressed) { return }
        toggle()
    }

    fun toggle() {
        drawMap = !drawMap
    }

    private fun getCollision(actorCollision: ActorCollision): CollisionProperty? {
        val currentScene = SceneManager.getCurrentScene()

        val collisionsByMain = actorCollision.collisionsByArea[currentScene.getMainArea()]
        if (!collisionsByMain.isNullOrEmpty()) {
            return collisionsByMain.first()
        }

        val subArea = currentScene.getSubArea() ?: return null
        val collisionsBySub = actorCollision.collisionsByArea[subArea]
        if (!collisionsBySub.isNullOrEmpty()) {
            return collisionsBySub.first()
        }

        return null
    }

    private fun draw(zoneMap: ZoneMap, directoryResource: DirectoryResource) {
        if (!drawMap) { return }

        val resource = directoryResource.getOnlyChildByType(UiElementResource::class)
        val group = resource.uiElementGroup

        val offset = zoneMap.offset * -1f
        UiElementHelper.drawUiElement(lookup = group.name, index = 0, position = offset, color = HALF_ALPHA)

        val actor = ActorManager.player()
        val mapPos = getPlayerMapPosition(actor.displayPosition, zoneMap)
        UiElementHelper.drawUiElement(lookup = "menu    keytops3", index = 159, position = mapPos + offset, color = HALF_ALPHA, rotation = actor.displayFacingDir)
    }

    private fun getPlayerMapPosition(actorPos: Vector3f, zoneMap: ZoneMap): Vector2f {
        return Vector2f(0.5f + actorPos.x / zoneMap.size * 512f, -0.5f + -actorPos.z / zoneMap.size * 512f)
    }

}