package xim.poc.game.configuration

import xim.poc.*
import xim.poc.gl.ByteColor
import xim.resource.Blur
import xim.resource.BlurConfig

typealias MonsterId = Int

object MonsterDefinitions {

    private val definitions = HashMap<MonsterId, MonsterDefinition>()

    operator fun get(monsterId: MonsterId?): MonsterDefinition? {
        monsterId ?: return null
        return definitions[monsterId]
    }

    operator fun set(monsterId: MonsterId, monsterDefinition: MonsterDefinition) {
        if (definitions.containsKey(monsterId)) { throw IllegalStateException("Monster definition $monsterId is already defined by ${definitions[monsterId]}") }
        definitions[monsterId] = monsterDefinition
    }

}

fun standardBlurConfig(color: ByteColor, radius: Float = 70f): BlurConfig {
    val configs = ArrayList<Blur>()
    repeat(5) { configs += Blur(radius, color) }
    return BlurConfig(configs)
}

data class MonsterDefinition(
    val id: MonsterId,
    val name: String,
    val look: ModelLook,
    val behaviorId: BehaviorId = ActorBehaviors.BasicMonsterController,
    val movementController: ActorController = DefaultEnemyController(),
    val mobSkills: List<Int> = emptyList(),
    val mobSpells: List<Int> = emptyList(),
    val customModelSettings: CustomModelSettings = CustomModelSettings(),
    val onSpawn: ((Actor) -> Unit)? = null
) { init { MonsterDefinitions[id] = this } }

class MonsterInstance(val monsterDefinition: MonsterDefinition, val actorId: ActorId)