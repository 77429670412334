package xim.poc.game.configuration.zones

import xim.poc.ActorId
import xim.poc.ActorManager
import xim.poc.game.*
import xim.poc.game.configuration.MonsterSpawnerInstance
import xim.poc.game.configuration.NpcInteraction
import xim.poc.game.configuration.playerDistanceCheck
import xim.poc.ui.InventoryUi
import xim.poc.ui.ShiftJis
import xim.resource.DatId
import xim.resource.InventoryItems

class SpawnChestInteraction(val monsterSpawnerInstance: MonsterSpawnerInstance) : NpcInteraction {

    private var displayOpened = false

    override fun onInteraction(npcId: ActorId) {
        if (!playerDistanceCheck(5f, npcId)) { return }

        val inventory = inventory(npcId)
        if (inventory == null || inventory.inventoryItems.isEmpty()) { return }

        val progress = monsterSpawnerInstance.getProgress() ?: return
        var prompt = "Progress: ${progress.first}/${progress.second}"

        if (monsterSpawnerInstance.hasDefeatedAllMonsters()) {
            onOpened(npcId)
            prompt = "${ShiftJis.colorItem}${prompt}${ShiftJis.colorItem}"
        }

        UiStateHelper.openQueryMode(
            prompt = prompt,
            options = getOptions(npcId),
            drawFn = { drawHoveredItem(npcId, it) },
        ) { handleSelection(npcId, it) }
    }

    private fun getOptions(npcId: ActorId): List<QueryMenuOption> {
        val inventory = inventory(npcId) ?: return emptyList()
        if (inventory.inventoryItems.isEmpty()) { return emptyList() }

        val options = ArrayList<QueryMenuOption>()

        if (monsterSpawnerInstance.hasDefeatedAllMonsters()) {
            options += QueryMenuOption("Take nothing.", -2)
            options += QueryMenuOption("Take all.", -1)
        }

        inventory.inventoryItems.forEachIndexed { index, item ->
            val info = InventoryItems[item.id]
            options += QueryMenuOption(info.name, index)
        }

        return options
    }

    private fun handleSelection(npcId: ActorId, queryMenuOption: QueryMenuOption?) {
        if (!monsterSpawnerInstance.hasDefeatedAllMonsters()) { return }

        if (queryMenuOption == null || queryMenuOption.value == -2) { return }
        val inventory = inventory(npcId) ?: return

        if (queryMenuOption.value == -1) {
            inventory.inventoryItems.forEach { claimItem(npcId, it) }
            onEmpty(npcId)
        } else {
            val item = inventory.inventoryItems.getOrNull(queryMenuOption.value) ?: return
            claimItem(npcId, item)
            if (inventory.inventoryItems.size == 1) { onEmpty(npcId) }
        }
    }

    private fun inventory(npcId: ActorId): Inventory? {
        return ActorStateManager[npcId]?.inventory
    }

    private fun claimItem(npcId: ActorId, inventoryItem: InventoryItem) {
        GameEngine.submitDiscardItem(npcId, inventoryItem)
        GameEngine.submitGainItem(ActorStateManager.playerId, inventoryItem.info())
    }

    private fun onOpened(npcId: ActorId) {
        if (displayOpened) { return }
        displayOpened = true
        ActorManager[npcId]?.playRoutine(DatId("open"))
    }

    private fun onEmpty(npcId: ActorId) {
        val actorState = ActorStateManager[npcId] ?: return
        actorState.setHp(0)

        val actor = ActorManager[npcId] ?: return
        actor.enqueueModelRoutine(DatId("clos"))
        actor.enqueueModelRoutine(DatId("ntoe"))
    }

    private fun drawHoveredItem(npcId: ActorId, queryMenuOption: QueryMenuOption) {
        val inventory = inventory(npcId) ?: return
        val item = inventory.inventoryItems.getOrNull(queryMenuOption.value) ?: return
        InventoryUi.drawSelectedInventoryItem(item)
    }

}