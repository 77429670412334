package xim.poc.game

import kotlinx.serialization.Serializable
import xim.resource.EquipSlot
import xim.resource.InventoryItems
import kotlin.random.Random

typealias InternalItemId = Long

@Serializable
data class InventoryItem(val id: Int, val internalId: InternalItemId = Random.nextLong(), var quantity: Int = 1) {
    fun info() = InventoryItems[id]
}

@Serializable
class Inventory {

    companion object {
        fun player() = ActorStateManager.player().inventory
    }

    val inventoryItems = ArrayList<InventoryItem>()

    fun getInventoryItemsByEquipSlot(equipSlot: EquipSlot) : List<InventoryItem> {
        return inventoryItems.filter { InventoryItems[it.id].equipmentItemInfo.equipSlots.contains(equipSlot) }
    }

    fun addItem(id: Int, quantity: Int) {
        repeat(quantity) { addItem(id) }
    }

    fun addItem(id: Int) {
        val itemInfo = InventoryItems[id]

        if (!itemInfo.isStackable()) {
            inventoryItems += InventoryItem(id)
            return
        }

        val current = inventoryItems.firstOrNull { it.id == id }
        if (current != null) {
            current.quantity += 1
        } else {
            inventoryItems += InventoryItem(id, quantity = 1)
        }
    }

    fun discardItem(internalItemId: InternalItemId) {
        inventoryItems.removeAll { it.internalId == internalItemId }
    }

    fun sort() {
        inventoryItems.sortWith(this::compare)
    }

    fun copyFrom(other: Inventory) {
        inventoryItems.clear()
        inventoryItems.addAll(other.inventoryItems)
    }

    fun getByInternalId(internalItemId: InternalItemId): InventoryItem? {
        return inventoryItems.firstOrNull { it.internalId == internalItemId }
    }

    private fun compare(a: InventoryItem, b: InventoryItem): Int {
        val aInfo = a.info()
        val bInfo = b.info()

        val typeComparison = aInfo.itemType.sortOrder.compareTo(bInfo.itemType.sortOrder)
        if (typeComparison != 0) { return typeComparison }

        val aMask = aInfo.equipmentItemInfo.equipSlots.firstOrNull()?.mask
        val bMask = bInfo.equipmentItemInfo.equipSlots.firstOrNull()?.mask

        if (aMask != null && bMask != null) {
            return aMask.compareTo(bMask)
        } else if (aMask != null) {
            return 1
        } else if (bMask != null) {
            return -1
        }

        return a.id.compareTo(b.id)
    }

}