package xim.poc.tools

import web.dom.Element
import web.dom.document

object DebugToolsManager {

    fun showDebugOnlyTools() {
        val debugElements = document.getElementsByClassName("debugTool")

        val elements = ArrayList<Element>()
        val iterator = debugElements.iterator()
        while (iterator.hasNext()) { elements += iterator.next() }

        elements.forEach { it.classList.remove("debugTool") }
    }

}