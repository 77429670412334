package xim.poc.game.configuration

import xim.poc.game.GameEngine
import xim.poc.game.configuration.SkillApplierHelper.makeApplier
import xim.poc.game.event.TrustSummonEvent
import xim.resource.MagicType
import xim.resource.SpellInfo
import xim.resource.table.SpellInfoTable

object CommonSkillAppliers {

    val weaponSkillValidator = SkillApplierHelper.SkillUsableEvaluator {
        val cost = GameEngine.getAbilityCost(it.skillInfo.id).value
        if (!it.sourceState.isEngaged()) {
            SkillFailureReason.NotEngaged
        } else if (it.sourceState.getTp() < cost) {
            SkillFailureReason.NotEnoughTp
        } else {
            null
        }
    }

    fun registerTrustAppliers() {
        (0 until 1024)
            .map { SpellInfoTable[it] }
            .filter { it.magicType == MagicType.Trust }
            .forEach { SkillAppliers += makeApplier(spellInfo = it, targetEvaluator = summonTrust(it)) }
    }

    private fun summonTrust(spellInfo: SpellInfo): SkillApplierHelper.TargetEvaluator {
        return SkillApplierHelper.TargetEvaluator {
            listOf(TrustSummonEvent(it.sourceState.id, spellInfo.index))
        }
    }

}