package xim.poc.game.event

import xim.math.Vector3f
import xim.poc.ActorId
import xim.poc.ActorManager
import xim.poc.game.ActorStateManager
import xim.poc.game.configuration.GatheringType

class GatheringEvent(
    val actorId: ActorId,
    val nodeId: ActorId,
): Event {

    override fun apply(): List<Event> {
        val actor = ActorStateManager[actorId] ?: return emptyList()
        if (!actor.isIdle()) { return emptyList() }

        val node = ActorStateManager[nodeId] ?: return emptyList()
        if (node.isDead()) { return emptyList() }

        val gatheringState = node.gatheringNodeState ?: return emptyList()
        if (Vector3f.distance(actor.position, node.position) > 3f) { return emptyList() }

        actor.faceToward(node)
        actor.gatheringState.startGathering()

        val output = ArrayList<Event>()

        val item = gatheringState.applyGatherAttempt()
        output += InventoryGainEvent(actorId, itemId = item.itemId)

        node.consumeHp(1)
        if (node.isDead()) { ActorManager[nodeId]?.onDisplayDeath() }

        val actorDisplay = ActorManager[actorId]
        when (gatheringState.gatheringConfiguration.type) {
            GatheringType.Harvesting -> actorDisplay?.playEmote(7, 0)
            GatheringType.Logging -> actorDisplay?.playEmote(5, 0)
            GatheringType.Mining -> actorDisplay?.playEmote(6, 0)
        }

        return output
    }

}