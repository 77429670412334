package xim.poc.game.event

import xim.poc.ActorId
import xim.poc.ActorManager
import xim.poc.game.ActorStateManager
import xim.poc.game.AttackContext
import xim.poc.game.GameState

enum class AutoAttackType {
    Main,
    Sub,
    H2H,
}

data class AutoAttackResult(val context: AttackContext, val tpGained: Int, val damageDone: Int, val targetId: ActorId, val type: AutoAttackType)

class AutoAttackEvent(
    val sourceId: ActorId,
): Event {

    override fun apply(): List<Event> {
        val sourceState = ActorStateManager[sourceId] ?: return emptyList()
        if (!sourceState.isEngaged() || sourceState.isDead()) { return emptyList() }

        val targetId = sourceState.targetState.targetId ?: return emptyList()
        val targetState = ActorStateManager[targetId] ?: return emptyList()
        if (targetState.isDead()) { return emptyList() }

        val actor = ActorManager[sourceId]
        val outputEvents = ArrayList<Event>()

        val targetCastingState = targetState.getCastingState()
        if (targetCastingState?.spellInfo != null) { outputEvents += CastInterruptedEvent(targetId) }

        val results = GameState.getGameMode().getAutoAttackResult(sourceState, targetState)
        outputEvents += results.map { ActorDamagedEvent(sourceId = sourceId, targetId = it.targetId, amount = it.damageDone, actionContext = it.context) }
        sourceState.gainTp(results.sumOf { it.tpGained })
        results.forEach { actor?.displayAutoAttack(it) }

        return outputEvents
    }

}