package xim.poc.game.event

import xim.poc.ActorId
import xim.poc.game.ActorStateManager
import xim.poc.game.StatusEffect

class StatusEffectLostEvent(
    val sourceId: ActorId,
    val statusId: Int,
): Event {

    override fun apply(): List<Event> {
        val sourceState = ActorStateManager[sourceId] ?: return emptyList()
        val events = ArrayList<Event>()

        if (statusId == StatusEffect.Indicolure.id && sourceState.bubble != null) {
            events += BubbleReleaseEvent(sourceId)
        }

        return events
    }

}