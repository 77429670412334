package xim.poc.game.event

import xim.poc.ActorId
import xim.poc.game.ActorStateManager

class ActorTargetEvent(
    val sourceId: ActorId,
    val targetId: ActorId?,
    val locked: Boolean = false,
): Event {

    override fun apply(): List<Event> {
        val sourceState = ActorStateManager[sourceId] ?: return emptyList()
        if (sourceState.isDead()) { return emptyList() }

        val actualTarget = ActorStateManager[targetId]?.id
        sourceState.setTargetState(actualTarget, locked)

        return emptyList()
    }

}