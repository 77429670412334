package xim.poc.game

import xim.math.Vector3f
import xim.poc.*
import xim.poc.game.configuration.ActorBehaviors
import xim.poc.game.event.InitialActorState
import xim.resource.AbilityInfo
import xim.resource.EquipSlot
import xim.resource.Skill
import xim.resource.SpellInfo
import xim.resource.table.ItemModelTable
import xim.resource.table.NpcInfo
import xim.resource.table.StatusEffectHelper
import xim.util.Fps.secondsToFrames
import kotlin.math.atan2
import kotlin.math.floor
import kotlin.random.Random
import kotlin.time.Duration

enum class ActorType {
    Pc,
    Npc,
    Enemy,
    Effect,
}

class ActorState(val id: ActorId, initialActorState: InitialActorState) {

    val name = initialActorState.name
    val type = initialActorState.type

    var zone = initialActorState.zoneSettings?.copy()

    val position = Vector3f(initialActorState.position)
    var rotation = initialActorState.rotation
    val velocity = Vector3f()
    var lastCollisionResult: ActorCollision = ActorCollision()

    private val movementController = initialActorState.movementController

    private val npcInfo = initialActorState.npcInfo

    private var baseLook = ModelLook.blank()
    val equipment = Equipment()

    private var castingState: CastingState? = null
    private var synthesisState: SynthesisState? = null
    private val recastStates = RecastStates()
    private val restingState = RestingState()
    private val engagedState = EngagedState()
    private val deathState = DeathState()
    private val statusEffects = HashMap<Int, StatusEffectState>()

    val skillChainTargetState = SkillChainTargetState()
    val gatheringState = GatheringState()
    val rangedAttackRecast = RangedAttackRecast()

    val inventory = Inventory()

    val jobState = JobState()
    val jobLevels = JobLevels()

    var targetState = TargetState(targetId = null, locked = false)
        private set

    val owner: ActorId? = initialActorState.mountSettings?.riderId
        ?: initialActorState.petSettings?.ownerId
        ?: initialActorState.bubbleSettings?.ownerId
        ?: initialActorState.trustSettings?.ownerId

    var mountedState: Mount? = null
    var pet: ActorId? = null
    var bubble: ActorId? = null

    val isMount = initialActorState.mountSettings != null
    val isBubble = initialActorState.bubbleSettings != null

    val doorState = DoorState()
    val gatheringNodeState: GatheringNodeState? = initialActorState.gatheringConfiguration?.let { GatheringNodeState(it) }

    val monsterId = initialActorState.monsterId
    val behaviorController = ActorBehaviors.createController(initialActorState.behaviorController, this)

    private var hp = 20
    private var maxHp = 20

    private var mp = 999
    private var maxMp = 999

    private var tp = 0
    private var maxTp = 3000

    fun getCastingState(): CastingState? {
        return castingState
    }

    fun isCasting(): Boolean {
        return getCastingState() != null
    }

    fun setCastingState(castingState: CastingState?) {
        this.castingState = castingState
    }

    fun getRecastStates(): RecastStates {
        return recastStates
    }

    fun getRecastDelay(spellInfo: SpellInfo): RecastState? {
        return recastStates.getSpellRecastState(spellInfo)
    }

    fun getRecastDelay(abilityInfo: AbilityInfo): RecastState? {
        return recastStates.getAbilityRecastState(abilityInfo)
    }

    fun isResting(): Boolean {
        return restingState.isResting()
    }

    fun getRestingState(): RestingState {
        return restingState
    }

    fun isSynthesizing(): Boolean {
        return synthesisState != null
    }

    fun setSynthesisState(synthesisState: SynthesisState?) {
        this.synthesisState = synthesisState
    }

    fun getSynthesisState(): SynthesisState? {
        return synthesisState
    }

    fun setEngagedState(state: EngagedState.State) {
        engagedState.state = state
    }

    fun isEngaged(): Boolean {
        return engagedState.state == EngagedState.State.Engaged
    }

    fun getMainHandDamage(): Int {
        return delayToDamage(getMainDelayInFrames())
    }

    fun getSubHandDamage(): Int {
        return delayToDamage(getSubDelayInFrames())
    }

    fun getHp(): Int {
        return hp
    }

    fun setHp(amount: Int) {
        hp = amount.coerceIn(0, maxHp)
    }

    fun setMaxHp(amount: Int) {
        maxHp = amount
        hp = hp.coerceAtMost(maxHp)
    }

    fun consumeHp(cost: Int) {
        hp -= cost
        hp = hp.coerceAtLeast(0)
    }

    fun gainHp(amount: Int) {
        hp += amount
        hp = hp.coerceAtMost(maxHp)
    }

    fun getMaxHp(): Int {
        return maxHp
    }

    fun getHpp(): Float {
        return getHp().toFloat() / getMaxHp().toFloat()
    }

    fun isDead(): Boolean {
        return hp == 0
    }

    fun getMp(): Int {
        return mp
    }

    fun setMp(amount: Int) {
        mp = amount.coerceIn(0, maxMp)
    }

    fun consumeMp(cost: Int) {
        mp -= cost
        mp = mp.coerceAtLeast(0)
    }

    fun gainMp(amount: Int) {
        mp += amount
        mp = mp.coerceAtMost(maxMp)
    }

    fun getMaxMp(): Int {
        return maxMp
    }

    fun getMpp(): Float {
        return getMp().toFloat() / getMaxMp().toFloat()
    }

    fun gainTp(amount: Int) {
        setTp(tp + amount)
    }

    fun consumeTp(amount: Int) {
        setTp(tp - amount)
    }

    fun setTp(amount: Int) {
        tp = amount.coerceIn(0, maxTp)
    }

    fun getTp(): Int {
        return tp
    }

    fun getTpp(): Float {
        return tp.toFloat() / maxTp.toFloat()
    }

    fun timeSinceDeath(): Duration {
        return deathState.timeSinceDeath()
    }

    fun hasBeenDeadFor(duration: Duration): Boolean {
        if (!isDead()) { return false }
        return deathState.hasBeenDeadFor(duration)
    }

    fun updateDeathTimer(elapsedFrames: Float) {
        if (!isDead()) {
            deathState.framesSinceDeath = 0f
        } else {
            deathState.framesSinceDeath += elapsedFrames
        }
    }

    fun setBaseLook(modelLook: ModelLook) {
        this.baseLook = modelLook
        updateBaseLookFromEquipment()
    }

    fun getBaseLook(): ModelLook {
        return baseLook
    }

    fun getCurrentLook(): ModelLook {
        val costume = statusEffects[StatusEffect.Costume.id]
        if (costume != null) { return ModelLook.npc(costume.counter) }

        return baseLook
    }

    fun isDualWield(): Boolean {
        val sub = equipment.getItem(inventory, EquipSlot.Sub) ?: return false
        val subInfo = sub.info()

        val equipSlots = subInfo.equipmentItemInfo.equipSlots
        return equipSlots.contains(EquipSlot.Main) && equipSlots.contains(EquipSlot.Sub)
    }

    fun isHandToHand(): Boolean {
        val main = equipment.getItem(inventory, EquipSlot.Main) ?: return false
        val mainInfo = main.info()
        return mainInfo.skill() == Skill.HandToHand
    }

    fun getRangedAttackItems(): Pair<InventoryItem?, InventoryItem?>? {
        val ranged = getEquipment(EquipSlot.Range)
        val rangedSkill = ranged?.info()?.skill()

        val ammo = getEquipment(EquipSlot.Ammo)
        val ammoSkill = ammo?.info()?.skill()

        return if (rangedSkill == Skill.Thrown) {
            Pair(ranged, null)
        } else if (ranged != null && ranged.info().isBowOrGun()) {
            if (rangedSkill == ammoSkill) { Pair(ranged, ammo) } else { null }
        } else if (ranged == null && ammoSkill == Skill.Thrown) {
            Pair(null, ammo)
        } else {
            null
        }
    }

    fun equip(equipSlot: EquipSlot, item: InventoryItem) {
        equipment.setItem(equipSlot, item)
        validateEquipment()
        updateBaseLookFromEquipment()
    }

    fun unequip(equipSlot: EquipSlot) {
        equipment.setItem(equipSlot, null)
        validateEquipment()
        updateBaseLookFromEquipment()
    }

    private fun validateEquipment() {
        if (!equipment.validateSub(inventory)) { equipment.setItem(EquipSlot.Sub, null) }
        if (!equipment.validateAmmo(inventory)) { equipment.setItem(EquipSlot.Ammo, null) }
    }

    fun isEquipped(item: InventoryItem): Boolean {
        return equipment.isEquipped(item)
    }

    private fun updateBaseLookFromEquipment() {
        if (type != ActorType.Pc) { return }

        for (slot in EquipSlot.values()) {
            val modelSlot = slot.toModelSlot() ?: continue
            val item = equipment.getItem(inventory, slot)
            val itemModelId = ItemModelTable[item?.info()]
            baseLook.equipment[modelSlot] = itemModelId
        }

        val mainWepItemInfo = equipment.getItem(inventory, EquipSlot.Main)?.info() ?: return
        if (mainWepItemInfo.equipmentItemInfo.weaponInfo.skill == Skill.HandToHand) {
            baseLook.equipment[ItemModelSlot.Sub] = baseLook.equipment[ItemModelSlot.Main]
        }
    }

    fun getEquipment(equipSlot: EquipSlot) : InventoryItem? {
        return equipment.getItem(inventory, equipSlot)
    }

    fun getControllerVelocity(elapsedFrames: Float): Vector3f {
        return movementController.getVelocity(this, elapsedFrames)
    }

    fun getAutoAttackInterval(): Float {
        return getMainDelayInFrames() + getSubDelayInFrames()
    }

    fun getRangedAttackInterval(): Float {
        return getRangedDelayInFrames()
    }

    private fun getMainDelayInFrames(): Float {
        return getEquipment(EquipSlot.Main)?.info()?.equipmentItemInfo?.weaponInfo?.delay?.toFloat() ?: secondsToFrames(3)
    }

    private fun getSubDelayInFrames(): Float {
        return getEquipment(EquipSlot.Sub)?.info()?.equipmentItemInfo?.weaponInfo?.delay?.toFloat() ?: 0f
    }

    private fun getRangedDelayInFrames(): Float {
        val range = getEquipment(EquipSlot.Range)?.info()?.equipmentItemInfo?.weaponInfo?.delay?.toFloat()
        if (range != null) { return range }

        val ammo = getEquipment(EquipSlot.Ammo)?.info()?.equipmentItemInfo?.weaponInfo?.delay?.toFloat()
        if (ammo != null) { return ammo }

        return secondsToFrames(3f)
    }

    private fun delayToDamage(delay: Float): Int {
        val base = delay / 60f
        val remainder = (base % 1f)
        val roundUp = if (Random.nextFloat() < remainder) { 1 } else { 0 }
        return floor(base).toInt() + roundUp
    }

    fun isPlayer(): Boolean {
        return id == ActorStateManager.playerId
    }

    fun getNpcInfo(): NpcInfo? {
        return npcInfo
    }

    fun isStaticNpc(): Boolean {
        return getNpcInfo() != null
    }

    fun shouldApplyMovement(): Boolean {
        if (type == ActorType.Effect) { return false }

        getNpcInfo() ?: return true

        if (!ActorManager.isVisible(id)) { return false }
        return !isDoor() && !isElevator()
    }

    fun isDoor(): Boolean {
        return getNpcInfo()?.datId?.isDoorId() ?: return false
    }

    fun isElevator(): Boolean {
        return getNpcInfo()?.datId?.isElevatorId() ?: return false
    }

    fun getMovementSpeed(): Float {
        return if (mountedState != null) { 15f } else { 7.5f } / secondsToFrames(1)
    }

    fun isDependent(): Boolean {
        return owner != null
    }

    fun faceToward(node: ActorState) {
        setRotation(node.position - position)
    }

    fun setRotation(direction: Vector3f) {
        val horizontal = direction.normalize().withY(0f)
        if (horizontal.magnitudeSquare() < 1e-5f) { return }

        val dir = horizontal.normalize()
        rotation = -atan2(dir.z, dir.x)
    }

    fun setTargetState(targetId: ActorId?, locked: Boolean) {
        targetState = if (targetId == null) {
            TargetState(null, false)
        } else {
            TargetState(targetId, locked)
        }
    }

    fun isEnemy(): Boolean {
        return type == ActorType.Enemy
    }


    fun updateStatusEffects(elapsedFrames: Float): Collection<StatusEffectState> {
        val removed = HashMap<Int, StatusEffectState>()

        for ((id, effect) in statusEffects) {
            if (effect.remainingDuration == null) { continue }

            effect.remainingDuration = effect.remainingDuration!! - elapsedFrames
            if (effect.isExpired()) { removed[id] = effect }
        }

        removed.forEach { statusEffects.remove(it.key) }
        return removed.values
    }

    fun gainStatusEffect(status: StatusEffect, duration: Duration? = null): StatusEffectState {
        return gainStatusEffect(status.id, duration?.inWholeSeconds?.toInt())
    }

    fun gainStatusEffect(statusId: Int, durationInSeconds: Int? = null): StatusEffectState {
        val state = StatusEffectState(StatusEffectHelper[statusId])

        if (durationInSeconds != null) {
            state.remainingDuration = secondsToFrames(durationInSeconds)
        }

        statusEffects[statusId] = state
        return state
    }

    fun getStatusEffects(): List<StatusEffectState> {
        return statusEffects.values.toList().filter { !it.isExpired() }
    }

    fun getStatusEffect(status: StatusEffect): StatusEffectState? {
        return getStatusEffect(status.id)
    }

    fun getStatusEffect(statusId: Int): StatusEffectState? {
        val status = statusEffects[statusId] ?: return null
        return if (status.isExpired()) { null } else { status }
    }

    fun hasStatusEffect(statusId: Int): Boolean {
        return getStatusEffect(statusId) != null
    }

    fun expireStatusEffect(statusId: Int) {
        statusEffects[statusId]?.remainingDuration = 0f
    }

    fun getTargetDirectionVector(): Vector3f? {
        val targetActor = ActorStateManager[targetState.targetId] ?: return null
        return (targetActor.position - position).also { it.y = 0f }.normalizeInPlace()
    }

    fun isIdle(): Boolean {
        return !isDead() && !isSynthesizing() && !isResting() && !isCasting() && !isEngaged() && !isGathering()
    }

    fun isIdleOrEngaged(): Boolean {
        return !isDead() && !isSynthesizing() && !isResting() && !isCasting() && !isGathering()
    }

    fun getJobLevel(job: Job?): JobLevel? {
        return jobLevels[job]
    }

    fun getMainJobLevel(): JobLevel {
        return getJobLevel(jobState.mainJob) ?: throw IllegalStateException("[$name] No main job level?")
    }

    fun getSubJobLevel(): Int? {
        val mainJobLevel = getMainJobLevel()
        val level = getJobLevel(jobState.subJob)?.level ?: return null
        return level.coerceAtMost(mainJobLevel.level / 2).coerceAtLeast(1)
    }

    fun isGathering(): Boolean {
        return gatheringState.isGathering()
    }

    fun isGatheringNode(): Boolean {
        return gatheringNodeState != null
    }


}