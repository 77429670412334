package xim.poc.game.event

import xim.poc.ActorContext
import xim.poc.ActorId
import xim.poc.ActorManager
import xim.poc.game.*
import xim.poc.game.configuration.SkillAppliers
import xim.poc.ui.ChatLog
import xim.poc.ui.ChatLogColor
import xim.poc.ui.ShiftJis
import xim.resource.DatId
import xim.resource.table.AbilityNameTable
import xim.resource.table.MobSkillNameTable

class CastingChargeCompleteEvent(
    val sourceId: ActorId
) : Event {

    override fun apply(): List<Event> {
        val actorState = ActorStateManager[sourceId] ?: return emptyList()

        val current = actorState.getCastingState() ?: return emptyList()
        current.onExecute()

        val targetState = ActorStateManager[current.targetId]

        if (current.interrupted || targetState == null || !current.isTargetValid()) {
            ChatLog.addLine("${actorState.name} interrupted!", ChatLogColor.Action)
            return listOf(CastInterruptedEvent(sourceId))
        }

        return if (current.spellInfo != null) {
            invokeSpell(actorState, targetState, current)
        } else if (current.rangedAttack) {
            invokeRangedAttack(actorState, current)
        } else if (current.itemInfo != null) {
            invokeItem(actorState, current)
        } else if (current.abilityInfo != null) {
            invokeAbility(actorState, targetState, current)
        } else if (current.mobSkillInfo != null) {
            invokeMobSkill(actorState, targetState, current)
        } else {
            emptyList()
        }
    }

    private fun invokeSpell(actorState: ActorState, targetState: ActorState, current: CastingState): List<Event> {
        val spellInfo = current.spellInfo ?: return emptyList()
        if (!GameEngine.canCastSpell(actorState.id, spellInfo)) { return listOf(CastInterruptedEvent(sourceId)) }

        val applierResult = SkillAppliers.invoke(actorState, targetState, spellInfo)

        if (!applierResult.success) {
            ChatLog(applierResult.failureReason ?: "${actorState.name} casting failed!", ChatLogColor.Info)
            return listOf(CastInterruptedEvent(sourceId))
        }

        actorState.consumeMp(spellInfo.mpCost)
        actorState.getRecastStates().addSpellRecastState(spellInfo, spellInfo.recastDelayInFrames())

        GameEngine.displaySpell(spellInfo, current.sourceId, current.targetId, applierResult.allTargetIds, applierResult.contexts)
        return applierResult.events
    }

    private fun invokeRangedAttack(actorState: ActorState, current: CastingState): List<Event> {
        val recast = GameEngine.getRangedAttackRecast(actorState)
        actorState.rangedAttackRecast.set(recast)

        val actor = ActorManager[sourceId] ?: return emptyList()
        actor.enqueueModelRoutine(DatId("shlg"), ActorContext(current.sourceId, current.targetId))
        return emptyList()
    }

    private fun invokeItem(actorState: ActorState, current: CastingState): List<Event> {
        val itemInfo = current.itemInfo ?: return emptyList()

        if (itemInfo.itemId == ItemIds.CopseCandy.id) {
            val status = actorState.gainStatusEffect(StatusEffect.Costume.id, durationInSeconds = 60)
            status.counter = 0x9E2
        } else if (itemInfo.itemId == ItemIds.IcarusWing.id) {
            actorState.gainTp(9999)
        }

        GameEngine.displayItemAnimation(itemInfo, current.sourceId, current.targetId)

        return emptyList()
    }

    private fun invokeAbility(actorState: ActorState, targetState: ActorState, current: CastingState): List<Event> {
        val abilityInfo = current.abilityInfo ?: return emptyList()
        val abilityName = AbilityNameTable.first(abilityInfo.index)
        if (!GameEngine.canUseAbility(actorState.id, abilityInfo)) { return listOf(CastInterruptedEvent(sourceId)) }

        val applierResult = SkillAppliers.invoke(actorState, targetState, abilityInfo)

        if (!applierResult.success) {
            ChatLog.addLine(applierResult.failureReason ?: "${actorState.name} failed to use $abilityName!", ChatLogColor.Info)
            return listOf(CastInterruptedEvent(sourceId))
        }

        val recast = GameEngine.getAbilityRecast(actorState, abilityInfo)
        actorState.getRecastStates().addAbilityRecastState(abilityInfo, recast)

        GameEngine.displayAbility(abilityInfo, sourceId = sourceId, primaryTargetId = current.targetId, allTargetIds = applierResult.allTargetIds, actionContext = applierResult.contexts)

        return applierResult.events
    }

    private fun invokeMobSkill(actorState: ActorState, targetState: ActorState, current: CastingState): List<Event> {
        val mobSkillInfo = current.mobSkillInfo ?: return emptyList()
        val mobSkillName = MobSkillNameTable[mobSkillInfo.id]

        val applierResult = SkillAppliers.invoke(actorState, targetState, mobSkillInfo)

        if (!applierResult.success) {
            ChatLog.addLine(applierResult.failureReason ?: "${actorState.name} failed to use $mobSkillName!", ChatLogColor.Info)
            return listOf(CastInterruptedEvent(sourceId))
        }

        GameEngine.displayMobSkill(mobSkillInfo = mobSkillInfo, sourceId = sourceId, primaryTargetId = current.targetId, allTargetIds = applierResult.allTargetIds, actionContext = applierResult.contexts)

        ChatLog.addLine("${actorState.name} uses $mobSkillName ${ShiftJis.rightArrow} ${targetState.name}!", ChatLogColor.Info)

        return applierResult.events
    }

}