package xim.poc

import xim.math.Vector2f
import xim.math.Vector4f
import xim.poc.UiElementHelper.offsetScaling
import xim.poc.browser.DatLoader
import xim.poc.game.ScrollSettings
import xim.poc.game.StatusEffectState
import xim.poc.gl.BlendFunc
import xim.poc.gl.Color
import xim.poc.gl.DrawXimUiCommand
import xim.poc.ui.ChatLogColor
import xim.poc.ui.FontMojiHelper
import xim.resource.InventoryItemInfo
import xim.resource.UiElement
import xim.resource.UiMenu
import xim.resource.UiMenuElement
import xim.util.Fps.framesToSeconds
import xim.util.OnceLogger
import kotlin.math.floor

class FontChar(val char: Char, val elementName: String, val index: Int, val offset: Vector2f = Vector2f(0f, 0f), val colorOverride: Color? = null)
class PositionedCharacter(val fontChar: FontChar, val position: Vector2f, val color: Color)

class FormattedString(val numLines: Int, val characters: List<PositionedCharacter>)

enum class TextAlignment {
    Left,
    Center,
    Right
}

enum class TextDirection {
    TopToBottom,
    BottomToTop,
}

enum class AppendType {
    StackAndAppend,
    Append,
    None,
    HorizontalOnly,
}

enum class MenuStacks(val menuStack: MenuStack) {
    PartyStack(MenuStack(Vector2f(290f, 580f), Vector2f())),
    LogStack(MenuStack(Vector2f(0f, 580f), Vector2f())),
}

enum class Font(val elementName: String) {
    FontMoji("font    moji    "),
    FontShp("font    fontshp "),
    FontFont("font    font    ")
}

class MenuStack(private val basePosition: Vector2f, val offset: Vector2f) {

    val currentPosition = Vector2f()

    init { reset() }

    fun reset() {
        currentPosition.copyFrom((basePosition + offset).scale(offsetScaling))
    }

    fun appendAndGetPosition(appendType: AppendType, menu: UiMenu): Vector2f {
        val heightDelta = menu.frame.size.y + 1.5f * UiElementHelper.globalUiScale.y

        return when (appendType) {
            AppendType.StackAndAppend -> {
                currentPosition.y -= heightDelta
                currentPosition
            }
            AppendType.Append -> {
                currentPosition - Vector2f(0f, heightDelta)
            }
            AppendType.None -> {
                currentPosition
            }
            AppendType.HorizontalOnly -> {
                Vector2f(currentPosition.x, menu.frame.offset.y)
            }
        }
    }

}

object UiElementHelper {

    val offsetScaling = Vector2f(1f, 1f)
    val globalUiScale = Vector2f(1f, 1f)

    private var uiFrameCounter = 0f
    private var uiFrame = 0

    private val standardColors by lazy { fetchStandardColors() }

    fun update(elapsedFrames: Float) {
        uiFrameCounter += elapsedFrames
        if (uiFrameCounter > 8f) { uiFrameCounter -= 8f; uiFrame += 1 }

        MenuStacks.values().forEach { it.menuStack.reset() }
    }

    fun getStandardTextColor(index: Int): Color {
        return standardColors.getOrNull(index) ?: Color.NO_MASK
    }

    fun drawInventoryItem(itemInfo: InventoryItemInfo, position: Vector2f, scale: Vector2f = Vector2f(1f, 1f)) {
        val texture = itemInfo.textureResource.name
        val dummyElement = UiElement.basic32x32(texture)

        MainTool.drawer.drawXimUi(
            DrawXimUiCommand(
                uiElement = dummyElement,
                position = position,
                scale = globalUiScale,
                elementScale = scale
            )
        )
    }

    fun drawStatusEffect(statusEffectState: StatusEffectState, position: Vector2f, scale: Vector2f = Vector2f(1f, 1f)) {
        val texture = statusEffectState.info.icon.textureName
        val dummyElement = UiElement.basicSquare(texture, size = 18, uvHeight = 32, uvWidth = 32)

        MainTool.drawer.drawXimUi(
            DrawXimUiCommand(
                uiElement = dummyElement,
                position = position,
                scale = globalUiScale,
                elementScale = scale
            )
        )

        val framesRemaining = statusEffectState.remainingDuration ?: return
        val secondsRemaining = framesToSeconds(framesRemaining)

        val remainingString = if (secondsRemaining.inWholeHours > 0) {
            "${secondsRemaining.inWholeHours}h"
        } else if (secondsRemaining.inWholeMinutes > 0) {
            "${secondsRemaining.inWholeMinutes}m"
        } else {
            "${secondsRemaining.inWholeSeconds}s"
        }

        val stringPos = Vector2f().copyFrom(position)
        stringPos.y += 12f
        stringPos.x += 8f

        drawString(remainingString, stringPos, font = Font.FontShp, alignment = TextAlignment.Center)
    }

    fun drawUiElement(lookup: String, index: Int, position: Vector2f, color: Color = Color.NO_MASK, clipSize: Vector4f? = null, scale: Vector2f = Vector2f(1f, 1f), scaleUvs: Boolean = false, disableGlobalScale:Boolean = false, rotation: Float = 0f) {
        if (lookup.isEmpty()) { return }

        val uiResource = UiResourceManager.getElement(lookup)
        if (uiResource == null) {
            OnceLogger.warn("[UI] Couldn't find resource: [$lookup]")
            return
        }

        MainTool.drawer.drawXimUi(
            DrawXimUiCommand(
                uiElement = uiResource.uiElementGroup.uiElements[index],
                position = position,
                elementScale = scale,
                scale = if (disableGlobalScale) { Vector2f(1f, 1f) } else { globalUiScale },
                colorMask = color,
                scaleUvs = scaleUvs,
                rotation = rotation,
                clipSize = clipSize,
            )
        )
    }

    fun currentCursorIndex(numAnimFrames: Int): Int {
        return uiFrame % numAnimFrames
    }

    fun drawDownloadingDataElement(offset: Vector2f) {
        val index = uiFrame % 5
        drawUiElement(lookup = "menu    keytops3", index = 165 + index, position = offset)

        val loadingCount = DatLoader.getLoadingCount()
        drawString("Remaining: $loadingCount", font = Font.FontMoji, offset = offset + Vector2f(50f, 25f))
    }

    fun drawBlackScreenCover(opacity: Float) {
        val color = Color(r = 0f, g = 0f, b = 0f, a = opacity)
        drawScreenOverlay(color, BlendFunc.Src_InvSrc_Add)
    }

    fun drawScreenOverlay(color: Color, blendFunc: BlendFunc) {
        val dummyElement = UiElement.screenElement()
        MainTool.drawer.drawXimUi(DrawXimUiCommand(uiElement = dummyElement, position = Vector2f(), scale = globalUiScale, colorMask = color, blendFunc = blendFunc))
    }

    fun drawMenu(menuName: String, cursorIndex: Int = -1, offsetOverride: Vector2f? = null, menuStacks: MenuStacks? = null, scrollSettings: ScrollSettings? = null, appendType: AppendType = AppendType.StackAndAppend, drawFrame: Boolean = true, elementPositionOverride: ((UiMenuElement) -> Vector2f)? = null, earlyDraw: ((Vector2f) -> Unit)? = null): Vector2f? {
        val menu = UiResourceManager.getMenu(menuName) ?: return null

        val frame = menu.uiMenu.frame
        val framePosition = if (menuStacks != null) {
            val frameOffsetScaling = if (menuStacks == MenuStacks.PartyStack) { offsetScaling } else { Vector2f(1f, 1f) }
            Vector2f(frame.offset.x, 0f).scale(frameOffsetScaling) + menuStacks.menuStack.appendAndGetPosition(appendType, menu.uiMenu)
        } else if (offsetOverride != null) {
            offsetOverride
        } else {
            Vector2f(frame.offset.x, frame.offset.y)
        }

        drawBorder(frame, framePosition)

        framePosition.x = floor(framePosition.x)
        framePosition.y = floor(framePosition.y)

        if (drawFrame) {
            val frameElement = frame.options[0]
            drawUiElement(frameElement.elementGroupName, frameElement.elementIndex, framePosition)
        }

        earlyDraw?.invoke(framePosition) // TODO - is there a better way to draw gauge bars? The "fill" needs to be drawn before the bar

        menu.uiMenu.elements.forEachIndexed { index, elementRef ->
            val position = (elementPositionOverride?.invoke(elementRef) ?: Vector2f(elementRef.offset.x, elementRef.offset.y)) + framePosition

            val element = (if (index == cursorIndex) {
                elementRef.options.firstOrNull { it.config == 0x03 }
            } else {
                null
            }) ?: elementRef.options.getOrNull(0)

            if (element != null) { drawUiElement(element.elementGroupName, element.elementIndex, position = position) }
        }

        val maybeCursor = frame.options.firstOrNull { it.config == 0x06 }
        if (cursorIndex >= 0 && maybeCursor != null && menu.uiMenu.elements.isNotEmpty()) {
            val cursorElement = UiResourceManager.getElement(maybeCursor.elementGroupName) ?: throw IllegalStateException("No cursor?")

            val element = menu.uiMenu.elements.getOrNull(cursorIndex)

            if (element != null) {
                val position = Vector2f(element.offset.x, element.offset.y) + framePosition
                val index = currentCursorIndex(cursorElement.uiElementGroup.uiElements.size)
                drawUiElement(lookup = maybeCursor.elementGroupName, index = index, position = position)
            }
        }

        drawScrollBar(frame, framePosition, scrollSettings)

        return framePosition
    }

    private fun drawBorder(frame: UiMenuElement, framePosition: Vector2f) {
        val cornerSize = 24f
        val sidesSize = 80f

        if (frame.size.x < cornerSize || frame.size.y < cornerSize) { // TODO - better way to tell if frame doesn't need a border?
            return
        }

        val lookup = "menu    win00   "
        drawUiElement(lookup, 0, position = framePosition, scale = Vector2f(frame.size.x / 128f, frame.size.y / 128f), scaleUvs = true)

        val topBottomWidth = (frame.size.x - 2 * cornerSize)
        val leftRightHeight = (frame.size.y - 2 * cornerSize)


        // top-left, top-right
        drawUiElement(lookup, 1, position = framePosition + Vector2f(0f, 0f))
        drawUiElement(lookup, 3, position = framePosition + Vector2f(cornerSize + topBottomWidth, 0f))

        // bottom-left, bottom-right
        drawUiElement(lookup, 6, position = framePosition + Vector2f(0f, leftRightHeight + cornerSize))
        drawUiElement(lookup, 8, position = framePosition + Vector2f(topBottomWidth + cornerSize, leftRightHeight + cornerSize))

        // left, right
        if (leftRightHeight > 0) {
            drawUiElement(lookup, 4, position = framePosition + Vector2f(0f, cornerSize), scale = Vector2f(1f, leftRightHeight / sidesSize))
            drawUiElement(lookup, 5, position = framePosition + Vector2f(topBottomWidth + cornerSize, cornerSize), scale = Vector2f(1f, leftRightHeight / sidesSize))
        }

        // top, bottom
        if (topBottomWidth > 0) {
            val frameMenuElement = frame.options[0]

            var minX: Float? = null
            var maxX: Float? = null

            val frameElementGroup = UiResourceManager.getElement(frameMenuElement.elementGroupName)
            if (frameElementGroup != null) {
                val frameElement = frameElementGroup.uiElementGroup.uiElements[frameMenuElement.elementIndex]

                // For top, need to ensure that the title isn't overlapped. I don't see a good way to do this...
                // It's definitely related to the unknown flags in the UiElement

                minX = frameElement.components.filter { it.textureName == "menu    hfr1    " }
                    .flatMap { it.vertices }
                    .minOfOrNull { it.point.x }

                maxX = frameElement.components.filter { it.textureName == "menu    hfr1    " }
                    .flatMap { it.vertices }
                    .maxOfOrNull { it.point.x }
            }

            val topLeftScale = if (minX == null) { topBottomWidth / sidesSize } else { (minX - cornerSize) / sidesSize }
            drawUiElement(lookup, 2, position = framePosition + Vector2f(cornerSize, 0f), scale = Vector2f(topLeftScale, 1f))

            if (maxX != null) {
                val topRightScale = (topBottomWidth - (maxX - cornerSize)) / sidesSize
                if (topRightScale > 0f) {
                    drawUiElement(lookup, 2, position = framePosition + Vector2f(maxX, 0f), scale = Vector2f(topRightScale, 1f))
                }
            }

            drawUiElement(lookup, 7, position = framePosition + Vector2f(cornerSize, leftRightHeight + cornerSize-1f), scale = Vector2f(topBottomWidth/sidesSize, 1f))
        }

    }

    private fun drawScrollBar(frame: UiMenuElement, framePosition: Vector2f, scrollSettings: ScrollSettings?) {
        if (scrollSettings == null) { return }

        val visibleItems = scrollSettings.numElementsInPage
        val totalItems = scrollSettings.numElementsProvider.invoke()

        if (visibleItems >= totalItems) { return }

        val capSize = Vector2f(8f, 4f)
        val barSize = 64f

        val scrollBarScale = (frame.size.y - 2 * capSize.y) / barSize
        val scrollBarFillScale = scrollBarScale * visibleItems.toFloat() / totalItems.toFloat()
        val scrollBarFillOffset = capSize.y + (frame.size.y - 2 * capSize.y) * (scrollSettings.lowestViewableItemIndex.toFloat() / totalItems.toFloat())

        drawUiElement(lookup = "menu    scroll  ", index = 3, position = framePosition + Vector2f(frame.size.x - capSize.x, scrollBarFillOffset), scale = Vector2f(1f, scrollBarFillScale))
        drawUiElement(lookup = "menu    scroll  ", index = 2, position = framePosition + Vector2f(frame.size.x - capSize.x, frame.size.y - capSize.y))
        drawUiElement(lookup = "menu    scroll  ", index = 1, position = framePosition + Vector2f(frame.size.x - capSize.x, capSize.y), scale = Vector2f(1f, scrollBarScale))
        drawUiElement(lookup = "menu    scroll  ", index = 0, position = framePosition + Vector2f(frame.size.x - capSize.x, 0f))
    }

    fun drawString(text: String, offset: Vector2f, font: Font = Font.FontMoji, color: Color = Color.NO_MASK, alignment: TextAlignment = TextAlignment.Left) {
        val fontElement = UiResourceManager.getElement(font.elementName) ?: return
        val position = Vector2f().copyFrom(offset)

        val textToDraw = if (alignment == TextAlignment.Right) { text.reversed() } else { text }
        val positions = ArrayList<PositionedCharacter>()

        var currentColor = color

        for (char in textToDraw.toCharArray()) {
            if (char.code == 10) {
                if (alignment != TextAlignment.Left) { throw IllegalStateException("Aligned text isn't implemented for multi-line strings") }
                position.x = offset.x
                position.y += 16f
                continue
            }

            val (element, fontChar) = if (char.code < 128) {
                val index = (char - 32).code // Font doesn't include the first 32 non-render elements
                val element = fontElement.uiElementGroup.uiElements[index].components[0]
                Pair(element, FontChar(char, font.elementName, index))
            } else {
                val specialChar = map(char, font)
                if (specialChar.colorOverride != null) {
                    currentColor = specialChar.colorOverride
                    continue
                }

                val element = UiResourceManager.getElement(specialChar.elementName)!!.uiElementGroup.uiElements[specialChar.index].components[0]
                Pair(element, specialChar)
            }

            if (alignment == TextAlignment.Right) { position.x -= element.width }
            positions += PositionedCharacter(fontChar, Vector2f().copyFrom(position), currentColor)
            if (alignment != TextAlignment.Right) { position.x += element.width }
        }

        if (alignment == TextAlignment.Center) {
            val shiftFactor = (position.x - offset.x)/2f
            positions.forEach { it.position.x -= shiftFactor }
        }

        for (positionedChar in positions) {
            val fontChar = positionedChar.fontChar
            drawUiElement(lookup = fontChar.elementName, index = fontChar.index, position = positionedChar.position, color = color)
        }

    }

    fun drawMultilineText(text: String, offset: Vector2f, maxWidth: Int, textDirection: TextDirection, clipSize: Vector4f? = null, color: Color = Color.NO_MASK, font: Font = Font.FontMoji): Int? {
        val formattedString = formatString(text, maxWidth, textDirection, font, color) ?: return null
        drawFormattedString(formattedString, clipSize, offset)
        return formattedString.numLines
    }

    fun drawFormattedString(formattedString: FormattedString, clipSize: Vector4f? = null, offset: Vector2f = Vector2f()) {
        for (char in formattedString.characters) {
            drawUiElement(char.fontChar.elementName, char.fontChar.index, char.position + offset, color = char.color, clipSize = clipSize)
        }
    }

    fun formatString(text: String, maxWidth: Int, textDirection: TextDirection, font: Font = Font.FontMoji, color: Color = ChatLogColor.Normal.color): FormattedString? {
        val fontElement = UiResourceManager.getElement(font.elementName) ?: return null
        val position = Vector2f()

        var numLines = 1
        val positions = ArrayList<PositionedCharacter>()

        var currentColor = color

        for (char in text.toCharArray()) {
            if (char.code == 0x0A) {
                when (textDirection) {
                    TextDirection.TopToBottom -> position.y += 16f
                    TextDirection.BottomToTop -> positions.forEach { it.position.y -= 16f }
                }

                position.x = 0f
                numLines += 1
                continue
            }

            val (element, fontChar) = if (char.code < 128) {
                val index = (char - 32).code // Font doesn't include the first 32 non-render elements
                val element = fontElement.uiElementGroup.uiElements[index].components[0]
                Pair(element, FontChar(char, font.elementName, index))
            } else {
                val specialChar = map(char, font)
                if (specialChar.colorOverride != null) {
                    currentColor = specialChar.colorOverride
                    continue
                }

                val element = UiResourceManager.getElement(specialChar.elementName)!!.uiElementGroup.uiElements[specialChar.index].components[0]
                Pair(element, specialChar)
            }

            if (position.x + element.width > maxWidth) {
                linebreakLatestWord(position, positions, textDirection)
                numLines += 1
                if (textDirection == TextDirection.TopToBottom) { position.y += 16f }
            }

            positions += PositionedCharacter(fontChar, Vector2f().copyFrom(position), currentColor)
            position.x += element.width
        }

        return FormattedString(numLines, positions)
    }

    private fun linebreakLatestWord(position: Vector2f, positions: ArrayList<PositionedCharacter>, textDirection: TextDirection) {
        val maxLookBack = (positions.size - 16).coerceAtLeast(1)

        // Try to find a recent " ", and break on it
        for (i in positions.size - 1 downTo maxLookBack) {
            if (positions[i-1].fontChar.char != ' ') { continue }

            val posChar = positions[i]
            val adjustment = posChar.position.x

            for (j in i until positions.size) {
                positions[j].position.x -= adjustment
                if (textDirection == TextDirection.TopToBottom) { positions[j].position.y += 16f }
            }

            if (textDirection == TextDirection.BottomToTop) {
                for (j in 0 until i) { positions[j].position.y -= 16f }
            }

            position.x -= adjustment
            return
        }

        // Didn't find a recent " " - just break on the latest char
        position.x = 0f

        if (textDirection == TextDirection.BottomToTop) {
            for (pos in positions) { pos.position.y -= 16f }
        }
    }

    private fun map(char: Char, font: Font): FontChar {
        val encode = (char.code shr 0x8) and 0xFF
        return if (encode == 0x1E) {
            val index = (char.code and 0xFF)
            FontChar(Char(0x1E), "", 0, colorOverride = resolveColor(index))
        } else if (encode == 0xEF) {
            val index = (char.code and 0xFF) - 0x20
            val elementIndex = index.toShort() + 1
            return FontChar(char, "font    usgaiji ", elementIndex, Vector2f(0f, -5f))
        } else {
            FontChar(char, font.elementName, FontMojiHelper.mapShiftJisToIndex(char))
        }
    }

    private fun resolveColor(index: Int): Color {
        return if (index == 2) {
            getStandardTextColor(4)
        } else {
            getStandardTextColor(0)
        }
    }

    private fun fetchStandardColors(): List<Color> {
        val elementResource = UiResourceManager.getElement("menu    ncol    ") ?: return emptyList()
        val colors = ArrayList<Color>()

        for (element in elementResource.uiElementGroup.uiElements) {
            colors += Color(element.components[0].vertices[0].color.multiply(2))
        }

        return colors
    }

}