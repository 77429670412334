package xim.poc.game.configuration.v0

import xim.math.Vector3f
import xim.poc.ActorAssociation
import xim.poc.ActorId
import xim.poc.ActorManager
import xim.poc.game.*
import xim.poc.game.configuration.*
import xim.poc.gl.ByteColor
import xim.resource.EquipSlot
import xim.util.Fps
import kotlin.time.Duration.Companion.seconds

class EntranceInteraction(val destination: Vector3f): NpcInteraction {

    override fun onInteraction(npcId: ActorId) {
        UiStateHelper.openQueryMode("What will you do?", getOptions(), callback = this::handleQueryResponse)
    }

    private fun getOptions(): List<QueryMenuOption> {
        val options = ArrayList<QueryMenuOption>()
        options += QueryMenuOption("Do not use.", value = -1)
        options += QueryMenuOption("Enter!", value = 1)
        return options
    }

    private fun handleQueryResponse(response: QueryMenuOption?): QueryMenuResponse {
        if (response == null || response.value < 0) { return QueryMenuResponse.pop }

        val playerAssociation = ActorAssociation(ActorManager.player())

        val script = EventScript(
            listOf(
                EffectRoutineEventItem(fileTableIndex = 0x113c8, playerAssociation, eagerlyComplete = true),
                WaitRoutine(Fps.secondsToFrames(1.9f)),
                FadeOutEvent(1.seconds),
                WarpSameZoneEventItem(destination),
                RunOnceEventItem {
                    GameV0.resetFloor(newLogic = Floor1Definition.newInstance())
                    inflictEncumbrance()
                    ActorManager.player().renderState.effectColor = ByteColor.half
                },
                FadeInEvent(1.seconds),
            )
        )

        EventScriptRunner.runScript(script)
        return QueryMenuResponse.pop
    }

    private fun inflictEncumbrance() {
        val state = ActorStateManager.player().gainStatusEffect(StatusEffect.Encumbrance)
        state.potency = EquipSlot.Main.mask or EquipSlot.Sub.mask or EquipSlot.Range.mask
    }

}