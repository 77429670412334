package xim.resource

import xim.math.Vector2f
import xim.poc.UiResourceManager

enum class UiMenuCursorKey {
    Up,
    Down,
    Right,
    Left,
}

class UiMenuElementOption(val elementIndex: Int, val config: Int, val elementGroupName: String)

class UiMenuElement(val offset: Vector2f, val size: Vector2f, val options: List<UiMenuElementOption>, val next: Map<UiMenuCursorKey, Int>) {
    fun deepCopy(): UiMenuElement {
        return UiMenuElement(offset = Vector2f().copyFrom(offset), size = Vector2f().copyFrom(size), options.toMutableList(), next.toMutableMap())
    }
}

class UiMenu(val name: String, val frame: UiMenuElement, val elements: List<UiMenuElement>)

class UiMenuSection(val sectionHeader: SectionHeader) : ResourceParser {

    override fun getResource(byteReader: ByteReader): ParserResult {
        val menu = read(byteReader)
        val resource = UiMenuResource(sectionHeader.sectionId, menu)
        UiResourceManager.register(resource)
        return ParserResult.from(resource)
    }

    private fun read(byteReader: ByteReader) : UiMenu {
        byteReader.offsetFromDataStart(sectionHeader)

        val menuName = byteReader.nextString(0x10)

        val maybeType = byteReader.next8()
        val numElements = byteReader.next8()
        byteReader.align0x10()

        val elements = ArrayList<UiMenuElement>(numElements + 1)

        val frame = readElement(byteReader)

        for (i in 0 until numElements) {
            elements.add(readElement(byteReader))
        }

        return UiMenu(menuName, frame, elements)
    }

    private fun readElement(byteReader: ByteReader) : UiMenuElement {
        val start = byteReader.position
        val size = byteReader.next16()

        val xOffset = byteReader.next16().toFloat()
        val yOffset = byteReader.next16().toFloat()

        val unk0 = byteReader.next16()
        val unk1 = byteReader.next16()

        val width = byteReader.next16Signed()
        val height = byteReader.next16Signed()

        val unk2 = byteReader.next16Signed()
        val unk3 = byteReader.next16Signed()

        val elementIndex = byteReader.next8()

        val frameSetting0 = byteReader.next8()
        val frameSetting1 = byteReader.next8()

        val unkKeyElementIndex0 = byteReader.next8()
        val unkKeyElementIndex1 = byteReader.next8()

        val nextMap = HashMap<UiMenuCursorKey, Int>()
        nextMap[UiMenuCursorKey.Up] = byteReader.next8()
        nextMap[UiMenuCursorKey.Down] = byteReader.next8()
        nextMap[UiMenuCursorKey.Right] = byteReader.next8()
        nextMap[UiMenuCursorKey.Left] = byteReader.next8()

        val elementConfig0 = byteReader.next16()
        val elementConfig1 = byteReader.next16()

        val unk4 = byteReader.next8()

        val elementsToRead = if (elementIndex == 0) { frameSetting1 } else { elementConfig0 }
        val options = ArrayList<UiMenuElementOption>(elementsToRead)

        for (i in 0 until elementsToRead) {
            val config = byteReader.next16()    // For cursors, number of frames? For elements, status (enabled/disabled)?
            val uiElementIndex = byteReader.next16()
            val groupName = byteReader.nextString(0x10)
            options.add(UiMenuElementOption(elementIndex = uiElementIndex, config = config, elementGroupName = groupName))
        }

        // TODO - there's two weird zero-terminated Strings here

        byteReader.position = start + size
        return UiMenuElement(
            offset = Vector2f(xOffset, yOffset),
            size = Vector2f(width.toFloat(), height.toFloat()),
            options = options,
            next = nextMap,
        )
    }

}