package xim.poc.game.configuration.v0

import xim.poc.game.*
import xim.poc.game.configuration.WeightedTable
import xim.poc.game.configuration.v0.ActorSkillType.*
import xim.poc.game.configuration.v0.Floor1AugmentPools.bronzeEquipmentAugmentPool
import xim.resource.InventoryItemType
import xim.resource.InventoryItems
import xim.resource.table.SpellNameTable
import kotlin.math.roundToInt

data class ItemTrust(val trustId: Int)

data class ItemDefinition(
    val id: Int,
    val internalLevel: Int,
    val damage: Int = 0,
    val delay: Int = 0,
    val skillTiers: Map<ActorSkillType, TierLevel> = emptyMap(),
    val combatStats: CombatStats = CombatStats(),
    val trusts: List<ItemTrust> = emptyList(),
    val augmentSlots: List<WeightedTable<ItemAugmentId>> = emptyList(),
) {

    fun scale(inventoryItem: InventoryItem): ItemDefinition {
        val qualityBonus = AugmentHelper.qualityBonus(inventoryItem)
        val scaledDamage = (damage * qualityBonus).roundToInt()
        val scaledStats = combatStats * qualityBonus
        return copy(damage = scaledDamage, combatStats = scaledStats)
    }

    fun toDescription(): String {
        val info = InventoryItems[id]

        val str = StringBuilder()

        if (info.itemType == InventoryItemType.Weapon) {
            str.appendLine("DMG: $damage Delay: $delay")
        }

        for ((skill, level) in skillTiers) {
            str.append("$skill[$level]  ")
        }
        if (skillTiers.isNotEmpty()) { str.appendLine() }

        var addedStat = false
        for (combatStat in CombatStat.values()) {
            val stat = combatStats[combatStat]
            if (stat != 0) { str.append("${combatStat.displayName}+$stat"); addedStat = true }
        }
        if (addedStat) { str.appendLine() }

        for (trust in trusts) {
            str.appendLine("Trust[${SpellNameTable[trust.trustId].first()}]")
        }

        return str.toString()
    }

}

object ItemDefinitions {

    val definitionsById: Map<Int, ItemDefinition>

    init {
        val definitions = ArrayList<ItemDefinition>()

        // Bronze Dagger
        definitions += ItemDefinition(id = 16448, internalLevel = 1, damage = 3, delay = 150, skillTiers = mapOf(Dagger to 1), augmentSlots = listOf(
        ))

        // Bronze Sword
        definitions += ItemDefinition(id = 16535, internalLevel = 1, damage = 5, delay = 210,
            skillTiers = mapOf(Sword to 1, EnfeeblingMagic to 1),
            augmentSlots = listOf(
                WeightedTable.single(12)
            )
        )

        // Xiphos
        definitions += ItemDefinition(id = 16530, internalLevel = 5, damage = 8, delay = 210, skillTiers = mapOf(Sword to 1), augmentSlots = listOf(
        ))

        // Maple Club
        definitions += ItemDefinition(id = 17049, internalLevel = 1, damage = 4, delay = 200, skillTiers = mapOf(Club to 1, HealingMagic to 1))

        // Ash Staff
        definitions += ItemDefinition(id = 17088, internalLevel = 1, damage = 6, delay = 360, skillTiers = mapOf(Staff to 1, ElementalMagic to 1))

        // Bronze Cap
        definitions += ItemDefinition(id = 12448, internalLevel = 1, combatStats = CombatStats(vit = 1), augmentSlots = listOf(
            WeightedTable.single(7),
            bronzeEquipmentAugmentPool,
            bronzeEquipmentAugmentPool,
        ))

        // Bronze Harness
        definitions += ItemDefinition(id = 12576, internalLevel = 1, combatStats = CombatStats(vit = 2), augmentSlots = listOf(
            WeightedTable.single(3),
            bronzeEquipmentAugmentPool,
            bronzeEquipmentAugmentPool,
        ))

        // Leather Vest
        definitions += ItemDefinition(id = 12568, internalLevel = 5, combatStats = CombatStats(vit = 4), augmentSlots = listOf(
            WeightedTable.single(3),
        ))

        // Bronze Mittens
        definitions += ItemDefinition(id = 12704, internalLevel = 1, combatStats = CombatStats(vit = 1), augmentSlots = listOf(
            WeightedTable.single(4),
            bronzeEquipmentAugmentPool,
            bronzeEquipmentAugmentPool,
        ))

        // Bronze Subligar
        definitions += ItemDefinition(id = 12832, internalLevel = 1, combatStats = CombatStats(vit = 2), augmentSlots = listOf(
            WeightedTable.single(8),
            bronzeEquipmentAugmentPool,
            bronzeEquipmentAugmentPool,
        ))

        // Bronze Leggings
        definitions += ItemDefinition(id = 12960, internalLevel = 1, combatStats = CombatStats(vit = 1), augmentSlots = listOf(
            WeightedTable.single(6),
            bronzeEquipmentAugmentPool,
            bronzeEquipmentAugmentPool,
        ))

        // Ascetic's Ring
        definitions += ItemDefinition(id = 13440, internalLevel = 1, trusts = listOf(ItemTrust(898)), augmentSlots = listOf(
            WeightedTable.single(10),
        ))

        // Copper Ring
        definitions += ItemDefinition(id = 13454, internalLevel = 1, trusts = listOf(ItemTrust(902)), augmentSlots = listOf(
            WeightedTable.single(11),
        ))

        // Hermit's Ring
        definitions += ItemDefinition(id = 13475, internalLevel = 1, trusts = listOf(ItemTrust(896)), augmentSlots = listOf(
            WeightedTable.single(10),
        ))

        definitionsById = definitions.associateBy { it.id }
        validate()
    }

    operator fun get(inventoryItem: InventoryItem): ItemDefinition {
        return getNullable(inventoryItem) ?: throw IllegalStateException("[${inventoryItem.id}] No such item definition")
    }

    fun getNullable(inventoryItem: InventoryItem): ItemDefinition? {
        val definition = definitionsById[inventoryItem.id] ?: return null
        return definition.scale(inventoryItem)
    }

    private fun validate() {
        for ((id, def) in definitionsById) {
            val info = InventoryItems[id]
            if (info.itemType == InventoryItemType.Weapon && !info.isGrip()) {
                check(def.damage > 0) { "[$def] Damage is unset for weapon" }
                check(def.delay > 0) { "[$def] Delay is unset for weapon" }
            }
        }
    }

}