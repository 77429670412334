package xim.poc.browser

import js.buffer.ArrayBuffer
import js.promise.Promise
import js.promise.catch
import js.typedarrays.Uint8Array
import kotlinx.browser.window
import web.cache.Cache
import web.cache.caches
import web.http.fetchAsync
import xim.poc.browser.FileStatus.*
import xim.resource.ByteReader
import xim.resource.DatParser
import xim.resource.DirectoryResource
import xim.util.OnceLogger.error
import xim.util.OnceLogger.info

data class ParserContext(val zoneResource: Boolean = false)

object DatLoader {

    private lateinit var browserFileCache: Cache
    private val inMemoryCache: MutableMap<String, DatWrapper> = HashMap()

    private val serverPath = if (window.location.hostname == "localhost") { "" } else  { "https://pub-4c5b697e938c4ba5960755de1d171e8c.r2.dev/" }

    init {
        caches.open("dats").then(
            onFulfilled = { browserFileCache = it; browserFileCache.delete("ROM/1/Custom.DAT") },
            onRejected = { error("Failed to open browser cache: ${it.cause}") }
        )
    }

    fun isReady(): Boolean {
        return this::browserFileCache.isInitialized
    }

    fun load(resourceName: String, parserContext: ParserContext = ParserContext(), lazy: Boolean = false): DatWrapper {
        val cachedValue = inMemoryCache[resourceName]
        if (cachedValue != null && cachedValue.isReady()) {
            return cachedValue
        }

        return inMemoryCache.getOrPut(resourceName) {
            val wrapper = DatWrapper(resourceName, parserContext, serverPath)
            if (lazy) { wrapper } else { wrapper.load() }
        }
    }

    fun getFileCache(): Cache {
        return browserFileCache
    }

    fun release(resourceName: String) {
        inMemoryCache.remove(resourceName)
    }

    fun getLoadingCount(): Int {
        return inMemoryCache.values.count { it.status == IN_PROGRESS }
    }

}

enum class FileStatus {
    NOT_STARTED,
    IN_PROGRESS,
    REJECTED,
    READY
}

class DatWrapper(val resourceName: String, val parserContext: ParserContext, val serverPath: String) {

    private val callbacks = ArrayList<(DatWrapper) -> Unit>()

    var status: FileStatus = NOT_STARTED
    private lateinit var buffer: ArrayBuffer

    private val rootDirectory: DirectoryResource by lazy {
        DatParser.parse(resourceName, Uint8Array(buffer), parserContext)
    }

    fun onReady(callback: (DatWrapper) -> Unit): DatWrapper {
        if (status == READY) {
            callback.invoke(this)
        } else {
            callbacks += callback
        }

        return this
    }

    internal fun load(): DatWrapper {
        if (status != NOT_STARTED) { return this }
        status = IN_PROGRESS
        info("Loading $resourceName")

        DatLoader.getFileCache().match(resourceName)
            .flatThen {
                if (it != null) {
                    info("[$resourceName] Found in browser cache")
                    it.arrayBuffer()
                } else {
                    info("[$resourceName] Not found in browser cache")
                    fetchResource(resourceName)
                }
            }.then {
                buffer = it
                status = READY
                completeCallbacks()
            }.catch {
                info("[$resourceName] Fetch failure; $it")
                status = REJECTED
            }
        return this
    }

    private fun completeCallbacks() {
        callbacks.forEach { it(this) }
        callbacks.clear()
    }

    private fun fetchResource(resourceName: String): Promise<ArrayBuffer> {
        return fetchAsync("${serverPath}${resourceName}")
            .flatThen {
                if (!it.ok) {
                    throw IllegalStateException()
                }

                if (it.status != 206.toShort()) {
                    info("[$resourceName] Fetch success")
                    DatLoader.getFileCache().put(resourceName, it.clone())
                } else {
                    info("[$resourceName] Partial fetch")
                }

                it.arrayBuffer()
            }
    }


    fun isRejected() = status == REJECTED

    fun isReady(): Boolean {
        return when (status) {
            READY -> true
            NOT_STARTED -> { load(); false }
            else -> false
        }
    }

    fun getAsResourceIfReady(): DirectoryResource? {
        return if (isReady()) { rootDirectory } else { null }
    }

    fun getAsResource(): DirectoryResource {
        return getAsResourceIfReady() ?: throw IllegalStateException("Attempting to resolve $resourceName, but it is not ready yet")
    }

    fun getAsBufferIfReady(): ArrayBuffer? {
        return if (isReady()) { buffer } else { null }
    }

    fun getAsBuffer(): ArrayBuffer {
        return getAsBufferIfReady() ?: throw IllegalStateException("Attempting to resolve $resourceName, but it is not ready yet")
    }

    fun getAsBytes(): ByteReader {
        val buffer = getAsBufferIfReady() ?: throw IllegalStateException("Attempting to resolve $resourceName, but it is not ready yet")
        return ByteReader(Uint8Array(buffer))
    }

}