package xim.poc.game.configuration.v0

import xim.poc.game.AbilityId

typealias TierLevel = Int

enum class ActorSkillType(val subEligible: Boolean) {
    Sword(subEligible = false),
    Dagger(subEligible = false),
    Club(subEligible = false),
    Staff(subEligible = false),
    HealingMagic(subEligible = true),
    ElementalMagic(subEligible = true),
    EnfeeblingMagic(subEligible = true),
}

data class ActorSkillTier(
    val tier: TierLevel,
    val abilityIds: List<AbilityId> = emptyList(),
    val spellIds: List<Int> = emptyList(),
    val traits: Map<AbilityId, Int> = emptyMap(),
)

object ActorSkillTiers {

    private val tiers = HashMap<ActorSkillType, List<ActorSkillTier>>()

    init {
        tiers[ActorSkillType.Sword] = listOf(
            ActorSkillTier(tier = 1, abilityIds = listOf(AbilityId.FastBlade, AbilityId.BurningBlade, AbilityId.RedLotusBlade))
        )

        tiers[ActorSkillType.Dagger] = listOf(
            ActorSkillTier(tier = 1, traits = mapOf(AbilityId.DualWield to 10))
        )

        tiers[ActorSkillType.Club] = listOf(
            ActorSkillTier(tier = 1)
        )

        tiers[ActorSkillType.Staff] = listOf(
            ActorSkillTier(tier = 1)
        )

        tiers[ActorSkillType.HealingMagic] = listOf(
            ActorSkillTier(tier = 1, spellIds = listOf(1, 7))
        )

        tiers[ActorSkillType.ElementalMagic] = listOf(
            ActorSkillTier(tier = 1, spellIds = listOf(159, 189))
        )

        tiers[ActorSkillType.EnfeeblingMagic] = listOf(
            ActorSkillTier(tier = 1, spellIds = listOf(549, 623, 530))
        )

    }

    fun getAbilityIds(type: ActorSkillType, level: TierLevel): List<AbilityId> {
        val levels = tiers[type] ?: return emptyList()
        return levels.filter { it.tier <= level }.flatMap { it.abilityIds }
    }

    fun getSpellIds(type: ActorSkillType, level: TierLevel): List<Int> {
        val levels = tiers[type] ?: return emptyList()
        return levels.filter { it.tier <= level }.flatMap { it.spellIds }
    }

    fun aggregateTraitBonuses(type: ActorSkillType, level: TierLevel, aggregate: CombatBonusAggregate) {
        val levels = tiers[type] ?: return

        for (lvl in levels) {
            if (lvl.tier > level) { continue }

            for ((traitId, potency) in lvl.traits) {
                aggregate.aggregate(traitId, potency)
            }
        }
    }

}