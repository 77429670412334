package xim.poc.ui

import xim.math.Vector2f
import xim.poc.*
import xim.poc.game.*
import xim.resource.InventoryItemInfo
import xim.resource.InventoryItemType
import xim.resource.ItemListType

object InventoryUi {

    fun getItems(equipSlotFilter: Int? = null, itemTypeFilter: ItemListType? = null): List<InventoryItem> {
        return if (equipSlotFilter != null) {
            EquipScreenUi.getInventoryItemsByEquipSlot(equipSlotFilter)
        } else if (itemTypeFilter != null) {
            Inventory.player().inventoryItems.filter { it.info().type == itemTypeFilter }
        } else {
            Inventory.player().inventoryItems
        }
    }

    fun getSelectedItemIndex(uiState: UiState): Int {
        return uiState.cursorIndex + uiState.scrollSettings!!.lowestViewableItemIndex
    }

    fun getSelectedItem(uiState: UiState, equipSlotFilter: Int? = null, itemTypeFilter: ItemListType? = null): InventoryItem? {
        val currentItemIndex = getSelectedItemIndex(uiState)
        return getItems(equipSlotFilter = equipSlotFilter, itemTypeFilter = itemTypeFilter).getOrNull(currentItemIndex)
    }

    fun drawInventoryItems(uiState: UiState, equipSlotFilter: Int? = null, itemTypeFilter: ItemListType? = null) {
        val stackPos = uiState.latestPosition ?: return
        val offset = Vector2f(0f, 0f)

        val filteredItems = getItems(equipSlotFilter, itemTypeFilter)
        val playerState = ActorStateManager.player()

        val scrollSettings = uiState.scrollSettings!!
        for (i in scrollSettings.lowestViewableItemIndex until scrollSettings.lowestViewableItemIndex + scrollSettings.numElementsInPage) {
            if (i >= filteredItems.size) { break }

            val item = filteredItems[i]
            val itemInfo = item.info()

            val color = if (playerState.isEquipped(item)) {
                UiElementHelper.getStandardTextColor(4)
            } else if (itemInfo.itemType == InventoryItemType.UsableItem || itemInfo.itemType == InventoryItemType.Crystal) {
                UiElementHelper.getStandardTextColor(5)
            }  else {
                UiElementHelper.getStandardTextColor(0)
            }

            UiElementHelper.drawInventoryItem(itemInfo = itemInfo, position = offset + stackPos + Vector2f(2f, 4f), scale = Vector2f(0.5f, 0.5f))
            UiElementHelper.drawString(text = itemInfo.name, color = color, offset = offset + stackPos + Vector2f(22f, 8f))
            offset.y += 18f
        }

        if (UiStateHelper.isFocus(uiState)) { drawSelectedInventoryItem(uiState, equipSlotFilter, itemTypeFilter) }
    }

    private fun drawSelectedInventoryItem(uiState: UiState, equipSlotFilter: Int?, itemTypeFilter: ItemListType? = null ) {
        val currentItem = getSelectedItem(uiState, equipSlotFilter, itemTypeFilter) ?: return
        val context = if (equipSlotFilter != null) { UiStateHelper.equipContext } else { null }
        drawSelectedInventoryItem(currentItem, context)
    }

    fun drawSelectedInventoryItem(item: InventoryItem, context: UiState? = null) {
        val itemInfo = item.info()
        val quantity = if (itemInfo.isStackable()) { item.quantity } else { null }
        drawSelectedInventoryItem(itemInfo, quantity, context)
    }

    fun drawSelectedInventoryItem(itemInfo: InventoryItemInfo, quantity: Int? = null, context: UiState? = null) {
        if (itemInfo.itemId == 0) { return }

        var description = itemInfo.logName.replaceFirstChar { it.uppercaseChar() }
        if (quantity != null) { description += "\nQuantity: $quantity" }
        description += "\n${itemInfo.description}"

        val formattedDescription = UiElementHelper.formatString(description, maxWidth = 300, textDirection = TextDirection.TopToBottom) ?: return
        val descriptionLines = formattedDescription.numLines.coerceIn(3, 12)

        val menuName = when {
            descriptionLines == 3 -> "menu    iteminfo"
            descriptionLines <= 9 -> "menu    item${descriptionLines}inf"
            else -> "menu    item${descriptionLines}in"
        }

        val menuPos = if (context != null) {
            val offset = Vector2f(context.latestPosition!!.x, context.latestPosition!!.y + context.latestMenu!!.frame.size.y + 2f)
            UiElementHelper.drawMenu(menuName = menuName, offsetOverride = offset) ?: return
        } else {
            UiElementHelper.drawMenu(menuName = menuName, menuStacks = MenuStacks.LogStack) ?: return
        }

        UiElementHelper.drawFormattedString(formattedDescription, offset = menuPos + Vector2f(x = 46f, y = 8f))
        UiElementHelper.drawInventoryItem(itemInfo, position = menuPos + Vector2f(8f, 10f))
    }

    fun useSelectedInventoryItem(currentItem: InventoryItem, target: ActorId) {
        val player = ActorManager.player()
        val currentItemInfo = currentItem.info()

        if (currentItemInfo.type == ItemListType.UsableItem) {
            GameEngine.startUsingItem(player.id, target, currentItem)
        }
    }

}