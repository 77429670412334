package xim.poc.tools

import xim.poc.gl.DrawXimCommand
import xim.poc.gl.Drawer
import xim.poc.ActorManager
import xim.resource.ZoneResource

object CollisionViewer {

    fun drawZoneCollision(drawer: Drawer, zoneDat: ZoneResource) {
        zoneDat.zoneCollisionMeshes.forEach { grouping ->
            grouping.collisionObjects.forEach {
                drawer.drawXim(
                    DrawXimCommand(
                        meshes = listOf(it.collisionMesh.meshBuffer),
                        modelTransform = it.transformInfo.transform,
                    )
                )
            }
        }
    }

    fun drawLocalCollisionMap(drawer: Drawer, zoneDat: ZoneResource) {
        val collisionObjectGroupList = zoneDat.zoneCollisionMap?.getCollisionObjects(ActorManager.player().displayPosition)
        collisionObjectGroupList?.forEach { collisionObjectGroup ->
            collisionObjectGroup.collisionObjects.forEach {
                drawer.drawXim(
                    DrawXimCommand(
                    meshes = listOf(it.collisionMesh.meshBuffer),
                    modelTransform = it.transformInfo.transform,
                )
                )
            }
        }
    }

}