package xim.poc.game

import xim.math.Vector3f
import xim.poc.*
import xim.poc.browser.DatLoader
import xim.poc.browser.LocalStorage
import xim.poc.game.configuration.*
import xim.poc.game.event.*
import xim.poc.game.event.ZoneSettings
import xim.poc.tools.DestinationZoneConfig
import xim.poc.tools.ZoneNpcTool
import xim.resource.*
import xim.resource.table.*
import xim.util.Fps
import xim.util.OnceLogger
import xim.util.Stack
import kotlin.math.min

object GameEngine {

    private val events = Stack<Event>()
    private var setup = false

    fun setup() {
        if (setup) { return }
        setup = true

        createPlayer()
        applyEvents()
    }

    fun tick(elapsedFrames: Float) {
        val allActors = ActorStateManager.getAll()
            .filter { !ZoneNpcTool.isForceHidden(it) }

        events += allActors.map { TickActorEvent(elapsedFrames, it) }
        applyEvents()

        EventScriptRunner.update(elapsedFrames)
        GameState.update(elapsedFrames)
    }

    private fun applyEvents() {
        while (!events.isEmpty()) {
            val event = events.pop()
            events += event.apply()
        }
    }

    fun submitCreateActorState(initialActorState: InitialActorState, callback: CreateDisplayCallback? = null) {
        events += ActorCreateEvent(initialActorState, callback)
    }

    fun submitDeleteActor(it: ActorId) {
        events += ActorDeleteEvent(it)
    }

    fun updateBaseLook(actor: ActorId, modelLook: ModelLook) {
        events += ActorUpdateBaseLookEvent(actor, modelLook)
    }

    fun onDisplayDeath(actorId: ActorId) {
        val actorState = ActorStateManager[actorId] ?: return
        if (actorState.isPlayer()) { UiStateHelper.clear() }

        onDisengage(actorState)

        val actor = ActorManager[actorId] ?: return
        actor.onDisplayDeath()
    }

    fun onPlayerEngage() {
        val player = ActorStateManager.player()
        val targetId = player.targetState.targetId ?: return
        onEngage(player.id, targetId)
    }

    private fun onEngage(actor: ActorId, target: ActorId) {
        events += BattleEngageEvent(actor, target)
    }

    fun onPlayerDisengage() = onDisengage(ActorStateManager.player())

    private fun onDisengage(actorState: ActorState?) {
        actorState ?: return
        if (!actorState.isEngaged()) { return }
        events += BattleDisengageEvent(actorState.id)
    }

    fun startCasting(source: ActorId, target: ActorId, spellInfo: SpellInfo) {
        events += CastSpellStart(source, target, spellInfo.index)
    }

    fun startRangedAttack(source: ActorId, target: ActorId) {
        events += CastRangedAttackStart(source, target)
    }

    fun startUsingItem(sourceId: ActorId, target: ActorId, inventoryItem: InventoryItem) {
        events += CastItemStart(sourceId, target, inventoryItem.internalId)
    }

    fun useAbility(abilityInfo: AbilityInfo, sourceId: ActorId, targetId: ActorId) {
        events += CastAbilityStart(sourceId, targetId, abilityInfo.index)
    }

    fun useMobSkill(mobSkillInfo: MobSkillInfo, sourceId: ActorId, targetId: ActorId) {
        events += CastMobSkillStart(sourceId, targetId, mobSkillInfo.id)
    }

    fun displaySpell(spellInfo: SpellInfo, sourceId: ActorId, primaryTargetId: ActorId, allTargetIds: List<ActorId>, actionContext: AttackContexts) {
        val animationId = SpellAnimationTable[spellInfo]
        val datPath = FileTableManager.getFilePath(animationId) ?: throw IllegalStateException("Spell has no resource? $spellInfo")
        displayMain(datPath = datPath, sourceId = sourceId, primaryTargetId = primaryTargetId, allTargets = allTargetIds, attackContexts = actionContext)
    }

    fun displayAbility(abilityInfo: AbilityInfo, sourceId: ActorId, primaryTargetId: ActorId, allTargetIds: List<ActorId>, actionContext: AttackContexts) {
        val source = ActorStateManager[sourceId] ?: return
        val race = source.getCurrentLook().race

        val animationId = if (abilityInfo.index == AbilityId.DoubleUp.id) {
            val linkedAbility = source.getStatusEffect(StatusEffect.DoubleUpChance.id)?.linkedAbilityId ?: return
            AbilityTable.getAnimationId(AbilityInfoTable[linkedAbility], race)
        } else {
            AbilityTable.getAnimationId(abilityInfo, race)
        }

        if (abilityInfo.type == AbilityType.PetAbility || abilityInfo.type == AbilityType.PetWard) {
            displayPetAbility(source, primaryTargetId, abilityInfo)
        }

        if (animationId == null) {
            OnceLogger.warn("No animation for: ${AbilityNameTable.first(abilityInfo.index)}")
            return
        }

        val datPath = FileTableManager.getFilePath(animationId) ?: throw IllegalStateException("Ability has no resource? ${abilityInfo.index}")
        displayMain(datPath = datPath, sourceId = sourceId, primaryTargetId = primaryTargetId, allTargets = allTargetIds, attackContexts = actionContext)
    }

    fun displayMobSkill(mobSkillInfo: MobSkillInfo, sourceId: ActorId, primaryTargetId: ActorId, allTargetIds: List<ActorId>, actionContext: AttackContexts) {
        val datPath = MobSkillInfoTable.getAnimationPath(mobSkillInfo) ?: return
        displayMain(datPath = datPath, sourceId = sourceId, primaryTargetId = primaryTargetId, allTargets = allTargetIds, attackContexts = actionContext)
    }

    private fun displayPetAbility(actor: ActorState, targetId: ActorId, abilityInfo: AbilityInfo) {
        val pet = ActorStateManager[actor.pet] ?: return
        val petAnimation = PetSkillTable.getAnimationId(abilityInfo) ?: return
        val petDatPath = FileTableManager.getFilePath(petAnimation) ?: return
        displayMain(datPath = petDatPath, sourceId = pet.id, primaryTargetId = targetId, attackContext = AttackContext.noop())
    }

    fun displayItemAnimation(inventoryItemInfo: InventoryItemInfo, sourceId: ActorId, targetId: ActorId) {
        val source = ActorManager[sourceId]
        val animPath = ItemAnimationTable.getAnimationPath(inventoryItemInfo)

        if (animPath == null) {
            source?.transitionToIdle(10f)
            return
        }

        val attackContext = AttackContext.noop()
        displayMain(datPath = animPath, sourceId = sourceId, primaryTargetId = targetId, attackContext = attackContext)
    }

    fun canBeginCastSpell(actorId: ActorId, spellInfo: SpellInfo): Boolean {
        val actorState = ActorStateManager[actorId] ?: return false
        if (!actorState.isIdleOrEngaged()) { return false }
        return canCastSpell(actorId, spellInfo)
    }

    fun canCastSpell(actorId: ActorId, spellInfo: SpellInfo): Boolean {
        val actorState = ActorStateManager[actorId] ?: return false

        val recastDelay = actorState.getRecastDelay(spellInfo)
        if (recastDelay != null && !recastDelay.isComplete()) { return false }

        if (spellInfo.mpCost > actorState.getMp()) { return false }

        if (spellInfo.magicType == MagicType.Trust) {
            val actorParty = PartyManager[actorId]
            if (actorParty.size() >= 6) { return false }
        }

        return true
    }

    fun canBeginUseAbility(actorId: ActorId, abilityInfo: AbilityInfo): Boolean {
        val actorState = ActorStateManager[actorId] ?: return false
        if (!actorState.isIdleOrEngaged()) { return false }
        return canUseAbility(actorId, abilityInfo)
    }

    fun canUseAbility(actorId: ActorId, abilityInfo: AbilityInfo): Boolean {
        val actorState = ActorStateManager[actorId] ?: return false

        val recastDelay = actorState.getRecastDelay(abilityInfo)
        if (recastDelay != null && !recastDelay.isComplete()) { return false }

        val cost = getAbilityCost(abilityInfo.index)
        if (cost.type == AbilityCostType.Tp && cost.value > actorState.getTp()) { return false }

        return true
    }

    fun canBeginRangedAttack(actorId: ActorId): Pair<Boolean, String?> {
        val actorState = ActorStateManager[actorId] ?: return Pair(false, null)
        if (!actorState.isIdleOrEngaged()) { return Pair(false, "You must wait longer to perform that action.") }
        return canUseRangedAttack(actorId)
    }

    fun canUseRangedAttack(actorId: ActorId): Pair<Boolean, String?> {
        val actorState = ActorStateManager[actorId] ?: return Pair(false, null)

        val canRecast = actorState.rangedAttackRecast.canRangedAttack()
        if (!canRecast) { return Pair(false, "You must wait longer to perform that action.") }

        return Pair(true, null)
    }

    fun getRangedAttackTargetFilter(): ActionTargetFilter {
        return ActionTargetFilter(TargetFlag.Enemy.flag)
    }

    fun submitMountEvent(source: Actor, index: Int) {
        events += ActorMountEvent(source.id, index)
    }

    fun submitDismountEvent(source: Actor) {
        events += ActorDismountEvent(source.id)
    }

    fun displayMain(datPath: String, sourceId: ActorId, primaryTargetId: ActorId, attackContext: AttackContext) {
        displayMain(datPath = datPath, sourceId = sourceId, primaryTargetId = primaryTargetId, attackContexts = AttackContexts.single(primaryTargetId, attackContext))
    }

    private fun displayMain(datPath: String, sourceId: ActorId, primaryTargetId: ActorId, allTargets: List<ActorId> = listOf(primaryTargetId), attackContexts: AttackContexts) {
        DatLoader.load(datPath).onReady { displayMain(sourceId, primaryTargetId, allTargets, it.getAsResource(), attackContexts) }
    }

    private fun displayMain(sourceId: ActorId, primaryTargetId: ActorId, allTargets: List<ActorId>, dat: DirectoryResource, attackContexts: AttackContexts) {
        val main = dat.getNullableChildRecursivelyAs(DatId.main, EffectRoutineResource::class) ?: throw IllegalStateException("[${dat.id}] Couldn't find [main]")

        val source = ActorManager[sourceId] ?: return
        val target = ActorManager[primaryTargetId] ?: return

        val context = ActorContext(originalActor = source.id, primaryTargetId = target.id, allTargetIds = allTargets, attackContexts = attackContexts)
        source.enqueueRoutine(context, main, options = RoutineOptions(highPriority = true))
    }

    fun getMaximumCraftable(actor: ActorId, recipe: SynthesisRecipe): Int {
        val actorState = ActorStateManager[actor] ?: return 0
        val inventory = actorState.inventory

        var max = 99

        for (input in recipe.input.distinct()) {
            val recipeCount = recipe.input.count { it == input }

            val inventoryCount = inventory.inventoryItems
                .filter { it.id == input }
                .filter { !actorState.isEquipped(it) }
                .sumOf { it.quantity }

            max = min(max, inventoryCount / recipeCount)
        }

        return max
    }

    fun submitReleaseTrust(id: ActorId, target: ActorId?) {
        target ?: return
        events += TrustReleaseEvent(id, target)
    }

    private fun releaseTrusts(actor: ActorState): List<Event> {
        return getTrusts(actor.id).map { TrustReleaseEvent(actor.id, it.id) }
    }

    fun engageTrusts(actorId: ActorId, targetId: ActorId) {
        getTrusts(actorId).forEach { onEngage(it.id, targetId) }
    }

    fun getTrusts(actorId: ActorId): List<ActorState> {
        return PartyManager[actorId].getAllState().filter { it.owner == actorId }
    }

    fun releaseDependents(actor: ActorState): List<Event> {
        val releaseEvents = ArrayList<Event>()
        releaseEvents += releaseTrusts(actor)
        releaseEvents += BubbleReleaseEvent(actor.id)
        releaseEvents += PetReleaseEvent(actor.id)
        return releaseEvents
    }

    fun toggleResting(actor: ActorId) {
        val state = ActorStateManager[actor] ?: return
        if (state.isResting()) { stopResting(actor) } else { startResting(actor) }
    }

    private fun startResting(actor: ActorId) {
        events += RestingStartEvent(actor)
    }

    private fun stopResting(actor: ActorId) {
        events += RestingEndEvent(actor)
    }

    fun startSynthesis(actor: ActorId, recipe: SynthesisRecipe?, quantity: Int) {
        recipe ?: return
        events += SynthesisStartEvent(actor, recipe.id, quantity)
    }

    fun equipItem(actor: ActorId, equipSlot: EquipSlot, internalItemId: InternalItemId?) {
        events += ActorEquipEvent(actor, mapOf(equipSlot to internalItemId))
    }

    fun requestZoneChange(destinationZoneConfig: DestinationZoneConfig) {
        events += ChangeZoneEvent(ActorStateManager.playerId, destinationZoneConfig)
    }

    fun submitTargetUpdate(sourceId: ActorId, targetId: ActorId?) {
        events += ActorTargetEvent(sourceId, targetId)
    }

    fun submitClearTarget(sourceId: ActorId) {
        submitTargetUpdate(sourceId, null)
    }

    fun toggleTargetLock(sourceId: ActorId) {
        val state = ActorStateManager[sourceId] ?: return
        events += ActorTargetEvent(sourceId, state.targetState.targetId, !state.targetState.locked)
    }

    fun submitChangeJob(sourceId: ActorId, mainJob: Job? = null, subJob: Job? = null) {
        events += ChangeJobEvent(sourceId, mainJob?.index, subJob?.index)
    }

    fun submitGainItem(actor: ActorId, item: InventoryItemInfo) {
        events += InventoryGainEvent(actor, item.itemId)
    }

    fun submitDiscardItem(actor: ActorId, item: InventoryItem) {
        events += InventoryDiscardEvent(actor, item.internalId)
    }

    fun submitInventorySort(actor: ActorId) {
        events += InventorySortEvent(actor)
    }

    fun submitOpenDoor(actor: ActorId, doorId: DatId) {
        events += DoorOpenEvent(actor, doorId)
    }

    fun submitGatheringAttempt(actor: ActorId, gatheringNode: ActorId) {
        events += GatheringEvent(actor, gatheringNode)
    }

    fun getSpellList(actorId: ActorId): List<SpellInfo> {
        val actor = ActorStateManager[actorId] ?: return emptyList()
        val spellIds = if (actor.monsterId != null) {
            MonsterDefinitions[actor.monsterId]?.mobSpells ?: return emptyList()
        } else if (GameState.isDebugMode()) {
            DebugHelpers.allValidSpellIds
        } else {
            listOf(1, 2, 7, 144, 149, 174, 179, 296, 533)
        }

        return spellIds.map { SpellInfoTable[it] }
    }

    fun getRangeInfo(actor: ActorState, skillInfo: SkillInfo): SkillRangeInfo {
        return when (skillInfo.type) {
            SkillType.Spell -> getCastingRangeInfo(actor, skillInfo.id)
            SkillType.MobSkill -> getMobSkillRange(actor, skillInfo.id)
            SkillType.Ability -> getAbilityRange(actor, skillInfo.id)
        }
    }

    fun getCastingRangeInfo(actorState: ActorState, spellId: Int): SkillRangeInfo {
        val spellInfo = SpellInfoTable[spellId]
        return SkillRangeInfo(maxTargetDistance = 10f, effectRadius = spellInfo.aoeSize.toFloat(), type = spellInfo.aoeType)
    }

    fun getItemRange(actorState: ActorState, itemId: Int): SkillRangeInfo {
        return SkillRangeInfo(maxTargetDistance = 10f, effectRadius = 0f, type = AoeType.None)
    }

    fun getAbilityRange(actor: ActorState, abilityId: Int): SkillRangeInfo {
        val abilityInfo = AbilityInfoTable[abilityId]
        return SkillRangeInfo(maxTargetDistance = 10f, effectRadius = abilityInfo.aoeSize.toFloat(), type = abilityInfo.aoeType)
    }

    fun getMobSkillRange(actor: ActorState, mobSkillId: Int): SkillRangeInfo {
        return MobSkills.definitionsById[mobSkillId]?.rangeInfo ?: SkillRangeInfo(maxTargetDistance = 10f, effectRadius = 0f, type = AoeType.None)
    }

    fun getRangedAttackRangeInfo(actor: ActorState): SkillRangeInfo {
        return SkillRangeInfo(maxTargetDistance = 10f, effectRadius = 0f, type = AoeType.None)
    }

    fun getTpGained(attacker: ActorState, defender: ActorState, equipSlot: EquipSlot): Int {
        val equip = attacker.getEquipment(equipSlot) ?: return 0
        val delay = equip.info().equipmentItemInfo.weaponInfo.delay ?: return 0
        return delay / 3
    }

    fun getAbilityCost(abilityId: Int): AbilityCost {
        val gameSkillDefinition = AbilitySkills.definitionsById[abilityId]?.abilityCost
        if (gameSkillDefinition != null) { return gameSkillDefinition }

        // TODO: Depends on ability-type - MP, stratagem charge, quick-draw card, etc
        val abilityInfo = AbilityInfoTable[abilityId]
        return AbilityCost(AbilityCostType.Tp, abilityInfo.cost)
    }

    fun getAbilityRecast(actorState: ActorState, abilityInfo: AbilityInfo): Float {
        val delayInSeconds = if (abilityInfo.type == AbilityType.WeaponSkill) {
            2.5f
        } else if (abilityInfo.type == AbilityType.PhantomRoll) {
            2.5f
        } else {
            5f
        }

        return Fps.secondsToFrames(delayInSeconds)
    }

    fun getWeaponSkillPotency(actor: ActorState, abilityId: Int): Float {
        return when (abilityId) {
            32 -> 1.5f
            34 -> 4f
            else -> 0f
        }
    }

    fun getWeaponSkillSkillChainAttributes(actor: ActorState, abilityId: Int): List<SkillChainAttribute> {
        return AbilityInfoTable.getApproximateSkillChainAttributes(abilityId)
    }

    fun getMagicBurstBonus(source: ActorState, target: ActorState, spellId: Int): Float? {
        val spellInfo = SpellInfoTable[spellId]
        return target.skillChainTargetState.getMagicBurstBonus(spellInfo)
    }

    fun getRangedAttackRecast(source: ActorState): Float {
        return source.getRangedAttackInterval()
    }

    fun getAutoAttackRecast(source: ActorState): Float {
        return source.getAutoAttackInterval()
    }

    private fun createPlayer() {
        val config = LocalStorage.getPlayerConfiguration()
        val modelLook = config.playerLook.copy()

        val zoneSettings = ZoneSettings(zoneId = config.playerPosition.zoneId, subAreaId = config.playerPosition.subAreaId)

        submitCreateActorState(InitialActorState(
            name = "Player",
            type = ActorType.Pc,
            position = Vector3f(config.playerPosition.position),
            rotation = config.playerPosition.rotation,
            zoneSettings = zoneSettings,
            modelLook = modelLook,
            equipment = Equipment().copyFrom(config.playerEquipment),
            movementController = KeyboardActorController(),
            presetId = ActorStateManager.playerId,
            jobSettings = JobSettings(config.playerJob.mainJob, config.playerJob.subJob),
            jobLevels = config.playerLevels,
            inventory = config.playerInventory,
        ))
    }

}

private object DebugHelpers {
    val allValidSpellIds: List<Int> by lazy {
        (1 until 1024)
            .filter { SpellInfoTable.hasInfo(it) }
            .filter { SpellNameTable.first(it) != "." }
            .filter { SpellNameTable.first(it) != "jet-stream-attack" }
            .filter { SpellNameTable.first(it) != "dummy" }
    }
}