package xim.poc.game.configuration

import xim.poc.*
import xim.poc.game.CombatStats
import xim.poc.gl.ByteColor
import xim.resource.Blur
import xim.resource.BlurConfig

typealias MonsterId = Int

fun standardBlurConfig(color: ByteColor, radius: Float = 70f): BlurConfig {
    val configs = ArrayList<Blur>()
    repeat(5) { configs += Blur(radius, color) }
    return BlurConfig(configs)
}

data class ItemDropSlot(val itemId: Int, val quantity: Int = 1, val quality: Int = 0, val augmentRank: Int = 1)

data class MonsterDefinition(
    val id: MonsterId,
    val name: String,
    val look: ModelLook,
    val behaviorId: BehaviorId = ActorBehaviors.BasicMonsterController,
    val movementController: ActorController = DefaultEnemyController(),
    val mobSkills: List<Int> = emptyList(),
    val mobSpells: List<Int> = emptyList(),
    val customModelSettings: CustomModelSettings = CustomModelSettings(),
    val baseLevel: Int = 1,
    val baseDamage: Int = 3,
    val baseDelay: Int = 180,
    val baseCombatStats: CombatStats = CombatStats(20, 20, 5, 5, 5, 5, 5, 5, 5),
    val notoriousMonster: Boolean = false,
    val dropTable: List<WeightedTable<ItemDropSlot?>> = emptyList(),
    val onSpawn: ((Actor) -> Unit)? = null
)

class MonsterInstance(val monsterDefinition: MonsterDefinition, val actorId: ActorId)

object MonsterDefinitions {

    private val definitions = HashMap<MonsterId, MonsterDefinition>()

    operator fun get(monsterId: MonsterId?): MonsterDefinition? {
        monsterId ?: return null
        return get(monsterId)
    }

    operator fun get(monsterId: MonsterId): MonsterDefinition {
        return definitions[monsterId] ?: throw IllegalStateException("[$monsterId] Undefined monster")
    }

    operator fun set(monsterId: MonsterId, monsterDefinition: MonsterDefinition) {
        if (definitions.containsKey(monsterId)) { throw IllegalStateException("Monster definition $monsterId is already defined by ${definitions[monsterId]}") }
        definitions[monsterId] = monsterDefinition
    }

    fun registerAll(definitions: List<MonsterDefinition>) {
        definitions.forEach { set(it.id, it) }
    }

}