package xim.poc.game.configuration.assetviewer

import xim.poc.ModelLook
import xim.poc.NoOpActorController
import xim.poc.game.CombatStats
import xim.poc.game.StatusEffect
import xim.poc.game.configuration.ActorBehaviors
import xim.poc.game.configuration.MonsterDefinition

object AssetViewerMonsterDefinitions {

    val definitions: List<MonsterDefinition>

    init {
        definitions = ArrayList()

        definitions += MonsterDefinition(id = 0x001, "Mandy", ModelLook.npc(0x012C), mobSkills = listOf(44, 45, 46, 48, 49, 50))
        definitions += MonsterDefinition(id = 0x002, "Crawler", ModelLook.npc(0x018C), mobSkills = listOf(88, 89, 90))
        definitions += MonsterDefinition(id = 0x003, "Gobbie", ModelLook.npc(0x01E5), mobSkills = listOf(334, 335, 336))
        definitions += MonsterDefinition(id = 0x004, "Yagudo", ModelLook.npc(0x0246), mobSkills = listOf(361, 362, 363, 364))
        definitions += MonsterDefinition(id = 0x005, "Bird", ModelLook.npc(0x01BD), mobSkills = listOf(366, 367))
        definitions += MonsterDefinition(id = 0x006, "Dhalmel", ModelLook.npc(0x014C), mobSkills = listOf(24, 25, 28, 29, 30, 31))

        definitions += MonsterDefinition(id = 0x007, "Fire", ModelLook.npc(0x01B4))  { it.getState().gainStatusEffect(StatusEffect.BlazeSpikes.id); it.getState().gainStatusEffect(StatusEffect.Enfire.id) }
        definitions += MonsterDefinition(id = 0x008, "Ice", ModelLook.npc(0x01B5)) { it.getState().gainStatusEffect(StatusEffect.IceSpikes.id); it.getState().gainStatusEffect(StatusEffect.Enblizzard.id) }
        definitions += MonsterDefinition(id = 0x009, "Earth", ModelLook.npc(0x01B7))

        definitions += MonsterDefinition(id = 0x00A, "Dummy", ModelLook.npc(0x091B), behaviorId = ActorBehaviors.NoAction, movementController = NoOpActorController(), baseCombatStats = CombatStats.defaultBaseStats.copy(maxHp = 999))
    }

}