package xim.poc.ui

import xim.poc.game.AugmentHelper
import xim.poc.game.InventoryItem

data class InventoryItemDescription(
    val name: String,
    val pages: List<String> = emptyList(),
    val itemType: String? = null,
    val quantity: String? = null,
    val jobLevels: String? = null,
    val augmentPath: String? = null,
    val itemCast: String? = null,
    val itemLevel: String? = null,
) {
    companion object {
        fun toDescription(inventoryItem: InventoryItem): InventoryItemDescription {
            val itemInfo = inventoryItem.info()

            val name = itemInfo.logName.replaceFirstChar { it.uppercaseChar() }
            val quantity = if (inventoryItem.isStackable()) { "Quantity: ${inventoryItem.quantity}" } else { null }

            val descriptionLines = itemInfo.description.split("\n")
            val pages = if (descriptionLines.size <= 7) {
                listOf(itemInfo.description)
            } else {
                listOf(
                    descriptionLines.subList(0, 7).joinToString(separator = "\n"),
                    descriptionLines.subList(7, descriptionLines.size).joinToString(separator = "\n"),
                )
            }

            var augmentPath: String? = null
            val augment = inventoryItem.augments
            if (augment != null) {
                val rpNeeded = AugmentHelper.getRpToNextLevel(augment.rankLevel) - augment.rankPoints
                augmentPath = "${ShiftJis.colorAug}< Rank:${augment.rankLevel} / Next Rank:$rpNeeded >${ShiftJis.colorClear}"
            }

            return InventoryItemDescription(
                name = name,
                pages = pages,
                quantity = quantity,
                augmentPath = augmentPath,
            )
        }
    }
}