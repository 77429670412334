package xim.poc.game

import kotlinx.serialization.Serializable
import xim.poc.ui.ShiftJis
import xim.resource.EquipSlot
import xim.resource.InventoryItems
import xim.resource.table.AbilityInfoTable
import kotlin.random.Random

typealias InternalItemId = Long
typealias ItemAugmentId = Int

@Serializable
data class ItemAugment(
    var rankPoints: Int = 0,
    var rankLevel: Int = 1,
    var augmentIds: MutableList<ItemAugmentId> = ArrayList(),
)

@Serializable
data class InventoryItem(
    val id: Int,
    val internalId: InternalItemId = Random.nextLong(),
    var quantity: Int = 1,
    var augments: ItemAugment? = null,
    var internalQuality: Int = 0,
) {

    fun info() = InventoryItems[id]

    fun isStackable() = augments == null && info().isStackable()

}

@Serializable
class Inventory {

    companion object {
        fun player() = ActorStateManager.player().inventory
    }

    val inventoryItems = ArrayList<InventoryItem>()

    fun getInventoryItemsByEquipSlot(equipSlot: EquipSlot) : List<InventoryItem> {
        return inventoryItems.filter { InventoryItems[it.id].equipmentItemInfo.equipSlots.contains(equipSlot) }
    }

    fun addItem(id: Int, quantity: Int) {
        repeat(quantity) { addItem(id) }
    }

    fun addItem(inventoryItem: InventoryItem, stack: Boolean = true) {
        if (stack && inventoryItem.isStackable()) {
            addItem(inventoryItem.id, inventoryItem.quantity)
        } else {
            inventoryItems += inventoryItem
        }
    }

    fun addItem(id: Int) {
        val itemInfo = InventoryItems[id]

        if (!itemInfo.isStackable()) {
            inventoryItems += InventoryItem(id)
            return
        }

        val current = inventoryItems.firstOrNull { it.isStackable() && it.id == id }
        if (current != null) {
            current.quantity += 1
        } else {
            inventoryItems += InventoryItem(id, quantity = 1)
        }
    }

    fun discardItem(internalItemId: InternalItemId) {
        inventoryItems.removeAll { it.internalId == internalItemId }
    }

    fun discardItem(internalItemId: InternalItemId, amount: Int) {
        val item = getByInternalId(internalItemId) ?: return
        if (item.quantity > amount) { item.quantity -= amount } else { inventoryItems.remove(item) }
    }

    fun sort() {
        inventoryItems.sortWith(this::compare)
    }

    fun copyFrom(other: Inventory) {
        inventoryItems.clear()
        inventoryItems.addAll(other.inventoryItems)
    }

    fun getByInternalId(internalItemId: InternalItemId?): InventoryItem? {
        internalItemId ?: return null
        return inventoryItems.firstOrNull { it.internalId == internalItemId }
    }

    private fun compare(a: InventoryItem, b: InventoryItem): Int {
        val aInfo = a.info()
        val bInfo = b.info()

        val typeComparison = aInfo.itemType.sortOrder.compareTo(bInfo.itemType.sortOrder)
        if (typeComparison != 0) { return typeComparison }

        val aMask = aInfo.equipmentItemInfo.equipSlots.firstOrNull()?.mask
        val bMask = bInfo.equipmentItemInfo.equipSlots.firstOrNull()?.mask

        if (aMask != null && bMask != null) {
            return aMask.compareTo(bMask)
        } else if (aMask != null) {
            return 1
        } else if (bMask != null) {
            return -1
        }

        return a.id.compareTo(b.id)
    }

}

object AugmentHelper {

    fun qualityBonus(item: InventoryItem): Float {
        return 1f + item.internalQuality/3f
    }

    fun isMaxRank(augment: ItemAugment): Boolean {
        return augment.rankLevel >= 10
    }

    fun getRpToNextLevel(rank: Int): Int {
        if (rank == 10) { return 0 }
        return AbilityInfoTable.getReinforcementPointsTable().table.entries[rank]
    }

    fun getQualityColorDisplay(item: InventoryItem): Char {
        return when (item.internalQuality) {
            0 -> ShiftJis.colorClear
            1 -> ShiftJis.colorItem
            2 -> ShiftJis.colorKey
            3 -> ShiftJis.colorGold
            else -> throw IllegalStateException("Unknown quality: ${item.internalQuality}")
        }
    }

}