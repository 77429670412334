package xim.poc.game.event

import xim.poc.ActorId
import xim.poc.game.ActorStateManager
import xim.poc.game.StatusEffect
import xim.poc.ui.ChatLog

class StatusEffectLostEvent(
    val sourceId: ActorId,
    val statusId: Int,
    val applierId: ActorId? = null,
): Event {

    override fun apply(): List<Event> {
        val sourceState = ActorStateManager[sourceId] ?: return emptyList()
        val status = StatusEffect.values().firstOrNull { it.id == statusId } ?: return emptyList()

        val events = ArrayList<Event>()

        if (status == StatusEffect.Indicolure && sourceState.bubble != null) {
            events += BubbleReleaseEvent(sourceId)
        }

        if (!sourceState.isDead() && applierId == ActorStateManager.playerId) {
            ChatLog.statusEffectLost(sourceState.name, status)
        }

        return events
    }

}