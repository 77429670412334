package xim.poc.game.configuration.assetviewer

import xim.poc.ActorId
import xim.poc.game.*
import xim.poc.game.configuration.*
import xim.poc.game.event.ActorEffectTickResult
import xim.poc.game.event.AutoAttackResult
import xim.poc.game.event.AutoAttackType
import xim.poc.game.event.Event
import xim.poc.tools.CustomZoneConfig
import xim.poc.tools.ZoneConfig
import xim.poc.ui.InventoryItemDescription
import xim.resource.*
import xim.resource.table.AbilityInfoTable
import xim.resource.table.AbilityNameTable
import xim.resource.table.SpellInfoTable
import xim.resource.table.SpellNameTable
import xim.util.Fps
import kotlin.math.floor
import kotlin.random.Random

private val assetViewerConfiguration = GameConfiguration(
    gameModeId = "AssetViewer",
    startingZoneConfig = CustomZoneConfig.Sarutabartua_East.config,
    debugControlsEnabled = true
)

object AssetViewer: GameLogic {

    override val configuration = assetViewerConfiguration

    private var zoneLogic: ZoneLogic? = null

    override fun setup(): List<Event> {
        MonsterDefinitions.registerAll(AssetViewerMonsterDefinitions.definitions)

        CommonSkillAppliers.registerTrustAppliers()

        AssetViewerAbilitySkills.register()

        AssetViewerSpellAppliers.register()

        return emptyList()
    }

    override fun update(elapsedFrames: Float) {
        zoneLogic?.update(elapsedFrames)
    }

    override fun onChangedZones(zoneConfig: ZoneConfig) {
        zoneLogic?.cleanUp()

        zoneLogic = when (zoneConfig.zoneId) {
            EastSaru.zoneId -> EastSaru.zoneLogic
            else -> null
        }
    }

    override fun getNpcInteraction(actorId: ActorId): NpcInteraction? {
        val actor = ActorStateManager[actorId] ?: return null

        if (actor.name.startsWith("Home Point")) {
            return HomePointInteraction
        }

        return null
    }

    override fun getActorSpellList(actorId: ActorId): List<SpellInfo> {
        return DebugHelpers.allValidSpellIds.map { SpellInfoTable[it] }
    }

    override fun getActorAbilityList(actorId: ActorId, abilityType: AbilityType): List<AbilityInfo> {
        return DebugHelpers.abilitiesByType[abilityType] ?: emptyList()
    }

    override fun canDualWield(actorState: ActorState): Boolean {
        return true
    }

    override fun getActorCombatStats(actorId: ActorId): CombatStats {
        val actor = ActorStateManager[actorId] ?: return CombatStats.defaultBaseStats

        val monsterDefinition = MonsterDefinitions[actor.monsterId]
        if (monsterDefinition?.baseCombatStats != null) { return monsterDefinition.baseCombatStats }

        return CombatStats.defaultBaseStats.copy(maxMp = 999)
    }

    override fun getAbilityCost(abilityInfo: AbilityInfo): AbilityCost {
        // TODO: Depends on ability-type - MP, stratagem charge, quick-draw card, etc
        return AbilityCost(AbilityCostType.Tp, abilityInfo.cost)
    }

    override fun generateItem(itemId: Int, quantity: Int, quality: Int, augmentRank: Int): InventoryItem {
        return InventoryItem(id = itemId, quantity = quantity, internalQuality = quality)
    }

    override fun getItemDescription(actorId: ActorId, inventoryItem: InventoryItem): InventoryItemDescription {
        return InventoryItemDescription.toDescription(inventoryItem)
    }

    override fun getAutoAttackResult(attacker: ActorState, defender: ActorState): List<AutoAttackResult> {
        val attacks = ArrayList<AutoAttackResult>()

        attacks += DebugHelpers.getAutoAttackResult(attacker, defender, AutoAttackType.Main)
        if (attacker.isDualWield()) {
            attacks += DebugHelpers.getAutoAttackResult(attacker, defender, AutoAttackType.Sub)
        } else if (attacker.isHandToHand()) {
            attacks += DebugHelpers.getAutoAttackResult(attacker, defender, AutoAttackType.H2H)
        }

        return attacks
    }

    override fun getAutoAttackInterval(attacker: ActorState): Float {
        return DebugHelpers.getMainDelayInFrames(attacker) + DebugHelpers.getSubDelayInFrames(attacker)
    }

    override fun getAugmentRankPointGain(attacker: ActorState, defender: ActorState, inventoryItem: InventoryItem): Int {
        return 0
    }

    override fun getExperiencePointGain(attacker: ActorState, defender: ActorState): Int {
        return 500
    }

    override fun getActorEffectTickResult(actorState: ActorState): ActorEffectTickResult {
        return ActorEffectTickResult(hpDelta = 0, mpDelta = 0)
    }

    override fun getItemReinforcementValues(targetItem: InventoryItem): Map<Int, Int> {
        return emptyMap()
    }

    override fun getSkillRangeInfo(skillType: SkillType, skillId: Int): SkillRangeInfo {
        return SkillRangeInfo(maxTargetDistance = 10f, effectRadius = 0f, type = AoeType.None)
    }

    override fun getSpellCastTime(caster: ActorState, spellId: Int): Float {
        val spellInfo = SpellInfoTable[spellId]
        return spellInfo.castTimeInFrames()
    }

}

private object DebugHelpers {
    val allValidSpellIds: List<Int> by lazy {
        (1 until 1024)
            .filter { SpellInfoTable.hasInfo(it) }
            .filter { SpellNameTable.first(it) != "." }
            .filter { SpellNameTable.first(it) != "jet-stream-attack" }
            .filter { SpellNameTable.first(it) != "dummy" }
    }

    val abilitiesByType: Map<AbilityType, List<AbilityInfo>> by lazy {
        (1 until 0x1000)
            .filter { AbilityInfoTable.hasInfo(it) }
            .filter { AbilityNameTable.first(it) != "." }
            .map { AbilityInfoTable[it] }
            .groupBy { it.type }
    }

    fun getMainHandDamage(actorState: ActorState): Int {
        return delayToDamage(getMainDelayInFrames(actorState))
    }

    fun getSubHandDamage(actorState: ActorState): Int {
        return delayToDamage(getSubDelayInFrames(actorState))
    }

    fun getMainDelayInFrames(actorState: ActorState): Float {
        return actorState.getEquipment(EquipSlot.Main)?.info()?.equipmentItemInfo?.weaponInfo?.delay?.toFloat()
            ?: Fps.secondsToFrames(3)
    }

    fun getSubDelayInFrames(actorState: ActorState): Float {
        return actorState.getEquipment(EquipSlot.Sub)?.info()?.equipmentItemInfo?.weaponInfo?.delay?.toFloat()
            ?: 0f
    }

    fun delayToDamage(delay: Float): Int {
        val base = delay / 60f
        val remainder = (base % 1f)
        val roundUp = if (Random.nextFloat() < remainder) { 1 } else { 0 }
        return floor(base).toInt() + roundUp
    }

    fun getAutoAttackResult(attacker: ActorState, defender: ActorState, type: AutoAttackType): AutoAttackResult {
        val damage = when (type) {
            AutoAttackType.Main -> getMainHandDamage(attacker)
            AutoAttackType.Sub -> getSubHandDamage(attacker)
            AutoAttackType.H2H -> getMainHandDamage(attacker)
        }

        val context = AttackContext.from(attacker, defender)
        context.setSourceFlag(attacker)

        if (Random.nextDouble() < 0.5) {
            context.setCriticalHitFlag()
        }

        return AutoAttackResult(context = context, tpGained = 1000, damageDone = damage, targetId = defender.id, type = type)
    }

}