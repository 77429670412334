package xim.poc.game.event

import xim.poc.ActorId
import xim.poc.ActorManager
import xim.poc.game.ActorStateManager
import xim.poc.game.SynthesisState
import xim.poc.game.configuration.RecipeId
import xim.poc.game.configuration.SynthesisRecipes

class SynthesisStartEvent(
    val sourceId: ActorId,
    val recipeId: RecipeId,
    val quantity: Int,
): Event {

    override fun apply(): List<Event> {
        val actorState = ActorStateManager[sourceId] ?: return emptyList()
        val recipe = SynthesisRecipes[recipeId] ?: return emptyList()

        if (!actorState.isIdle()) { return emptyList() }
        actorState.setSynthesisState(SynthesisState(recipeId, quantity))

        val actor = ActorManager[sourceId] ?: return emptyList()
        actor.startSynthesis(recipe.synthesisType)

        return emptyList()
    }

}