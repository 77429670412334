package xim.resource.table

import xim.poc.ActionTargetFilter
import xim.poc.browser.DatLoader
import xim.resource.ByteReader
import xim.resource.TargetFlag

val MobSkillNameTable = BasicStringTable("ROM/27/80.DAT", 0x80.toByte())

data class MobSkillInfo(val id: Int, val animationId: Int, val type: Int) {

    val targetFlag: Int = validTargetsToTargetFlag(type)
    val targetFilter = ActionTargetFilter(targetFlag)

    private fun validTargetsToTargetFlag(validTargets: Int): Int {
        return when (validTargets) {
            1 -> TargetFlag.Self.flag
            2 -> TargetFlag.Party.flag
            3 -> TargetFlag.Self.flag or TargetFlag.Party.flag
            4 -> TargetFlag.Enemy.flag
            // 16 -> pup?
            else -> 0
        }
    }

}

// https://raw.githubusercontent.com/LandSandBoat/server/base/sql/mob_skills.sql
object MobSkillInfoTable: LoadableResource {


    private lateinit var animInfos: Map<Int, MobSkillInfo>
    private var preloaded = false

    override fun preload() {
        if (preloaded) { return }
        preloaded = true
        loadTable()
    }

    override fun isFullyLoaded() : Boolean {
        return this::animInfos.isInitialized
    }

    operator fun get(skillId: Int): MobSkillInfo? {
        return animInfos[skillId]
    }

    fun getAnimationPath(info: MobSkillInfo): String? {
        return getAnimationPath(info.animationId)
    }

    fun getAnimationPath(animationId: Int): String? {
        val fileTableOffset = if (animationId < 0x200) {
            0x0F3C
        } else if (animationId < 0x600) {
            0xC1EF
        } else if (animationId < 0x800) {
            0xE739
        } else {
            0x14B07
        }

        val fileTableIndex = fileTableOffset + animationId
        return FileTableManager.getFilePath(fileTableIndex)
    }

    private fun loadTable() {
        DatLoader.load("landsandboat/MobSkillsTable.DAT").onReady { parseTable(it.getAsBytes()) }
    }

    private fun parseTable(byteReader: ByteReader) {
        val skills = HashMap<Int, MobSkillInfo>()
        while (byteReader.hasMore()) {
            val skill = MobSkillInfo(id = byteReader.next16(), animationId = byteReader.next16(), type = byteReader.next16())
            skills[skill.id] = skill
        }
        this.animInfos = skills
    }

}
