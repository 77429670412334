package xim.poc.game.event

import xim.poc.ActorId
import xim.poc.game.ActorStateManager
import xim.poc.game.AttackContext
import xim.poc.game.AugmentHelper
import xim.poc.game.InternalItemId
import xim.poc.ui.ChatLog
import xim.poc.ui.ShiftJis

class InventoryItemRpGainEvent(
    val sourceId: ActorId,
    val itemId: InternalItemId,
    val amount: Int,
    val actionContext: AttackContext? = null
): Event {

    override fun apply(): List<Event> {
        val source = ActorStateManager[sourceId] ?: return emptyList()
        val inventoryItem = source.inventory.getByInternalId(itemId) ?: return emptyList()

        val augment = inventoryItem.augments ?: return emptyList()

        val rpNeeded = AugmentHelper.getRpToNextLevel(augment.rankLevel)
        if (rpNeeded == 0) { return emptyList() }

        augment.rankPoints += amount
        if (augment.rankPoints < rpNeeded) { return emptyList() }

        augment.rankPoints = 0
        augment.rankLevel += 1

        val callback = {
            ChatLog("${ShiftJis.colorAug}[${inventoryItem.info().name}] Rank ${ShiftJis.rightArrow} ${augment.rankLevel}!${ShiftJis.colorClear}")
        }

        if (source.isPlayer()) {
            actionContext?.composeCallback(callback) ?: callback.invoke()
        }

        return emptyList()
    }

}