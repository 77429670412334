package xim.poc.game.configuration

import xim.poc.ActorId
import xim.poc.game.ActorState
import xim.poc.game.CombatStats
import xim.poc.game.InventoryItem
import xim.poc.game.event.ActorEffectTickResult
import xim.poc.game.event.AutoAttackResult
import xim.poc.game.event.AutoAttackType
import xim.poc.game.event.Event
import xim.poc.tools.ZoneConfig
import xim.poc.ui.InventoryItemDescription
import xim.resource.AbilityCost
import xim.resource.AbilityInfo
import xim.resource.AbilityType
import xim.resource.SpellInfo

typealias GameModeId = String

class GameConfiguration(
    val gameModeId: GameModeId,
    val startingZoneConfig: ZoneConfig,
    val debugControlsEnabled: Boolean,
)

interface GameLogic {

    val configuration: GameConfiguration

    fun setup(): List<Event>

    fun update(elapsedFrames: Float)

    fun onChangedZones(zoneConfig: ZoneConfig)

    fun getNpcInteraction(actorId: ActorId): NpcInteraction?

    fun getActorSpellList(actorId: ActorId): List<SpellInfo>

    fun getActorAbilityList(actorId: ActorId, abilityType: AbilityType): List<AbilityInfo>

    fun canDualWield(actorState: ActorState): Boolean

    fun getActorCombatStats(actorId: ActorId): CombatStats

    fun getAbilityCost(abilityInfo: AbilityInfo): AbilityCost

    fun generateItem(itemId: Int, quantity: Int = 1, quality: Int = 0, augmentRank: Int = 0): InventoryItem

    fun getItemDescription(actorId: ActorId, inventoryItem: InventoryItem): InventoryItemDescription

    fun getAutoAttackResult(attacker: ActorState, defender: ActorState): List<AutoAttackResult>

    fun getAutoAttackInterval(attacker: ActorState): Float

    fun getAugmentRankPointGain(attacker: ActorState, defender: ActorState, inventoryItem: InventoryItem): Int

    fun getExperiencePointGain(attacker: ActorState, defender: ActorState): Int

    fun getActorEffectTickResult(actorState: ActorState): ActorEffectTickResult

    fun getItemReinforcementValues(targetItem: InventoryItem): Map<Int, Int>

    fun getSkillRangeInfo(skillType: SkillType, skillId: Int): SkillRangeInfo

    fun getSpellCastTime(caster: ActorState, spellId: Int): Float

}