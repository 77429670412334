package xim.poc.game.event

import xim.poc.ActorId
import xim.poc.game.ActorStateManager
import xim.poc.game.AttackContext
import xim.poc.ui.ChatLog
import xim.poc.ui.ShiftJis

class ActorHealedEvent(
    val sourceId: ActorId,
    val targetId: ActorId,
    val amount: Int,
    val actionContext: AttackContext? = null,
): Event {

    override fun apply(): List<Event> {
        val sourceState = ActorStateManager[sourceId] ?: return emptyList()
        val targetState = ActorStateManager[targetId] ?: return emptyList()
        if (targetState.isDead()) { return emptyList() }

        targetState.gainHp(amount)

        val outEvents = ArrayList<Event>()

        actionContext?.composeCallback { ChatLog.addLine("${sourceState.name}${ShiftJis.rightArrow}${targetState.name} $amount healed.") }

        return outEvents
    }

}