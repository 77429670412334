package xim.poc.game

import kotlinx.serialization.Serializable
import xim.poc.ActorId
import xim.poc.MainTool
import xim.poc.browser.Keyboard
import xim.poc.game.configuration.NpcInteraction
import xim.poc.game.configuration.ZoneLogic

@Serializable
enum class GameMode {
    AssetViewer,
    GameV0,
}

object GameState {

    var gameSpeed = 1.0f
        private set

    private var gameMode: GameMode = GameMode.AssetViewer

    private var zoneLogic: ZoneLogic? = null

    private val npcInteractions = HashMap<ActorId, NpcInteraction>()

    fun update(elapsedFrames: Float) {
        zoneLogic?.update(elapsedFrames)
        npcInteractions.entries.removeAll { ActorStateManager[it.key] == null }
        updateGameSpeed()
    }

    fun setZoneLogic(newLogic: ZoneLogic?) {
        zoneLogic?.cleanUp()
        zoneLogic = newLogic
    }

    fun getInteraction(actorId: ActorId): NpcInteraction? {
        return npcInteractions[actorId]
    }

    fun registerInteraction(actorId: ActorId, npcInteraction: NpcInteraction) {
        registerInteractions(mapOf(actorId to npcInteraction))
    }

    fun registerInteractions(interactions: Map<ActorId, NpcInteraction>) {
        npcInteractions += interactions
    }

    fun setGameMode(gameMode: GameMode) {
        this.gameMode = gameMode
    }

    fun getGameMode(): GameMode {
        return gameMode
    }

    fun isDebugMode(): Boolean {
        return gameMode == GameMode.AssetViewer
    }

    private fun updateGameSpeed() {
        if (MainTool.platformDependencies.keyboard.isKeyPressed(Keyboard.Key.P)) {
            gameSpeed = if (gameSpeed < 1f) { 1f } else { 0f }
        } else if (MainTool.platformDependencies.keyboard.isKeyPressed(Keyboard.Key.Z)) {
            gameSpeed = if (gameSpeed < 1f) { 1f } else { 0.25f }
        }
    }

}