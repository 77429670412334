package xim.poc.game.configuration.v0

import xim.poc.game.StatusEffect
import xim.poc.game.StatusEffectState
import xim.poc.game.configuration.SkillApplier
import xim.poc.game.configuration.SkillApplierHelper.TargetEvaluator
import xim.poc.game.configuration.SkillAppliers
import xim.poc.game.configuration.SkillType
import xim.poc.game.event.ActorDamagedEvent
import xim.poc.game.event.ActorHealedEvent
import xim.poc.game.event.AutoAttackType
import xim.poc.ui.ChatLog
import xim.resource.table.StatusEffectNameTable
import xim.util.Fps
import kotlin.math.roundToInt
import kotlin.time.Duration.Companion.seconds

object V0SpellDefinitions {

    fun register() {
        // Cure
        SkillAppliers += SkillApplier(skillId = 1, skillType = SkillType.Spell, targetEvaluator = basicHealingMagic(potency = 0.6f, maxAmount = 30))

        // Dia
        SkillAppliers += SkillApplier(skillId = 23, skillType = SkillType.Spell, targetEvaluator = applyDia(StatusEffect.Dia) {
            it.remainingDuration = Fps.secondsToFrames(30)
            it.linkedAbilityId = 23
        })

        // Refueling
        SkillAppliers += SkillApplier(skillId = 530, skillType = SkillType.Spell, targetEvaluator = applyBasicStatusEffect(StatusEffect.Haste) {
            it.remainingDuration = Fps.secondsToFrames(300)
            it.linkedAbilityId = 530
            it.potency = 10
        })

        // Pollen
        SkillAppliers += SkillApplier(skillId = 549, skillType = SkillType.Spell, targetEvaluator = basicHealingMagic(potency = 0.4f, maxAmount = 36))

        // Head Butt
        SkillAppliers += SkillApplier(skillId = 623, skillType = SkillType.Spell, targetEvaluator = applyHeadButt())
    }

    private fun basicHealingMagic(potency: Float, maxAmount: Int): TargetEvaluator {
        return TargetEvaluator {
            val healAmount = DamageCalculator.getHealAmount(it.sourceState, it.targetState, potency, maxAmount)
            val outputEvents = ActorHealedEvent(sourceId = it.sourceState.id, targetId = it.targetState.id, amount = healAmount, actionContext = it.context)
            listOf(outputEvents)
        }
    }

    private fun applyDia(statusEffect: StatusEffect, statusStateSetter: (StatusEffectState) -> Unit): TargetEvaluator {
        return TargetEvaluator {
            val state = it.targetState.gainStatusEffect(statusEffect)
            statusStateSetter.invoke(state)
            val outputEvents = ActorDamagedEvent(sourceId = it.sourceState.id, targetId = it.targetState.id, amount = 1, actionContext = it.context)
            listOf(outputEvents)
        }
    }

    private fun applyBasicStatusEffect(statusEffect: StatusEffect, statusStateSetter: (StatusEffectState) -> Unit): TargetEvaluator {
        return TargetEvaluator {
            val state = it.targetState.gainStatusEffect(statusEffect)
            statusStateSetter.invoke(state)
            ChatLog.statusEffectGained(it.targetState.name, statusEffect)
            emptyList()
        }
    }

    private fun applyHeadButt(): TargetEvaluator {
        return TargetEvaluator {
            val state = it.targetState.gainStatusEffect(StatusEffect.Stun, duration = 3.seconds, sourceId = it.sourceState.id)
            state.preventsAction = true
            state.preventsMovement = true

            val damage = DamageCalculator.getAutoAttackDamage(it.sourceState, it.targetState, AutoAttackType.Main).roundToInt()
            val outputEvents = ActorDamagedEvent(sourceId = it.sourceState.id, targetId = it.targetState.id, amount = damage, actionContext = it.context)
            listOf(outputEvents)
        }
    }

}