package xim.poc.ui

import xim.math.Vector2f
import xim.math.Vector4f
import xim.poc.TextDirection
import xim.poc.UiElementHelper
import xim.poc.UiResourceManager
import xim.poc.game.UiState
import xim.poc.gl.Color
import xim.resource.DatId
import xim.resource.UiMenu
import xim.resource.UiMenuElement
import xim.resource.UiMenuResource

enum class ChatLogColor(val color: Color) {
    Normal(Color(255, 255, 255, 255)),
    Info(Color(255, 255, 200, 255)),
    Action(Color(255, 255, 128, 255)),
    SystemMessage(Color(215, 115, 255, 255)),
    Error(Color(255, 140, 140, 255)),
}

private class ChatLogLine(val text: String, val chatColor: ChatLogColor)

object ChatLog {

    private const val expansionFactor = 4
    private const val numLines = 8
    private const val lineHeight = 16f

    private const val width = 512f

    private const val innerHeight = numLines * lineHeight
    private const val height = innerHeight + 10f
    private const val expandedHeight = expansionFactor * innerHeight + 10f

    const val fakeUiMenuName = "fake chatlog menu"

    private var registered = false
    private lateinit var chatLogFrame: UiMenuElement

    private val lines = ArrayDeque<ChatLogLine>()
    private var expanded = false

    operator fun invoke(line: String?, chatLogColor: ChatLogColor = ChatLogColor.Normal) {
        addLine(line, chatLogColor)
    }

    fun addLine(line: String?, chatLogColor: ChatLogColor = ChatLogColor.Normal) {
        line ?: return
        lines.addFirst(ChatLogLine(line, chatLogColor))
        while (lines.size > 80) { lines.removeLast() }
    }

    fun toggleExpand(state: Boolean) {
        expanded = state
        chatLogFrame.size.y = getHeight()
    }

    fun draw(uiState: UiState) {
        val logWindowPos = uiState.latestPosition ?: return

        val currentHeight = getHeight()
        val currentNumLines = getNumLines()

        val borderSize = Vector2f(8f, 8f)
        val lineOffset = Vector2f(0f, currentHeight - borderSize.y - lineHeight)

        val maxWidth = width - borderSize.x + logWindowPos.x
        val clipSize = Vector4f(logWindowPos.x, logWindowPos.y + borderSize.y, maxWidth, currentHeight - borderSize.y)

        var totalLines = 0
        for (line in lines) {
            val offset = logWindowPos + borderSize + lineOffset

            val linesDrawn = UiElementHelper.drawMultilineText(line.text, offset, maxWidth = maxWidth.toInt(), textDirection = TextDirection.BottomToTop, color = line.chatColor.color, clipSize = clipSize)
                ?: throw IllegalStateException("Couldn't draw line")

            lineOffset.y -= (lineHeight * linesDrawn)

            totalLines += linesDrawn
            if (totalLines >= currentNumLines) { break }
        }
    }

    fun registerFakeMenu() {
        if (registered) { return }
        registered = true

        val originalMenu = UiResourceManager.getMenu("menu    log8    ") ?: throw IllegalStateException("Couldn't find 'menu    log8    '")

        val originalElement = originalMenu.uiMenu.elements[0]
        val fakeElement = UiMenuElement(
            offset = Vector2f().copyFrom(originalElement.offset),
            size = Vector2f().copyFrom(originalElement.size),
            options = emptyList(),
            next = originalElement.next,
        )

        val fakeMenu = UiMenu(fakeUiMenuName, originalMenu.uiMenu.frame.deepCopy(), listOf(fakeElement))

        chatLogFrame = fakeMenu.frame
        chatLogFrame.size.x = width
        chatLogFrame.size.y = getHeight()

        UiResourceManager.register(UiMenuResource(DatId.zero, fakeMenu))
    }

    private fun getExpansionFactor(): Int {
        return if (expanded) { expansionFactor } else { 1 }
    }

    private fun getHeight(): Float {
        return if (expanded) { expandedHeight } else { height }
    }

    private fun getNumLines(): Int {
        return numLines * getExpansionFactor()
    }

}