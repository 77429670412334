package xim.poc.game.configuration

import xim.poc.game.ActorState
import xim.poc.game.ActorStateManager
import xim.poc.game.event.Event

object SkillApplierRunner {

    fun applySkill(skillInfo: SkillInfo, applier: SkillApplier, source: ActorState, targetEvaluation: SkillTargetEvaluation): ApplierResult {
        val failure = applier.validEvaluator.isUsable(SkillApplierHelper.SkillUsableEvaluatorContext(skillInfo, source))
        if (failure != null) { return ApplierResult.failure(failure) }

        val primaryTargetState = ActorStateManager[targetEvaluation.primaryTarget] ?: return evaluationToNoop(targetEvaluation)
        val primaryContext = targetEvaluation.primaryTargetContext()

        val events = ArrayList<Event>()

        if (applier.additionalSelfEvaluator != null) {
            events += applier.additionalSelfEvaluator.getEvents(SkillApplierHelper.TargetEvaluatorContext(skillInfo, source, source, primaryTargetState, primaryContext))
        }

        if (applier.primaryTargetEvaluator != null) {
            events += applier.primaryTargetEvaluator.getEvents(SkillApplierHelper.TargetEvaluatorContext(skillInfo, source, primaryTargetState, primaryTargetState, primaryContext))
        }

        for (targetId in targetEvaluation.allTargetIds) {
            if (applier.primaryTargetEvaluator != null && targetId == targetEvaluation.primaryTarget) { continue }
            val target = ActorStateManager[targetId] ?: continue
            val context = targetEvaluation.contexts[targetId]
            events += applier.targetEvaluator.getEvents(SkillApplierHelper.TargetEvaluatorContext(skillInfo, source, target, primaryTargetState, context))
        }

        if (applier.postEvaluation != null) {
            events += applier.postEvaluation.getEvents(SkillApplierHelper.TargetEvaluatorContext(skillInfo, source, primaryTargetState, primaryTargetState, primaryContext))
        }

        return ApplierResult(events = events, allTargetIds = targetEvaluation.allTargetIds, contexts = targetEvaluation.contexts)
    }

    private fun evaluationToNoop(targetEvaluation: SkillTargetEvaluation): ApplierResult {
        return ApplierResult(emptyList(), allTargetIds = targetEvaluation.allTargetIds, contexts = targetEvaluation.contexts)
    }

}