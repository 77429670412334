package xim.poc.game

enum class StatusEffect(val id: Int) {
    BlazeSpikes(34),
    IceSpikes(35),

    Enfire(94),
    Enblizzard(95),

    Costume(127),

    Visitant(285),

    DoubleUpChance(308),
    Bust(309),
    FightersRoll(310),

    Indicolure(612),
}

enum class AbilityId(val id: Int) {
    Provoke(547),
    BloodPactRage(603),
    PhantomRoll(609),
    FightersRoll(610),
    DoubleUp(635),
    QuickDraw(636),
    BloodPactWard(684),
    Samba(694),
    Waltz(695),
    Step(710),
    FlourishI(711),
    Jig(712),
    FlourishII(725),
    FlourishIII(775),
}

enum class ItemIds(val id: Int) {
    IcarusWing(4213),
    CopseCandy(6008),
}