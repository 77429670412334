package xim.poc.game

import kotlinx.serialization.Serializable
import xim.poc.ActionTargetFilter
import xim.poc.ActorId
import xim.poc.Area
import xim.poc.CollisionProperty
import xim.poc.game.SkillChainAttribute.*
import xim.poc.game.configuration.GatheringConfiguration
import xim.poc.game.configuration.GatheringNodeItem
import xim.poc.game.configuration.RecipeId
import xim.resource.*
import xim.resource.table.AbilityInfoTable
import xim.resource.table.MobSkillInfo
import xim.resource.table.StatusEffectInfo
import xim.util.Fps
import kotlin.time.Duration
import kotlin.time.Duration.Companion.seconds

class EngagedState {

    enum class State {
        Disengaged,
        Engaged,
    }

    var state = State.Disengaged

}

class TargetState(val targetId: ActorId?, val locked: Boolean)

class DeathState {
    var framesSinceDeath = 0f

    fun hasBeenDeadFor(duration: Duration): Boolean {
        return timeSinceDeath() >= duration
    }

    fun timeSinceDeath(): Duration {
        return Fps.framesToSeconds(framesSinceDeath)
    }
}

@Serializable
class Equipment {

    private val items: HashMap<EquipSlot, InternalItemId> = HashMap()

    fun copyFrom(equipment: Equipment): Equipment {
        items.clear()
        items.putAll(equipment.items)
        return this
    }

    fun getAllItems(): Map<EquipSlot, InternalItemId> {
        return items
    }

    fun setItem(equipSlot: EquipSlot, item: InventoryItem?) {
        if (item == null) {
            items.remove(equipSlot)
            return
        }

        val alreadyEquippedSlot = items.entries.firstOrNull { it.value == item.internalId }
        val replacedItem = items[equipSlot]
        if (alreadyEquippedSlot != null && replacedItem != null) { items[alreadyEquippedSlot.key] = replacedItem }

        items[equipSlot] = item.internalId
    }

    fun getItem(inventory: Inventory, equipSlot: EquipSlot): InventoryItem? {
        val internalId = items[equipSlot] ?: return null
        return inventory.getByInternalId(internalId)
    }

    fun validateSub(inventory: Inventory): Boolean {
        val sub = getItem(inventory, EquipSlot.Sub) ?: return true
        val subInfo = sub.info()

        val main = getItem(inventory, EquipSlot.Main)
        val mainInfo = main?.info()

        if (sub.internalId == main?.internalId) { return false}

        if (mainInfo != null && mainInfo.isH2H()) { return false }

        if (subInfo.isShield()) { return mainInfo == null || !mainInfo.isTwoHanded() }
        if (subInfo.isGrip()) { return mainInfo != null && mainInfo.isTwoHanded() }

        if (mainInfo == null || mainInfo.isTwoHanded()) { return false }

        return true
    }

    fun validateAmmo(inventory: Inventory): Boolean {
        val ammo = getItem(inventory, EquipSlot.Ammo)?.info() ?: return true
        val ranged = getItem(inventory, EquipSlot.Range)?.info() ?: return true

        return if (ranged.skill() == Skill.Marksmanship || ranged.skill() == Skill.Ranged || ranged.skill() == Skill.Fishing) {
            ranged.skill() == ammo.skill()
        } else {
            false
        }
    }

    fun isEquipped(item: InventoryItem): Boolean {
        return items.values.any { it == item.internalId }
    }

}

class SynthesisState(val recipeId: RecipeId, val quantity: Int) {

    enum class SynthesisResult {
        Break,
        NormalQuality,
        HighQuality,
    }

    companion object {

        private val noInterruptDuration = Fps.secondsToFrames(3)
        private val craftingDuration = Fps.secondsToFrames(10)

        fun getRandomSuccessResult(): SynthesisResult {
            return listOf(SynthesisResult.NormalQuality, SynthesisResult.HighQuality).random()
        }
    }

    private var craftingTime = 0f

    var result: SynthesisResult? = null
        private set

    fun update(elapsedFrames: Float) {
        craftingTime += elapsedFrames
    }

    fun isReadyForResult(): Boolean {
        return craftingTime >= craftingDuration
    }

    fun canInterrupt(): Boolean {
        return this.result == null && craftingTime >= noInterruptDuration
    }

    fun setResult(result: SynthesisResult) {
        if (this.result != null) { return }
        this.result = result
        craftingTime = craftingDuration.coerceAtLeast(craftingDuration)
    }

    fun isComplete(): Boolean {
        val totalDuration = getTotalDuration() ?: return false
        return craftingTime >= totalDuration
    }

    private fun getTotalDuration(): Float? {
        val result = result ?: return null

        return when (result) {
            SynthesisResult.Break -> Fps.secondsToFrames(12)
            SynthesisResult.NormalQuality -> Fps.secondsToFrames(15)
            SynthesisResult.HighQuality -> Fps.secondsToFrames(15)
        }
    }

}

class RecastState(private var timeRemainingInFrames: Float) {

    fun update(elapsedFrames: Float) {
        timeRemainingInFrames -= elapsedFrames
    }

    fun getRemaining(): Duration {
        return Fps.framesToSecondsRoundedUp(timeRemainingInFrames)
    }

    fun isComplete(): Boolean {
        return timeRemainingInFrames <= 0f
    }

}

class RecastStates {

    private val spellRecastState = HashMap<Int, RecastState>()
    private val abilityRecastState = HashMap<Int, RecastState>()

    fun update(elapsedFrames: Float) {
        spellRecastState.forEach { it.value.update(elapsedFrames) }
        spellRecastState.entries.removeAll { it.value.isComplete() }

        abilityRecastState.forEach { it.value.update(elapsedFrames) }
        abilityRecastState.entries.removeAll { it.value.isComplete() }
    }

    fun getSpellRecastState(spellInfo: SpellInfo): RecastState? {
        return spellRecastState[spellInfo.index]
    }

    fun addSpellRecastState(spellInfo: SpellInfo, frameDuration: Float) {
        spellRecastState[spellInfo.index] = RecastState(frameDuration)
    }

    fun getAbilityRecastState(abilityInfo: AbilityInfo): RecastState? {
        return spellRecastState[abilityInfo.recastId]
    }

    fun addAbilityRecastState(abilityInfo: AbilityInfo, frameDuration: Float) {
        spellRecastState[abilityInfo.recastId] = RecastState(frameDuration)
    }


}

class StatusEffectState(val info: StatusEffectInfo) {

    var remainingDuration: Float? = null
    var counter = 0
    var linkedStatusId = 0
    var linkedAbilityId = 0

    fun isExpired(): Boolean {
        return remainingDuration?.let { it <= 0f } ?: false
    }

}

class CastingState private constructor(
    val castTime: Float,
    val sourceId: ActorId,
    val targetId: ActorId,
    val spellInfo: SpellInfo? = null,
    val itemInfo: InventoryItemInfo? = null,
    val abilityInfo: AbilityInfo? = null,
    val mobSkillInfo: MobSkillInfo? = null,
    val rangedAttack: Boolean = false,
) {

    companion object {
        fun spell(castTime: Float, sourceId: ActorId, targetId: ActorId, spellInfo: SpellInfo): CastingState {
            return CastingState(castTime, sourceId, targetId, spellInfo = spellInfo)
        }

        fun item(castTime: Float, sourceId: ActorId, targetId: ActorId, item: InventoryItem): CastingState {
            return CastingState(castTime, sourceId, targetId, itemInfo = item.info())
        }

        fun ability(castTime: Float, sourceId: ActorId, targetId: ActorId, abilityInfo: AbilityInfo): CastingState {
            return CastingState(castTime, sourceId, targetId, abilityInfo = abilityInfo)
        }

        fun mobSkill(castTime: Float, sourceId: ActorId, targetId: ActorId, mobSkill: MobSkillInfo?): CastingState {
            return CastingState(castTime, sourceId, targetId, mobSkillInfo = mobSkill)
        }

        fun rangedAttack(castTime: Float, sourceId: ActorId, targetId: ActorId): CastingState {
            return CastingState(castTime, sourceId, targetId, rangedAttack = true)
        }
    }

    private val lockTime = castTime + Fps.secondsToFrames(1f)
    private var currentTime = 0f
    private var executed = false

    var interrupted = false

    private val targetFilter: ActionTargetFilter =
        ActionTargetFilter(spellInfo?.targetFlags ?: itemInfo?.targetFlags ?: abilityInfo?.targetFlags ?: mobSkillInfo?.targetFlag ?: TargetFlag.Enemy.flag)

    fun update(elapsedFrames: Float) {
        if (isComplete()) { return }
        currentTime += elapsedFrames
        if (!isTargetValid()) { interrupted = true }
    }

    fun percentProgress(): Int {
        return (currentTime/castTime * 100f).toInt()
    }

    fun isDoneCharging(): Boolean {
        return !executed && (interrupted || currentTime >= castTime)
    }

    fun onExecute() {
        executed = true
        currentTime = currentTime.coerceAtLeast(castTime)
    }

    fun isComplete(): Boolean {
        return executed && currentTime >= lockTime
    }

    fun isTargetValid(): Boolean {
        return targetFilter.targetFilter(sourceId, targetId)
    }

}

class ActorCollision(val collisionsByArea: Map<Area, List<CollisionProperty>> = emptyMap(), val freeFallDuration: Float = 0f) {

    fun isInFreeFall(): Boolean {
        return freeFallDuration > 0f
    }

    fun getEnvironment(): Pair<Area?, DatId?> {
        for (perAreaCollisionProperties in collisionsByArea) {
            val first = perAreaCollisionProperties.value.firstOrNull { it.environmentId != null } ?: continue
            return Pair(perAreaCollisionProperties.key, first.environmentId)
        }

        return Pair(null, null)
    }

}

class DoorState {
    var open = false
    var framesSinceOpen = 0f

    fun timeSinceOpened(): Duration {
        if (!open) { return Duration.ZERO }
        return Fps.framesToSeconds(framesSinceOpen)
    }

}

@Serializable
data class JobLevel(var exp: Int, var level: Int) {

    fun gainExp(amount: Int) {
        exp += amount
        val next = getExpNeeded()

        if (exp >= next) {
            exp -= next
            level += 1

            val nextNext = getExpNeeded()
            exp = exp.coerceAtMost(nextNext - 1)
        }
    }

    fun loseExp(amount: Int) {
        exp -= amount

        if (exp < 0) {
            level -= 1
            exp += getExpNeeded()
            exp = exp.coerceAtLeast(0)
        }
    }

    fun getExpNeeded(): Int {
        return 10 * AbilityInfoTable.getLevelTable().table.entries[level]
    }

}

@Serializable
class JobLevels {

    private val levels = Job.values()
        .associateWith { JobLevel(exp = 0, level = 1) }
        .toMutableMap()

    operator fun get(job: Job?): JobLevel? {
        return levels[job]
    }

    fun copyFrom(other: JobLevels) {
        levels.clear()
        levels.putAll(other.levels)
    }

}

@Serializable
data class JobState(var mainJob: Job = Job.War, var subJob: Job? = null) {
    fun copyFrom(other: JobState) {
        this.mainJob = other.mainJob
        this.subJob = other.subJob
    }
}


class RestingState {

    private val tickSize = Fps.secondsToFrames(1)

    enum class TransitionState {
        None,
        StartResting,
        Resting,
        StopResting,
    }

    private var state = TransitionState.None
    private var transitionTime = 0f
    private var timeSpentResting = 0f

    fun updateAndGetTicks(elapsedFrames: Float): Int {
        when (state) {
            TransitionState.None -> {}
            TransitionState.StartResting -> {
                transitionTime -= elapsedFrames
                if (transitionTime < 0f) { state = TransitionState.Resting }
            }
            TransitionState.Resting -> {
                timeSpentResting += elapsedFrames
            }
            TransitionState.StopResting -> {
                transitionTime -= elapsedFrames
                if (transitionTime < 0f) { state = TransitionState.None }
            }
        }

        if (state != TransitionState.Resting) { return 0 }

        var numTicks = 0

        while (timeSpentResting > tickSize) {
            timeSpentResting -= tickSize
            numTicks += 1
        }

        return numTicks
    }

    fun startResting() {
        if (state != TransitionState.None) { return }
        state = TransitionState.StartResting
        transitionTime = Fps.secondsToFrames(1f)
    }

    fun stopResting() {
        if (state != TransitionState.Resting) { return}
        state = TransitionState.StopResting
        transitionTime = Fps.secondsToFrames(1f)
    }

    fun isResting(): Boolean {
        return state == TransitionState.StartResting || state == TransitionState.Resting
    }

    fun isMovementLocked(): Boolean {
        return state != TransitionState.None
    }

}

class GatheringState {

    private val gatheringTime = Fps.secondsToFrames(3.25f)

    private var gathering = false
    private var remainingFrames = 0f

    fun startGathering() {
        gathering = true
        remainingFrames = gatheringTime
    }

    fun update(elapsedFrames: Float) {
        if (!gathering) { return }
        remainingFrames -= elapsedFrames
        if (remainingFrames < 0f) { gathering = false }
    }

    fun isGathering(): Boolean {
        return gathering
    }

}

class GatheringNodeState(val gatheringConfiguration: GatheringConfiguration) {

    fun applyGatherAttempt(): GatheringNodeItem {
        return gatheringConfiguration.getRandomItem()
    }

}

enum class SkillChainAttribute(val level: Int, val elements: List<SpellElement>) {
    Transfixion(level = 1, elements = listOf(SpellElement.Light)),
    Compression(level = 1, elements = listOf(SpellElement.Dark)),
    Liquefaction(level = 1, elements = listOf(SpellElement.Fire)),
    Scission(level = 1, elements = listOf(SpellElement.Earth)),
    Reverberation(level = 1, elements = listOf(SpellElement.Water)),
    Detonation(level = 1, elements = listOf(SpellElement.Wind)),
    Induration(level = 1, elements = listOf(SpellElement.Ice)),
    Impaction(level = 1, elements = listOf(SpellElement.Lightning)),
    Gravitation(level = 2, elements = listOf(SpellElement.Dark, SpellElement.Earth)),
    Distortion(level = 2, elements = listOf(SpellElement.Water, SpellElement.Ice)),
    Fusion(level = 2, elements = listOf(SpellElement.Fire, SpellElement.Light)),
    Fragmentation(level = 2, elements = listOf(SpellElement.Lightning, SpellElement.Wind)),
    Light(level = 3, elements = listOf(SpellElement.Fire, SpellElement.Wind, SpellElement.Lightning, SpellElement.Light)),
    Darkness(level = 3, elements = listOf(SpellElement.Water, SpellElement.Ice, SpellElement.Earth, SpellElement.Dark)),
    Radiance(level = 4, elements = listOf(SpellElement.Fire, SpellElement.Wind, SpellElement.Lightning, SpellElement.Light)),
    Umbra(level = 4, elements = listOf(SpellElement.Water, SpellElement.Ice, SpellElement.Earth, SpellElement.Dark)),
}

class SkillChain(val numSteps: Int, val attribute: SkillChainAttribute)

class SkillChainTargetState {

    companion object {
        private val openers = mapOf(
            Transfixion to mapOf(Compression to Compression, Scission to Distortion, Reverberation to Reverberation),
            Compression to mapOf(Transfixion to Transfixion, Detonation to Detonation),
            Liquefaction to mapOf(Scission to Scission, Impaction to Fusion),
            Scission to mapOf(Liquefaction to Liquefaction, Detonation to Detonation, Reverberation to Reverberation),
            Reverberation to mapOf(Induration to Induration, Impaction to Impaction),
            Detonation to mapOf(Compression to Gravitation, Scission to Scission),
            Induration to mapOf(Compression to Compression, Reverberation to Fragmentation, Impaction to Impaction),
            Impaction to mapOf(Liquefaction to Liquefaction, Detonation to Detonation),

            Gravitation to mapOf(Distortion to Darkness, Fragmentation to Fragmentation),
            Distortion to mapOf(Gravitation to Darkness, Fusion to Fusion),
            Fusion to mapOf(Gravitation to Gravitation, Fragmentation to Light),
            Fragmentation to mapOf(Distortion to Distortion, Fusion to Light),

            Light to mapOf(Light to Radiance),
            Darkness to mapOf(Darkness to Umbra),
        )
    }

    private var timeSinceSet = 0f
    private var skillChain: SkillChain? = null

    fun update(elapsedFrames: Float) {
        timeSinceSet += elapsedFrames
    }

    fun applyState(attributes: List<SkillChainAttribute>): SkillChain? {
        if (attributes.isEmpty()) {
            skillChain = null
            return null
        }

        val timeInSeconds = Fps.framesToSeconds(timeSinceSet)
        val currentSkillChain = skillChain

        if (currentSkillChain == null || timeInSeconds < 3.seconds || timeInSeconds > 9.seconds) {
            setSkillChain(attributes.first())
            return null
        }

        val currentAttribute = currentSkillChain.attribute
        var closingAttribute: SkillChainAttribute? = null

        for (attribute in attributes) {
            closingAttribute = openers[currentAttribute]?.get(attribute)
            if (closingAttribute != null) { break }
        }

        if (closingAttribute == null) {
            setSkillChain(attributes.first())
            return null
        }

        setSkillChain(closingAttribute, currentSkillChain.numSteps + 1)
        return skillChain
    }

    fun getMagicBurstBonus(spellInfo: SpellInfo): Float? {
        val current = skillChain ?: return null

        val timeInSeconds = Fps.framesToSeconds(timeSinceSet)
        if (current.numSteps < 2 || timeInSeconds < 3.seconds || timeInSeconds > 9.seconds ) {
            return null
        }

        if (current.attribute.elements.none { it == spellInfo.element }) {
            return null
        }

        return 1.0f + 0.1f * current.numSteps + 0.15f * current.attribute.level
    }

    private fun setSkillChain(attribute: SkillChainAttribute, numSteps: Int = 1) {
        timeSinceSet = 0f
        skillChain = SkillChain(numSteps, attribute)
    }

}

class RangedAttackRecast {

    private var recast = 0f

    fun set(recast: Float) {
        this.recast = recast
    }

    fun update(elapsedFrames: Float) {
        recast -= elapsedFrames
        recast = recast.coerceAtLeast(0f)
    }

    fun canRangedAttack(): Boolean {
        return recast == 0f
    }

}