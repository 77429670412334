package xim.poc

import xim.math.Vector3f
import xim.poc.game.*
import xim.resource.TargetFlag

class ActionTargetFilter(val targetFlags: Int): TargetFilter {

    companion object {
        fun areEnemies(actorState: ActorState, targetState: ActorState): Boolean {
            if (targetState.type == ActorType.Effect || targetState.isStaticNpc()) { return false }
            return (actorState.type == ActorType.Enemy && targetState.type != ActorType.Enemy) || (actorState.type != ActorType.Enemy && targetState.type == ActorType.Enemy)
        }
    }

    override fun isValidTarget(sourceId: ActorId, targetId: ActorId): Boolean {
        return targetFilter(sourceId, targetId)
    }

    fun targetFilter(sourceId: ActorId?, targetId: ActorId?): Boolean {
        val sourceState = ActorStateManager[sourceId] ?: return false
        val targetState = ActorStateManager[targetId] ?: return false
        return targetFilter(sourceState, targetState)
    }

    fun targetFilter(sourceState: ActorState, targetState: ActorState): Boolean {
        // TODO real range check?
        if (Vector3f.distance(sourceState.position, targetState.position) > 50f) {
            return false
        }

        if (TargetFlag.Corpse.match(targetFlags) && !targetState.isDead()) {
            return false
        }

        if (!TargetFlag.Corpse.match(targetFlags) && targetState.isDead()) {
            return false
        }

        if (TargetFlag.Enemy.match(targetFlags) && areEnemies(sourceState, targetState)) {
            return true
        }

        if (TargetFlag.Self.match(targetFlags) && sourceState.id == targetState.id) {
            return true
        }

        if (TargetFlag.Player.match(targetFlags) && targetState.isPlayer()) {
            return true
        }

        val party = PartyManager[sourceState.id]
        if (TargetFlag.Party.match(targetFlags) && sourceState.id != targetState.id && party.contains(targetState.id)) {
            return true
        }

        if (TargetFlag.Ally.match(targetFlags) && sourceState.pet == targetState.id) {
            return true
        }

        if (TargetFlag.Npc.match(targetFlags) && targetState.isStaticNpc()) {
            return true
        }

        return false
    }

}