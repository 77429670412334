package xim.poc.game.event

import xim.math.Vector3f
import xim.poc.ActorId
import xim.poc.game.ActorState
import xim.poc.game.ActorStateManager
import xim.poc.game.ActorType
import xim.poc.game.configuration.MonsterDefinition
import xim.poc.game.configuration.MonsterDefinitions
import xim.poc.game.configuration.MonsterId
import xim.util.PI_f
import xim.util.RandHelper

class BossSpawnEvent(
    val sourceId: ActorId,
    val monsterId: MonsterId,
    val position: Vector3f,
    val requiredItemIds: List<Int>,
    val createDisplayCallback: CreateDisplayCallback? = null
): Event {

    override fun apply(): List<Event> {
        val sourceActor = ActorStateManager[sourceId] ?: return emptyList()
        if (!consumeRequiredItems(sourceActor)) { return emptyList() }

        val bossDefinition = MonsterDefinitions[monsterId]
        val bossActorId = ActorStateManager.nextId()

        val output = ArrayList<Event>()
        output += makeCreateEvent(bossActorId, bossDefinition)
        output += BattleEngageEvent(bossActorId, sourceId)
        output += ActorTargetEvent(sourceId, bossActorId)
        return output
    }

    private fun consumeRequiredItems(sourceActor: ActorState): Boolean {
        var success = true
        for (requiredItem in requiredItemIds) {
            success = sourceActor.discardNotEquippedItems(requiredItem, 1) && success
        }
        return success
    }

    private fun makeCreateEvent(bossActorId: ActorId, bossDefinition: MonsterDefinition): Event {
        return ActorCreateEvent(InitialActorState(
            presetId = bossActorId,
            monsterId = bossDefinition.id,
            name = bossDefinition.name,
            type = ActorType.Enemy,
            position = position,
            modelLook = bossDefinition.look,
            rotation = 2 * PI_f * RandHelper.rand(),
            movementController = bossDefinition.movementController,
            behaviorController = bossDefinition.behaviorId,
        ), createDisplayCallback)
    }

}