package xim.poc.game.configuration

import xim.math.Vector3f
import xim.poc.ActionTargetFilter
import xim.poc.ActorManager
import xim.poc.game.ActorState
import xim.poc.game.ActorStateManager
import xim.poc.game.GameEngine
import xim.poc.game.event.AutoAttackEvent
import xim.poc.game.event.CastMobSkillStart
import xim.poc.game.event.Event
import xim.resource.TargetFlag
import xim.resource.table.MobSkillInfoTable
import xim.util.Fps
import xim.util.PI_f

typealias BehaviorId = Int

class AutoAttackState(val actorState: ActorState) {

    companion object {
        private val filter = ActionTargetFilter(TargetFlag.Enemy.flag)
    }

    private val attackRange = Pair(0.5f, 4f)
    private var timeUntilAttack = 0f

    init { resetAutoAttack() }

    fun update(elapsedFrames: Float) {
        timeUntilAttack -= elapsedFrames

        val actor = ActorManager[actorState.id]
        if (actor != null && actor.isMovementOrAnimationLocked()) {
            timeUntilAttack = timeUntilAttack.coerceAtLeast(Fps.millisToFrames(100f))
        }

        if (!canAutoAttack()) {
            timeUntilAttack = timeUntilAttack.coerceAtLeast(Fps.secondsToFrames(2f))
        }
    }

    fun isReadyToAutoAttack(): Boolean {
        return timeUntilAttack <= 0f && canAutoAttack()
    }

    fun resetAutoAttack() {
        timeUntilAttack = GameEngine.getAutoAttackRecast(actorState)
    }

    private fun canAutoAttack(): Boolean {
        val targetState = ActorStateManager[actorState.targetState.targetId] ?: return false
        if (!filter.targetFilter(actorState, targetState)) { return false }
        if (!GameEngine.canBeginAction(actorState)) { return false }
        return actorState.isEngaged() && isInAttackRange(targetState) && !actorState.isDead() && !actorState.isCasting()
    }

    private fun isInAttackRange(targetState: ActorState): Boolean {

        if (!actorState.isFacingTowards(targetState, halfAngle = PI_f/3f)) { return false }

        val distance = Vector3f.distance(actorState.position, targetState.position)

        return if (actorState.isPlayer()) {
            distance >= attackRange.first && distance <= attackRange.second
        } else {
            distance <= attackRange.second
        }
    }

}


interface ActorBehaviorController {
    fun update(elapsedFrames: Float): List<Event>
}

class NoOpBehaviorController: ActorBehaviorController {
    override fun update(elapsedFrames: Float): List<Event> {
        return emptyList()
    }
}

class AutoAttackController(val actorState: ActorState): ActorBehaviorController {

    private val autoAttackState = AutoAttackState(actorState)

    override fun update(elapsedFrames: Float): List<Event> {
        autoAttackState.update(elapsedFrames)
        if (!autoAttackState.isReadyToAutoAttack()) { return emptyList() }

        autoAttackState.resetAutoAttack()
        return listOf(AutoAttackEvent(actorState.id))
    }

}

class BasicMonsterController(val actorState: ActorState): ActorBehaviorController {

    private val autoAttackDelegate = AutoAttackController(actorState)
    private var usedSkill = false

    override fun update(elapsedFrames: Float): List<Event> {
        if (eligibleToUseSkill()) {
            usedSkill = true
            return useSkill()
        }

        return autoAttackDelegate.update(elapsedFrames)
    }

    private fun eligibleToUseSkill(): Boolean {
        return !usedSkill && actorState.getHpp() <= 0.5f
    }

    private fun useSkill(): List<Event> {
        val defn = MonsterDefinitions[actorState.monsterId] ?: return emptyList()
        val randomSkillId = defn.mobSkills.randomOrNull() ?: return emptyList()
        val mobSkillInfo = MobSkillInfoTable[randomSkillId] ?: return emptyList()

        val targetFilter = mobSkillInfo.targetFilter
        val targetId = (if (targetFilter.targetFilter(actorState.id, actorState.id)) {
            actorState.id
        } else if (targetFilter.targetFilter(actorState.id, actorState.targetState.targetId)) {
            actorState.targetState.targetId
        } else {
            null
        }) ?: return emptyList()

        return listOf(CastMobSkillStart(actorState.id, targetId, randomSkillId))
    }

}

object ActorBehaviors {

    const val NoAction: BehaviorId = 0
    const val AutoAttackOnly: BehaviorId = 1
    const val BasicMonsterController: BehaviorId = 2

    private val behaviors = HashMap<BehaviorId, (ActorState) -> ActorBehaviorController>()

    init {
        register(NoAction) { NoOpBehaviorController() }
        register(AutoAttackOnly) { AutoAttackController(it) }
        register(BasicMonsterController) { BasicMonsterController(it) }

        register(898) { HealerBehavior(it) }
        register(902) { CurillaBehavior(it) }
        register(911) { RangedAttackerBehavior(it) }
        register(940) { RangedAttackerBehavior(it) }
        register(1014) { RangedAttackerBehavior(it) }
    }

    fun register(behaviorId: BehaviorId, factory: (ActorState) -> ActorBehaviorController): BehaviorId {
        check(behaviors[behaviorId] == null) { "BehaviorId $behaviorId was already defined" }
        behaviors[behaviorId] = factory
        return behaviorId
    }

    fun createController(behaviorId: BehaviorId, actorState: ActorState): ActorBehaviorController {
        val factory = behaviors[behaviorId] ?: return NoOpBehaviorController()
        return factory.invoke(actorState)
    }

}