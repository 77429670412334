package xim.poc.game

import xim.math.Vector2f
import xim.math.Vector3f
import xim.poc.*
import xim.poc.audio.AudioManager
import xim.poc.audio.SystemSound
import xim.poc.browser.Keyboard
import xim.poc.game.configuration.EventScriptRunner
import xim.poc.game.configuration.SkillRangeInfo
import xim.poc.game.configuration.SynthesisRecipes
import xim.poc.gl.Color
import xim.poc.tools.MacroTool
import xim.poc.tools.ZoneChanger
import xim.poc.ui.*
import xim.resource.*
import xim.util.Fps.millisToFrames
import kotlin.math.max
import kotlin.math.min
import kotlin.time.Duration.Companion.seconds

enum class ParentRelative {
    RightOfParent,
    TopOfParent,
}

private enum class ChangeJobType { Main, Sub }

class HandlerContext(val local: UiState, val cursorPos: Int)

data class UiState(
    val additionalDraw: ((UiState) -> Unit)? = null,
    val focusMenu: String? = null,
    val dynamicFocusMenu: (() -> String?)? = null,
    val menuStacks: MenuStacks? = null,
    val appendType: AppendType = AppendType.Append,
    val drawParent: Boolean = false,
    val drawBorder: Boolean = true,
    val childStates: (() -> List<UiState>)? = null,
    val parentRelative: ParentRelative? = null,
    val subTargetSelect: Boolean = false,
    val onPushed: (() -> Boolean)? = null,
    val onPopped: (() -> Unit)? = null,
    val scrollSettings: ScrollSettings? = null,
    val resetCursorIndexOnPush: Boolean = true,
    val defaultCursorIndex: Int? = null,
    val locksMovement: Boolean = false,
    val hideGauge: Boolean = false,
    val handler: (handlerInput: HandlerContext) -> Boolean,
) {
    var cursorIndex: Int = 0
    var latestPosition: Vector2f? = null
    var latestMenu: UiMenu? = null
    var latestParent: UiState? = null

    fun resetCursorIndex() {
        cursorIndex = 0
        scrollSettings?.lowestViewableItemIndex = 0
    }

    fun applyCursorBounds() {
        val scrollSettings = this.scrollSettings ?: return

        val totalItems = scrollSettings.numElementsProvider()

        val maxItemIndex = (totalItems - 1).coerceAtLeast(0)
        val maxCursorIndex = scrollSettings.numElementsInPage - 1

        if (scrollSettings.lowestViewableItemIndex + maxCursorIndex > maxItemIndex) {
            val sub = scrollSettings.lowestViewableItemIndex + maxCursorIndex - maxItemIndex
            scrollSettings.lowestViewableItemIndex -= sub
            scrollSettings.lowestViewableItemIndex = scrollSettings.lowestViewableItemIndex.coerceAtLeast(0)
        }

        cursorIndex = cursorIndex.coerceAtMost(min(maxItemIndex, maxCursorIndex))
    }

    fun getFocusMenu(): String? {
        return focusMenu ?: dynamicFocusMenu?.invoke()
    }

}

data class ScrollSettings(
    val numElementsInPage: Int,
    val numElementsProvider: () -> Int,
) {
    var lowestViewableItemIndex = 0
}

data class UiStateFrame(
    val state: UiState,
    val parent: UiStateFrame?,
)

class PendingAction(
    val targetFilter: TargetFilter,
    val rangeInfo: SkillRangeInfo? = null,
    val execute: () -> Unit,
)

data class QueryMenuOption(val text: String, val value: Int)

class QueryMenuResponse private constructor(val pop: Boolean = false, val popAll: Boolean = false) {
    companion object {
        val noop = QueryMenuResponse()
        val pop = QueryMenuResponse(pop = true)
        val popAll = QueryMenuResponse(popAll = true)
    }

    var soundType: SystemSound? = null

}

object UiStateHelper {

    var defaultContext: UiState
    lateinit var actionContext: UiState
    lateinit var magicTypeSelectContext: UiState
    lateinit var magicSelectContext: UiState
    lateinit var subTargetContext: UiState
    lateinit var menuWindowContext: UiState
    lateinit var inventoryContext: UiState
    lateinit var itemUseContext: UiState
    lateinit var itemSortContext: UiState
    lateinit var pendingYesNoContext: UiState

    lateinit var equipContext: UiState
    lateinit var equipSelectContext: UiState
    lateinit var equipSetSelectContext: UiState

    lateinit var returnToHomePointContext: UiState
    lateinit var statusWindowContext: UiState
    lateinit var mountSelectContext: UiState
    lateinit var targetAnimationContext: UiState

    lateinit var abilityTypeContext: UiState

    lateinit var changeJobTypeContext: UiState
    lateinit var changeJobContext: UiState

    lateinit var synthesisRecipeContext: UiState
    lateinit var synthesisInputContext: UiState
    lateinit var synthesisAmountContext: UiState

    lateinit var chatLogContext: UiState
    lateinit var expandedChatLogContext: UiState
    lateinit var buffContext: UiState

    lateinit var battleGaugeUi: UiState
    lateinit var macroUi: UiState

    lateinit var rankItemSelectContext: UiState
    lateinit var rankUpgradeItemSelectContext: UiState
    lateinit var rankInputAmountContext: UiState

    private var pendingAction: PendingAction? = null
    private var pendingYesNoCommand: (() -> Unit)? = null
    private var magicFilter: MagicType = MagicType.None
    private var itemFilter: ItemListType? = null
    private var changeJobType: ChangeJobType = ChangeJobType.Main
    private val subTargetAoeIndicator = AoeIndicator()

    var synthesisType: SynthesisType = SynthesisType.Fire
        private set

    private val jobAbilitySelectMenu = HashMap<AbilityType, UiState>()

    init {
        defaultContext = UiState(
            dynamicFocusMenu = { getDefaultContextMenu() },
            additionalDraw = { drawDefaultContextMenu(it) }
        ) { handleDefaultContextMenu(it.cursorPos) }

        actionContext = UiState(
            dynamicFocusMenu = { getActionContextMenu() },
            menuStacks = MenuStacks.LogStack,
        ) { handleActionContextMenu(it.cursorPos) }

        abilityTypeContext = UiState(
            focusMenu = "menu    abiselec",
            menuStacks = MenuStacks.LogStack,
        ) {
            if (it.cursorPos == 0 && isEnterPressed()) {
                pushState(makeAbilityMenu(AbilityType.JobAbility), SystemSound.MenuSelect)
                true
            } else if (it.cursorPos == 1 && isEnterPressed()) {
                pushState(makeAbilityMenu(AbilityType.WeaponSkill), SystemSound.MenuSelect)
                true
            } else if (it.cursorPos == 2 && isEnterPressed()) {
                val canUseRangedAttack = ActorStateManager.player().getRangedAttackItems() != null
                if (!canUseRangedAttack) {
                    AudioManager.playSystemSoundEffect(SystemSound.Invalid)
                    return@UiState false
                }

                val (canBegin, reason) = GameEngine.canBeginRangedAttack(ActorStateManager.playerId)
                if (!canBegin) {
                    ChatLog(reason, ChatLogColor.Error)
                    AudioManager.playSystemSoundEffect(SystemSound.Invalid)
                    return@UiState false
                }

                pendingAction = PendingAction(
                    targetFilter = GameEngine.getRangedAttackTargetFilter(),
                    rangeInfo = GameEngine.getRangedAttackRangeInfo(ActorStateManager.player()),
                    execute = {
                        val player = ActorManager.player()
                        GameClient.submitStartRangedAttack(player.id, player.subTarget!!)
                    }
                )
                pushState(subTargetContext, SystemSound.MenuSelect)
                true
            } else if (it.cursorPos == 3 && isEnterPressed()) {
                pushState(makeAbilityMenu(AbilityType.PetCommand), SystemSound.MenuSelect)
                true
            } else if (it.cursorPos == 4 && isEnterPressed()) {
                pushState(mountSelectContext, SystemSound.MenuSelect)
                true
            } else if (keyPressed(Keyboard.Key.ESC)) {
                popState()
                true
            } else {
                false
            }
        }

        magicTypeSelectContext = UiState(
            focusMenu = "menu    magselec",
            menuStacks = MenuStacks.LogStack,
            drawParent = true,
        ) { 
            if (keyPressed(Keyboard.Key.LEFT) || keyPressed(Keyboard.Key.ESC)) {
                popState()
                true
            } else if (keyPressed(Keyboard.Key.ENTER)) {
                val newMagicFilter = when(it.cursorPos) {
                    0 -> MagicType.WhiteMagic
                    1 -> MagicType.BlackMagic
                    2 -> MagicType.Songs
                    3 -> MagicType.Ninjutsu
                    4 -> MagicType.Summoning
                    5 -> MagicType.BlueMagic
                    6 -> MagicType.Geomancy
                    else -> throw IllegalStateException()
                }

                changeMagicFilter(newMagicFilter)
                pushState(magicSelectContext, SystemSound.MenuSelect)
                true
            } else {
                false
            }
        }

        magicSelectContext = UiState(
            additionalDraw = { SpellSelectUi.drawSpells(it, magicFilter); SpellSelectUi.drawRecast(currentItemIndex(), magicFilter) },
            focusMenu = "menu    magic   ",
            menuStacks = MenuStacks.LogStack,
            appendType = AppendType.StackAndAppend,
            resetCursorIndexOnPush = false,
            scrollSettings = ScrollSettings(numElementsInPage = 12) { SpellSelectUi.getSpellItems(magicFilter).size },
        ) { 
            if (keyPressed(Keyboard.Key.ENTER)) {
                val player = ActorManager.player()
                if (player.isCasting()) { return@UiState true }

                val spellIndex = currentItemIndex()
                val selectedSpell = SpellSelectUi.getSelectedSpell(spellIndex, magicFilter) ?: return@UiState true

                if (!GameEngine.canBeginCastSpell(player.id, selectedSpell)) {
                    AudioManager.playSystemSoundEffect(SystemSound.Invalid)
                    return@UiState true
                }

                pendingAction = PendingAction(
                    targetFilter = selectedSpell.targetFilter,
                    rangeInfo = GameEngine.getCastingRangeInfo(ActorStateManager.player(), selectedSpell.index),
                    execute = { SpellSelectUi.castSelectedSpell(spellIndex, ActorManager.player().subTarget!!, magicFilter) }
                )

                pushState(subTargetContext, SystemSound.MenuSelect)
                true
            } else if (keyPressed(Keyboard.Key.ESC)) {
                popState(SystemSound.MenuClose)
                true
            } else {
                false
            }
        }

        subTargetContext = UiState(
            subTargetSelect = true,
            onPushed = {
                val selectedTarget = PlayerTargetSelector.onStartSelectingSubTarget(pendingAction!!.targetFilter)
                if (selectedTarget == null) {
                    popState(SystemSound.Invalid)
                    false
                } else {
                    if (getPendingAoeInfo() != null) { updateSubTargetAoeIndicator(); subTargetAoeIndicator.show() }
                    true
                }
            },
            onPopped = {
                subTargetAoeIndicator.hide()
                PlayerTargetSelector.onFinishedSelectingSubTarget()
            }
        ) { 
            val pending = pendingAction!!
            updateSubTargetAoeIndicator()

            if (!PlayerTargetSelector.updateSubTarget(pending.targetFilter)) {
                popState(SystemSound.MenuClose)
                true
            } else if (keyPressed(Keyboard.Key.ENTER)) {
                if (isSubTargetTooFar()) {
                    AudioManager.playSystemSoundEffect(SystemSound.Invalid)
                } else {
                    pending.execute()
                    popState(SystemSound.MenuClose)
                    popState()
                }
                true
            } else if (keyPressed(Keyboard.Key.TAB)) {
                PlayerTargetSelector.targetCycle(subTarget = true, targetFilter = pending.targetFilter)
                updateSubTargetAoeIndicator()
                true
            } else if (targetDirectly()){
                true
            } else if (keyPressed(Keyboard.Key.ESC)) {
                popState(SystemSound.MenuClose)
                true
            } else {
                false
            }
        }

        menuWindowContext = UiState(
            focusMenu = "menu    menuwind",
            resetCursorIndexOnPush = false,
            hideGauge = true,
            menuStacks = MenuStacks.PartyStack,
            appendType = AppendType.HorizontalOnly,
        ) { 
            if (it.cursorPos == 0 && keyPressed(Keyboard.Key.ENTER)) {
                pushState(statusWindowContext, SystemSound.MenuSelect)
                true
            } else if (it.cursorPos == 1 && keyPressed(Keyboard.Key.ENTER)) {
                pushState(equipContext, SystemSound.MenuSelect)
                true
            } else if (it.cursorPos == 3 && keyPressed(Keyboard.Key.ENTER)) {
                pushState(inventoryContext, SystemSound.MenuSelect)
                true
            } else if (it.cursorPos == 11 && keyPressed(Keyboard.Key.ENTER)) {
                MapDrawer.toggle()
                AudioManager.playSystemSoundEffect(SystemSound.MenuSelect)
                true
            } else if (keyPressed(Keyboard.Key.ESC)) {
                popState(SystemSound.MenuClose)
                true
            } else {
                false
            }
        }

        inventoryContext = UiState(
            additionalDraw = { InventoryUi.drawInventoryItems(it, itemTypeFilter = itemFilter) },
            focusMenu = "menu    inventor",
            resetCursorIndexOnPush = false,
            scrollSettings = ScrollSettings(numElementsInPage = 10) { InventoryUi.getItems(itemTypeFilter = itemFilter).size },
            hideGauge = true,
            onPopped = { itemFilter = null },
        ) {
            if (keyPressed(Keyboard.Key.ESC)) {
                popState(SystemSound.MenuClose)
                true
            } else if (isEnterPressed()) {
                InventoryUi.getSelectedItem(it.local, itemTypeFilter = itemFilter) ?: return@UiState true
                pushState(itemUseContext, SystemSound.MenuSelect)
                true
            } else if (keyPressed(Keyboard.Key.F)) {
                pushState(itemSortContext, SystemSound.MenuSelect)
                true
            } else {
                false
            }
        }

        itemUseContext = UiState(
            focusMenu = "menu    iuse    ",
            drawParent = true,
            menuStacks = MenuStacks.PartyStack,
            appendType = AppendType.HorizontalOnly,
        ) { 
            if (keyPressed(Keyboard.Key.ESC)) {
                popState(SystemSound.MenuClose)
                true
            } else if (it.cursorPos == 0 && isEnterPressed()) {
                val currentItem = InventoryUi.getSelectedItem(inventoryContext, itemTypeFilter = itemFilter)
                if (currentItem == null) {
                    popState(SystemSound.Invalid)
                    return@UiState true
                }

                if (currentItem.info().itemType == InventoryItemType.Crystal) {
                    synthesisType = SynthesisType.fromItemId(currentItem.id)
                    pushState(synthesisRecipeContext, SystemSound.MenuSelect)
                    return@UiState true
                }

                pendingAction = PendingAction(
                    targetFilter = currentItem.info().targetFilter,
                    rangeInfo = GameEngine.getItemRange(ActorStateManager.player(), currentItem.id),
                    execute = { InventoryUi.useSelectedInventoryItem(currentItem, ActorManager.player().subTarget!!) }
                )

                pushState(subTargetContext, SystemSound.MenuSelect)
                true
            } else if (it.cursorPos == 1 && isEnterPressed()) {
                val currentItem = InventoryUi.getSelectedItem(inventoryContext, itemTypeFilter = itemFilter)!!
                pendingYesNoCommand = { GameClient.submitDiscardItem(ActorStateManager.playerId, currentItem); popState() }
                pushState(pendingYesNoContext, SystemSound.MenuSelect)
                true
            } else {
                false
            }
        }

        itemSortContext = UiState(
            focusMenu = "menu    mgcsortw",
            drawParent = true,
            menuStacks = MenuStacks.PartyStack,
            appendType = AppendType.HorizontalOnly,
        ) { 
            if (keyPressed(Keyboard.Key.ESC)) {
                popState(SystemSound.MenuClose)
                true
            } else if (it.cursorPos == 0 && isEnterPressed()) {
                pendingYesNoCommand = { GameClient.submitInventorySort(ActorStateManager.playerId) }
                pushState(pendingYesNoContext, SystemSound.MenuSelect)
                AudioManager.playSystemSoundEffect(SystemSound.MenuSelect)
                true
            } else {
                false
            }
        }

        pendingYesNoContext = UiState(
            focusMenu = "menu    sortyn  ",
            drawParent = true,
            menuStacks = MenuStacks.PartyStack,
            appendType = AppendType.HorizontalOnly,
            defaultCursorIndex = 1,
        ) { 
            if (keyPressed(Keyboard.Key.ESC)) {
                pendingYesNoCommand = null
                popState(SystemSound.MenuClose)
                true
            } else if (it.cursorPos == 0 && isEnterPressed()) {
                pendingYesNoCommand?.invoke()
                pendingYesNoCommand = null
                popState(SystemSound.MenuSelect)
                true
            } else if (it.cursorPos == 1 && isEnterPressed()) {
                pendingYesNoCommand = null
                popState(SystemSound.MenuClose)
                true
            } else {
                false
            }
        }

        equipContext = UiState(
            additionalDraw = { EquipScreenUi.draw(it) },
            dynamicFocusMenu = { if (EquipScreenUi.currentEquipSet == null) { "menu    equip   " } else { "menu    mcresed " } },
            hideGauge = true,
            childStates = { listOf(equipSelectContext, equipSetSelectContext) },
            resetCursorIndexOnPush = false,
        ) { 
            if (keyPressed(Keyboard.Key.ENTER)) {
                val numItems = EquipScreenUi.getInventoryItemsByEquipSlot(equipContext.cursorIndex).size
                if (numItems == 0) {
                    AudioManager.playSystemSoundEffect(SystemSound.Invalid)
                } else {
                    pushState(equipSelectContext, SystemSound.MenuSelect)
                }
                true
            } else if ((keyPressed(Keyboard.Key.RIGHT) && it.cursorPos >= 12) || keyPressed(Keyboard.Key.F)) {
                pushState(equipSetSelectContext, SystemSound.TargetCycle)
                true
            } else if (keyPressed(Keyboard.Key.ESC) && EquipScreenUi.currentEquipSet != null) {
                pushState(equipSetSelectContext, SystemSound.MenuClose)
                true
            } else if (keyPressed(Keyboard.Key.ESC)) {
                popState(SystemSound.MenuClose)
                true
            } else {
                false
            }
        }

        equipSelectContext = UiState(
            focusMenu = "menu    inventor",
            drawParent = true,
            parentRelative = ParentRelative.RightOfParent,
            additionalDraw = { InventoryUi.drawInventoryItems(it, equipSlotFilter = equipContext.cursorIndex) },
            scrollSettings = ScrollSettings(numElementsInPage = 10) { EquipScreenUi.getInventoryItemsByEquipSlot(equipContext.cursorIndex).size },
        ) { 
            if (keyPressed(Keyboard.Key.ENTER)) {
                EquipScreenUi.equipHoveredItem(equipSelectContext)
                popState(SystemSound.MenuSelect)
                true
            } else if (keyPressed(Keyboard.Key.ESC)) {
                popState(SystemSound.MenuClose)
                true
            } else {
                false
            }
        }

        equipSetSelectContext = UiState(
            focusMenu = "menu    mcres20 ",
            drawParent = true,
            menuStacks = MenuStacks.PartyStack,
            appendType = AppendType.HorizontalOnly,
            resetCursorIndexOnPush = false,
            additionalDraw = { EquipScreenUi.drawEquipSets(it) }
        ) {
            if (isEnterPressed()) {
                EquipScreenUi.currentEquipSet = it.cursorPos
                popState(SystemSound.MenuSelect)
                true
            } else if (keyPressed(Keyboard.Key.ESC) || keyPressed(Keyboard.Key.LEFT)) {
                EquipScreenUi.currentEquipSet = null
                popState(SystemSound.MenuClose)
                true
            } else {
                false
            }
        }

        mountSelectContext = UiState(
            additionalDraw = { MountSelectUi.draw(it) },
            focusMenu = "menu    magic   ",
            menuStacks = MenuStacks.LogStack,
            appendType = AppendType.StackAndAppend,
            resetCursorIndexOnPush = false,
            scrollSettings = ScrollSettings(numElementsInPage = 12) { MountSelectUi.getItems().size },
        ) { 
            if (keyPressed(Keyboard.Key.ENTER)) {
                val itemIndex = currentItemIndex()
                MountSelectUi.useSelectedAbility(itemIndex)
                true
            } else if (keyPressed(Keyboard.Key.ESC)) {
                popState(SystemSound.MenuClose)
                true
            } else {
                false
            }
        }

        targetAnimationContext = UiState(
            additionalDraw = { TargetAnimationUi.draw(it) },
            focusMenu = "menu    magic   ",
            menuStacks = MenuStacks.LogStack,
            appendType = AppendType.StackAndAppend,
            resetCursorIndexOnPush = false,
            scrollSettings = ScrollSettings(numElementsInPage = 12) { TargetAnimationUi.getItems().size },
        ) { 
            if(ActorManager.player().target == null) {
                popState(SystemSound.MenuClose)
                true
            } else if (keyPressed(Keyboard.Key.ENTER)) {
                val itemIndex = currentItemIndex()
                TargetAnimationUi.selectedOption(itemIndex)
                popState(SystemSound.MenuClose)
                true
            } else if (keyPressed(Keyboard.Key.ESC)) {
                popState(SystemSound.MenuClose)
                true
            } else {
                false
            }
        }

        returnToHomePointContext = yesNoMenu("Return to Home Point?",
            onYes = { ZoneChanger.returnToHomePoint(restore = true); popState() },
            onNo = { popState(SystemSound.MenuClose) }
        )

        statusWindowContext = UiState(
            focusMenu = "menu    statuswi",
            drawParent = true,
            additionalDraw = { StatusWindowUi.draw(it) },
        ) { 
            if (keyPressed(Keyboard.Key.ESC)) {
                popState(SystemSound.MenuClose)
                true
            } else {
                false
            }
        }

        changeJobTypeContext = UiState(
            focusMenu = "menu    jobchang",
            menuStacks = MenuStacks.LogStack,
        ) { 
            if (!isCloseToMoogle()) {
                popState(SystemSound.MenuClose)
                true
            } else if (isEnterPressed()) {
                changeJobType = if (it.cursorPos == 0) { ChangeJobType.Main } else { ChangeJobType.Sub }
                pushState(changeJobContext, SystemSound.MenuSelect)
                true
            } else if (keyPressed(Keyboard.Key.ESC)) {
                popState(SystemSound.MenuClose)
                true
            } else {
                false
            }
        }

        changeJobContext = UiState(
            focusMenu = "menu    jobcselu",
            menuStacks = MenuStacks.LogStack,
            additionalDraw = { JobSelectUi.draw(it) }
        ) { 
            if (!isCloseToMoogle()) {
                popState(SystemSound.MenuClose)
                true
            } else if (isEnterPressed()) {
                val job = Job.byIndex(it.cursorPos + 1) ?: return@UiState true

                when (changeJobType) {
                    ChangeJobType.Main -> GameClient.submitChangeJob(ActorStateManager.playerId, mainJob = job)
                    ChangeJobType.Sub -> GameClient.submitChangeJob(ActorStateManager.playerId, subJob = job)
                }

                true
            } else if (keyPressed(Keyboard.Key.ESC)) {
                popState(SystemSound.MenuClose)
                true
            } else {
                false
            }
        }

        synthesisAmountContext = UiState(
            focusMenu = "menu    itemctrl",
            drawParent = true,
            parentRelative = ParentRelative.TopOfParent,
            additionalDraw = { SynthesisUi.drawItemControl(it) }
        ) { 
            SynthesisUi.quantityInput.refresh()

            if (!SynthesisUi.canCraftSelectedRecipe()) {
                popState(SystemSound.MenuClose)
                true
            } else if (keyPressed(Keyboard.Key.ENTER)) {
                GameClient.submitStartSynthesis(ActorStateManager.playerId, SynthesisUi.getSelectedRecipe(), quantity = SynthesisUi.quantityInput.value)
                popState(SystemSound.MenuSelect)
                true
            } else if (SynthesisUi.quantityInput.processInput()) {
                true
            } else if (keyPressed(Keyboard.Key.ESC)) {
                popState(SystemSound.MenuClose)
                true
            } else {
                false
            }
        }

        synthesisInputContext = UiState(
            focusMenu = "menu    tskill1 ",
            drawParent = true,
            parentRelative = ParentRelative.RightOfParent,
            defaultCursorIndex = 8,
            additionalDraw = { SynthesisUi.drawRecipeInputItems(it) },
        ) { 
            if (keyPressed(Keyboard.Key.ENTER) && it.cursorPos == 8) {
                if (!ActorStateManager.player().isIdle() || !SynthesisUi.canCraftSelectedRecipe()) {
                    AudioManager.playSystemSoundEffect(SystemSound.Invalid)
                } else if (SynthesisUi.canCraftMultiple()) {
                    pushState(synthesisAmountContext, SystemSound.MenuSelect)
                } else {
                    GameClient.submitStartSynthesis(ActorStateManager.playerId, SynthesisUi.getSelectedRecipe(), quantity = 1)
                    AudioManager.playSystemSoundEffect(SystemSound.MenuSelect)
                }
                true
            } else if (isEnterPressed() && it.cursorPos == 9) {
                popState(SystemSound.MenuClose)
                true
            } else if (keyPressed(Keyboard.Key.ESC)) {
                popState(SystemSound.MenuClose)
                true
            } else {
                false
            }
        }

        synthesisRecipeContext = UiState(
            focusMenu = "menu    cmbhlst ",
            childStates = { listOf(synthesisInputContext) },
            resetCursorIndexOnPush = false,
            hideGauge = true,
            scrollSettings = ScrollSettings(numElementsInPage = 10) { SynthesisRecipes.getAvailableRecipes(synthesisType).size },
            additionalDraw = { SynthesisUi.drawRecipeInventory() }
        ) { 
            if (keyPressed(Keyboard.Key.ENTER)) {
                pushState(synthesisInputContext)
                true
            } else if (keyPressed(Keyboard.Key.ESC)) {
                popState(SystemSound.MenuClose)
                true
            } else {
                false
            }
        }

        ChatLog.registerFakeMenu()
        chatLogContext = UiState(
            focusMenu = ChatLog.fakeUiMenuName,
            menuStacks = MenuStacks.LogStack,
            appendType = AppendType.StackAndAppend,
            additionalDraw = { ChatLog.draw(it) },
        ) { 
            if (isEnterPressed()) {
                pushState(expandedChatLogContext, SystemSound.MenuSelect)
                true
            } else if (keyPressed(Keyboard.Key.F)) {
                popState()
                pushState(buffContext)
                true
            } else if (keyPressed(Keyboard.Key.ESC)) {
                popState(SystemSound.MenuClose)
                true
            } else {
                false
            }
        }

        expandedChatLogContext = UiState(
            onPushed = { ChatLog.toggleExpand(true); true },
            onPopped = { ChatLog.toggleExpand(false) },
            drawParent = true
        ) { 
            if (keyPressed(Keyboard.Key.ESC)) {
                popState(SystemSound.MenuClose)
                true
            } else {
                false
            }
        }

        buffContext = UiState(
            focusMenu = "menu    buff    ",
            onPushed = { if (StatusEffectUi.isValid()) { true } else { popState(); false } },
            drawBorder = false,
            additionalDraw = { StatusEffectUi.draw(it) }
        ) { 
            if (keyPressed(Keyboard.Key.F)) {
                popState()
                true
            } else if (keyPressed(Keyboard.Key.ESC)) {
                popState(SystemSound.MenuClose)
                true
            } else {
                StatusEffectUi.navigateCursor(buffContext)
            }
        }

        battleGaugeUi = UiState(
            focusMenu = "menu    gaugewin",
            additionalDraw = { BattleGaugeUi.draw(it) },
        ) {  false }

        macroUi = UiState(
            additionalDraw = { MacroTool.draw() },
        ) {
            MacroTool.handleInput(MainTool.platformDependencies.keyboard)
            false
        }

        rankInputAmountContext = UiState(
            focusMenu = "menu    itemctrl",
            drawParent = true,
            additionalDraw = { ItemReinforcementUi.drawItemControl(it) }
        ) { 
            ItemReinforcementUi.quantityInput.refresh()

            if (keyPressed(Keyboard.Key.ENTER)) {
                ItemReinforcementUi.submitUpgrade()
                popState(SystemSound.MenuSelect)
                true
            } else if (ItemReinforcementUi.quantityInput.processInput()) {
                true
            } else if (keyPressed(Keyboard.Key.ESC)) {
                popState(SystemSound.MenuClose)
                true
            } else {
                false
            }
        }

        rankUpgradeItemSelectContext = UiState(
            additionalDraw = { InventoryUi.drawInventoryItems(it, filter = ItemReinforcementUi.getUpgradeItemFilter()) },
            drawParent = true,
            parentRelative = ParentRelative.RightOfParent,
            focusMenu = "menu    inventor",
            resetCursorIndexOnPush = false,
            scrollSettings = ScrollSettings(numElementsInPage = 10) { InventoryUi.getItems(filter = ItemReinforcementUi.getUpgradeItemFilter()).size },
            hideGauge = true,
        ) { 
            if (ItemReinforcementUi.isSelectedItemMaxRank()) {
                popState(SystemSound.MenuClose)
                true
            } else if (isEnterPressed() && ItemReinforcementUi.getSelectedUpgradeMaterial() != null) {
                pushState(rankInputAmountContext, SystemSound.MenuSelect)
                true
            } else if (keyPressed(Keyboard.Key.ESC)) {
                popState(SystemSound.MenuClose)
                true
            } else {
                false
            }
        }

        rankItemSelectContext = UiState(
            additionalDraw = { InventoryUi.drawInventoryItems(it, filter = EquipmentFilter) },
            focusMenu = "menu    inventor",
            childStates = { listOf(rankUpgradeItemSelectContext) },
            resetCursorIndexOnPush = false,
            scrollSettings = ScrollSettings(numElementsInPage = 10) { InventoryUi.getItems(filter = EquipmentFilter).size },
            hideGauge = true,
        ) { 
            if (isEnterPressed()) {
                if (ItemReinforcementUi.isSelectedItemMaxRank()) {
                    ChatLog("This item is already at its maximum rank.", ChatLogColor.Error)
                    AudioManager.playSystemSoundEffect(SystemSound.Invalid)
                    return@UiState true
                }

                pushState(rankUpgradeItemSelectContext, SystemSound.MenuSelect)
                true
            } else if (keyPressed(Keyboard.Key.ESC)) {
                popState(SystemSound.MenuClose)
                true
            } else {
                false
            }
        }

    }

    private var currentState = UiStateFrame(defaultContext, null)
    private var inputThrottler = 0f

    fun update(elapsedFrames: Float) {
        inputThrottler -= elapsedFrames
        if (inputThrottler > 0f) {
            return
        }

        val current = current()
        current.state.applyCursorBounds()

        val handlerContext = HandlerContext(current.state, current.state.cursorIndex)
        if (current.state.handler.invoke(handlerContext)) {
            inputThrottler = millisToFrames(100)
        } else if (moveCursor()) {
            inputThrottler = millisToFrames(100)
        } else if (advanceItemDescriptionPage()) {
            inputThrottler = millisToFrames(100)
        }

        if (shouldShowMacros()) {
            macroUi.handler.invoke(handlerContext)
        }
    }

    fun draw() {
        if (shouldShowBattleGauge()) { drawFrame(UiStateFrame(battleGaugeUi, parent = null), focus = false) }
        if (!isInCurrentFrame(chatLogContext)) { drawFrame(UiStateFrame(chatLogContext, parent = null), focus = false) }
        if (!isInCurrentFrame(buffContext)) { drawFrame(UiStateFrame(buffContext, parent = null), focus = false) }
        if (shouldShowMacros()) { drawFrame(UiStateFrame(macroUi, parent = null), false) }
        drawFrame(current(), true)
    }

    fun isSubTargetMode(): Boolean {
        return current().state.subTargetSelect
    }

    private fun drawFrame(frame: UiStateFrame, focus: Boolean, skipParents: Boolean = false) {
        if (!skipParents && frame.state.drawParent && frame.parent != null) {
            frame.state.latestParent = frame.parent.state
            drawFrame(frame.parent, false)
        }

        if (focus && frame.state.subTargetSelect) {
            val subTarget = ActorManager[ActorManager.player().subTarget]
            if (subTarget != null) { PartyUi.drawTargetPointer(subTarget, getSubTargetColorMask(subTarget)) }
        }

        val cursorPos = if (focus) { frame.state.cursorIndex } else { -1 }

        val offsetOverride = if (frame.state.parentRelative != null) {
            val parent = frame.parent!!
            val parentLatestPos = parent.state.latestPosition!!
            val parentLatestSettings = parent.state.latestMenu!!

            when(frame.state.parentRelative) {
                ParentRelative.RightOfParent -> Vector2f(parentLatestPos.x + parentLatestSettings.frame.size.x + 2f, parentLatestPos.y)
                ParentRelative.TopOfParent -> Vector2f(parentLatestPos.x, parentLatestPos.y + parentLatestSettings.frame.size.y + 2f)
            }
        } else {
            null
        }

        frame.state.getFocusMenu()?.let {
            frame.state.latestMenu = UiResourceManager.getMenu(it)?.uiMenu
            frame.state.latestPosition = UiElementHelper.drawMenu(
                menuName = it,
                cursorIndex = cursorPos,
                offsetOverride = offsetOverride,
                menuStacks = frame.state.menuStacks,
                appendType = frame.state.appendType,
                scrollSettings = frame.state.scrollSettings,
                drawFrame = frame.state.drawBorder,
            )
        }

        frame.state.additionalDraw?.invoke(frame.state)

        if (focus) {
            val children = frame.state.childStates?.invoke() ?: emptyList()
            for (child in children) { drawFrame(UiStateFrame(child, frame), focus = false, skipParents = true) }
        }
    }

    fun movementLocked(): Boolean {
        return current().state.locksMovement
    }

    fun cursorLocked(): Boolean {
        return current().state.getFocusMenu() != null
    }

    fun clear(systemSound: SystemSound? = null) {
        while (currentState.parent != null) { popState() }
        if (systemSound != null) { AudioManager.playSystemSoundEffect(systemSound) }
    }

    fun isFocus(uiState: UiState) : Boolean {
        return current().state == uiState
    }

    fun popActionContext() {
        if (current().state == actionContext) { popState() }
    }

    private fun current(): UiStateFrame {
        return currentState
    }

    private fun pushState(newState: UiState, systemSound: SystemSound? = null) {
        currentState = UiStateFrame(newState, current())
        if (newState.resetCursorIndexOnPush) { newState.resetCursorIndex() } else { newState.applyCursorBounds() }

        if (newState.defaultCursorIndex != null) { newState.cursorIndex = newState.defaultCursorIndex }

        val success = newState.onPushed?.invoke() ?: true
        if (success && systemSound != null) { AudioManager.playSystemSoundEffect(systemSound) }
    }

    private fun popState(systemSound: SystemSound? = null) {
        currentState.state.onPopped?.invoke()
        currentState = currentState.parent!!
        if (systemSound != null) { AudioManager.playSystemSoundEffect(systemSound) }
    }

    private fun isEnterPressed() : Boolean {
        return keyPressed(Keyboard.Key.ENTER)
    }

    private fun keyPressed(key: Keyboard.Key): Boolean {
        return getFakeTouchPresses().contains(key) || MainTool.platformDependencies.keyboard.isKeyPressed(key)
    }

    private fun keyPressedOrRepeated(key: Keyboard.Key): Boolean {
        return getFakeTouchPresses().contains(key) || MainTool.platformDependencies.keyboard.isKeyPressedOrRepeated(key)
    }

    fun getDirectionalInput(): UiMenuCursorKey? {
        return if (keyPressedOrRepeated(Keyboard.Key.LEFT)) { UiMenuCursorKey.Left }
        else if (keyPressedOrRepeated(Keyboard.Key.RIGHT)) { UiMenuCursorKey.Right }
        else if (keyPressedOrRepeated(Keyboard.Key.UP)) { UiMenuCursorKey.Up }
        else if (keyPressedOrRepeated(Keyboard.Key.DOWN)) { UiMenuCursorKey.Down}
        else { null }
    }

    private fun moveCursor(): Boolean {
        val key = getDirectionalInput() ?: return false

        val current = current().state
        val startingIndex = current.cursorIndex

        val focusMenuName = current.getFocusMenu() ?: return false
        val menu = UiResourceManager.getMenu(focusMenuName)?.uiMenu ?: return false

        val scrollSettings = current.scrollSettings
        val startingView = scrollSettings?.lowestViewableItemIndex

        if (scrollSettings != null) {
            val numTotalItems = scrollSettings.numElementsProvider.invoke()
            val itemIndex = scrollSettings.lowestViewableItemIndex + current.cursorIndex

            val maxItemIndex = (numTotalItems - 1).coerceAtLeast(0)
            val highestViewableItemIndex = scrollSettings.lowestViewableItemIndex + min(maxItemIndex, scrollSettings.numElementsInPage - 1)

            val maxCursorIndex = scrollSettings.numElementsInPage - 1

            if (itemIndex == 0 && key == UiMenuCursorKey.Up) {
                current.cursorIndex = min(maxItemIndex, maxCursorIndex)
                scrollSettings.lowestViewableItemIndex = max(0, numTotalItems - scrollSettings.numElementsInPage)
            } else if (current.cursorIndex == 0 && key == UiMenuCursorKey.Up) {
                scrollSettings.lowestViewableItemIndex -= 1
            } else if (itemIndex == maxItemIndex && key == UiMenuCursorKey.Down) {
                current.cursorIndex = 0
                scrollSettings.lowestViewableItemIndex = 0
            } else if (current.cursorIndex == maxCursorIndex && key == UiMenuCursorKey.Down) {
                scrollSettings.lowestViewableItemIndex += 1
            } else if (key == UiMenuCursorKey.Left && scrollSettings.lowestViewableItemIndex == 0) {
                current.cursorIndex = 0
            } else if (key == UiMenuCursorKey.Left) {
                val amountToSub = min(scrollSettings.numElementsInPage, scrollSettings.lowestViewableItemIndex)
                scrollSettings.lowestViewableItemIndex -= amountToSub
            } else if (key == UiMenuCursorKey.Right && (highestViewableItemIndex == maxItemIndex || maxItemIndex < scrollSettings.numElementsInPage)) {
                current.cursorIndex = min(maxItemIndex, maxCursorIndex)
            } else if (key == UiMenuCursorKey.Right) {
                val amountToAdd = min(scrollSettings.numElementsInPage, maxItemIndex - highestViewableItemIndex)
                scrollSettings.lowestViewableItemIndex += amountToAdd
            } else {
                navigateCursor(menu, key)
            }
        } else {
            navigateCursor(menu, key)
        }

        val endingIndex = current.cursorIndex
        val endingView = scrollSettings?.lowestViewableItemIndex

        if (startingIndex != endingIndex || startingView != endingView) { AudioManager.playSystemSoundEffect(SystemSound.CursorMove) }
        return true
    }

    private fun getFakeTouchPresses(): Set<Keyboard.Key> {
        val touches = MainTool.platformDependencies.keyboard.getTouchData().filter { it.isControlTouch() }

        val fakeKeys = HashSet<Keyboard.Key>()
        for (touch in touches) {
            if (touch.dX < -0.1) { fakeKeys += Keyboard.Key.LEFT }
            else if (touch.dX > 0.1) { fakeKeys += Keyboard.Key.RIGHT }
            else if (touch.dY < -0.1) { fakeKeys += Keyboard.Key.UP }
            else if (touch.dY > 0.1) { fakeKeys += Keyboard.Key.DOWN }
        }

        val click = MainTool.platformDependencies.keyboard.getClickEvents().lastOrNull()
        if (click != null && !click.consumed) {
            if (click.normalizedScreenPosition.x > 0.9 && click.normalizedScreenPosition.y < 0.1) {
                 fakeKeys += Keyboard.Key.MINUS
            } else if (click.isLongClick()) {
                if (click.normalizedScreenPosition.x > 0.5) { fakeKeys += Keyboard.Key.ESC }
            } else {
                fakeKeys += Keyboard.Key.ENTER
            }
        }

        return fakeKeys
    }

    private fun navigateCursor(menu: UiMenu, key: UiMenuCursorKey) {
        if (menu.elements.isEmpty()) { return }
        val current = current()
        val currentElement = menu.elements[current.state.cursorIndex]
        current.state.cursorIndex = currentElement.next[key]!! - 1
    }

    private fun advanceItemDescriptionPage(): Boolean {
        return if (keyPressed(Keyboard.Key.MINUS)) {
            InventoryUi.advanceItemDescriptionPage()
            true
        } else {
            false
        }
    }

    fun currentItemIndex(): Int {
        val current = current()
        return current.state.cursorIndex + (current.state.scrollSettings?.lowestViewableItemIndex ?: 0)
    }

    private fun handleDefaultEnter() {
        val player = ActorManager.player()
        val playerState = ActorStateManager.player()

        if (playerState.isGathering()) { return }

        val target = ActorManager[player.target]
        val targetState = ActorStateManager[player.target]


        if (target == null) {
            val selectedTarget = PlayerTargetSelector.targetCycle()
            if (selectedTarget) { return }
        } else {
            val npcInteraction = SceneManager.getCurrentScene().getNpcInteraction(target.id)

            if (npcInteraction != null) {
                if (EventScriptRunner.isRunningScript()) { return }
                if (!npcInteraction.distanceCheck(target.id)) { return ChatLog("Target out of range.", ChatLogColor.Info) }
                if (playerState.isEngaged()) { GameClient.submitPlayerDisengage() }
                npcInteraction.onInteraction(target.id)
                return
            } else if (target.isDoor() || target.isElevator()) {
                val interactionId = target.getNpcInfo()?.datId ?: return
                if (TargetAnimationUi.isBasicDoor()) {
                    GameClient.submitOpenDoor(ActorStateManager.playerId, interactionId)
                    PlayerTargetSelector.clearTarget()
                    AudioManager.playSystemSoundEffect(SystemSound.MenuClose)
                } else if (TargetAnimationUi.getItems().isNotEmpty()) {
                    pushState(targetAnimationContext, SystemSound.TargetConfirm)
                } else {
                    PlayerTargetSelector.clearTarget()
                    AudioManager.playSystemSoundEffect(SystemSound.MenuClose)
                }
                return
            } else if (targetState?.gatheringNodeState != null) {
                GameClient.submitGatheringAttempt(player.id, targetState.id)
                return
            }
        }

        pushState(actionContext, SystemSound.TargetConfirm)
    }

    private fun getDefaultContextMenu(): String? {
        val player = ActorManager.player()

        return if (ZoneChanger.isChangingZones()) {
            null
        } else if (player.isDisplayedDead()) {
            "menu    dead    "
        } else {
            null
        }
    }

    private fun drawDefaultContextMenu(uiState: UiState) {
        if (ZoneChanger.isChangingZones()) { return }
        Compass.draw()

        val player = ActorManager.player()
        if (!player.isDisplayedDead()) { return }

        val initialTime = 60.seconds
        val time = initialTime - ActorStateManager[player.id]!!.timeSinceDeath()

        val minutes = time.inWholeMinutes
        val seconds = (time.inWholeSeconds % 60).toString().padStart(2, '0')

        UiElementHelper.drawString("${minutes}:${seconds}", offset = uiState.latestPosition!! + Vector2f(78f, 5f), Font.FontShp)
    }

    fun hasActiveUi(): Boolean {
        return current().state != defaultContext
    }

    fun getPendingActionTargetFilter(): TargetFilter? {
        if (current().state != subTargetContext) { return null }
        return pendingAction?.targetFilter
    }

    fun handleTargetConfirm() {
        handleDefaultEnter()
    }

    private fun changeMagicFilter(magicType: MagicType) {
        if (magicFilter != magicType) { magicSelectContext.resetCursorIndex() }
        magicFilter = magicType
    }

    private fun handleDefaultContextMenu(cursorPos: Int): Boolean {
        val player = ActorManager.player()

        return if (ZoneChanger.isChangingZones()) {
            true
        } else if (player.isDisplayedDead()) {
            if (isEnterPressed()) {
                pushState(returnToHomePointContext, SystemSound.MenuSelect)
                true
            } else {
                false
            }
        } else {
            if (isEnterPressed()) {
                handleDefaultEnter()
                true
            } else if (targetDirectly()){
                true
            } else if (keyPressed(Keyboard.Key.F)) {
                pushState(chatLogContext, SystemSound.TargetConfirm)
                true
            } else if (keyPressed(Keyboard.Key.TAB)) {
                PlayerTargetSelector.targetCycle()
                true
            } else if (keyPressed(Keyboard.Key.ESC)) {
                PlayerTargetSelector.clearTarget()
                true
            } else if (keyPressed(Keyboard.Key.MINUS)) {
                pushState(menuWindowContext, SystemSound.MenuSelect)
                true
            } else {
                false
            }
        }
    }

    private fun targetDirectly(): Boolean {
        return if (keyPressed(Keyboard.Key.F1)){
            PlayerTargetSelector.targetPartyMember(0)
            true
        } else if (keyPressed(Keyboard.Key.F2)){
            PlayerTargetSelector.targetPartyMember(1)
            true
        } else if (keyPressed(Keyboard.Key.F3)){
            PlayerTargetSelector.targetPartyMember(2)
            true
        } else if (keyPressed(Keyboard.Key.F4)){
            PlayerTargetSelector.targetPartyMember(3)
            true
        } else if (keyPressed(Keyboard.Key.F5)){
            PlayerTargetSelector.targetPartyMember(4)
            true
        } else if (keyPressed(Keyboard.Key.F6)){
            PlayerTargetSelector.targetPartyMember(5)
            true
        } else {
            false
        }
    }

    private fun getActionContextMenu(): String {
        val items = getCurrentActions()
        ActionMenuBuilder.register("custom  actions ", items)
        return "custom  actions "
    }

    private fun handleActionContextMenu(cursorPos: Int): Boolean {
        val actions = getCurrentActions()
        val current = actions.getOrNull(cursorPos)

        if (current == null) {
            clear()
            return true
        }

        return if (current == ActionMenuItem.Attack && isEnterPressed()) {
            GameClient.submitPlayerEngage()
            true
        } else if (current == ActionMenuItem.Abilities && isEnterPressed()) {
            pushState(abilityTypeContext, SystemSound.MenuSelect)
            true
        } else if (current == ActionMenuItem.Magic && isEnterPressed()) {
            changeMagicFilter(MagicType.None)
            pushState(magicSelectContext, SystemSound.MenuSelect)
            true
        } else if (current == ActionMenuItem.Trust && isEnterPressed()) {
            changeMagicFilter(MagicType.Trust)
            pushState(magicSelectContext, SystemSound.MenuSelect)
            true
        } else if (current == ActionMenuItem.Magic && keyPressed(Keyboard.Key.RIGHT)) {
            pushState(magicTypeSelectContext)
            true
        } else if (current == ActionMenuItem.Check && isEnterPressed()) {
            pushState(targetAnimationContext, SystemSound.MenuSelect)
            true
        } else if (current == ActionMenuItem.Items && isEnterPressed()) {
            itemFilter = ItemListType.UsableItem
            pushState(inventoryContext, SystemSound.MenuSelect)
            true
        } else if (current == ActionMenuItem.Release && isEnterPressed()) {
            val player = ActorManager.player()
            GameClient.submitReleaseTrust(player.id, player.target)
            true
        } else if (current == ActionMenuItem.Dismount && isEnterPressed()) {
            GameClient.submitDismountEvent(ActorManager.player())
            true
        } else if (current == ActionMenuItem.Disengage && isEnterPressed()) {
            GameClient.submitPlayerDisengage()
            AudioManager.playSystemSoundEffect(SystemSound.MenuSelect)
            true
        } else if (current == ActionMenuItem.ChangeJobs && isEnterPressed()) {
            ActorManager.playerTarget()?.playRoutine(DatId("job0"))
            pushState(changeJobTypeContext, SystemSound.MenuSelect)
            true
        } else if (keyPressed(Keyboard.Key.TAB)) {
            PlayerTargetSelector.targetCycle()
            true
        } else if (keyPressed(Keyboard.Key.ESC)) {
            popState(SystemSound.MenuClose)
            true
        } else if (keyPressed(Keyboard.Key.MINUS)) {
            pushState(menuWindowContext, SystemSound.MenuSelect)
            true
        } else {
            false
        }
    }

    private fun getCurrentActions(): List<ActionMenuItem> {
        val actions = ArrayList<ActionMenuItem>()

        val player = ActorManager.player()
        val playerState = ActorStateManager.player()
        val targetState = ActorStateManager[playerState.targetState.targetId]

        if (playerState.isIdle() && targetState != null && targetState.isEnemy()) {
            actions += ActionMenuItem.Attack
        }

        actions += ActionMenuItem.Magic
        actions += ActionMenuItem.Abilities
        actions += ActionMenuItem.Trust
        actions += ActionMenuItem.Items

        if (playerState.mountedState != null) {
            actions += ActionMenuItem.Dismount
        }

        val playerParty = PartyManager[ActorStateManager.playerId]
        if (targetState != null && playerParty.contains(targetState.id) && targetState.owner == player.id) {
            actions += ActionMenuItem.Release
        }

        if (player.isDisplayEngaged()) {
            actions += ActionMenuItem.Disengage
        }

        if (targetState != null) {
            actions += ActionMenuItem.Check
        }

        if (targetState != null && targetState.name == "Moogle") {
            actions += ActionMenuItem.ChangeJobs
        }

        return actions
    }

    fun openQueryMode(
        prompt: String,
        options: List<QueryMenuOption>,
        closeable: Boolean = true,
        drawFn: ((QueryMenuOption) -> Unit)? = null,
        callback: (QueryMenuOption?) -> QueryMenuResponse,
    ) {
        pushState(UiState(
            focusMenu = "menu    query   ",
            locksMovement = true,
            menuStacks = MenuStacks.LogStack,
            appendType = AppendType.StackAndAppend,
            additionalDraw = { QueryUi.draw(it, prompt, options); drawFn?.invoke(options[currentItemIndex()]) },
            scrollSettings = ScrollSettings(numElementsInPage = 3) { options.size },
        ) { 
            val (response, sound) = if (isEnterPressed()) {
                Pair(callback.invoke(options[currentItemIndex()]), SystemSound.MenuSelect)
            } else if (closeable && keyPressed(Keyboard.Key.ESC)) {
                Pair(callback.invoke(null), SystemSound.MenuClose)
            } else {
                Pair(null, null)
            }

            if (response != null) {
                if (response.pop) {
                    popState(sound)
                } else if (response.popAll) {
                    clear(sound)
                } else {
                    AudioManager.playSystemSoundEffect(response.soundType ?: SystemSound.MenuSelect)
                }
                true
            } else {
                false
            }
        })
    }

    fun openInventorySelectMode(
        filter: InventoryFilter = InventoryFilter { true },
        callback: (InventoryItem?) -> QueryMenuResponse,
    ) {
        pushState(UiState(
            additionalDraw = { InventoryUi.drawInventoryItems(it, filter = filter) },
            focusMenu = "menu    inventor",
            locksMovement = true,
            resetCursorIndexOnPush = false,
            scrollSettings = ScrollSettings(numElementsInPage = 10) { InventoryUi.getItems(filter = filter).size },
            hideGauge = true,
        ) {
            val (response, sound) = if (isEnterPressed()) {
                Pair(callback.invoke(InventoryUi.getSelectedItem(it.local, filter = filter)), SystemSound.MenuSelect)
            } else if (keyPressed(Keyboard.Key.ESC)) {
                Pair(callback.invoke(null), SystemSound.MenuClose)
            } else {
                Pair(null, null)
            }

            if (response != null) {
                if (response.pop) {
                    popState(sound)
                } else if (response.popAll) {
                    clear(sound)
                } else {
                    AudioManager.playSystemSoundEffect(SystemSound.MenuSelect)
                }
                true
            } else {
                false
            }
        })
    }

    fun openItemReinforcementContext() {
        pushState(rankItemSelectContext, SystemSound.MenuSelect)
    }

    fun yesNoMenu(text: String, onYes: () -> Unit, onNo: () -> Unit, closeable: Boolean = true): UiState {
        return UiState(
            additionalDraw = { UiElementHelper.drawString(text, Vector2f(8f, 8f) + it.latestPosition!!)},
            focusMenu = "menu    comyn   ",
            drawParent = true,
        ) { 
            if (it.cursorPos == 0 && isEnterPressed()) {
                onYes.invoke()
                true
            } else if (it.cursorPos == 1 && isEnterPressed()) {
                onNo.invoke()
                true
            } else if (closeable && keyPressed(Keyboard.Key.ESC)) {
                popState(SystemSound.MenuClose)
                true
            } else {
                false
            }
        }
    }

    private fun makeAbilityMenu(type: AbilityType): UiState {
        return jobAbilitySelectMenu.getOrPut(type) { makeAbilityMenuInternal(type) }
    }

    private fun makeAbilityMenuInternal(type: AbilityType): UiState {
        return UiState(
            additionalDraw = { AbilitySelectUi.draw(type, it); AbilitySelectUi.drawRecast(currentItemIndex(), type) },
            focusMenu = "menu    magic   ",
            menuStacks = MenuStacks.LogStack,
            appendType = AppendType.StackAndAppend,
            resetCursorIndexOnPush = false,
            scrollSettings = ScrollSettings(numElementsInPage = 12) { AbilitySelectUi.getItems(type).size },
        ) { 
            if (keyPressed(Keyboard.Key.ENTER)) {
                val itemIndex = currentItemIndex()

                val subTypeMenu = AbilitySelectUi.getSubAbilityMenuType(type, itemIndex)
                if (subTypeMenu != null) {
                    pushState(makeAbilityMenu(subTypeMenu), SystemSound.MenuSelect)
                } else {
                    val selectedAbility = AbilitySelectUi.getSelectedAbility(type, itemIndex) ?: return@UiState true

                    if (!GameEngine.canBeginUseAbility(ActorStateManager.playerId, selectedAbility)) {
                        AudioManager.playSystemSoundEffect(SystemSound.Invalid)
                        return@UiState true
                    }

                    pendingAction = PendingAction(
                        targetFilter = selectedAbility.targetFilter,
                        rangeInfo = GameEngine.getAbilityRange(ActorStateManager.player(), selectedAbility.index),
                        execute = { AbilitySelectUi.useSelectedAbility(type, itemIndex, ActorManager.player().subTarget!!) }
                    )
                    pushState(subTargetContext, SystemSound.MenuSelect)
                }

                true
            } else if (keyPressed(Keyboard.Key.ESC)) {
                popState(SystemSound.MenuClose)
                true
            } else {
                false
            }
        }
    }

    private fun isCloseToMoogle(): Boolean {
        val sourceState = ActorStateManager.player()
        val targetState = ActorStateManager.playerTarget() ?: return false

        if (!targetState.name.endsWith("Moogle")) { return false }
        if (Vector3f.distance(sourceState.position, targetState.position) > 10f) { return false }

        return true
    }

    private fun isInCurrentFrame(uiState: UiState): Boolean {
        var current: UiStateFrame? = current()
        if (current?.state == uiState) { return true }

        while (current != null) {
            current = current.parent
            if (uiState == current?.state) { return true }
        }

        return false
    }

    private fun getPendingAoeInfo(): SkillRangeInfo? {
        return pendingAction?.rangeInfo
    }

    private fun updateSubTargetAoeIndicator() {
        subTargetAoeIndicator.update()
        val subTarget = ActorManager.player().subTarget ?: return
        val aoeSkillInfo = pendingAction?.rangeInfo ?: return
        subTargetAoeIndicator.configure(ActorStateManager.playerId, subTarget, aoeSkillInfo)
    }

    private fun isSubTargetTooFar(): Boolean {
        val maxRange = pendingAction?.rangeInfo?.maxTargetDistance ?: return true
        val subTarget = ActorStateManager[ActorManager.player().subTarget] ?: return true
        return Vector3f.distance(ActorStateManager.player().position, subTarget.position) >= maxRange
    }

    private fun getSubTargetColorMask(subTarget: Actor): Color {
        val maxRange = pendingAction?.rangeInfo?.maxTargetDistance ?: return Color.NO_MASK
        val distance = Vector3f.distance(ActorStateManager.player().position, subTarget.getState().position)

        return if (distance < maxRange * 0.8f) {
            Color(r = 128, g = 128, b = 255, a = 255)
        } else if (distance < maxRange) {
            Color(r = 200, g = 200, b = 128, a = 255)
        } else {
            Color(r = 255, g = 128, b = 128, a = 255)
        }
    }

    private fun shouldShowBattleGauge(): Boolean {
        if (!ActorManager.player().isDisplayEngagedOrEngaging()) { return false }
        var current: UiStateFrame? = current()

        while (current != null) {
            if (current.state.hideGauge) { return false }
            current = current.parent
        }

        return true
    }

    private fun shouldShowMacros(): Boolean {
        val current = current()
        return current.state != chatLogContext && current.state != expandedChatLogContext
    }

}