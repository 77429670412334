package xim.poc.ui

import xim.math.Vector2f
import xim.poc.UiElementHelper
import xim.poc.game.*
import xim.poc.game.configuration.SynthesisRecipe
import xim.poc.game.configuration.SynthesisRecipes
import xim.resource.InventoryItemInfo
import xim.resource.InventoryItems

object SynthesisUi {

    val quantityInput = QuantityInputController {
        val selectedRecipe = getSelectedRecipe() ?: return@QuantityInputController 0
        GameEngine.getMaximumCraftable(ActorStateManager.playerId, selectedRecipe)
    }

    fun getSelectedRecipe(): SynthesisRecipe? {
        val currentItemIndex = getSelectedRecipeIndex()
        return getCurrentRecipes().getOrNull(currentItemIndex)
    }

    fun drawRecipeInventory() {
        val context = UiStateHelper.synthesisRecipeContext
        drawRecipeList(context)
    }

    fun canCraftSelectedRecipe(): Boolean {
        val selectedRecipe = getSelectedRecipe() ?: return false
        return GameEngine.getMaximumCraftable(ActorStateManager.playerId, selectedRecipe) > 0
    }

    fun canCraftMultiple(): Boolean {
        val selectedRecipe = getSelectedRecipe() ?: return false
        return InventoryItems[selectedRecipe.output].isStackable()
    }

    fun drawRecipeInputItems(uiState: UiState) {
        val menu = uiState.latestMenu ?: return
        val menuOffset = uiState.latestPosition ?: return
        val recipe = getSelectedRecipe() ?: return

        recipe.input.forEachIndexed { index, input ->
            val itemInfo = InventoryItems[input]
            val offset = menuOffset + menu.elements[index].offset
            UiElementHelper.drawInventoryItemIcon(itemInfo = itemInfo, position = offset)
        }

        if (UiStateHelper.isFocus(uiState)) {
            val cursorIndex = uiState.cursorIndex

            val selectedItem = if (cursorIndex >= 8) {
                InventoryItems[recipe.output]
            } else if (cursorIndex < recipe.input.size) {
                InventoryItems[recipe.input[cursorIndex]]
            } else {
                return
            }

            drawItemPreview(selectedItem)
        }
    }

    fun drawItemControl(uiState: UiState) {
        quantityInput.draw(uiState)
    }

    private fun drawRecipeList(uiState: UiState) {
        val stackPos = uiState.latestPosition ?: return
        val offset = Vector2f(0f, 0f)

        val recipes = getCurrentRecipes()

        val scrollSettings = uiState.scrollSettings!!
        for (i in scrollSettings.lowestViewableItemIndex until scrollSettings.lowestViewableItemIndex + scrollSettings.numElementsInPage) {
            if (i >= recipes.size) { break }

            val itemInfo = InventoryItems[recipes[i].output]

            val color = if (GameEngine.getMaximumCraftable(ActorStateManager.playerId, recipes[i]) > 0) {
                UiElementHelper.getStandardTextColor(1)
            } else {
                UiElementHelper.getStandardTextColor(0)
            }

            UiElementHelper.drawInventoryItemIcon(itemInfo = itemInfo, position = offset + stackPos + Vector2f(2f, 4f), scale = Vector2f(0.5f, 0.5f))
            UiElementHelper.drawString(text = itemInfo.name, offset = offset + stackPos + Vector2f(22f, 8f), color = color)
            offset.y += 18f
        }

        if (UiStateHelper.isFocus(uiState)) {
            val selectedRecipe = getSelectedRecipe() ?: return
            val outputItem = InventoryItems[selectedRecipe.output]
            drawItemPreview(outputItem)
        }
    }

    private fun getSelectedRecipeIndex(): Int {
        val context = UiStateHelper.synthesisRecipeContext
        return context.cursorIndex + context.scrollSettings!!.lowestViewableItemIndex
    }

    private fun getCurrentRecipes(): List<SynthesisRecipe> {
        return SynthesisRecipes.getAvailableRecipes(UiStateHelper.synthesisType)
    }

    private fun drawItemPreview(itemInfo: InventoryItemInfo) {
        val fakeItem = InventoryItem(itemInfo.itemId, quantity = 1)
        InventoryUi.drawSelectedInventoryItem(fakeItem, context = UiStateHelper.synthesisRecipeContext)
    }

}