package xim.poc.game.configuration.v0

import xim.math.Vector3f
import xim.poc.ActorId
import xim.poc.ActorManager
import xim.poc.ModelLook
import xim.poc.NoOpActorController
import xim.poc.audio.SystemSound
import xim.poc.game.*
import xim.poc.game.configuration.*
import xim.poc.game.event.InitialActorState
import xim.poc.gl.ByteColor
import xim.poc.ui.InventoryUi
import xim.poc.ui.ShiftJis
import xim.resource.DatId
import xim.resource.InventoryItems

enum class TreasureChestLook(val look: ModelLook) {
    Blue(ModelLook.npc(0x3c5)),
    BrownSimple(ModelLook.npc(0x3c6)),
    Brown(ModelLook.npc(0x3c7)),
    Red(ModelLook.npc(0x3c8)),
    Gold(ModelLook.npc(0x3c9)),
    Dark(ModelLook.npc(0x979)),
    Rainbow(ModelLook.npc(0x97A)),
}

data class SpawnerChestDefinition(val position: Vector3f, val itemIds: Map<Int, WeightedTable<ItemDropSlot>>, val treasureChestLook: TreasureChestLook)

class SpawnerChest(val chestDefinition: SpawnerChestDefinition, val monsterSpawnerInstance: MonsterSpawnerInstance) {

    private var id: ActorId? = null

    val itemRequirements = HashMap<InternalItemId, Int>()

    init {
        spawnChest()
    }

    fun update(elapsedFrames: Float) {
        val chestState = ActorStateManager[id] ?: return
        if (chestState.inventory.inventoryItems.isEmpty()) { onEmpty(chestState) }
    }

    fun clear() {
        GameEngine.submitDeleteActor(id)
    }

    private fun spawnChest() {
        val inventory = Inventory()

        for ((requirement, chestSlot) in chestDefinition.itemIds) {
            val chestItem = chestSlot.getRandom()
            val item = GameState.getGameMode().generateItem(
                itemId = chestItem.itemId,
                quantity = chestItem.quantity,
                quality = chestItem.quality,
                augmentRank = chestItem.augmentRank,
            )

            itemRequirements[item.internalId] = requirement
            inventory.addItem(item, stack = false)
        }

        GameEngine.submitCreateActorState(
            InitialActorState(
                name = "Treasure Chest",
                type = ActorType.Npc,
                position = chestDefinition.position,
                modelLook = chestDefinition.treasureChestLook.look,
                movementController = NoOpActorController(),
                behaviorController = ActorBehaviors.NoAction,
                inventory = inventory,
            )
        ) {
            id = it.id
            it.renderState.effectColor = ByteColor.zero
            GameV0.interactionManager.registerInteraction(it.id, SpawnChestInteraction(this))
            it.onReadyToDraw { it.playRoutine(DatId.pop) }
        }
    }

    private fun onEmpty(actorState: ActorState) {
        if (actorState.isDead()) { return }
        actorState.setHp(0)

        val actor = ActorManager[actorState.id] ?: return
        actor.enqueueModelRoutine(DatId("clos"))
        actor.enqueueModelRoutine(DatId("ntoe"))
    }

}

class SpawnChestInteraction(val chest: SpawnerChest) : NpcInteraction {

    private val monsterSpawnerInstance = chest.monsterSpawnerInstance
    private var displayOpened = false

    override fun onInteraction(npcId: ActorId) {
        val inventory = inventory(npcId)
        if (inventory == null || inventory.inventoryItems.isEmpty()) { return }

        if (inventory.inventoryItems.any { hasMetRequirements(it) }) { onOpened(npcId) }

        val prompt = "Defeated: [${monsterSpawnerInstance.getTotalDefeated()}]"

        UiStateHelper.openQueryMode(
            prompt = prompt,
            options = getOptions(npcId),
            drawFn = { drawHoveredItem(npcId, it) },
        ) { handleSelection(npcId, it) }
    }

    private fun getOptions(npcId: ActorId): List<QueryMenuOption> {
        val inventory = inventory(npcId) ?: return emptyList()
        if (inventory.inventoryItems.isEmpty()) { return emptyList() }

        val options = ArrayList<QueryMenuOption>()

        if (inventory.inventoryItems.count { hasMetRequirements(it) } > 1) {
            options += QueryMenuOption("Take all.", -1)
        }

        inventory.inventoryItems.forEachIndexed { index, item ->
            val info = InventoryItems[item.id]
            val requirement = chest.itemRequirements[item.internalId] ?: 0

            val requirementText = if (hasMetRequirements(item)) {
                "${ShiftJis.colorItem}[$requirement]${ShiftJis.colorClear}"
            } else {
                "[$requirement]"
            }

            val qualityColor = AugmentHelper.getQualityColorDisplay(item)

            val quantityText = if (item.quantity > 1) {
                "x${item.quantity}"
            } else {
                ""
            }

            options += QueryMenuOption("$requirementText ${qualityColor}${info.name} ${quantityText}${ShiftJis.colorClear}", index)
        }

        return options
    }

    private fun handleSelection(npcId: ActorId, queryMenuOption: QueryMenuOption?): QueryMenuResponse {
        if (queryMenuOption == null) { return QueryMenuResponse.pop }
        val inventory = inventory(npcId) ?: return QueryMenuResponse.pop

        if (queryMenuOption.value == -1) {
            inventory.inventoryItems.filter { hasMetRequirements(it) }
                .forEach { claimItem(npcId, it) }
        } else {
            val item = inventory.inventoryItems.getOrNull(queryMenuOption.value) ?: return QueryMenuResponse.pop

            if (!hasMetRequirements(item)) {
                return QueryMenuResponse.noop.also { it.soundType = SystemSound.Invalid }
            }

            claimItem(npcId, item)
        }

        return QueryMenuResponse.pop
    }

    private fun inventory(npcId: ActorId): Inventory? {
        return ActorStateManager[npcId]?.inventory
    }

    private fun claimItem(npcId: ActorId, inventoryItem: InventoryItem) {
        GameClient.submitItemTransferEvent(npcId, ActorStateManager.playerId, inventoryItem)
    }

    private fun onOpened(npcId: ActorId) {
        if (displayOpened) { return }
        displayOpened = true
        ActorManager[npcId]?.playRoutine(DatId("open"))
    }

    private fun drawHoveredItem(npcId: ActorId, queryMenuOption: QueryMenuOption) {
        val inventory = inventory(npcId) ?: return
        val item = inventory.inventoryItems.getOrNull(queryMenuOption.value) ?: return
        InventoryUi.drawSelectedInventoryItem(item)
    }

    private fun hasMetRequirements(item: InventoryItem): Boolean {
        val requirement = chest.itemRequirements[item.internalId] ?: 0
        return monsterSpawnerInstance.getTotalDefeated() >= requirement
    }

}