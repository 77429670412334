package xim.poc.game.configuration.v0

import xim.math.Vector3f
import xim.poc.ActorId
import xim.poc.game.*
import xim.poc.game.configuration.*
import xim.poc.game.configuration.v0.DamageCalculator.getAutoAttackTypeResult
import xim.poc.game.configuration.v0.GameV0Helpers.getActorSkillTiers
import xim.poc.game.configuration.v0.GameV0Helpers.getAugmentDescription
import xim.poc.game.configuration.v0.GameV0Helpers.getItemLevel
import xim.poc.game.configuration.v0.GameV0Helpers.getPlayerLevelStatMultiplier
import xim.poc.game.configuration.v0.GameV0Helpers.getTrustMagic
import xim.poc.game.configuration.v0.GameV0Helpers.getTrustStats
import xim.poc.game.event.ActorEffectTickResult
import xim.poc.game.event.AutoAttackResult
import xim.poc.game.event.AutoAttackType
import xim.poc.game.event.Event
import xim.poc.tools.ZoneConfig
import xim.poc.ui.InventoryItemDescription
import xim.poc.ui.ShiftJis
import xim.resource.*
import xim.resource.table.AbilityInfoTable
import xim.resource.table.SpellInfoTable
import xim.util.Fps
import kotlin.math.abs
import kotlin.math.max
import kotlin.math.pow
import kotlin.math.roundToInt

private val gameConfiguration = GameConfiguration(
    gameModeId = "GameV0",
    startingZoneConfig = ZoneConfig(CustomSarutabaruta.definition.zoneId, startPosition = Vector3f(x=-165.44f,y=3.05f,z=-702.08f)),
    debugControlsEnabled = true
)

object GameV0: GameLogic {

    override val configuration = gameConfiguration

    val interactionManager = DynamicNpcInteractionManager()
    private var floorInstance: FloorInstance? = null

    override fun setup(): List<Event> {
        MonsterDefinitions.registerAll(V0MonsterDefinitions.definitions)
        for ((npcId, interaction) in CustomSarutabaruta.staticNpcInteractions) { interactionManager.registerInteraction(npcId, interaction) }

        CommonSkillAppliers.registerTrustAppliers()

        V0AbilityDefinitions.register()
        V0MobSkillDefinitions.register()
        V0SpellDefinitions.register()

        return emptyList()
    }

    override fun update(elapsedFrames: Float) {
        floorInstance?.update(elapsedFrames)
        interactionManager.update(elapsedFrames)
        GameV0SaveStateHelper.autoSave()
        CombatBonusAggregator.clear()
    }

    override fun onChangedZones(zoneConfig: ZoneConfig) {
        if (zoneConfig.zoneId != CustomSarutabaruta.definition.zoneId) { throw IllegalStateException("Unexpected zone: $zoneConfig") }
        resetFloor(newLogic = Floor1Definition.newInstance()) // TODO - delete me
    }

    override fun getNpcInteraction(actorId: ActorId): NpcInteraction? {
        return interactionManager.getInteraction(actorId)
    }

    override fun getActorSpellList(actorId: ActorId): List<SpellInfo> {
        val tiers = getActorSkillTiers(actorId)
        val spells = tiers.entries.flatMap { ActorSkillTiers.getSpellIds(it.key, it.value) }.map { SpellInfoTable[it] }
        val trusts = getTrustMagic(actorId)

        return spells + trusts
    }

    override fun getActorAbilityList(actorId: ActorId, abilityType: AbilityType): List<AbilityInfo> {
        val tiers = getActorSkillTiers(actorId)
        return tiers.entries.flatMap { ActorSkillTiers.getAbilityIds(it.key, it.value) }
            .map { AbilityInfoTable[it] }
            .filter { it.type == abilityType }
    }

    override fun canDualWield(actorState: ActorState): Boolean {
        val bonuses = CombatBonusAggregator.recompute(actorState)
        return bonuses.canDualWield
    }

    override fun getActorCombatStats(actorId: ActorId): CombatStats {
        val actor = ActorStateManager[actorId] ?: return CombatStats.defaultBaseStats
        val bonuses = CombatBonusAggregator[actor]

        var stats = if (actor.type == ActorType.Pc) {
            CombatStats.defaultBaseStats * getPlayerLevelStatMultiplier(actor.getMainJobLevel().level)
        } else if (actor.isGatheringNode()) {
            CombatStats.defaultBaseStats.copy(maxHp = 3)
        } else if (actor.monsterId != null) {
            MonsterDefinitions[actor.monsterId].baseCombatStats
        } else if (actor.trustId != null) {
            val owner = ActorStateManager[actor.owner] ?: return CombatStats.defaultBaseStats
            getTrustStats(summoner = owner, trust = actor)
        } else {
            CombatStats.defaultBaseStats
        }

        stats += bonuses.additiveStats.build()
        for ((stat, value) in bonuses.multiplicativeStats) { stats = stats.multiply(stat, value) }

        return stats
    }

    override fun getAbilityCost(abilityInfo: AbilityInfo): AbilityCost {
        return V0AbilityDefinitions.getCost(abilityInfo) ?: throw IllegalStateException("[${abilityInfo.index}] Cost not defined")
    }

    override fun getItemDescription(actorId: ActorId, inventoryItem: InventoryItem): InventoryItemDescription {
        val info = inventoryItem.info()
        val baseDescription = InventoryItemDescription.toDescription(inventoryItem)

        return if (info.itemType == InventoryItemType.Weapon || info.itemType == InventoryItemType.Armor) {
            val itemDefinition = ItemDefinitions[inventoryItem]
            var description = itemDefinition.toDescription()

            val augmentDescription = getAugmentDescription(inventoryItem)
            if (augmentDescription != null) { description += augmentDescription }

            val itemLevel = getItemLevel(inventoryItem)
            val itemLevelDescription = "< Item Level: $itemLevel >"

            baseDescription.copy(pages = listOf(description), itemLevel = itemLevelDescription)
        } else {
            baseDescription
        }
    }

    override fun getAutoAttackResult(attacker: ActorState, defender: ActorState): List<AutoAttackResult> {
        val attacks = ArrayList<AutoAttackResult>()

        attacks += getAutoAttackTypeResult(attacker, defender, AutoAttackType.Main)
        if (attacker.isDualWield()) {
            attacks += getAutoAttackTypeResult(attacker, defender, AutoAttackType.Sub)
        } else if (attacker.isHandToHand()) {
            attacks += getAutoAttackTypeResult(attacker, defender, AutoAttackType.H2H)
        }

        return attacks.take(8)
    }

    override fun getAutoAttackInterval(attacker: ActorState): Float {
        val mainSlot = attacker.getEquipment(EquipSlot.Main) ?: return Fps.secondsToFrames(3)
        var delay = ItemDefinitions[mainSlot].delay

        val bonuses = CombatBonusAggregator[attacker]
        var attackSpeed = bonuses.haste

        val subSlot = attacker.getEquipment(EquipSlot.Sub)
        if (subSlot != null && attacker.isDualWield()) {
            delay += ItemDefinitions[subSlot].delay
            attackSpeed += bonuses.dualWield
        }

        delay = (delay * 100f/(100f+attackSpeed)).roundToInt()
        return delay.toFloat()
    }

    override fun getAugmentRankPointGain(attacker: ActorState, defender: ActorState, inventoryItem: InventoryItem): Int {
        val itemLevel = getItemLevel(inventoryItem)
        val levelDifference = defender.getMainJobLevel().level - itemLevel
        return if (levelDifference >= 0) {
            10 * (levelDifference+1)
        } else {
            10 / (abs(levelDifference)+1)
        }
    }

    override fun getExperiencePointGain(attacker: ActorState, defender: ActorState): Int {
        if (!attacker.isPlayer()) { return 0 }

        val monsterId = defender.monsterId ?: return 0

        val saveState = GameV0SaveStateHelper.getState()
        val previousValue = saveState.defeatedMonsterCounter[monsterId] ?: 0
        saveState.defeatedMonsterCounter[monsterId] = previousValue + 1

        val levelDelta = defender.getMainJobLevel().level - attacker.getMainJobLevel().level
        val baseExp = if (levelDelta >= 0) {
            20 + levelDelta * 5
        } else {
            20 / ((abs(levelDelta) + 1))
        }

        val monsterDefinition = MonsterDefinitions[monsterId]
        val nmBonus = if (monsterDefinition.notoriousMonster) { 5 } else { 1 }

        return baseExp * nmBonus
    }

    override fun getActorEffectTickResult(actorState: ActorState): ActorEffectTickResult {
        val bonuses = CombatBonusAggregator[actorState]
        return ActorEffectTickResult(hpDelta = bonuses.regen, mpDelta = bonuses.refresh)
    }

    override fun getItemReinforcementValues(targetItem: InventoryItem): Map<Int, Int> {
        return mapOf(9084 to 10, 9085 to 100)
    }

    override fun getSkillRangeInfo(skillType: SkillType, skillId: Int): SkillRangeInfo {
        if (skillType != SkillType.MobSkill) { throw IllegalStateException("Only mob-skill ranges have custom configuration") }
        return MobSkills[skillId]?.rangeInfo ?: SkillRangeInfo(maxTargetDistance = 10f, effectRadius = 0f, type = AoeType.None)
    }

    override fun getSpellCastTime(caster: ActorState, spellId: Int): Float {
        val spellInfo = SpellInfoTable[spellId]
        val baseCastTime = spellInfo.castTimeInFrames()

        val bonuses = CombatBonusAggregator[caster]
        val potency = bonuses.fastCast + bonuses.haste
        return baseCastTime * 100f / (100f + potency)
    }

    fun resetFloor(newLogic: FloorInstance) {
        floorInstance?.cleanUp()
        floorInstance = newLogic
    }

    override fun generateItem(itemId: Int, quantity: Int, quality: Int, augmentRank: Int): InventoryItem {
        val item = InventoryItem(id = itemId, quantity = quantity, internalQuality = quality)

        val itemDefinition = ItemDefinitions.getNullable(item) ?: return item
        if (itemDefinition.augmentSlots.isEmpty()) { return item }

        val augments = ItemAugment(rankLevel = augmentRank)
        item.augments = augments

        for (augmentSlot in itemDefinition.augmentSlots) {
            augments.augmentIds += augmentSlot.getRandom()
        }

        return item
    }

}

private object GameV0Helpers {

    fun getPlayerLevelStatMultiplier(level: Int): Float {
        return if (level <= 5) {
            1f + 0.2f * (level - 1)
        } else {
            2f * (1.09298f.pow(level - 6))
        }
    }

    fun getTrustStats(summoner: ActorState, trust: ActorState): CombatStats {
        val trustLevel = getTrustLevel(summoner, trust)
        val summonerChr = summoner.combatStats.chr

        val def = TrustDefinitions.definitionsById[trust.trustId] ?: throw IllegalStateException("[${trust.name}] Definition is not set")

        val chrBonus = def.chrScaling * (summonerChr * 0.5f * 0.01f)
        return (CombatStats.defaultBaseStats * getPlayerLevelStatMultiplier(trustLevel)) + chrBonus
    }

    private fun getTrustLevel(summoner: ActorState, trust: ActorState): Int {
        var trustLevel = 1

        for ((_, item) in summoner.getEquipment()) {
            item ?: continue
            val itemDefinition = ItemDefinitions[item]

            if (itemDefinition.trusts.any { it.trustId == trust.trustId }) {
                trustLevel = max(trustLevel, getItemLevel(item))
            }
        }

        trustLevel = trustLevel.coerceAtMost(summoner.getMainJobLevel().level)

        return trustLevel
    }

    fun getAugmentDescription(item: InventoryItem): String? {
        val augments = item.augments ?: return null

        val descriptions = StringBuilder()
        descriptions.append("${ShiftJis.colorInfo}")

        var idx = 1
        for (augmentId in augments.augmentIds) {
            if (idx != 1) { descriptions.appendLine() }

            val def = ItemAugmentDefinitions[augmentId]
            val value = def.valueFn.calculate(item)
            descriptions.append("[$idx] ${def.attribute.toDescription(value)}")

            idx += 1
        }

        descriptions.append("${ShiftJis.colorClear}")
        return descriptions.toString()
    }

    fun getItemLevel(inventoryItem: InventoryItem): Int {
        val augmentRank = inventoryItem.augments?.rankLevel ?: 1
        val definition = ItemDefinitions[inventoryItem]
        return definition.internalLevel + (augmentRank - 1)
    }

    fun getActorSkillTiers(actorId: ActorId): Map<ActorSkillType, Int> {
        val actorState = ActorStateManager[actorId] ?: return emptyMap()

        val equipment = actorState.getEquipment()
        val tiers = HashMap<ActorSkillType, Int>()

        for ((slot, item) in equipment) {
            if (item == null) { continue }
            val definition = ItemDefinitions.definitionsById[item.id] ?: continue

            for ((type, tier) in definition.skillTiers) {
                if (slot == EquipSlot.Sub && !type.subEligible) { continue }
                tiers[type] = tiers.getOrPut(type) { 0 } + tier
            }
        }

        return tiers
    }

    fun getTrustMagic(actorId: ActorId): List<SpellInfo> {
        val actorState = ActorStateManager[actorId] ?: return emptyList()

        val equipment = actorState.getEquipment()

        val trusts = HashSet<Int>()

        for ((_, item) in equipment) {
            if (item == null) { continue }
            val definition = ItemDefinitions.definitionsById[item.id] ?: continue
            trusts += definition.trusts.map { it.trustId }
        }

        return trusts.map { SpellInfoTable[it] }
    }

}

object CombatBonusAggregator {

    private val computed = HashMap<ActorId, CombatBonusAggregate>()

    fun clear() {
        computed.clear()
    }

    operator fun get(actorState: ActorState): CombatBonusAggregate {
        return computed.getOrPut(actorState.id) { computeActorCombatBonuses(actorState) }
    }

    fun recompute(actorState: ActorState): CombatBonusAggregate {
        val aggregate = computeActorCombatBonuses(actorState)
        computed[actorState.id] = aggregate
        return aggregate
    }

    private fun computeActorCombatBonuses(actor: ActorState): CombatBonusAggregate {
        val aggregate = CombatBonusAggregate()
        aggregateStatusEffects(actor, aggregate)
        aggregateEquipmentStats(actor, aggregate)
        aggregateEquipmentTraitEffects(actor, aggregate)
        aggregateEquipmentAugmentEffects(actor, aggregate, typeFilter = AugmentSubType.None)

        val owner = ActorStateManager[actor.owner]
        if (actor.trustId != null && owner != null) {
            aggregateEquipmentAugmentEffects(owner, aggregate, typeFilter = AugmentSubType.Trust)
        }

        return aggregate
    }

    private fun aggregateEquipmentAugmentEffects(actorState: ActorState, aggregate: CombatBonusAggregate, typeFilter: AugmentSubType) {
        for ((_, item) in actorState.getEquipment()) {
            if (item == null) { continue }
            addEquipmentAugmentEffects(item, aggregate, typeFilter)
        }
    }

    private fun addEquipmentAugmentEffects(item: InventoryItem, aggregate: CombatBonusAggregate, typeFilter: AugmentSubType) {
        val augments = item.augments ?: return
        for (augmentId in augments.augmentIds) {
            val def = ItemAugmentDefinitions[augmentId]
            if (def.attribute.type != typeFilter) { continue }
            aggregate.aggregate(def.attribute.augment, def.valueFn.calculate(item))
        }
    }

    private fun aggregateEquipmentTraitEffects(actorState: ActorState, aggregate: CombatBonusAggregate) {
        val skillLevels = getActorSkillTiers(actorState.id)
        for ((skillType, skillLevel) in skillLevels) {
            ActorSkillTiers.aggregateTraitBonuses(skillType, skillLevel, aggregate)
        }
    }

    private fun aggregateEquipmentStats(actor: ActorState, aggregate: CombatBonusAggregate) {
        val equipment = actor.getEquipment()

        for ((_, item) in equipment) {
            item ?: continue
            val itemDefinition = ItemDefinitions[item]
            aggregate.aggregate(itemDefinition.combatStats)
        }
    }

    private fun aggregateStatusEffects(actor: ActorState, aggregate: CombatBonusAggregate) {
        val statuses = actor.getStatusEffects()
        statuses.forEach { aggregate.aggregate(it) }
    }

}