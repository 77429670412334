package xim.poc.game.configuration

import xim.math.Vector3f
import xim.poc.ActorManager
import xim.poc.game.ActorState
import xim.poc.game.ActorStateManager
import xim.poc.game.GameEngine
import xim.poc.game.event.AutoAttackEvent
import xim.poc.game.event.Event
import xim.resource.table.MobSkillInfoTable
import xim.util.Fps

typealias BehaviorId = Int

class AutoAttackState(val actorState: ActorState) {

    private val attackRange = Pair(0.5f, 4f)
    private var timeUntilAttack = 0f

    init { resetAutoAttack() }

    fun update(elapsedFrames: Float) {
        timeUntilAttack -= elapsedFrames

        val actor = ActorManager[actorState.id]
        if (actor != null && actor.isMovementOrAnimationLocked()) {
            timeUntilAttack = timeUntilAttack.coerceAtLeast(Fps.millisToFrames(100f))
        }

        if (!canAutoAttack()) {
            timeUntilAttack = timeUntilAttack.coerceAtLeast(Fps.secondsToFrames(2f))
        }
    }

    fun isReadyToAutoAttack(): Boolean {
        return timeUntilAttack <= 0f && canAutoAttack()
    }

    fun resetAutoAttack() {
        timeUntilAttack = GameEngine.getAutoAttackRecast(actorState)
    }

    private fun canAutoAttack(): Boolean {
        return actorState.isEngaged() && isInAttackRange() && !actorState.isDead() && !actorState.isCasting()
    }

    private fun isInAttackRange(): Boolean {
        val targetState = ActorStateManager[actorState.targetState.targetId] ?: return false
        val distance = Vector3f.distance(actorState.position, targetState.position)

        return if (actorState.isPlayer()) {
            distance >= attackRange.first && distance <= attackRange.second
        } else {
            distance <= attackRange.second
        }
    }

}


interface ActorBehaviorController {
    fun update(elapsedFrames: Float): List<Event>
}

class NoOpBehaviorController: ActorBehaviorController {
    override fun update(elapsedFrames: Float): List<Event> {
        return emptyList()
    }
}

class AutoAttackController(val actorState: ActorState): ActorBehaviorController {

    private val autoAttackState = AutoAttackState(actorState)

    override fun update(elapsedFrames: Float): List<Event> {
        autoAttackState.update(elapsedFrames)
        if (!autoAttackState.isReadyToAutoAttack()) { return emptyList() }

        autoAttackState.resetAutoAttack()
        return listOf(AutoAttackEvent(actorState.id))
    }

}

class BasicMonsterController(val actorState: ActorState): ActorBehaviorController {

    private val autoAttackDelegate = AutoAttackController(actorState)
    private var usedSkill = false

    override fun update(elapsedFrames: Float): List<Event> {
        if (eligibleToUseSkill()) {
            usedSkill = true
            useSkill()
            return emptyList()
        }

        return autoAttackDelegate.update(elapsedFrames)
    }

    private fun eligibleToUseSkill(): Boolean {
        return !usedSkill && actorState.getHpp() <= 0.5f
    }

    private fun useSkill() {
        val defn = MonsterDefinitions[actorState.monsterId] ?: return
        val randomSkillId = defn.mobSkills.randomOrNull() ?: return
        val mobSkillInfo = MobSkillInfoTable[randomSkillId] ?: return

        val targetFilter = mobSkillInfo.targetFilter
        val targetId = (if (targetFilter.targetFilter(actorState.id, actorState.id)) {
            actorState.id
        } else if (targetFilter.targetFilter(actorState.id, actorState.targetState.targetId)) {
            actorState.targetState.targetId
        } else {
            null
        }) ?: return

        GameEngine.useMobSkill(mobSkillInfo, actorState.id, targetId)
    }

}

object ActorBehaviors {

    const val NoAction: BehaviorId = 0
    const val AutoAttackOnly: BehaviorId = 1
    const val BasicMonsterController: BehaviorId = 2

    private const val trustStart = 896

    fun createController(behaviorId: BehaviorId, actorState: ActorState): ActorBehaviorController {
        if (behaviorId >= trustStart) {
            return when (behaviorId) {
                898 -> HealerBehavior(actorState)
                902 -> CurillaBehavior(actorState)
                911, 940, 1014 -> RangedAttackerBehavior(actorState)
                else -> AutoAttackController(actorState)
            }
        }

        return when (behaviorId) {
            NoAction -> NoOpBehaviorController()
            AutoAttackOnly -> AutoAttackController(actorState)
            BasicMonsterController -> BasicMonsterController(actorState)
            else -> throw IllegalStateException("Unconfigured behavior for [${actorState.name}]: $behaviorId")
        }
    }

}