package xim.poc.game.configuration.assetviewer

import xim.poc.game.GameEngine
import xim.poc.game.StatusEffect
import xim.poc.game.configuration.CommonSkillAppliers.weaponSkillValidator
import xim.poc.game.configuration.SkillApplier
import xim.poc.game.configuration.SkillApplierHelper.TargetEvaluator
import xim.poc.game.configuration.SkillType
import xim.poc.game.event.*
import xim.resource.AbilityInfo
import xim.resource.DatId
import xim.resource.SpellInfo
import xim.resource.table.SpellNameTable
import kotlin.math.roundToInt
import kotlin.time.Duration

data class PotencyConfiguration(val potency: Float)

object AssetViewerSkillApplierHelpers {

    fun basicPhysicalDamage(config: PotencyConfiguration): TargetEvaluator {
        return TargetEvaluator {
            val damageAmount = (5 * config.potency).roundToInt()
            val outputEvents = ActorDamagedEvent(sourceId = it.sourceState.id, targetId = it.targetState.id, amount = damageAmount, actionContext = it.context)
            listOf(outputEvents)
        }
    }

    fun basicElementDamage(config: PotencyConfiguration): TargetEvaluator {
        return TargetEvaluator {
            var magicBurstBonus = GameEngine.getMagicBurstBonus(it.sourceState, it.primaryTargetState, it.skillInfo.id)
            magicBurstBonus = if (magicBurstBonus != null) { it.context.magicBurst = true; magicBurstBonus } else { 1f }

            val damageAmount = (5 * config.potency * magicBurstBonus).roundToInt()
            val outputEvents = ActorDamagedEvent(sourceId = it.sourceState.id, targetId = it.targetState.id, amount = damageAmount, actionContext = it.context)
            listOf(outputEvents)
        }
    }

    fun basicHealingMagic(config: PotencyConfiguration): TargetEvaluator {
        return TargetEvaluator {
            val healAmount = (5 * config.potency).roundToInt()
            val outputEvents = ActorHealedEvent(sourceId = it.sourceState.id, targetId = it.targetState.id, amount = healAmount, actionContext = it.context)
            listOf(outputEvents)
        }
    }

    fun sourceHpPercentDamage(config: PotencyConfiguration): TargetEvaluator {
        return TargetEvaluator {
            val damageAmount = (it.sourceState.getHp() * config.potency).roundToInt()
            val outputEvents = ActorDamagedEvent(sourceId = it.sourceState.id, targetId = it.targetState.id, amount = damageAmount, actionContext = it.context)
            listOf(outputEvents)
        }
    }

    fun basicEnhancingMagicStatusEffect(status: StatusEffect, baseDuration: Duration? = null): TargetEvaluator {
        return TargetEvaluator {
            it.sourceState.gainStatusEffect(status, baseDuration)
            emptyList()
        }
    }

    fun summonLuopan(spellInfo: SpellInfo): TargetEvaluator {
        return TargetEvaluator {
            val offenseOffset = if (it.targetState.isEnemy()) { 0x8 } else { 0x0 }
            val lookId = 0xB22 + spellInfo.element.index + offenseOffset
            listOf(
                PetReleaseEvent(it.sourceState.id),
                PetSummonEvent(it.sourceState.id, lookId = lookId, name = "Luopan", entranceAnimation = DatId.pop, stationary = true),
            )
        }
    }

    fun summonBubble(spellInfo: SpellInfo): TargetEvaluator {
        return TargetEvaluator {
            listOf(BubbleReleaseEvent(it.sourceState.id), BubbleGainEvent(it.sourceState.id, spellInfo.index),)
        }
    }

    fun summonPet(lookId: Int): TargetEvaluator {
        return TargetEvaluator {
            val name = SpellNameTable.first(it.skillInfo.id)
            listOf(PetReleaseEvent(it.sourceState.id), PetSummonEvent(it.sourceState.id, name = name, lookId = lookId))
        }
    }

    fun releasePet(): TargetEvaluator {
        return TargetEvaluator {
            listOf(PetReleaseEvent(it.sourceState.id))
        }
    }

}