package xim.poc.game

import xim.poc.Actor
import xim.poc.ActorId
import xim.poc.ModelLook
import xim.poc.game.configuration.SynthesisRecipe
import xim.poc.game.event.*
import xim.poc.tools.DestinationZoneConfig
import xim.resource.AbilityInfo
import xim.resource.DatId
import xim.resource.EquipSlot
import xim.resource.SpellInfo
import xim.resource.table.MobSkillInfo

object GameClient {

    fun submitUpdateBaseLook(actor: ActorId, modelLook: ModelLook) {
        GameEngine.submitEvent(ActorUpdateBaseLookEvent(actor, modelLook))
    }

    fun submitPlayerEngage() {
        val player = ActorStateManager.player()
        val targetId = player.targetState.targetId ?: return
        GameEngine.submitEvent(BattleEngageEvent(player.id, targetId))
    }

    fun submitPlayerDisengage() {
        GameEngine.submitEvent(BattleDisengageEvent(ActorStateManager.playerId))
    }

    fun submitStartCasting(source: ActorId, target: ActorId, spellInfo: SpellInfo) {
        GameEngine.submitEvent(CastSpellStart(source, target, spellInfo.index))
    }

    fun submitStartRangedAttack(source: ActorId, target: ActorId) {
        GameEngine.submitEvent(CastRangedAttackStart(source, target))
    }

    fun submitStartUsingItem(sourceId: ActorId, target: ActorId, inventoryItem: InventoryItem) {
        GameEngine.submitEvent(CastItemStart(sourceId, target, inventoryItem.internalId))
    }

    fun submitUseAbility(abilityInfo: AbilityInfo, sourceId: ActorId, targetId: ActorId) {
        GameEngine.submitEvent(CastAbilityStart(sourceId, targetId, abilityInfo.index))
    }

    fun submitUseMobSkill(mobSkillInfo: MobSkillInfo, sourceId: ActorId, targetId: ActorId) {
        GameEngine.submitEvent(CastMobSkillStart(sourceId, targetId, mobSkillInfo.id))
    }

    fun submitMountEvent(source: Actor, index: Int) {
        GameEngine.submitEvent(ActorMountEvent(source.id, index))
    }

    fun submitDismountEvent(source: Actor) {
        GameEngine.submitEvent(ActorDismountEvent(source.id))
    }

    fun submitTargetUpdate(sourceId: ActorId, targetId: ActorId?) {
        GameEngine.submitEvent(ActorTargetEvent(sourceId, targetId))
    }

    fun submitClearTarget(sourceId: ActorId) {
        submitTargetUpdate(sourceId, null)
    }
    
    fun submitReleaseTrust(id: ActorId, target: ActorId?) {
        target ?: return
        GameEngine.submitEvent(TrustReleaseEvent(id, target))
    }

    fun toggleResting(actor: ActorId) {
        val state = ActorStateManager[actor] ?: return
        if (state.isResting()) { stopResting(actor) } else { startResting(actor) }
    }

    private fun startResting(actor: ActorId) {
        GameEngine.submitEvent(RestingStartEvent(actor))
    }

    private fun stopResting(actor: ActorId) {
        GameEngine.submitEvent(RestingEndEvent(actor))
    }

    fun submitStartSynthesis(actor: ActorId, recipe: SynthesisRecipe?, quantity: Int) {
        recipe ?: return
        GameEngine.submitEvent(SynthesisStartEvent(actor, recipe.id, quantity))
    }

    fun submitEquipItem(actor: ActorId, equipSlot: EquipSlot, internalItemId: InternalItemId?) {
        GameEngine.submitEvent(ActorEquipEvent(actor, mapOf(equipSlot to internalItemId)))
    }

    fun submitRequestZoneChange(destinationZoneConfig: DestinationZoneConfig) {
        GameEngine.submitEvent(ChangeZoneEvent(ActorStateManager.playerId, destinationZoneConfig))
    }

    fun toggleTargetLock(sourceId: ActorId) {
        val state = ActorStateManager[sourceId] ?: return
        GameEngine.submitEvent(ActorTargetEvent(sourceId, state.targetState.targetId, !state.targetState.locked))
    }

    fun submitChangeJob(sourceId: ActorId, mainJob: Job? = null, subJob: Job? = null) {
        GameEngine.submitEvent(ChangeJobEvent(sourceId, mainJob?.index, subJob?.index))
    }

    fun submitDiscardItem(actor: ActorId, item: InventoryItem) {
        GameEngine.submitEvent(InventoryDiscardEvent(actor, item.internalId))
    }

    fun submitInventorySort(actor: ActorId) {
        GameEngine.submitEvent(InventorySortEvent(actor))
    }

    fun submitOpenDoor(actor: ActorId, doorId: DatId) {
        GameEngine.submitEvent(DoorOpenEvent(actor, doorId))
    }

    fun submitGatheringAttempt(actor: ActorId, gatheringNode: ActorId) {
        GameEngine.submitEvent(GatheringEvent(actor, gatheringNode))
    }

    fun submitItemTransferEvent(sourceId: ActorId, destinationId: ActorId, item: InventoryItem) {
        GameEngine.submitEvent(InventoryItemTransferEvent(sourceId, destinationId, item.internalId))
    }

}