package xim.poc.game.event

import xim.poc.ActorId
import xim.poc.game.ActorStateManager

class RestingStartEvent(
    val sourceId: ActorId
): Event {

    override fun apply(): List<Event> {
        val actorState = ActorStateManager[sourceId] ?: return emptyList()
        if (!actorState.isIdle()) { return emptyList() }

        actorState.getRestingState().startResting()
        return emptyList()
    }

}