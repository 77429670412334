package xim.poc.game.configuration.v0

import xim.math.Vector3f
import xim.poc.EquipmentLook
import xim.poc.ModelLook
import xim.poc.RaceGenderConfig
import xim.poc.game.configuration.CustomZoneDefinition
import xim.poc.game.configuration.basicNpc
import xim.resource.table.ZoneNpcList
import xim.resource.table.ZoneSettings

object CustomSarutabaruta {

    private const val zoneId = 0x1001

    private val conflux00 = basicNpc(zoneId, npcId = 0x001, name = "Conflux #00", npcLook = 0x0962, position = Vector3f(x=-87.57f,y=-4.85f,z=-559.39f))
    private val conflux01 = basicNpc(zoneId, npcId = 0x002, name = "Conflux #01", npcLook = 0x0962, position = Vector3f(x=-127.94f,y=-4.94f,z=-438.77f))
    private val confluxInteraction = ConfluxInteraction(listOf(conflux00, conflux01))

    private val entrance = basicNpc(zoneId, npcId = 0x009, name = "Entrance", npcLook = 0x0975, position = Vector3f(x=-155.40f,y=4.02f,z=-684.68f))
    private val entranceInteraction = EntranceInteraction(destination = Vector3f(x=-87.57f,y=-4.85f,z=-560.39f))

    private val stylist = basicNpc(zoneId, npcId = 0x00A, name = "Stylist", look = ModelLook.pc(RaceGenderConfig.HumeChildF, EquipmentLook()), position = Vector3f(x=-162.69f,y=4.00f,z=-682.73f))
    private val augmenter = basicNpc(zoneId, npcId = 0x00B, name = "Augment Book", look = ModelLook.npc(0x928), position = Vector3f(x=-182.73f,y=3.00f,z=-711.55f))

    private val reinforcementBook = basicNpc(zoneId, npcId = 0x00C, name = "Reinforcement Book", look = ModelLook.npc(0x928), position = Vector3f(x=-180.73f,y=3.00f,z=-711.55f))
    private val qualityBook = basicNpc(zoneId, npcId = 0x00D, name = "Quality Book", look = ModelLook.npc(0x928), Vector3f(x=-181.59f,y=2.90f,z=-713.58f))
    private val scrapBook = basicNpc(zoneId, npcId = 0x00E, name = "Scrap Book", look = ModelLook.npc(0x928), Vector3f(x=-183.29f,y=2.95f,z=-713.30f))

    private val staticNpcs = listOf(entrance, conflux00, conflux01, stylist, augmenter, reinforcementBook, qualityBook, scrapBook)

    private val staticNpcList = ZoneNpcList(
        resourceId = "",
        npcs = staticNpcs,
        npcsByDatId = emptyMap(),
    )

    val staticNpcInteractions = mapOf(
        entrance.actorId to entranceInteraction,
        conflux00.actorId to confluxInteraction,
        conflux01.actorId to confluxInteraction,
        stylist.actorId to StylistInteraction,
        augmenter.actorId to AugmentDebugger,
        reinforcementBook.actorId to ItemReinforcementInteraction,
        qualityBook.actorId to QualityUpgradeInteraction,
        scrapBook.actorId to ScrapInventoryItemInteraction,
    )

    val definition = CustomZoneDefinition(
        zoneId = zoneId,
        zoneMapId = 116,
        displayName = "Custom Sarutabaruta",
        zoneResourcePath = "ROM/1/Custom.DAT",
        zoneSettings = ZoneSettings(zoneId, musicId = 51, battleSoloMusicId = 52, battlePartyMusicId = 52),
        staticNpcList = staticNpcList,
    )

}