package xim.poc.game.event

import xim.poc.ActorId
import xim.poc.ActorManager
import xim.poc.game.ActorStateManager
import xim.poc.game.CastingState
import xim.poc.game.InternalItemId

class CastItemStart(
    val sourceId: ActorId,
    val targetId: ActorId,
    val internalItemId: InternalItemId,
): Event {

    override fun apply(): List<Event> {
        val actorState = ActorStateManager[sourceId] ?: return emptyList()
        if (!actorState.isIdleOrEngaged()) { return emptyList() }

        val item = actorState.inventory.getByInternalId(internalItemId) ?: return emptyList()
        val inventoryItemInfo = item.info()

        val current = actorState.getCastingState()
        if (current != null && !current.isComplete()) { return emptyList() }

        val castTime = inventoryItemInfo.usableItemInfo.castTimeInFrames()

        actorState.setCastingState(CastingState.item(castTime = castTime, sourceId = sourceId, targetId = targetId, item = item))

        ActorManager[sourceId]?.startUsingItem(inventoryItemInfo, targetId)

        return emptyList()
    }

}
