package xim.poc.game.configuration.v0

import xim.poc.game.configuration.GatheringConfiguration
import xim.poc.game.configuration.GatheringNodeSpawner
import xim.poc.game.configuration.MonsterSpawnerDefinition
import xim.poc.game.configuration.MonsterSpawnerInstance

class FloorDefinition(
    val gatheringPoints: List<GatheringConfiguration>,
    val monsterSpawnerDefinitions: List<MonsterSpawnerDefinition>,
    val bossSpawners: List<BossSpawnerDefinition>,
) {

    fun newInstance(): FloorInstance {
        val floorInstance = FloorInstance()

        for (gatheringPoint in gatheringPoints) {
            floorInstance.addGatheringNodeSpawner(gatheringPoint)
        }

        for (monsterSpawnerDef in monsterSpawnerDefinitions) {
            floorInstance.addMonsterSpawner(monsterSpawnerDef)
        }

        for (bossSpawnerDef in bossSpawners) {
            floorInstance.addBossSpawner(bossSpawnerDef)
        }

        return floorInstance
    }

}

class FloorInstance {

    private val monsterSpawners = ArrayList<MonsterSpawnerInstance>()
    private val gatheringPointSpawners = ArrayList<GatheringNodeSpawner>()
    private val treasureChests = ArrayList<SpawnerChest>()
    private val bossSpawners = ArrayList<BossSpawner>()

    fun update(elapsedFrames: Float) {
        bossSpawners.forEach { it.update(elapsedFrames) }
        gatheringPointSpawners.forEach { it.update(elapsedFrames) }
        monsterSpawners.forEach { it.update(elapsedFrames) }
        treasureChests.forEach { it.update(elapsedFrames) }
    }

    fun cleanUp() {
        monsterSpawners.forEach { it.clear() }
        gatheringPointSpawners.forEach { it.clear() }
        bossSpawners.forEach { it.clear() }
        treasureChests.forEach { it.clear() }
    }

    fun addMonsterSpawner(monsterSpawnerDefinition: MonsterSpawnerDefinition) {
        val instance = MonsterSpawnerInstance(monsterSpawnerDefinition)
        monsterSpawners += instance

        if (monsterSpawnerDefinition.chestDefinition != null) {
            treasureChests += SpawnerChest(monsterSpawnerDefinition.chestDefinition, instance)
        }
    }

    fun addGatheringNodeSpawner(gatheringConfiguration: GatheringConfiguration) {
        gatheringPointSpawners += GatheringNodeSpawner(gatheringConfiguration)
    }

    fun addBossSpawner(bossSpawnerDefinition: BossSpawnerDefinition) {
        bossSpawners += BossSpawner(bossSpawnerDefinition)
    }

}