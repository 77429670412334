package xim.poc.game.event

import xim.poc.ActorId
import xim.poc.game.ActorStateManager
import xim.poc.game.AugmentHelper
import xim.poc.game.InternalItemId
import xim.poc.ui.ChatLog
import xim.poc.ui.ShiftJis

class InventoryItemQualityUpgradeEvent(
    val sourceId: ActorId,
    val itemId: InternalItemId,
): Event {

    override fun apply(): List<Event> {
        val source = ActorStateManager[sourceId] ?: return emptyList()
        val inventoryItem = source.inventory.getByInternalId(itemId) ?: return emptyList()

        val augment = inventoryItem.augments ?: return emptyList()
        if (!AugmentHelper.isMaxRank(augment)) { return emptyList() }

        augment.rankLevel = 0
        inventoryItem.internalQuality += 1

        if (source.isPlayer()) {
            ChatLog("${ShiftJis.colorAug}[${inventoryItem.info().name}] Quality ${ShiftJis.rightArrow} +${inventoryItem.internalQuality}!${ShiftJis.colorClear}")
        }

        return emptyList()
    }

}