package xim.poc.game

import xim.poc.ActorId
import xim.poc.MainTool
import xim.poc.browser.Keyboard
import xim.poc.game.configuration.GameLogic
import xim.poc.game.configuration.NpcInteraction
import xim.poc.game.configuration.assetviewer.AssetViewer

object GameState {

    var gameSpeed = 1.0f
        private set

    private var gameLogic: GameLogic = AssetViewer

    fun update(elapsedFrames: Float) {
        gameLogic.update(elapsedFrames)
        updateGameSpeed()
    }

    fun getInteraction(actorId: ActorId): NpcInteraction? {
        return gameLogic.getNpcInteraction(actorId)
    }

    fun setGameMode(gameLogic: GameLogic) {
        this.gameLogic = gameLogic
    }

    fun getGameMode(): GameLogic {
        return gameLogic
    }

    fun isDebugMode(): Boolean {
        return gameLogic.configuration.debugControlsEnabled
    }

    private fun updateGameSpeed() {
        if (MainTool.platformDependencies.keyboard.isKeyPressed(Keyboard.Key.P)) {
            gameSpeed = if (gameSpeed < 1f) { 1f } else { 0f }
        } else if (MainTool.platformDependencies.keyboard.isKeyPressed(Keyboard.Key.Z)) {
            gameSpeed = if (gameSpeed < 1f) { 1f } else { 0.25f }
        }
    }

}