package xim.poc.game.configuration.v0

import xim.poc.game.configuration.*
import xim.poc.game.configuration.assetviewer.AssetViewerSkillApplierHelpers.basicHealingMagic
import xim.poc.game.configuration.assetviewer.AssetViewerSkillApplierHelpers.sourceHpPercentDamage
import xim.poc.game.configuration.assetviewer.PotencyConfiguration
import xim.poc.game.configuration.v0.behaviors.MobClusterBehavior
import xim.poc.game.event.ActorDamagedEvent
import xim.poc.game.event.AutoAttackType
import xim.resource.AoeType
import kotlin.math.roundToInt

class MobSkill(val id: Int, val rangeInfo: SkillRangeInfo)

object MobSkills {

    private val definitions = HashMap<Int, MobSkill>()

    fun register(mobSkill: MobSkill) {
        definitions[mobSkill.id] = mobSkill
    }

    operator fun plusAssign(mobSkill: MobSkill) = register(mobSkill)

    operator fun get(id: Int): MobSkill? {
        return definitions[id]
    }

}

object V0MobSkillDefinitions {

    fun register() {
        // Mandragora: Leaf Dagger
        MobSkills += MobSkill(id = 49, rangeInfo = SkillRangeInfo(16f, 5f, AoeType.Cone))
        SkillAppliers += SkillApplier(skillId = 49, skillType = SkillType.MobSkill,
            targetEvaluator = basicPhysicalDamage(potency = 2f),
        )

        // Bee: Sharp sting
        MobSkills += MobSkill(id = 78, rangeInfo = SkillRangeInfo(16f, 0f, AoeType.None))
        SkillAppliers += SkillApplier(skillId = 78, skillType = SkillType.MobSkill,
            targetEvaluator = basicPhysicalDamage(potency = 2f),
        )

        // Bee: Pollen
        MobSkills += MobSkill(id = 79, rangeInfo = SkillRangeInfo(1f, 0f, AoeType.None))
        SkillAppliers += SkillApplier(skillId = 79, skillType = SkillType.MobSkill,
            targetEvaluator = basicHealingMagic(PotencyConfiguration(potency = 3f)),
        )

        // Cluster: Self-Destruct (3 bombs -> 2 bombs)
        MobSkills += MobSkill(id = 315, rangeInfo = SkillRangeInfo(16f, 16f, AoeType.Source))
        SkillAppliers += MobClusterBehavior.selfDestruct(id = 315, destState = 1)

        // Cluster: Self-Destruct (3 bombs -> 0 bombs)
        MobSkills += MobSkill(id = 316, rangeInfo = SkillRangeInfo(16f, 16f, AoeType.Source))
        SkillAppliers += MobClusterBehavior.selfDestruct(id = 316, destState = null)

        // Cluster: Self-Destruct (2 bombs -> 1 bombs)
        MobSkills += MobSkill(id = 317, rangeInfo = SkillRangeInfo(16f, 16f, AoeType.Source))
        SkillAppliers += MobClusterBehavior.selfDestruct(id = 317, destState = 2)

        // Cluster: Self-Destruct (2 bombs -> 0 bombs)
        MobSkills += MobSkill(id = 318, rangeInfo = SkillRangeInfo(16f, 16f, AoeType.Source))
        SkillAppliers += MobClusterBehavior.selfDestruct(id = 318, destState = null)

        // Cluster: Self-Destruct (1 bombs -> 0 bombs)
        MobSkills += MobSkill(id = 319, rangeInfo = SkillRangeInfo(16f, 16f, AoeType.Source))
        SkillAppliers += MobClusterBehavior.selfDestruct(id = 319, destState = null)

        // Vulture: Hell Dive
        MobSkills += MobSkill(id = 366, rangeInfo = SkillRangeInfo(16f, 0f, AoeType.None))
        SkillAppliers += SkillApplier(skillId = 366, skillType = SkillType.MobSkill,
            targetEvaluator = basicPhysicalDamage(potency = 1.6f),
        )

        // Vulture: Wing Cutter
        MobSkills += MobSkill(id = 367, rangeInfo = SkillRangeInfo( 4f, 4f, AoeType.Cone))
        SkillAppliers += SkillApplier(skillId = 367, skillType = SkillType.MobSkill,
            targetEvaluator = basicPhysicalDamage(potency = 2.5f),
        )

        // Goblin: Normal bomb toss
        MobSkills += MobSkill(id = 335, rangeInfo = SkillRangeInfo(16f, 8f, AoeType.Target))
        SkillAppliers += SkillApplier(skillId = 335, skillType = SkillType.MobSkill,
            targetEvaluator = basicPhysicalDamage(potency = 1f),
        )

        // Goblin: dropped bomb toss
        MobSkills += MobSkill(id = 336, rangeInfo = SkillRangeInfo( 8f, 8f, AoeType.Source))
        SkillAppliers += SkillApplier(skillId = 336, skillType = SkillType.MobSkill,
            targetEvaluator = sourceHpPercentDamage(PotencyConfiguration(potency = 0.5f)),
            additionalSelfEvaluator = sourceHpPercentDamage(PotencyConfiguration(potency = 1f)),
        )
    }

    private fun basicPhysicalDamage(potency: Float): SkillApplierHelper.TargetEvaluator {
        return SkillApplierHelper.TargetEvaluator {
            val basicDamage = DamageCalculator.getAutoAttackDamage(it.sourceState, it.targetState, AutoAttackType.Main)
            val damageAmount = (basicDamage * potency).roundToInt()
            val outputEvents = ActorDamagedEvent(sourceId = it.sourceState.id, targetId = it.targetState.id, amount = damageAmount, actionContext = it.context)
            listOf(outputEvents)
        }
    }

}