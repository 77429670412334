package xim.poc.game

import xim.poc.Actor
import xim.poc.ActorId
import xim.poc.ActorManager

class Party(leader: ActorId) {

    private val members = mutableListOf(leader)

    fun addMember(actorId: ActorId) {
        members += actorId
    }

    fun removeMember(actorId: ActorId) {
        members.remove(actorId)
    }

    fun getIndex(actorId: ActorId): Int? {
        val index = members.indexOf(actorId)
        return if (index < 0) { null } else { index }
    }

    fun getByIndex(index: Int): Actor? {
        return ActorManager[members.getOrNull(index)]
    }

    fun getStateByIndex(index: Int): ActorState? {
        return ActorStateManager[members.getOrNull(index)]
    }

    fun contains(actor: Actor): Boolean {
        return contains(actor.id)
    }

    fun contains(actorId: ActorId): Boolean {
        return getIndex(actorId) != null
    }


    fun getAll(): List<Actor> {
        return members.mapNotNull { ActorManager[it] }
    }

    fun getAllState(): List<ActorState> {
        return members.mapNotNull { ActorStateManager[it] }
    }

    fun size(): Int {
        return members.size
    }

}

object PartyManager {

    private val playerParty by lazy { Party(ActorStateManager.playerId) }

    operator fun get(actor: Actor): Party {
        return get(actor.id)
    }

    operator fun get(actorId: ActorId): Party {
        val index = playerParty.getIndex(actorId)
        return if (index == null) { Party(actorId) } else { playerParty }
    }

    fun getOrCreate(leader: Actor): Party {
        if (leader != ActorManager.player()) { throw IllegalStateException("Making multiple parties???") }
        return playerParty
    }

}