package xim.poc.game.configuration.v0

import xim.poc.game.ActorState
import xim.poc.game.ActorType
import xim.poc.game.AttackContext
import xim.poc.game.configuration.MonsterDefinitions
import xim.poc.game.event.AutoAttackResult
import xim.poc.game.event.AutoAttackType
import xim.resource.EquipSlot
import kotlin.math.roundToInt
import kotlin.random.Random

object DamageCalculator {

    fun getWeaponPowerAndDelay(actor: ActorState, type: AutoAttackType): Pair<Int,Int>? {
        return if (actor.monsterId != null) {
            val monsterDefinition = MonsterDefinitions[actor.monsterId]
            Pair(monsterDefinition.baseDamage, monsterDefinition.baseDelay)
        } else if (actor.trustId != null) {
            val trustDefinition = TrustDefinitions[actor.trustId]
            Pair(trustDefinition.weapon.damage, trustDefinition.weapon.delay)
        } else if (actor.type == ActorType.Pc) {
            val item = when (type) {
                AutoAttackType.Main -> actor.getEquipment(EquipSlot.Main)
                AutoAttackType.Sub -> actor.getEquipment(EquipSlot.Sub)
                AutoAttackType.H2H -> actor.getEquipment(EquipSlot.Main)
            }

            if (item == null) {
                return if (type == AutoAttackType.Main) { Pair(3, 240) } else { null }
            }

            val itemDefinition = ItemDefinitions[item]
            Pair(itemDefinition.damage, itemDefinition.delay)
        } else {
            null
        }
    }

    fun getAutoAttackDamage(attacker: ActorState, defender: ActorState, weaponPower: Int): Float {
        val ratio = (attacker.combatStats.str.toFloat() / defender.combatStats.vit.toFloat()).coerceIn(0.1f, 10f)

        val defenderBonus = CombatBonusAggregator[defender]
        val physDmgReduction = (defenderBonus.toPercentBonus(defenderBonus.physicalDamageTaken)).coerceIn(0f, 1f)

        return ratio * weaponPower * physDmgReduction
    }

    fun getAutoAttackDamage(attacker: ActorState, defender: ActorState, type: AutoAttackType): Float {
        val (weaponPower, _) = getWeaponPowerAndDelay(attacker, type) ?: return 0f
        return getAutoAttackDamage(attacker, defender, weaponPower)
    }

    fun getHealAmount(caster: ActorState, target: ActorState, potency: Float, maxAmount: Int): Int {
        val (mainHand, _) = getWeaponPowerAndDelay(caster, AutoAttackType.Main) ?: Pair(1, 0)
        return (mainHand * caster.combatStats.mnd * potency).roundToInt().coerceAtMost(maxAmount)
    }

    fun getCriticalDamageMultiplier(attacker: ActorState, defender: ActorState): Float {
        val bonuses = CombatBonusAggregator[attacker]
        val bonus = bonuses.toPercentBonus(bonuses.criticalHitDamage)
        val ratio = attacker.combatStats.dex.toFloat() / defender.combatStats.agi.toFloat()
        return bonus * (1.15f * ratio).coerceIn(1.15f, 2.0f)
    }

    fun applyWeaponSkillBonus(attacker: ActorState, baseDamage: Int): Int {
        val bonuses = CombatBonusAggregator[attacker]
        val bonus = bonuses.toPercentBonus(bonuses.weaponSkillDamage)
        return (baseDamage * bonus).roundToInt()
    }

    fun getAutoAttackTypeResult(attacker: ActorState, defender: ActorState, type: AutoAttackType): List<AutoAttackResult> {
        val bonuses = CombatBonusAggregator[attacker]
        val doubleAttackChance = bonuses.doubleAttack

        val r = Random.nextDouble() * 100
        val numHits = if (r < doubleAttackChance) { 2 } else { 1 }

        return (0 until numHits).map { processAutoAttack(attacker, defender, type ) }
    }

    fun processAutoAttack(attacker: ActorState, defender: ActorState, type: AutoAttackType): AutoAttackResult {
        val context = AttackContext.from(attacker, defender)
        val bonuses = CombatBonusAggregator[attacker]

        val (power, delay) = getWeaponPowerAndDelay(attacker, type)
            ?: return AutoAttackResult(targetId = defender.id, context = context, tpGained = 0, damageDone = 0, type = type)

        val rawDamage = getAutoAttackDamage(attacker, defender, power)
        val criticalBonus = if (rollCriticalHit(attacker, defender)) {
            context.setCriticalHitFlag()
            getCriticalDamageMultiplier(attacker, defender)
        } else {
            1f
        }

        val damage = (rawDamage * criticalBonus * Random.nextDouble(1.0, 1.15)).roundToInt()

        val storeTpPotency = 1.0 + bonuses.storeTp/100.0
        val tpGained = (delay / 3.0 * storeTpPotency).roundToInt()

        return AutoAttackResult(targetId = defender.id, context = context, tpGained = tpGained, damageDone = damage, type = type)
    }

    fun rollCriticalHit(attacker: ActorState, defender: ActorState): Boolean {
        val attackerBonus = CombatBonusAggregator[attacker]
        val criticalHitRateBonus = attackerBonus.toPercentBonus(attackerBonus.criticalHitRate)

        val defenderBonus = CombatBonusAggregator[defender]
        val criticalDodgeBonus = defenderBonus.toPercentBonus(defenderBonus.enemyCriticalHitRate)

        val ratio = attacker.combatStats.dex.toFloat() / defender.combatStats.agi.toFloat()
        val chance = (0.1f * ratio * criticalHitRateBonus * criticalDodgeBonus).coerceIn(0.01f, 0.50f)
        return Random.nextFloat() <= chance
    }

    fun computeIntRatio(attacker: ActorState, defender: ActorState): Float {
        return attacker.combatStats.int.toFloat() / defender.combatStats.int.toFloat()
    }

}