package xim.poc.game.event

import xim.poc.ActorId
import xim.poc.game.ActorStateManager
import xim.poc.game.CastingState
import xim.poc.game.GameEngine
import xim.poc.ui.ChatLog
import xim.poc.ui.ChatLogColor
import xim.resource.table.AbilityInfoTable
import xim.resource.table.AbilityNameTable

class CastAbilityStart(
    val sourceId: ActorId,
    val targetId: ActorId,
    val abilityId: Int,
) : Event {

    override fun apply(): List<Event> {
        val actorState = ActorStateManager[sourceId] ?: return emptyList()
        if (!actorState.isIdleOrEngaged()) { return emptyList() }

        val abilityInfo = AbilityInfoTable[abilityId]
        if (!GameEngine.canBeginUseAbility(sourceId, abilityInfo)) { return emptyList() }

        val current = actorState.getCastingState()
        if (current != null && !current.isComplete()) { return emptyList() }

        val castingState = CastingState.ability(castTime = 0f, sourceId = sourceId, targetId = targetId, abilityInfo = abilityInfo)
        actorState.setCastingState(castingState)

        val abilityName = AbilityNameTable.first(abilityId)
        ChatLog.addLine("${actorState.name} readies $abilityName.", ChatLogColor.Action)

        return if (castingState.isDoneCharging()) {
            listOf(CastingChargeCompleteEvent(sourceId))
        } else {
            emptyList()
        }
    }

}