package xim.poc.game

import xim.math.Vector2f
import xim.poc.ActorManager
import xim.poc.MainTool
import xim.poc.RaySphereCollider
import xim.poc.UiElementHelper
import xim.poc.browser.ClickEvent
import xim.poc.camera.CameraReference

private class UiClickListener(val position: Vector2f, val size: Vector2f, val handler: (ClickEvent) -> Boolean)

object ClickHandler {

    private val listeners = ArrayList<UiClickListener>()

    fun registerUiClickHandler(position: Vector2f, size: Vector2f, handler: (ClickEvent) -> Boolean) {
        listeners += UiClickListener(position, size, handler)
    }

    fun handleClickEvents() {
        handleInternal()
        listeners.clear()
    }

    private fun handleInternal() {
        val clickEvent = MainTool.platformDependencies.keyboard.getClickEvents().lastOrNull() ?: return

        for (listener in listeners) {
            if (checkListener(listener, clickEvent)) {
                clickEvent.consumed = true
                break
            }
        }

        if (clickEvent.isLongClick()) {
            PlayerTargetSelector.tryDirectlyTarget(null)
        }

        val ray = CameraReference.getInstance().getWorldSpaceRay(clickEvent.normalizedScreenPosition) ?: return

        val visibleActors = ActorManager.getVisibleActors()
        for (actor in visibleActors) {
            val sphere = actor.getBoundingBox().toBoundingSphere()
            if (!RaySphereCollider.intersect(ray, sphere)) { continue }
            if (!PlayerTargetSelector.tryDirectlyTarget(actor)) { continue }
            clickEvent.consumed = true
            break
        }
    }

    private fun checkListener(listener: UiClickListener, clickEvent: ClickEvent): Boolean {
        val position = listener.position * UiElementHelper.globalUiScale
        val size = listener.size * UiElementHelper.globalUiScale

        val collides = position.x < clickEvent.screenPosition.x && (position.x + size.x) >= clickEvent.screenPosition.x
                && position.y < clickEvent.screenPosition.y && (position.y + size.y) >= clickEvent.screenPosition.y

        return if (collides) { listener.handler(clickEvent) } else { false }
    }

}