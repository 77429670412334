package xim.poc.game.event

import xim.poc.ActorId
import xim.poc.game.ActorStateManager
import xim.poc.game.AttackContext
import xim.poc.game.GameEngine
import kotlin.math.roundToInt

class ActorWeaponSkillEvent(
    val abilityId: Int,
    val startingTp: Int,
    val tpConsumed: Int,
    val sourceId: ActorId,
    val targetId: ActorId,
    val context: AttackContext,
): Event {

    override fun apply(): List<Event> {
        val source = ActorStateManager[sourceId] ?: return emptyList()
        val target = ActorStateManager[targetId] ?: return emptyList()

        val outputEvents = ArrayList<Event>()

        val potency = GameEngine.getWeaponSkillPotency(source, abilityId)
        val damageAmount = (source.getMainHandDamage() * potency).roundToInt()
        outputEvents += ActorDamagedEvent(sourceId, targetId, damageAmount, context)

        val skillChainAttributes = GameEngine.getWeaponSkillSkillChainAttributes(source, abilityId)
        val skillChainResult = target.skillChainTargetState.applyState(skillChainAttributes)

        if (skillChainResult != null) {
            outputEvents += ActorSkillChainEvent(sourceId, targetId, skillChainResult, damageAmount, context)
        }

        source.setTp(startingTp - tpConsumed)
        return outputEvents
    }

}