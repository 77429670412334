package xim.poc.tools

import xim.math.Vector2f
import xim.poc.ActorId
import xim.poc.Font
import xim.poc.TextAlignment
import xim.poc.UiElementHelper
import xim.poc.browser.Keyboard
import xim.poc.browser.Keyboard.Key
import xim.poc.browser.LocalStorage
import xim.poc.game.*
import xim.poc.game.configuration.v0.GameV0
import xim.poc.gl.Color
import xim.poc.ui.AbilitySelectUi
import xim.poc.ui.SpellSelectUi
import xim.resource.AbilityType
import xim.resource.EquipSlot
import xim.resource.TargetFlag
import xim.resource.table.AbilityInfoTable
import xim.resource.table.AbilityNameTable
import xim.resource.table.SpellInfoTable
import xim.resource.table.SpellNameTable

private enum class ActionType {
    Ability,
    Spell,
    EquipSet,
}

private enum class TargetType {
    Self,
    Target,
}

private class Macro(val id: Int, val actionType: ActionType, val targetType: TargetType, val shortCode: String)

private class MacroPalette {
    val macros: Array<Macro?> = Array(10) { null }
    val shiftMacros: Array<Macro?> = Array(10) { null }
    val altMacros: Array<Macro?> = Array(10) { null }
}

object MacroTool {

    private val buttonSize = Vector2f(32f, 32f)

    private val palettes = Array(10) { MacroPalette() }
    private var currentPalette = 0

    fun draw() {
        if (!isEnabled()) { return }

        val weaponSkills = GameState.getGameMode().getActorAbilityList(ActorStateManager.playerId, AbilityType.WeaponSkill)
        val wsMacros = weaponSkills.map { Macro(it.index, ActionType.Ability, TargetType.Target, AbilityNameTable[it.index].first().split(" ")[0].substring(0, 6)) }
        for (i in 0 until 10) { palettes[currentPalette].macros[i] = wsMacros.getOrNull(i) }

        val spells = GameState.getGameMode().getActorSpellList(ActorStateManager.playerId)
        val spellMacros = spells.map { Macro(it.index, ActionType.Spell, guessTargetType(it.targetFlags), SpellNameTable[it.index].first().split(" ")[0].substring(0, 6)) }
        for (i in 0 until 10) { palettes[currentPalette].altMacros[i] = spellMacros.getOrNull(i) }

        for (i in 0 until 10) {
            val equipmentSet = LocalStorage.getPlayerEquipmentSet(i) ?: continue
            equipmentSet.getItem(Inventory.player(), EquipSlot.Main) ?: continue
            palettes[currentPalette].shiftMacros[i] = Macro(id = i, actionType = ActionType.EquipSet, targetType = TargetType.Self, "")
        }

        val parentMenu = UiStateHelper.chatLogContext.latestMenu ?: return
        val parentPos = UiStateHelper.chatLogContext.latestPosition ?: return
        val basePosition = parentPos + Vector2f(parentMenu.frame.size.x + 8f, 0f)

        val palette = palettes[currentPalette]

        for (i in palette.macros.indices) {
            val macro = palette.macros[i] ?: continue
            drawMacro(macro, column = i, row = 0, basePosition = basePosition, macroName = "${(i+1)%10}")
        }

        for (i in palette.altMacros.indices) {
            val macro = palette.altMacros[i] ?: continue
            drawMacro(macro, column = i, row = 1, basePosition = basePosition, macroName = "a${(i+1)%10}")
        }

        for (i in palette.shiftMacros.indices) {
            val macro = palette.shiftMacros[i] ?: continue
            drawMacro(macro, column = i, row = 2, basePosition = basePosition, macroName = "s${(i+1)%10}")
        }
    }

    fun handleInput(keyboard: Keyboard) {
        if (!isEnabled()) { return }

        val (idx, modifiers) = when {
            keyboard.isKeyPressed(Key.N1) -> Pair(0, keyboard.getKeyModifiers(Key.N1))
            keyboard.isKeyPressed(Key.N2) -> Pair(1, keyboard.getKeyModifiers(Key.N2))
            keyboard.isKeyPressed(Key.N3) -> Pair(2, keyboard.getKeyModifiers(Key.N3))
            keyboard.isKeyPressed(Key.N4) -> Pair(3, keyboard.getKeyModifiers(Key.N4))
            keyboard.isKeyPressed(Key.N5) -> Pair(4, keyboard.getKeyModifiers(Key.N5))
            keyboard.isKeyPressed(Key.N6) -> Pair(5, keyboard.getKeyModifiers(Key.N6))
            keyboard.isKeyPressed(Key.N7) -> Pair(6, keyboard.getKeyModifiers(Key.N7))
            keyboard.isKeyPressed(Key.N8) -> Pair(7, keyboard.getKeyModifiers(Key.N8))
            keyboard.isKeyPressed(Key.N9) -> Pair(8, keyboard.getKeyModifiers(Key.N9))
            keyboard.isKeyPressed(Key.N0) -> Pair(9, keyboard.getKeyModifiers(Key.N0))
            else -> null
        } ?: return

        val macro = (if (modifiers.contains(Keyboard.KeyModifier.Alt)) {
            palettes[currentPalette].altMacros[idx]
        } else if (modifiers.contains(Keyboard.KeyModifier.Shift)) {
            palettes[currentPalette].shiftMacros[idx]
        } else {
            palettes[currentPalette].macros[idx]
        }) ?: return

        useMacro(macro)
    }

    private fun useMacro(macro: Macro) {
        val player = ActorStateManager.player()

        val target = when(macro.targetType) {
            TargetType.Self -> player.id
            TargetType.Target -> player.targetState.targetId
        } ?: return

        if (!canUseMacro(macro)) {
            return
        }

        when (macro.actionType) {
            ActionType.Ability -> castAbility(macro.id, target)
            ActionType.Spell -> castSpell(macro.id, target)
            ActionType.EquipSet -> useEquipSet(macro.id, target)
        }
    }

    private fun isEnabled(): Boolean {
        return GameState.getGameMode() == GameV0
    }

    private fun canUseMacro(macro: Macro): Boolean {
        return when (macro.actionType) {
            ActionType.Ability -> GameEngine.canBeginUseAbility(ActorStateManager.playerId, AbilityInfoTable[macro.id])
            ActionType.Spell -> GameEngine.canBeginCastSpell(ActorStateManager.playerId, SpellInfoTable[macro.id])
            ActionType.EquipSet -> true
        }
    }

    private fun castAbility(abilityId: Int, target: ActorId) {
        val abilityInfo = AbilityInfoTable[abilityId]
        GameClient.submitUseAbility(abilityInfo = abilityInfo, sourceId = ActorStateManager.playerId, targetId = target)
    }

    private fun castSpell(spellId: Int, target: ActorId) {
        val spellInfo = SpellInfoTable[spellId]
        GameClient.submitStartCasting(spellInfo = spellInfo, source = ActorStateManager.playerId, target = target)
    }

    private fun useEquipSet(equipSetId: Int, target: ActorId) {
        val equipment = LocalStorage.getPlayerEquipmentSet(equipSetId) ?: return
        for ((slot, item) in equipment.getAllItems()) { GameClient.submitEquipItem(ActorStateManager.playerId, slot, item) }
    }

    private fun drawMacro(macro: Macro, row: Int, column: Int, basePosition: Vector2f, macroName: String) {
        val (iconSet, iconId) = when (macro.actionType) {
            ActionType.Ability -> AbilitySelectUi.getAbilityIcon(AbilityInfoTable[macro.id])
            ActionType.Spell -> SpellSelectUi.getSpellIcon(SpellInfoTable[macro.id])
            ActionType.EquipSet -> Pair(null, null)
        }

        val mask = if (canUseMacro(macro)) { Color.ALPHA_75 } else { Color.ALPHA_25 }

        val position = basePosition + Vector2f(column * 44f, (2 - row) * 50f)

        if (iconSet != null && iconId != null) {
            UiElementHelper.drawUiElement(lookup = iconSet, index = iconId, scale = Vector2f(2f, 2f), position = position, color = mask)
        } else if (macro.actionType == ActionType.EquipSet) {
            val equipmentSet = LocalStorage.getPlayerEquipmentSet(macro.id) ?: return
            val item = equipmentSet.getItem(Inventory.player(), EquipSlot.Main) ?: return
            UiElementHelper.drawInventoryItemIcon(item, position = position, scale = Vector2f(1f, 1f))
        }

        val textPosition = Vector2f(position.x, position.y - 4f)
        UiElementHelper.drawString(text = "$macroName\n\n${macro.shortCode}", offset = textPosition, font = Font.FontShp, alignment = TextAlignment.Left)

        ClickHandler.registerUiClickHandler(position = position, size = buttonSize) { useMacro(macro); true }
    }

    private fun guessTargetType(targetFlags: Int): TargetType {
        return if (targetFlags and TargetFlag.Self.flag != 0) { TargetType.Self } else { TargetType.Target }
    }

}