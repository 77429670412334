package xim.poc.game.configuration

import xim.math.Vector3f
import xim.poc.ActorId
import xim.poc.ModelLook
import xim.poc.NoOpActorController
import xim.poc.game.*
import xim.poc.game.configuration.zones.SpawnArea
import xim.poc.game.configuration.zones.SpawnChestInteraction
import xim.poc.game.event.InitialActorState
import xim.resource.DatId
import xim.util.PI_f
import xim.util.RandHelper.rand
import kotlin.random.Random

data class WeightedMonsterDef(val monsterDefinition: MonsterDefinition, val weight: Double)

class MonsterTable(val definitions: List<WeightedMonsterDef>) {

    companion object {
        fun single(monsterDefinition: MonsterDefinition): MonsterTable {
            return MonsterTable(listOf(WeightedMonsterDef(monsterDefinition, 1.0)))
        }
    }

    private val sum = definitions.sumOf { it.weight }

    fun getRandomMonster(): MonsterDefinition {
        val rand = Random.nextDouble(sum)
        var cumulativeSum = 0.0

        for (definition in definitions) {
            cumulativeSum += definition.weight
            if (rand < cumulativeSum) {
                return definition.monsterDefinition
            }
        }

        throw IllegalStateException()
    }

}

data class MonsterSpawnerDefinition(val spawnArea: SpawnArea, val table: MonsterTable, val maxMonsters: Int, val spawnDelay: Float, val maxSpawnCount: Int? = null)

class MonsterSlot {
    var spawnDelay = 0f
    var monster: MonsterInstance? = null
}

class MonsterSpawnerInstance(val definition: MonsterSpawnerDefinition) {

    private val slots = Array(definition.maxMonsters) { MonsterSlot() }

    private var spawnedChest: Boolean = false
    private var chest: ActorId? = null

    private var totalSpawned = 0
    private var totalDefeated = 0

    fun update(elapsed: Float) {
        spawnChestIfNeeded()
        refreshMonsters(elapsed)
    }

    fun clear() {
        slots.mapNotNull { it.monster }.forEach { GameEngine.submitDeleteActor(it.actorId) }
        slots.forEach { it.monster = null }
        chest?.let { GameEngine.submitDeleteActor(it) }
    }

    fun getProgress(): Pair<Int, Int>? {
        if (definition.maxSpawnCount == null) { return null }
        return Pair(totalDefeated, definition.maxSpawnCount)
    }

    fun hasDefeatedAllMonsters(): Boolean {
        val progress = getProgress() ?: return false
        return progress.first == progress.second
    }

    private fun refreshMonsters(elapsedFrames: Float) {
        for (slot in slots) {
            val currentMonster = slot.monster

            // Empty slot - waiting for spawn delay
            if (currentMonster == null) {
                slot.spawnDelay -= elapsedFrames
                if (slot.spawnDelay < 0f && slot.monster == null) { spawnMonster(slot) }
                continue
            }

            // Active monster
            val monsterState = ActorStateManager[currentMonster.actorId]
            if (monsterState != null && !monsterState.isDead()) { continue }

            // Defeat/deleted monster - reset for respawn
            totalDefeated += 1
            slot.monster = null
            slot.spawnDelay = definition.spawnDelay
        }
    }

    private fun spawnMonster(slot: MonsterSlot) {
        if (definition.maxSpawnCount != null && totalSpawned >= definition.maxSpawnCount) { return }
        totalSpawned += 1

        val monsterDefinition = definition.table.getRandomMonster()

        val size = definition.spawnArea.size
        val position = definition.spawnArea.position + Vector3f(size.x * rand(), size.y * rand(), size.z * rand())

        GameEngine.submitCreateActorState(
            InitialActorState(
                monsterId = monsterDefinition.id,
                name = monsterDefinition.name,
                type = ActorType.Enemy,
                position = position,
                modelLook = monsterDefinition.look,
                rotation = 2 * PI_f * rand(),
                movementController = monsterDefinition.movementController,
                behaviorController = monsterDefinition.behaviorId,
            )
        ) {
            it.onReadyToDraw {
                it.transitionToIdle(0f)
                it.playRoutine(DatId.pop)
                it.actorModel?.customModelSettings = monsterDefinition.customModelSettings
            }

            slot.monster = MonsterInstance(monsterDefinition, it.id)
            monsterDefinition.onSpawn?.invoke(it)
        }

    }

    private fun spawnChestIfNeeded() {
        if (spawnedChest) { return }
        spawnedChest = true

        val chestPos = definition.spawnArea.chestPosition ?: return
        val chestModels = listOf(0x03C5, 0x03C6, 0x03C7, 0x03C8, 0x03C9, 0x0979, 0x097A)

        val inventory = Inventory()
        inventory.addItem(640)
        inventory.addItem(641)
        inventory.addItem(642)

        GameEngine.submitCreateActorState(
            InitialActorState(
                name = "Treasure Chest",
                type = ActorType.Npc,
                position = chestPos,
                modelLook = ModelLook.npc(chestModels.random()),
                rotation = 2 * PI_f * rand(),
                movementController = NoOpActorController(),
                behaviorController = ActorBehaviors.NoAction,
                inventory = inventory,
            )
        ) {
            chest = it.id
            GameState.registerInteraction(it.id, SpawnChestInteraction(monsterSpawnerInstance = this))
        }
    }

}