package xim.poc.game.configuration.zones

import xim.math.Vector3f
import xim.poc.game.GameState
import xim.poc.game.configuration.*
import xim.util.Fps

private const val zoneId = 116

private val varietyTable = MonsterTable(listOf(
    WeightedMonsterDef(mandyDef, 1.0),
    WeightedMonsterDef(birdDef, 1.0),
    WeightedMonsterDef(gobbieDef, 1.0),
    WeightedMonsterDef(yaguDef, 1.0),
    WeightedMonsterDef(crawlyDef, 1.0),
    WeightedMonsterDef(dhalDef, 1.0),
))

private val spawner = MonsterSpawnerDefinition(
    spawnArea = SpawnArea(position = Vector3f(-76f, -5f, -520f), size = Vector3f(20f, 0f, 20f)),
    spawnDelay = Fps.secondsToFrames(10f),
    maxMonsters = 8,
    table = varietyTable,
)

private val boxSpawner = MonsterSpawnerDefinition(
    spawnArea = SpawnArea(position = Vector3f(x=-99.91f,y=-5.00f,z=-528.85f), size = Vector3f(1f, 0f, 1f)),
    spawnDelay = Fps.secondsToFrames(10f),
    maxMonsters = 3,
    table = MonsterTable.single(dummyBox),
)

private val zoneLogic = ZoneLogic(
    monsterSpawnerDefinitions = listOf(spawner, boxSpawner),
    gatheringPoints = emptyList(),
)

val EastSaru = CustomZoneDefinition(
    zoneId = zoneId,
) {
    GameState.setZoneLogic(zoneLogic)
}