package xim.poc.game.configuration

import xim.math.Vector3f
import xim.poc.ActionTargetFilter
import xim.poc.ActorId
import xim.poc.game.*
import xim.poc.game.configuration.SkillApplierHelper.SkillUsableEvaluator
import xim.poc.game.configuration.SkillApplierHelper.SkillUsableEvaluatorContext
import xim.poc.game.configuration.SkillApplierHelper.TargetEvaluator
import xim.poc.game.configuration.SkillApplierHelper.TargetEvaluatorContext
import xim.poc.game.configuration.SkillApplierHelper.makeApplier
import xim.poc.game.event.Event
import xim.resource.*
import xim.resource.table.MobSkillInfo
import xim.util.PI_f
import kotlin.math.acos
import kotlin.math.cos
import kotlin.math.sin

class ApplierResult(val events: List<Event>, val allTargetIds: List<ActorId>, val contexts: AttackContexts, val success: Boolean = true, val failureReason: String? = null) {
    companion object {
        fun failure(reason: String? = null): ApplierResult {
            return ApplierResult(events = emptyList(), allTargetIds = emptyList(), contexts = AttackContexts(emptyMap()), success = false, failureReason = reason)
        }
    }
}

enum class SkillType {
    Spell,
    MobSkill,
    Ability,
}

class SkillRangeInfo(val maxTargetDistance: Float, val effectRadius: Float, val type: AoeType)

class SkillInfo(val id: Int, val type: SkillType, val targetFlags: Int) {
    val targetFilter = ActionTargetFilter(targetFlags)
}

object SkillAppliers {

    private val mobSkillAppliers = HashMap<Int, SkillApplier>()
    private val spellSkillAppliers = HashMap<Int, SkillApplier>()
    private val abilitySkillAppliers = HashMap<Int, SkillApplier>()

    init {
        MobSkills.definitions.forEach { mobSkillAppliers[it.id] = it.applier }
        SpellSkills.definitions.forEach { spellSkillAppliers[it.id] = it.applier }
        AbilitySkills.definitions.forEach { abilitySkillAppliers[it.id] = it.applier }
    }

    fun invoke(sourceState: ActorState, targetState: ActorState, abilityInfo: AbilityInfo): ApplierResult {
        var applier = abilitySkillAppliers[abilityInfo.index]

        if (applier == null) {
            applier = if (abilityInfo.type == AbilityType.WeaponSkill) {
                StandardSkillEvaluators.fixedCostWeaponSkill()
            } else {
                makeApplier(TargetEvaluator.noop())
            }
        }

        val skillInfo = SkillInfo(abilityInfo.index, SkillType.Ability, abilityInfo.targetFlags)
        return invoke(sourceState, targetState, applier, skillInfo)
    }

    fun invoke(sourceState: ActorState, targetState: ActorState, spellInfo: SpellInfo): ApplierResult {
        val applier = spellSkillAppliers[spellInfo.index] ?: SkillApplierHelper.makeApplier(TargetEvaluator.noop())
        val skillInfo = getSkillInfo(spellInfo)
        return invoke(sourceState, targetState, applier, skillInfo)
    }

    fun invoke(sourceState: ActorState, targetState: ActorState, mobSkillInfo: MobSkillInfo): ApplierResult {
        val applier = mobSkillAppliers[mobSkillInfo.id]
        val skillInfo = getSkillInfo(mobSkillInfo)
        return invoke(sourceState, targetState, applier, skillInfo)
    }

    private fun getSkillInfo(mobSkillInfo: MobSkillInfo?): SkillInfo? {
        mobSkillInfo ?: return null
        return SkillInfo(mobSkillInfo.id, SkillType.MobSkill, mobSkillInfo.targetFlag)
    }

    private fun getSkillInfo(spellInfo: SpellInfo): SkillInfo {
        return SkillInfo(spellInfo.index, SkillType.Spell, spellInfo.targetFlags)
    }

    private fun invoke(sourceState: ActorState, targetState: ActorState, applier: SkillApplier?, info: SkillInfo?): ApplierResult {
        return if (applier != null && info != null) {
            applier.apply(info, sourceState, targetState)
        } else {
            val context = AttackContext.noop()
            ApplierResult(
                events = emptyList(),
                allTargetIds = listOf(targetState.id),
                contexts = AttackContexts.single(targetState.id, context))
        }
    }
}

class SkillApplier(
    val targetEvaluator: TargetEvaluator,
    val validEvaluator: SkillUsableEvaluator = SkillUsableEvaluator.noop,
    val primaryTargetEvaluator: TargetEvaluator? = null,
    val additionalSelfEvaluator: TargetEvaluator? = null,

) {

    fun apply(skillInfo: SkillInfo, sourceState: ActorState, primaryTargetState: ActorState): ApplierResult {
        val failure = validEvaluator.isUsable(SkillUsableEvaluatorContext(skillInfo, sourceState))
        if (failure != null) { return ApplierResult.failure(failure) }

        val rangeInfo = GameEngine.getRangeInfo(sourceState, skillInfo)
        if (!isInSkillRange(skillInfo, rangeInfo, sourceState, primaryTargetState)) { return ApplierResult.failure() }

        val allTargets = getNearbyTargets(skillInfo, rangeInfo, sourceState, primaryTargetState)
        val allTargetIds = allTargets.map { it.id }
        val targetContexts = allTargetIds.associateWith { AttackContext.noop() }

        val primaryContext = targetContexts[primaryTargetState.id] ?: return ApplierResult.failure()

        val events = ArrayList<Event>()

        if (additionalSelfEvaluator != null) {
            events += additionalSelfEvaluator.getEvents(TargetEvaluatorContext(skillInfo, sourceState, sourceState, primaryTargetState, primaryContext))
        }

        if (primaryTargetEvaluator != null) {
            events += primaryTargetEvaluator.getEvents(TargetEvaluatorContext(skillInfo, sourceState, primaryTargetState, primaryTargetState, primaryContext))
        }

        for (target in allTargets) {
            if (primaryTargetEvaluator != null && target.id == primaryTargetState.id) { continue }
            val context = targetContexts[target.id] ?: throw IllegalStateException()
            events += targetEvaluator.getEvents(TargetEvaluatorContext(skillInfo, sourceState, target, primaryTargetState, context))
        }

        return ApplierResult(events = events, allTargetIds = allTargets.map { it.id }, contexts = AttackContexts(targetContexts))
    }

    private fun getNearbyTargets(skillInfo: SkillInfo, rangeInfo: SkillRangeInfo, sourceState: ActorState, targetState: ActorState): List<ActorState> {
        // The target-filter and effect-filter aren't necessarily the same, but the effect-filter isn't available client-side (?)
        val targetFilter = if (rangeInfo.type != AoeType.None && skillInfo.targetFlags == TargetFlag.Self.flag) {
            ActionTargetFilter(TargetFlag.Self.flag or TargetFlag.Party.flag)
        } else {
            skillInfo.targetFilter
        }

        return ActorStateManager
            .filter { isInEffectRange(skillInfo, rangeInfo, source = sourceState, primaryTarget = targetState, additionalTarget = it) }
            .filter { targetFilter.targetFilter(sourceState, it) }
    }

    private fun isInSkillRange(skillInfo: SkillInfo, rangeInfo: SkillRangeInfo, sourceState: ActorState, targetState: ActorState): Boolean {
        return Vector3f.distance(sourceState.position, targetState.position) <= rangeInfo.maxTargetDistance
    }

    private fun isInEffectRange(skillInfo: SkillInfo, rangeInfo: SkillRangeInfo, source: ActorState, primaryTarget: ActorState, additionalTarget: ActorState): Boolean {
        if (rangeInfo.type == AoeType.None) {
            return primaryTarget.id == additionalTarget.id
        }

        val effectPosition = when (rangeInfo.type) {
            AoeType.None -> throw IllegalStateException()
            AoeType.Target -> primaryTarget.position
            AoeType.Cone, AoeType.Source -> source.position
        }

        return if (Vector3f.distance(effectPosition, additionalTarget.position) > rangeInfo.effectRadius) {
            false
        } else if (rangeInfo.type == AoeType.Cone) {
            val targetDir = (additionalTarget.position - source.position).normalizeInPlace()
            val facingDir = Vector3f(cos(source.rotation), 0f, -sin(source.rotation))
            acos(targetDir.dot(facingDir)) < PI_f/4f
        } else {
            true
        }
    }

}

object SkillApplierHelper {

    class TargetEvaluatorContext(
        val skillInfo: SkillInfo,
        val sourceState: ActorState,
        val targetState: ActorState,
        val primaryTargetState: ActorState,
        val context: AttackContext,
    )

    fun interface TargetEvaluator {
        fun getEvents(context: TargetEvaluatorContext): List<Event>

        companion object {
            fun compose(vararg evaluators: TargetEvaluator): TargetEvaluator {
                return TargetEvaluator { c -> evaluators.flatMap { it.getEvents(c) } }
            }

            fun noop(): TargetEvaluator {
                return TargetEvaluator { emptyList() }
            }

        }
    }

    class SkillUsableEvaluatorContext(
        val skillInfo: SkillInfo,
        val sourceState: ActorState,
    )

    fun interface SkillUsableEvaluator {
        fun isUsable(context: SkillUsableEvaluatorContext): String?

        companion object {
            val noop = SkillUsableEvaluator { null }
        }
    }

    fun makeApplier(targetEvaluator: TargetEvaluator): SkillApplier {
        return SkillApplier(targetEvaluator = targetEvaluator)
    }

    fun makeApplier(targetEvaluator: TargetEvaluator, additionalSelfEvaluator: TargetEvaluator): SkillApplier {
        return SkillApplier(targetEvaluator = targetEvaluator, additionalSelfEvaluator = additionalSelfEvaluator)
    }

}

