package xim.poc.camera

import xim.math.Matrix4f
import xim.math.Vector2f
import xim.math.Vector3f
import xim.poc.*
import xim.poc.browser.Keyboard
import xim.poc.browser.LocalStorage
import xim.poc.game.UiStateHelper
import xim.poc.gl.ScreenSettingsSupplier
import xim.util.fallOff
import xim.util.toRads
import kotlin.math.*

private class TargetLock {
    var locked = false
    val target = Vector3f()
}

class PolarCamera (var target: Vector3f, var targetOffset: Vector3f, val screenSettingsSupplier: ScreenSettingsSupplier) : Camera {

    companion object {
        private const val increment = 0.05f

        private val fovIncrement = 1f.toRads()
        private val minFov = 25.0f.toRads()
        private val maxFov = 75.0f.toRads()

        const val near = 0.05f
        const val far = 4096f // Max sky-box is 2029.5

        private const val cullFar = 200f
    }

    private var phi = PI
    private var theta = PI/1.8
    private var fov = maxFov

    private val radius = 4.0

    private val eye = Vector3f()
    private val fovAdjustedEye = Vector3f()

    private val shakeOffset = Vector3f()
    private val targetLock = TargetLock()

    private val viewMatrix = Matrix4f()

    private lateinit var frustum: Frustum

    init {
        updateEye()
    }

    override fun getViewMatrix(): Matrix4f {
        return viewMatrix
    }

    override fun getProjectionMatrix(aspectRatio: Float?): Matrix4f {
        val projectionMatrix = Matrix4f()
        projectionMatrix.perspective(fov, aspectRatio ?: screenSettingsSupplier.aspectRatio, near, far)
        return projectionMatrix
    }

    override fun getPosition(): Vector3f {
        return eye
    }

    override fun getFovAdjustedPosition(): Vector3f {
        return fovAdjustedEye
    }

    override fun getViewVector(): Vector3f {
        return getTargetPosition() - eye
    }

    override fun getFoV(): Float {
        return fov
    }

    override fun lock(enable: Boolean, position: Vector3f?) {
        targetLock.locked = enable
        if (enable) { position?.let { targetLock.target.copyFrom(it) } }
    }

    override fun setTarget(target: Vector3f) {
        this.target = target
    }

    private fun getTargetPosition(): Vector3f {
        return if (targetLock.locked) { targetLock.target + targetOffset } else { target + targetOffset }
    }

    override fun getLensFlareRay(lightPos: Vector3f): Vector3f? {
        val viewVec = getViewVector().normalize()
        val centerNearPlane = eye + viewVec * near
        val near = Plane(viewVec, centerNearPlane)

        val distanceToPlane = near.normal.dot(lightPos) + near.constant

        val halfWayPlaneCenter = eye + (viewVec * (distanceToPlane * 0.5f))
        return halfWayPlaneCenter
//        return (halfWayPlaneCenter - lightPos).normalizeInPlace() * distanceToPlane
    }

    override fun getWorldSpaceRay(screenSpacePos: Vector2f): Ray {
        val viewVec = getViewVector().normalize()
        val leftVec = Vector3f.UP.cross(getViewVector()).normalize()
        val upVec = viewVec.cross(leftVec).normalize()

        val halfNearPlaneHeight = 2 * tan(fov /2) * near
        val halfNearPlaneWidth = halfNearPlaneHeight * (screenSettingsSupplier.width.toFloat() / screenSettingsSupplier.height.toFloat())
        val centerNearPlane = eye + viewVec * near

        val halfFarPlaneHeight = 2* tan(fov /2) * cullFar
        val halfFarPlaneWidth = halfFarPlaneHeight * (screenSettingsSupplier.width.toFloat() / screenSettingsSupplier.height.toFloat())
        val centerFarPlane = eye + viewVec * cullFar

        val tX = -(screenSpacePos.x - 0.5f)
        val tY = -(screenSpacePos.y - 0.5f)

        val nearPlanePos = centerNearPlane + (leftVec * tX * halfNearPlaneWidth) + (upVec * tY * halfNearPlaneHeight)
        val farPlanePos = centerFarPlane + (leftVec * tX * halfFarPlaneWidth) + (upVec * tY * halfFarPlaneHeight)

        return Ray(nearPlanePos, (farPlanePos - nearPlanePos).normalizeInPlace())
    }

    override fun isVisible(box: Box): Boolean {
        return frustum.intersects(box.getVertices())
    }

    override fun update(keyboard: Keyboard, elapsedFrames: Float) {
        if (!UiStateHelper.cursorLocked()) {
            val updated = updateFromKeyboardTouches(elapsedFrames, keyboard)
            if (!updated) { updateFromKeyboardButtons(elapsedFrames, keyboard) }
        }

        updateEye()
    }

    private fun updateEye() {
        val targetWithOffset = getTargetPosition() + shakeOffset
        eye.x = (targetWithOffset.x) + (radius*sin(theta)*cos(phi)).toFloat()
        eye.y = (targetWithOffset.y) + (radius*cos(theta)).toFloat()
        eye.z = (targetWithOffset.z) + (radius*sin(theta)*sin(phi)).toFloat()

        val fakeRadius = radius * (1f - fov.fallOff(0f, maxFov))
        fovAdjustedEye.x = (targetWithOffset.x) + (fakeRadius*sin(theta)*cos(phi)).toFloat()
        fovAdjustedEye.y = (targetWithOffset.y) + (fakeRadius*cos(theta)).toFloat()
        fovAdjustedEye.z = (targetWithOffset.z) + (fakeRadius*sin(theta)*sin(phi)).toFloat()

        viewMatrix.lookAt(eye, targetWithOffset)

        frustum = getFrustum()
        shakeOffset.copyFrom(Vector3f.ZERO)
    }

    private fun updateFromKeyboardTouches(elapsedFrames: Float, keyboard: Keyboard): Boolean {
        val events = keyboard.getTouchData()
        if (events.isEmpty()) { return false }

        for (event in events) {
            if (event.startingX < 0.5) { continue }

            val direction = Vector3f(event.dX.toFloat(), 0f, event.dY.toFloat())
            if (direction.magnitudeSquare() <= 1e-7) { return true }

            if (direction.z < 0.1 || direction.z > 0.1) {
                incrementTheta(elapsedFrames * direction.z)
            }
            if (direction.x < 0.1 || direction.x > 0.1) {
                incrementPhi(elapsedFrames * direction.x * 2)
            }
        }

        return false
    }

    private fun updateFromKeyboardButtons(elapsedFrames: Float, keyboard: Keyboard) {
        if (keyboard.isKeyPressedOrRepeated(Keyboard.Key.UP)) {
            incrementTheta(-elapsedFrames)
        }
        if (keyboard.isKeyPressedOrRepeated(Keyboard.Key.RIGHT)) {
            incrementPhi(elapsedFrames)
        }
        if (keyboard.isKeyPressedOrRepeated(Keyboard.Key.DOWN)) {
            incrementTheta(elapsedFrames)
        }
        if (keyboard.isKeyPressedOrRepeated(Keyboard.Key.LEFT)) {
            incrementPhi(-elapsedFrames)
        }
        if (keyboard.isKeyPressedOrRepeated(Keyboard.Key.ZOOM_IN)) {
            incrementFov(-elapsedFrames)
        }
        if (keyboard.isKeyPressedOrRepeated(Keyboard.Key.ZOOM_OUT)) {
            incrementFov(elapsedFrames)
        }
    }


    override fun applyShake(shakeFactor: Float) {
        shakeOffset.y = max(shakeFactor, shakeOffset.y)
    }

    override fun transform(areaTransform: AreaTransform): Camera {
        val transformed = PolarCamera(areaTransform.transform.transform(target), targetOffset, screenSettingsSupplier)
        transformed.eye.copyFrom(areaTransform.transform.transform(eye))

        transformed.phi = phi
        transformed.theta = theta

        transformed.frustum = transformed.getFrustum()

        return transformed
    }

    private fun incrementTheta(elapsedFrames: Float) {
        val invertY = LocalStorage.getConfiguration().cameraSettings.invertY
        theta += (elapsedFrames * increment) * if (invertY) { -1 } else { 1 }
        if (theta >= 7 * PI / 8) {
            theta = 7 * PI / 8 - 0.001f
        } else if (theta <= PI / 8) {
            theta = PI / 8 + 0.001f
        }
        val angle = cos(theta)
        if (angle == 1.0) {
            theta += 0.0001f
        } else if (angle == -1.0) {
            theta -= 0.0001f
        }
    }

    private fun incrementPhi(elapsedFrames: Float) {
        val invertX = LocalStorage.getConfiguration().cameraSettings.invertX
        phi += (elapsedFrames * increment) * if (invertX) { -1 } else { 1 }
        if (phi > 2 * PI) {
            phi -= 2 * PI
        } else if (phi < 0) {
            phi += 2 * PI
        }
    }

    private fun incrementFov(elapsedFrames: Float) {
        fov += elapsedFrames * fovIncrement
        fov = fov.coerceIn(minFov, maxFov)
    }

    private fun getFrustum(): Frustum {
        val viewVec = getViewVector().normalize()
        val leftVec = Vector3f.UP.cross(getViewVector()).normalize()
        val upVec = viewVec.cross(leftVec).normalize()

        val halfNearPlaneHeight = 2 * tan(fov /2) * near
        val halfNearPlaneWidth = halfNearPlaneHeight * (screenSettingsSupplier.width.toFloat() / screenSettingsSupplier.height.toFloat())
        val centerNearPlane = eye + viewVec * near

        val halfFarPlaneHeight = 2* tan(fov /2) * cullFar
        val halfFarPlaneWidth = halfFarPlaneHeight * (screenSettingsSupplier.width.toFloat() / screenSettingsSupplier.height.toFloat())
        val centerFarPlane = eye + viewVec * cullFar

        val nearBottomLeft =    centerNearPlane + leftVec * halfNearPlaneWidth - upVec * halfNearPlaneHeight
        val nearBottomRight =   centerNearPlane - leftVec * halfNearPlaneWidth - upVec * halfNearPlaneHeight
        val nearTopLeft =       centerNearPlane + leftVec * halfNearPlaneWidth + upVec * halfNearPlaneHeight
        val nearTopRight =      centerNearPlane - leftVec * halfNearPlaneWidth + upVec * halfNearPlaneHeight

        val farBottomLeft =     centerFarPlane + leftVec * halfFarPlaneWidth - upVec * halfFarPlaneHeight
        val farBottomRight =    centerFarPlane - leftVec * halfFarPlaneWidth - upVec * halfFarPlaneHeight
        val farTopLeft =        centerFarPlane + leftVec * halfFarPlaneWidth + upVec * halfFarPlaneHeight
        val farTopRight =       centerFarPlane - leftVec * halfFarPlaneWidth + upVec * halfFarPlaneHeight

        val near = Plane(viewVec, centerNearPlane)
        val far = Plane(viewVec * -1f, eye + (viewVec * far))

        val bottom = Plane(farBottomLeft, nearBottomLeft, farBottomRight)
        val top = Plane(farTopLeft, farTopRight, nearTopLeft)

        val right = Plane(farTopRight, farBottomRight, nearBottomRight)
        val left = Plane(nearBottomLeft, farBottomLeft, farTopLeft)

        return Frustum(left = left, right = right, top = top, bottom = bottom, near = near, far = far)
    }

}