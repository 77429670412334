package xim.poc.ui

import xim.math.Vector2f
import xim.poc.Font
import xim.poc.UiElementHelper
import xim.poc.browser.LocalStorage
import xim.poc.game.*
import xim.resource.EquipSlot

private class EquipUiSlot(val slot: EquipSlot, val x: Int, val y: Int)

object EquipScreenUi {

    var currentEquipSet: Int? = null

    private val equipOrder = listOf(
        EquipUiSlot(EquipSlot.Main, 0, 0),
        EquipUiSlot(EquipSlot.Head, 0, 1),
        EquipUiSlot(EquipSlot.Body, 0, 2),
        EquipUiSlot(EquipSlot.Back, 0, 3),
        EquipUiSlot(EquipSlot.Sub, 1, 0),
        EquipUiSlot(EquipSlot.Neck, 1, 1),
        EquipUiSlot(EquipSlot.Hands, 1, 2),
        EquipUiSlot(EquipSlot.Waist, 1, 3),
        EquipUiSlot(EquipSlot.Range, 2, 0),
        EquipUiSlot(EquipSlot.LEar, 2, 1),
        EquipUiSlot(EquipSlot.LRing, 2, 2),
        EquipUiSlot(EquipSlot.Legs, 2, 3),
        EquipUiSlot(EquipSlot.Ammo, 3, 0),
        EquipUiSlot(EquipSlot.REar, 3, 1),
        EquipUiSlot(EquipSlot.RRing, 3, 2),
        EquipUiSlot(EquipSlot.Feet, 3, 3),
    )

    fun draw(uiState: UiState) {
        drawEquippedItems(uiState)

        val hoveredItem = getHoveredEquipItem()
        if (hoveredItem != null && UiStateHelper.isFocus(uiState)) {
            InventoryUi.drawSelectedInventoryItem(hoveredItem, UiStateHelper.equipContext)
        }

        if (currentEquipSet == null) {
            val statusPosition = UiElementHelper.drawMenu(menuName = "menu    persona ")
            if (statusPosition != null) { StatusWindowUi.populateStatusWindow(statusPosition) }

            UiElementHelper.drawMenu(menuName = "menu    money   ")
        }
    }

    fun getInventoryItemsByEquipSlot(cursorIndex: Int) : List<InventoryItem> {
        return Inventory.player().getInventoryItemsByEquipSlot(equipOrder[cursorIndex].slot)
    }

    fun equipHoveredItem(uiState: UiState) {
        val equipIndex = UiStateHelper.equipContext.cursorIndex
        val slot = equipOrder[equipIndex].slot

        val filteredView = getInventoryItemsByEquipSlot(UiStateHelper.equipContext.cursorIndex)
        val index = uiState.cursorIndex + uiState.scrollSettings!!.lowestViewableItemIndex

        val item = filteredView[index]

        val current = getCurrentEquipment().getItem(ActorStateManager.player().inventory, slot)
        val itemId = if (current?.internalId == item.internalId) { null } else { item.internalId }

        if (currentEquipSet == null) {
            GameClient.submitEquipItem(ActorStateManager.playerId, slot, itemId)
        } else {
            updateEquipmentSet(slot, itemId)
        }
    }

    fun drawEquipSets(it: UiState) {
        val menu = it.latestMenu ?: return
        val offset = it.latestPosition ?: return

        if (UiStateHelper.isFocus(it)) {
            currentEquipSet = it.cursorIndex
        }

        for (i in 0 until 10) {
            val totalOffset = offset + menu.elements[i].offset + Vector2f(6f, 1f)

            val highlight = if (i == currentEquipSet) { "${ShiftJis.colorItem}" } else { "" }
            UiElementHelper.drawString("${highlight}Shift + ${(i+1)%10}", offset = totalOffset, font = Font.FontShp)
        }
    }

    private fun getCurrentEquipment(): Equipment {
        val current = currentEquipSet ?: return ActorStateManager.player().equipment
        return LocalStorage.getPlayerEquipmentSet(current) ?: ActorStateManager.player().equipment
    }

    private fun updateEquipmentSet(slot: EquipSlot, itemId: InternalItemId?) {
        val currentIndex = currentEquipSet ?: return
        val equipmentSet = LocalStorage.getPlayerEquipmentSet(currentIndex) ?: return

        val inventoryItem = ActorStateManager.player().inventory.getByInternalId(itemId)
        equipmentSet.setItem(slot, inventoryItem)
    }

    private fun getHoveredEquipItem(): InventoryItem? {
        val equipment = getCurrentEquipment()
        val inventory = ActorStateManager.player().inventory
        val hoveredIndex = UiStateHelper.equipContext.cursorIndex
        return equipment.getItem(inventory, equipOrder[hoveredIndex].slot)
    }

    private fun drawEquippedItems(uiState: UiState) {
        val stackPos = uiState.latestPosition ?: return
        val baseOffset = Vector2f(24f, 10f)

        val inventory = ActorStateManager.player().inventory
        val equipment = getCurrentEquipment()

        for (slot in equipOrder) {
            val item = equipment.getItem(inventory, slot.slot) ?: continue

            val offset = baseOffset + Vector2f(slot.x * 34f, slot.y * 34f)
            UiElementHelper.drawInventoryItemIcon(item = item, position = offset + stackPos)
        }
    }

}