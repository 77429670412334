package xim.poc.game.configuration.v0.events

import xim.poc.ActorId
import xim.poc.game.ActorStateManager
import xim.poc.game.InternalItemId
import xim.poc.game.event.Event

class InventoryItemScrapEvent(
    val sourceId: ActorId,
    val itemId: InternalItemId,
): Event {

    override fun apply(): List<Event> {
        val source = ActorStateManager[sourceId] ?: return emptyList()
        val item = source.inventory.getByInternalId(itemId) ?: return emptyList()

        if (source.isEquipped(item)) { return emptyList() }

        source.inventory.discardItem(itemId)
        source.inventory.addItem(9084, 3)

        return emptyList()
    }

}