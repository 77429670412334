package xim.resource.table

import xim.poc.ActionTargetFilter
import xim.poc.browser.DatLoader
import xim.resource.ByteReader
import xim.resource.TargetFlag
import xim.resource.table.AdditionalMobAnimations.additionalAnimations

val MobSkillNameTable = BasicStringTable("ROM/27/80.DAT", 0x80.toByte())

data class MobSkillInfo(val id: Int, val animationId: Int, val type: Int) {

    val targetFlag: Int = validTargetsToTargetFlag(type)
    val targetFilter = ActionTargetFilter(targetFlag)

    private fun validTargetsToTargetFlag(validTargets: Int): Int {
        return when (validTargets) {
            1 -> TargetFlag.Self.flag
            2 -> TargetFlag.Party.flag
            3 -> TargetFlag.Self.flag or TargetFlag.Party.flag
            4 -> TargetFlag.Enemy.flag
            // 16 -> pup?
            else -> 0
        }
    }

}

// https://raw.githubusercontent.com/LandSandBoat/server/base/sql/mob_skills.sql
// with some overrides & additions listed below
object MobSkillInfoTable: LoadableResource {

    private lateinit var animInfos: Map<Int, MobSkillInfo>
    private var preloaded = false

    override fun preload() {
        if (preloaded) { return }
        preloaded = true
        loadTable()
    }

    override fun isFullyLoaded() : Boolean {
        return this::animInfos.isInitialized
    }

    operator fun get(skillId: Int): MobSkillInfo? {
        return animInfos[skillId]
    }

    fun getAnimationPath(info: MobSkillInfo): String? {
        return getAnimationPath(info.animationId)
    }

    fun getAnimationPath(animationId: Int): String? {
        val fileTableOffset = if (animationId < 0x200) {
            0x0F3C
        } else if (animationId < 0x600) {
            0xC1EF
        } else if (animationId < 0x800) {
            0xE739
        } else {
            0x14B07
        }

        val fileTableIndex = fileTableOffset + animationId
        return FileTableManager.getFilePath(fileTableIndex)
    }

    private fun loadTable() {
        DatLoader.load("landsandboat/MobSkillsTable.DAT").onReady { parseTable(it.getAsBytes()) }
    }

    private fun parseTable(byteReader: ByteReader) {
        val skills = HashMap<Int, MobSkillInfo>()
        while (byteReader.hasMore()) {
            val skill = MobSkillInfo(id = byteReader.next16(), animationId = byteReader.next16(), type = byteReader.next16())
            skills[skill.id] = skill
        }
        this.animInfos = skills + additionalAnimations()
    }

}

private object AdditionalMobAnimations {

    fun additionalAnimations(): Map<Int, MobSkillInfo> {
        return listOf(
            MobSkillInfo(id = 315, animationId = 0x366, type = 4), // Cluster:Self-Destruct (3->2)
            MobSkillInfo(id = 316, animationId = 0x367, type = 4), // Cluster:Self-Destruct (3->0)
            MobSkillInfo(id = 317, animationId = 0x368, type = 4), // Cluster:Self-Destruct (2->1)
            MobSkillInfo(id = 318, animationId = 0x369, type = 4), // Cluster:Self-Destruct (2->0)
            MobSkillInfo(id = 319, animationId = 0x36A, type = 4), // Cluster:Self-Destruct (1->0)

            MobSkillInfo(id = 2743, animationId = 0x855, type = 4), // Bztavian:Auto-Attack 0
            MobSkillInfo(id = 2744, animationId = 0x856, type = 4), // Bztavian:Auto-Attack 1
            MobSkillInfo(id = 2745, animationId = 0x857, type = 4), // Bztavian:Auto-Attack 2
            MobSkillInfo(id = 2746, animationId = 0x858, type = 4), // Bztavian:Mandibular Lashing
            MobSkillInfo(id = 2747, animationId = 0x859, type = 4), // Bztavian:Vespine Hurricane
            MobSkillInfo(id = 2748, animationId = 0x85A, type = 4), // Bztavian:Stinger Volley
            MobSkillInfo(id = 2749, animationId = 0x85B, type = 4), // Bztavian:Droning Whirlwind
            MobSkillInfo(id = 2750, animationId = 0x85C, type = 4), // Bztavian:Incisive Denouement
            MobSkillInfo(id = 2751, animationId = 0x85D, type = 4), // Bztavian:Incisive Apotheosis

            MobSkillInfo(id = 2813, animationId = 0x8A6, type = 4), // Waktza:Auto-Attack 0
            MobSkillInfo(id = 2814, animationId = 0x8A7, type = 4), // Waktza:Auto-Attack 1
            MobSkillInfo(id = 2815, animationId = 0x8A8, type = 4), // Waktza:Auto-Attack 2
            MobSkillInfo(id = 2816, animationId = 0x8A9, type = 4), // Waktza:Crashing Thunder
            MobSkillInfo(id = 2817, animationId = 0x8AA, type = 4), // Waktza:Reverberating Cry
            MobSkillInfo(id = 2818, animationId = 0x8AB, type = 4), // Waktza:Brown Out
            MobSkillInfo(id = 2819, animationId = 0x8AC, type = 4), // Waktza:Reverse Current
            MobSkillInfo(id = 2820, animationId = 0x8AD, type = 4), // Waktza:Sparkstorm
            MobSkillInfo(id = 2821, animationId = 0x8AE, type = 4), // Waktza:Static Prison
        ).associateBy { it.id }
    }

}
