package xim.poc.game.configuration.v0

import xim.poc.game.AugmentHelper
import xim.poc.game.AugmentId
import xim.poc.game.InventoryItem
import xim.poc.game.ItemAugmentId
import xim.resource.table.AugmentTable
import xim.util.interpolate
import kotlin.math.ceil
import kotlin.math.pow
import kotlin.math.roundToInt

enum class AugmentSubType {
    None,
    Trust,
}

class ItemAugmentAttribute private constructor(val augment: AugmentId, val type: AugmentSubType = AugmentSubType.None) {

    companion object {
        val maxHp = ItemAugmentAttribute(augment = AugmentId.HP)
        val maxMp = ItemAugmentAttribute(augment = AugmentId.MP)
        val str = ItemAugmentAttribute(augment = AugmentId.STR)
        val dex = ItemAugmentAttribute(augment = AugmentId.DEX)
        val vit = ItemAugmentAttribute(augment = AugmentId.VIT)
        val agi = ItemAugmentAttribute(augment = AugmentId.AGI)
        val int = ItemAugmentAttribute(augment = AugmentId.INT)
        val mnd = ItemAugmentAttribute(augment = AugmentId.MND)
        val chr = ItemAugmentAttribute(augment = AugmentId.CHR)

        val criticalHitRate = ItemAugmentAttribute(augment = AugmentId.CriticalHitRate)
        val enemyCriticalHitRate = ItemAugmentAttribute(augment = AugmentId.EnemyCriticalHitRate)
        val haste = ItemAugmentAttribute(augment = AugmentId.Haste)
        val physicalDamageTaken = ItemAugmentAttribute(augment = AugmentId.PhysicalDamageTaken)
        val autoRegen = ItemAugmentAttribute(augment = AugmentId.Regen)
        val autoRefresh = ItemAugmentAttribute(augment = AugmentId.Refresh)
        val fastCast = ItemAugmentAttribute(augment = AugmentId.FastCast)
        val storeTp = ItemAugmentAttribute(augment = AugmentId.StoreTp)
        val doubleAttack = ItemAugmentAttribute(augment = AugmentId.DoubleAttack)
        val dualWield = ItemAugmentAttribute(augment = AugmentId.DualWield)
        val weaponSkillDamage = ItemAugmentAttribute(augment = AugmentId.WeaponSkillDamage)
        val criticalHitDamage = ItemAugmentAttribute(augment = AugmentId.CriticalHitDamage)

        val trustAutoRegen = ItemAugmentAttribute(augment = AugmentId.Regen, type = AugmentSubType.Trust)
        val trustAutoRefresh = ItemAugmentAttribute(augment = AugmentId.Refresh, type = AugmentSubType.Trust)
    }

    fun toDescription(bonusValue: Int): String {
        val augmentDisplay = AugmentTable.getAugmentName(augment, bonusValue)

        val prefix = when (type) {
            AugmentSubType.None -> ""
            AugmentSubType.Trust -> AugmentTable.getAugmentName(AugmentId.AlterEgo)
        }

        return "$prefix$augmentDisplay"
    }

}

fun interface ItemAugmentValue {
    fun calculate(item: InventoryItem): Int
}

data class ItemAugmentDefinition(
    val id: ItemAugmentId,
    val attribute: ItemAugmentAttribute,
    val valueFn: ItemAugmentValue,
)

object ItemAugmentDefinitions {

    private const val maxAugmentRank = 10

    val definitions: Map<ItemAugmentId, ItemAugmentDefinition>

    init {
        val defs = ArrayList<ItemAugmentDefinition>()

        // Standard combat stats
        defs += ItemAugmentDefinition(id = 3, attribute = ItemAugmentAttribute.str) { standardScaling(initialValue = 4f, it) }
        defs += ItemAugmentDefinition(id = 4, attribute = ItemAugmentAttribute.dex) { standardScaling(initialValue = 4f, it) }
        defs += ItemAugmentDefinition(id = 5, attribute = ItemAugmentAttribute.vit) { standardScaling(initialValue = 4f, it) }
        defs += ItemAugmentDefinition(id = 6, attribute = ItemAugmentAttribute.agi) { standardScaling(initialValue = 4f, it) }
        defs += ItemAugmentDefinition(id = 7, attribute = ItemAugmentAttribute.int) { standardScaling(initialValue = 4f, it) }
        defs += ItemAugmentDefinition(id = 8, attribute = ItemAugmentAttribute.mnd) { standardScaling(initialValue = 4f, it) }
        defs += ItemAugmentDefinition(id = 9, attribute = ItemAugmentAttribute.chr) { standardScaling(initialValue = 4f, it) }

        defs += ItemAugmentDefinition(id = 10, attribute = ItemAugmentAttribute.trustAutoRefresh) { 1 }
        defs += ItemAugmentDefinition(id = 11, attribute = ItemAugmentAttribute.trustAutoRegen) { 1 }

        defs += ItemAugmentDefinition(id = 12, attribute = ItemAugmentAttribute.fastCast) { rankQualityScaling(10f, it) }
        defs += ItemAugmentDefinition(id = 13, attribute = ItemAugmentAttribute.doubleAttack) { rankQualityScaling(5f, it) }
        defs += ItemAugmentDefinition(id = 14, attribute = ItemAugmentAttribute.storeTp) { rankQualityScaling(5f, it) }
        defs += ItemAugmentDefinition(id = 15, attribute = ItemAugmentAttribute.haste) { rankQualityScaling(5f, it) }
        defs += ItemAugmentDefinition(id = 16, attribute = ItemAugmentAttribute.weaponSkillDamage) { rankQualityScaling(5f, it) }
        defs += ItemAugmentDefinition(id = 17, attribute = ItemAugmentAttribute.criticalHitDamage) { rankQualityScaling(5f, it) }
        defs += ItemAugmentDefinition(id = 18, attribute = ItemAugmentAttribute.physicalDamageTaken) { rankQualityScaling(-3f, it) }
        defs += ItemAugmentDefinition(id = 19, attribute = ItemAugmentAttribute.autoRegen) { interpolatedQualityScaling(1f, 2f, it) }
        defs += ItemAugmentDefinition(id = 20, attribute = ItemAugmentAttribute.autoRefresh) { interpolatedQualityScaling(1f, 2f, it) }
        defs += ItemAugmentDefinition(id = 21, attribute = ItemAugmentAttribute.criticalHitRate) { rankQualityScaling(3f, it) }
        defs += ItemAugmentDefinition(id = 22, attribute = ItemAugmentAttribute.enemyCriticalHitRate) { rankQualityScaling(-3f, it) }

        definitions = defs.associateBy { it.id }
    }

    operator fun get(augmentId: ItemAugmentId): ItemAugmentDefinition {
        return definitions[augmentId] ?: throw IllegalStateException("[$augmentId] No such augment definition")
    }

    private fun standardScaling(initialValue: Float, item: InventoryItem): Int {
        val itemLevel = ItemDefinitions[item].internalLevel
        val qualityBonus = AugmentHelper.qualityBonus(item)

        val rawValue = ceil(initialValue * 1.084472f.pow(itemLevel)) * (1f + 0.5f * rankProportion(item)) * qualityBonus
        return rawValue.roundToInt()
    }

    private fun rankQualityScaling(initialValue: Float, item: InventoryItem): Int {
        val qualityBonus = AugmentHelper.qualityBonus(item)
        val rawValue = initialValue * (1f + 0.5f * rankProportion(item)) * qualityBonus
        return rawValue.roundToInt()
    }

    private fun interpolatedQualityScaling(initialValue: Float, r10Value: Float, item: InventoryItem): Int {
        val baseValue = initialValue.interpolate(r10Value, rankProportion(item))
        val qualityBonus = AugmentHelper.qualityBonus(item)
        return (baseValue * qualityBonus).roundToInt()
    }

    private fun rankProportion(item: InventoryItem): Float {
        val augmentLevel = item.augments?.rankLevel ?: 1
        return (augmentLevel - 1).toFloat() / (maxAugmentRank - 1).toFloat()
    }

}