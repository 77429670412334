package xim.poc.ui

import xim.math.Vector2f
import xim.math.Vector4f
import xim.poc.TextDirection
import xim.poc.UiElementHelper
import xim.poc.UiResourceManager
import xim.poc.game.StatusEffect
import xim.poc.game.UiState
import xim.poc.gl.ByteColor
import xim.resource.*
import xim.resource.table.StatusEffectNameTable

enum class ChatLogColor(val color: ByteColor) {
    Normal(ByteColor(0x80, 0x80, 0x80, 0x80)),
    Info(ByteColor(0x80, 0x80, 0x65, 0x80)),
    Action(ByteColor(0x80, 0x80, 0x40, 0x80)),
    SystemMessage(ByteColor(0x6B, 0x39, 0x80, 0x80)),
    Error(ByteColor(0x80, 0x46, 0x46, 0x80)),
}

private class ChatLogLine(val text: UiElement, val numLines: Int)

object ChatLog {

    private const val expansionFactor = 4
    private const val numLines = 8
    private const val lineHeight = 16f

    private const val maxWidth = 500f

    private const val innerHeight = numLines * lineHeight
    private const val height = innerHeight + 10f
    private const val expandedHeight = expansionFactor * innerHeight + 10f

    const val fakeUiMenuName = "fake chatlog menu"

    private var registered = false
    private lateinit var chatLogFrame: UiMenuElement

    private val lines = ArrayDeque<ChatLogLine>()
    private var expanded = false

    operator fun invoke(line: String?, chatLogColor: ChatLogColor = ChatLogColor.Normal) {
        addLine(line, chatLogColor)
    }

    fun addLine(line: String?, chatLogColor: ChatLogColor = ChatLogColor.Normal) {
        line ?: return

        val formatted = UiElementHelper.formatString(line, maxWidth = maxWidth.toInt(), textDirection = TextDirection.BottomToTop, color = chatLogColor.color) ?: return
        val element = UiElementHelper.toUiElement(formatted.characters)

        lines.addFirst(ChatLogLine(element, formatted.numLines))
        while (lines.size > 80) { lines.removeLast() }
    }

    fun statusEffectLost(actorName: String, statusEffect: StatusEffect) {
        val descriptions = StatusEffectNameTable[statusEffect.id]
        if (descriptions.size < 2) { return }

        if (descriptions.first().first().isUpperCase()) {
            addLine("$actorName's ${descriptions[0]} effect wears off.")
        } else {
            addLine("$actorName is no longer ${descriptions[1]}.")
        }
    }

    fun statusEffectGained(actorName: String, statusEffect: StatusEffect) {
        val descriptions = StatusEffectNameTable[statusEffect.id]
        if (descriptions.size < 2) { return }

        if (descriptions.first().first().isUpperCase()) {
            addLine("$actorName gains the effect of ${descriptions[0]}.")
        } else {
            addLine("$actorName is ${descriptions[1]}.")
        }
    }

    fun toggleExpand(state: Boolean) {
        expanded = state
        chatLogFrame.size.y = getHeight()
    }

    fun draw(uiState: UiState) {
        val logWindowPos = uiState.latestPosition ?: return

        val currentHeight = getHeight()
        val currentNumLines = getNumLines()

        val borderSize = Vector2f(8f, 8f)
        val lineOffset = Vector2f(0f, currentHeight - borderSize.y - lineHeight)

        val clipSize = Vector4f(logWindowPos.x, logWindowPos.y + borderSize.y, maxWidth, currentHeight - borderSize.y)

        var totalLines = 0
        for (line in lines) {
            val offset = logWindowPos + borderSize + lineOffset

            UiElementHelper.drawUiElement(line.text, position = offset, clipSize = clipSize)

            lineOffset.y -= (lineHeight * line.numLines)

            totalLines += line.numLines
            if (totalLines >= currentNumLines) { break }
        }
    }

    fun registerFakeMenu() {
        if (registered) { return }
        registered = true

        val originalMenu = UiResourceManager.getMenu("menu    log8    ") ?: throw IllegalStateException("Couldn't find 'menu    log8    '")

        val originalElement = originalMenu.uiMenu.elements[0]
        val fakeElement = UiMenuElement(
            offset = Vector2f().copyFrom(originalElement.offset),
            size = Vector2f().copyFrom(originalElement.size),
            options = emptyList(),
            next = originalElement.next,
        )

        val fakeMenu = UiMenu(fakeUiMenuName, originalMenu.uiMenu.frame.deepCopy(), listOf(fakeElement))

        chatLogFrame = fakeMenu.frame
        chatLogFrame.size.x = maxWidth
        chatLogFrame.size.y = getHeight()

        UiResourceManager.register(UiMenuResource(DatId.zero, fakeMenu))
    }

    private fun getExpansionFactor(): Int {
        return if (expanded) { expansionFactor } else { 1 }
    }

    private fun getHeight(): Float {
        return if (expanded) { expandedHeight } else { height }
    }

    private fun getNumLines(): Int {
        return numLines * getExpansionFactor()
    }

}