package xim.poc.game.configuration.v0

import xim.math.Vector3f
import xim.poc.game.configuration.*
import xim.poc.game.configuration.v0.Floor1DropTables.bronzeEquipmentQ0R5
import xim.poc.game.configuration.v0.Floor1DropTables.bronzeEquipmentQ1
import xim.poc.game.configuration.v0.Floor1DropTables.bronzeEquipmentQ1R5
import xim.poc.game.configuration.v0.Floor1DropTables.bronzeEquipmentQ2
import xim.util.Fps

object Floor1Definition {

    private val miningPoint = GatheringConfiguration(
        type = GatheringType.Mining,
        positions = listOf(
            Vector3f(x=-82.80f, y=0.09f, z=-612.00f),
            Vector3f(x=-90.55f, y=1.84f, z=-628.33f),
            Vector3f(x=-78.45f, y=3.07f, z=-636.91f),
            Vector3f(x=-90.63f, y=2.85f, z=-641.63f),
            Vector3f(x=-87.98f, y=2.91f, z=-639.02f),
        ),
        items = listOf(
            GatheringNodeItem(itemId = 640, weight = 1.0),
            GatheringNodeItem(itemId = 641, weight = 1.0),
            GatheringNodeItem(itemId = 642, weight = 1.0),
        )
    )

    private val loggingPoint = GatheringConfiguration(
        type = GatheringType.Logging,
        positions = listOf(
            Vector3f(x=-69.53f, y=2.65f, z=-677.63f),
            Vector3f(x=-73.78f, y=3.18f, z=-685.42f),
            Vector3f(x=-81.13f, y=3.00f, z=-679.50f),
            Vector3f(x=-110.00f, y=3.36f, z=-649.98f),
        ),
        items = listOf(
            GatheringNodeItem(itemId = 688, weight = 1.0),
            GatheringNodeItem(itemId = 689, weight = 1.0),
            GatheringNodeItem(itemId = 698, weight = 1.0),
        )
    )

    private val harvestingPoint = GatheringConfiguration(
        type = GatheringType.Harvesting,
        positions = listOf(
            Vector3f(x=-95.58f, y=3.04f, z=-642.17f),
            Vector3f(x=-92.67f, y=3.41f, z=-636.59f),
            Vector3f(x=-103.63f, y=2.97f, z=-643.53f),
            Vector3f(x=-96.43f, y=2.10f, z=-627.20f),
        ),
        items = listOf(
            GatheringNodeItem(itemId = 833, weight = 1.0),
            GatheringNodeItem(itemId = 834, weight = 1.0),
            GatheringNodeItem(itemId = 835, weight = 1.0),
        )
    )

    private val tinyMandragoraSpawner = MonsterSpawnerDefinition(
        spawnArea = SpawnArea(position = Vector3f(-76f, -5f, -520f), size = Vector3f(15f, 0f, 15f)),
        maxMonsters = 5,
        spawnDelay = Fps.secondsToFrames(15),
        table = WeightedTable.single(MonsterDefinitions[0x001]),
        chestDefinition = SpawnerChestDefinition(
            itemIds = mapOf(
                1 to WeightedTable(ItemDropSlot(16535) to 1.0),
                3 to WeightedTable(ItemDropSlot(12576) to 1.0),
                5 to WeightedTable(ItemDropSlot(13440) to 1.0),
                8 to WeightedTable(ItemDropSlot(9084, quantity = 3) to 1.0),
                10 to WeightedTable(ItemDropSlot(4368) to 1.0),
                12 to WeightedTable.grouped(bronzeEquipmentQ0R5 to 0.85, bronzeEquipmentQ1 to 0.15),
                15 to WeightedTable(ItemDropSlot(9084, quantity = 6) to 1.0),
                18 to WeightedTable(ItemDropSlot(9084, quantity = 9) to 1.0),
                20 to WeightedTable(ItemDropSlot(4368, quantity = 2) to 1.0),
                25 to WeightedTable.grouped(bronzeEquipmentQ1 to 0.5, bronzeEquipmentQ1R5 to 0.3, bronzeEquipmentQ2 to 0.2),
            ),
            position = Vector3f(x=-84.33f,y=-5.01f,z=-536.82f),
            treasureChestLook = TreasureChestLook.Brown,
        ),
    )

    private val bumbleBeeSpawner = MonsterSpawnerDefinition(
        spawnArea = SpawnArea(position = Vector3f(x=-42.30f,y=-5f,z=-447.21f), size = Vector3f(15f, 0f, 15f)),
        maxMonsters = 5,
        spawnDelay = Fps.secondsToFrames(15),
        table = WeightedTable.single(MonsterDefinitions[0x003]),
        chestDefinition = SpawnerChestDefinition(
            itemIds = mapOf(
                3 to WeightedTable(ItemDropSlot(12576) to 1.0),
                5 to WeightedTable(ItemDropSlot(13454) to 1.0),
                8 to WeightedTable(ItemDropSlot(9084, quantity = 4) to 1.0),
                10 to WeightedTable(ItemDropSlot(4370) to 1.0),
                12 to WeightedTable.grouped(bronzeEquipmentQ0R5 to 0.70, bronzeEquipmentQ1 to 0.30),
                15 to WeightedTable(ItemDropSlot(9084, quantity = 8) to 1.0),
                18 to WeightedTable(ItemDropSlot(9084, quantity = 12) to 1.0),
                20 to WeightedTable(ItemDropSlot(4370, quantity = 2) to 1.0),
                25 to WeightedTable.grouped(bronzeEquipmentQ1 to 0.3, bronzeEquipmentQ1R5 to 0.5, bronzeEquipmentQ2 to 0.2),
            ),
            position = Vector3f(x=-42.30f,y=-4.20f,z=-447.21f),
            treasureChestLook = TreasureChestLook.Brown,
        ),
    )

    private val crowSpawner = MonsterSpawnerDefinition(
        spawnArea = SpawnArea(position = Vector3f(x=-91.22f,y=-4.45f,z=-440.73f), size = Vector3f(10f, 0f, 10f)),
        maxMonsters = 5,
        spawnDelay = Fps.secondsToFrames(15),
        table = WeightedTable.single(MonsterDefinitions[0x004]),
        chestDefinition = SpawnerChestDefinition(
            itemIds = mapOf(
                3 to WeightedTable(ItemDropSlot(12576) to 1.0),
                5 to WeightedTable(ItemDropSlot(13475) to 1.0),
                8 to WeightedTable(ItemDropSlot(9084, quantity = 5) to 1.0),
                10 to WeightedTable(ItemDropSlot(4570) to 1.0),
                12 to WeightedTable.grouped(bronzeEquipmentQ0R5 to 0.50, bronzeEquipmentQ1 to 0.50),
                15 to WeightedTable(ItemDropSlot(9084, quantity = 10) to 1.0),
                18 to WeightedTable(ItemDropSlot(9084, quantity = 15) to 1.0),
                20 to WeightedTable(ItemDropSlot(4570, quantity = 2) to 1.0),
                25 to WeightedTable.grouped(bronzeEquipmentQ1R5 to 0.7, bronzeEquipmentQ2 to 0.3),
            ),
            position = Vector3f(x=-91.22f,y=-4.45f,z=-440.73f),
            treasureChestLook = TreasureChestLook.Brown,
        ),
    )

    private val clusterSpawner = MonsterSpawnerDefinition(
        spawnArea = SpawnArea(position = Vector3f(x=-55.56f,y=-4.94f,z=-403.94f), size = Vector3f(5f, 0f, 5f)),
        maxMonsters = 2,
        spawnDelay = Fps.secondsToFrames(10),
        table = WeightedTable.single(MonsterDefinitions[0x005]),
        chestDefinition = SpawnerChestDefinition(
            itemIds = mapOf(
                8 to WeightedTable(ItemDropSlot(9084, quantity = 7) to 1.0),
                15 to WeightedTable(ItemDropSlot(9084, quantity = 14) to 1.0),
                18 to WeightedTable(ItemDropSlot(9084, quantity = 21) to 1.0),
            ),
            position = Vector3f(x=-55.56f,y=-4.94f,z=-403.94f),
            treasureChestLook = TreasureChestLook.Brown,
        ),
    )

    private val tinyMandragoraBossSpawner = BossSpawnerDefinition(
        position = Vector3f(x=-50.13f,y=-5.00f,z=-489.65f),
        requiredItemIds = listOf(4368, 4370),
        bossMonsterId = 0x002,
    )

    fun newInstance(): FloorInstance {
        val definition = FloorDefinition(
            gatheringPoints = listOf(loggingPoint, miningPoint, harvestingPoint),
            monsterSpawnerDefinitions = listOf(tinyMandragoraSpawner, bumbleBeeSpawner, crowSpawner, clusterSpawner),
            bossSpawners = listOf(tinyMandragoraBossSpawner),
        )

        return definition.newInstance()
    }

}

object Floor1DropTables {

    val bronzeEquipmentQ0 = listOf(12448, 12576, 12704, 12832, 12960)
        .map { ItemDropSlot(it) }

    val bronzeEquipmentQ0R5 = listOf(12448, 12576, 12704, 12832, 12960)
        .map { ItemDropSlot(it, augmentRank = 5) }

    val bronzeEquipmentQ1 = listOf(12448, 12576, 12704, 12832, 12960)
        .map { ItemDropSlot(it, quality = 1) }

    val bronzeEquipmentQ1R5 = listOf(12448, 12576, 12704, 12832, 12960)
        .map { ItemDropSlot(it, quality = 1, augmentRank = 5) }

    val bronzeEquipmentQ2 = listOf(12448, 12576, 12704, 12832, 12960)
        .map { ItemDropSlot(it, quality = 2) }

}

object Floor1AugmentPools {

    val bronzeEquipmentAugmentPool = WeightedTable.uniform(12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22)

}