package xim.poc.game

import xim.math.Vector3f
import xim.poc.ActionTargetFilter
import xim.poc.ActorId
import xim.poc.KeyboardActorController
import xim.poc.browser.LocalStorage
import xim.poc.game.configuration.*
import xim.poc.game.event.*
import xim.poc.tools.ZoneNpcTool
import xim.resource.*
import xim.resource.table.AbilityInfoTable
import xim.resource.table.SpellInfoTable
import xim.util.Fps
import xim.util.Stack
import kotlin.math.min

object GameEngine {

    private val events = Stack<Event>()
    private var setup = false

    fun setup() {
        if (setup) { return }
        setup = true

        createPlayer()
        events += GameState.getGameMode().setup()
        applyEvents()
    }

    fun tick(elapsedFrames: Float) {
        val allActors = ActorStateManager.getAll()
            .filter { !ZoneNpcTool.isForceHidden(it) }

        events += allActors.map { TickActorEvent(elapsedFrames, it) }
        applyEvents()

        EventScriptRunner.update(elapsedFrames)
        GameState.update(elapsedFrames)
    }

    fun submitEvent(event: Event) {
        events += event
    }

    private fun applyEvents() {
        while (!events.isEmpty()) {
            val event = events.pop()
            events += event.apply()
        }
    }

    fun submitCreateActorState(initialActorState: InitialActorState, callback: CreateDisplayCallback? = null) {
        events += ActorCreateEvent(initialActorState, callback)
    }

    fun submitDeleteActor(it: ActorId?) {
        it ?: return
        events += ActorDeleteEvent(it)
    }

    fun canBeginAction(actorState: ActorState): Boolean {
        return !actorState.hasStatusActionLock()
    }

    fun canBeginCastSpell(actorId: ActorId, spellInfo: SpellInfo): Boolean {
        val actorState = ActorStateManager[actorId] ?: return false
        if (!actorState.isIdleOrEngaged() || !canBeginAction(actorState)) { return false }
        return canCastSpell(actorId, spellInfo)
    }

    fun canCastSpell(actorId: ActorId, spellInfo: SpellInfo): Boolean {
        val actorState = ActorStateManager[actorId] ?: return false

        val recastDelay = actorState.getRecastDelay(spellInfo)
        if (recastDelay != null && !recastDelay.isComplete()) { return false }

        if (spellInfo.mpCost > actorState.getMp()) { return false }

        if (spellInfo.magicType == MagicType.Trust) {
            val actorParty = PartyManager[actorId]
            if (actorParty.size() >= 6) { return false }
        }

        return true
    }

    fun canBeginUseAbility(actorId: ActorId, abilityInfo: AbilityInfo): Boolean {
        val actorState = ActorStateManager[actorId] ?: return false
        if (!actorState.isIdleOrEngaged() || !canBeginAction(actorState)) { return false }
        return canUseAbility(actorId, abilityInfo)
    }

    fun canUseAbility(actorId: ActorId, abilityInfo: AbilityInfo): Boolean {
        val actorState = ActorStateManager[actorId] ?: return false

        val recastDelay = actorState.getRecastDelay(abilityInfo)
        if (recastDelay != null && !recastDelay.isComplete()) { return false }

        val cost = getAbilityCost(abilityInfo.index)
        if (cost.type == AbilityCostType.Tp && cost.value > actorState.getTp()) { return false }

        return true
    }

    fun canBeginRangedAttack(actorId: ActorId): Pair<Boolean, String?> {
        val actorState = ActorStateManager[actorId] ?: return Pair(false, null)
        if (!actorState.isIdleOrEngaged() || !canBeginAction(actorState)) { return Pair(false, "You must wait longer to perform that action.") }
        return canUseRangedAttack(actorId)
    }

    fun canUseRangedAttack(actorId: ActorId): Pair<Boolean, String?> {
        val actorState = ActorStateManager[actorId] ?: return Pair(false, null)

        val canRecast = actorState.rangedAttackRecast.canRangedAttack()
        if (!canRecast) { return Pair(false, "You must wait longer to perform that action.") }

        return Pair(true, null)
    }

    fun getRangedAttackTargetFilter(): ActionTargetFilter {
        return ActionTargetFilter(TargetFlag.Enemy.flag)
    }

    fun getMaximumCraftable(actor: ActorId, recipe: SynthesisRecipe): Int {
        val actorState = ActorStateManager[actor] ?: return 0
        val inventory = actorState.inventory

        var max = 99

        for (input in recipe.input.distinct()) {
            val recipeCount = recipe.input.count { it == input }

            val inventoryCount = inventory.inventoryItems
                .filter { it.id == input }
                .filter { !actorState.isEquipped(it) }
                .sumOf { it.quantity }

            max = min(max, inventoryCount / recipeCount)
        }

        return max
    }

    private fun releaseTrusts(actor: ActorState): List<Event> {
        return getTrusts(actor.id).map { TrustReleaseEvent(actor.id, it.id) }
    }

    fun getTrusts(actorId: ActorId): List<ActorState> {
        return PartyManager[actorId].getAllState().filter { it.owner == actorId }
    }

    fun releaseDependents(actor: ActorState): List<Event> {
        val releaseEvents = ArrayList<Event>()
        releaseEvents += releaseTrusts(actor)
        releaseEvents += BubbleReleaseEvent(actor.id)
        releaseEvents += PetReleaseEvent(actor.id)
        return releaseEvents
    }

    fun getSpellList(actorId: ActorId): List<SpellInfo> {
        ActorStateManager[actorId] ?: return emptyList()
        return GameState.getGameMode().getActorSpellList(actorId)
    }

    fun getActorAbilityList(actorId: ActorId, abilityType: AbilityType): List<AbilityInfo> {
        return GameState.getGameMode().getActorAbilityList(actorId, abilityType)
    }

    fun canDualWield(actorState: ActorState): Boolean {
        return GameState.getGameMode().canDualWield(actorState)
    }

    fun getRangeInfo(actor: ActorState, skillInfo: SkillInfo): SkillRangeInfo {
        return when (skillInfo.type) {
            SkillType.Spell -> getCastingRangeInfo(actor, skillInfo.id)
            SkillType.MobSkill -> getMobSkillRange(actor, skillInfo.id)
            SkillType.Ability -> getAbilityRange(actor, skillInfo.id)
        }
    }

    fun getCastingRangeInfo(actorState: ActorState, spellId: Int): SkillRangeInfo {
        val spellInfo = SpellInfoTable[spellId]
        return SkillRangeInfo(maxTargetDistance = 10f, effectRadius = spellInfo.aoeSize.toFloat(), type = spellInfo.aoeType)
    }

    fun getItemRange(actorState: ActorState, itemId: Int): SkillRangeInfo {
        return SkillRangeInfo(maxTargetDistance = 10f, effectRadius = 0f, type = AoeType.None)
    }

    fun getAbilityRange(actor: ActorState, abilityId: Int): SkillRangeInfo {
        val abilityInfo = AbilityInfoTable[abilityId]
        return SkillRangeInfo(maxTargetDistance = 10f, effectRadius = abilityInfo.aoeSize.toFloat(), type = abilityInfo.aoeType)
    }

    fun getMobSkillRange(actor: ActorState, mobSkillId: Int): SkillRangeInfo {
        return GameState.getGameMode().getSkillRangeInfo(SkillType.MobSkill, mobSkillId)
    }

    fun getRangedAttackRangeInfo(actor: ActorState): SkillRangeInfo {
        return SkillRangeInfo(maxTargetDistance = 10f, effectRadius = 0f, type = AoeType.None)
    }

    fun getTpGained(attacker: ActorState, defender: ActorState, equipSlot: EquipSlot): Int {
        val equip = attacker.getEquipment(equipSlot) ?: return 0
        val delay = equip.info().equipmentItemInfo.weaponInfo.delay ?: return 0
        return delay / 3
    }

    fun getAbilityCost(abilityId: Int): AbilityCost {
        return GameState.getGameMode().getAbilityCost(AbilityInfoTable[abilityId])
    }

    fun getAbilityRecast(actorState: ActorState, abilityInfo: AbilityInfo): Float {
        val delayInSeconds = if (abilityInfo.type == AbilityType.WeaponSkill) {
            2.5f
        } else if (abilityInfo.type == AbilityType.PhantomRoll) {
            2.5f
        } else {
            5f
        }

        return Fps.secondsToFrames(delayInSeconds)
    }

    fun getActorEffectTickResult(actorState: ActorState): ActorEffectTickResult {
        return GameState.getGameMode().getActorEffectTickResult(actorState)
    }

    fun getWeaponSkillSkillChainAttributes(actor: ActorState, abilityId: Int): List<SkillChainAttribute> {
        return AbilityInfoTable.getApproximateSkillChainAttributes(abilityId)
    }

    fun getMagicBurstBonus(source: ActorState, target: ActorState, spellId: Int): Float? {
        val spellInfo = SpellInfoTable[spellId]
        return target.skillChainTargetState.getMagicBurstBonus(spellInfo)
    }

    fun getRangedAttackRecast(source: ActorState): Float {
        return source.getRangedAttackInterval()
    }

    fun getAutoAttackRecast(source: ActorState): Float {
        return GameState.getGameMode().getAutoAttackInterval(source)
    }

    fun hasAnyEnmity(actorId: ActorId): Boolean {
        return ActorStateManager.filter { !it.isDead() && it.isEnemy() && it.targetState.targetId == actorId }.isNotEmpty()
    }

    fun computeCombatStats(actorId: ActorId): CombatStats {
        return GameState.getGameMode().getActorCombatStats(actorId)
    }

    private fun createPlayer() {
        val config = LocalStorage.getPlayerConfiguration()
        val modelLook = config.playerLook.copy()

        val zoneSettings = ZoneSettings(zoneId = config.playerPosition.zoneId, subAreaId = config.playerPosition.subAreaId)

        submitCreateActorState(InitialActorState(
            name = "Player",
            type = ActorType.Pc,
            position = Vector3f(config.playerPosition.position),
            rotation = config.playerPosition.rotation,
            zoneSettings = zoneSettings,
            modelLook = modelLook,
            equipment = Equipment().copyFrom(config.playerEquipment),
            movementController = KeyboardActorController(),
            presetId = ActorStateManager.playerId,
            jobSettings = JobSettings(config.playerJob.mainJob, config.playerJob.subJob),
            jobLevels = config.playerLevels,
            inventory = config.playerInventory,
        ))
    }

}