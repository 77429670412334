package xim.resource.table

import xim.resource.ByteReader
import xim.poc.browser.DatLoader
import xim.resource.InventoryItemInfo

// https://github.com/LandSandBoat/server/blob/base/sql/item_equipment.sql
object ItemModelTable: LoadableResource {

    private lateinit var table: ByteReader
    private var preloaded = false

    override fun preload() {
        if (preloaded) { return }
        preloaded = true
        loadTable()
    }

    override fun isFullyLoaded() : Boolean {
        return this::table.isInitialized
    }

    operator fun get(item: InventoryItemInfo?) : Int {
        return getModelId(item)
    }

    fun getModelId(item: InventoryItemInfo?) : Int {
        if (item == null) { return 0 }
        return getModelId(item.itemId)
    }

    fun getModelId(itemId: Int) : Int {
        table.position = itemId * 2
        return table.next16()
    }

    private fun loadTable() {
        DatLoader.load("landsandboat/ItemModelTable.DAT").onReady { table = it.getAsBytes() }
    }

}