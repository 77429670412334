package xim.poc.game.configuration.v0

import xim.math.Vector3f
import xim.poc.*
import xim.poc.game.*
import xim.poc.game.configuration.ActorBehaviors
import xim.poc.game.configuration.MonsterDefinitions
import xim.poc.game.configuration.MonsterId
import xim.poc.game.configuration.NpcInteraction
import xim.poc.game.event.BossSpawnEvent
import xim.poc.game.event.InitialActorState
import xim.poc.gl.ByteColor
import xim.poc.ui.ChatLog
import xim.poc.ui.ChatLogColor
import xim.poc.ui.ShiftJis
import xim.resource.DatId
import xim.resource.InventoryItems

data class BossSpawnerDefinition(val position: Vector3f, val requiredItemIds: List<Int>, val bossMonsterId: MonsterId)

class BossSpawner(val definition: BossSpawnerDefinition) {

    private val bossDefinition = MonsterDefinitions[definition.bossMonsterId]

    private var spawnerId: ActorId? = null

    private var bossSpawned = false
    private var bossId: ActorId? = null

    init {
        createSpawner()
    }

    fun update(elapsedFrames: Float) {
        if (!bossSpawned) { return }
        val bossState = ActorStateManager[bossId] ?: return

        if (!bossState.isDead()) { return }

        bossSpawned = false
        bossId = null

        val spawnerActor = ActorManager[spawnerId]
        spawnerActor?.enqueueModelRoutine(DatId("s000"))
    }

    fun clear() {
        GameEngine.submitDeleteActor(spawnerId)
        GameEngine.submitDeleteActor(bossId)
    }

    fun hasActiveBoss(): Boolean {
        return bossSpawned
    }

    fun spawnBoss() {
        bossSpawned = true
        val spawnerActor = ActorManager[spawnerId]
        spawnerActor?.enqueueModelRoutine(DatId("s001"), options = RoutineOptions(callback = this::createBossState))
    }

    private fun createSpawner() {
        GameEngine.submitCreateActorState(
            InitialActorState(
                name = "???",
                type = ActorType.Npc,
                position = definition.position,
                modelLook = ModelLook.npc(0x96F),
                movementController = NoOpActorController(),
                behaviorController = ActorBehaviors.NoAction,
            )
        ) {
            spawnerId = it.id
            GameV0.interactionManager.registerInteraction(it.id, BossSpawnerInteraction(this))
            it.onReadyToDraw { it.playRoutine(DatId("s000")) }
        }
    }

    private fun createBossState() {
        val event = BossSpawnEvent(
            sourceId = ActorStateManager.playerId,
            monsterId = bossDefinition.id,
            position = definition.position,
            requiredItemIds = definition.requiredItemIds,
        ) {
            bossId = it.id
            it.renderState.effectColor = ByteColor.zero

            it.onReadyToDraw {
                it.transitionToIdle(0f)
                it.playRoutine(DatId.pop)
                it.actorModel?.customModelSettings = bossDefinition.customModelSettings
            }
        }

        GameEngine.submitEvent(event)
    }
}

class BossSpawnerInteraction(val bossSpawner: BossSpawner): NpcInteraction {

    override fun onInteraction(npcId: ActorId) {
        if (bossSpawner.hasActiveBoss()) {
            ChatLog("Now is not the time!", ChatLogColor.Error)
            return
        }

        val itemPrompts = bossSpawner.definition.requiredItemIds.map { InventoryItems[it] }
            .joinToString(", ") { "${ShiftJis.colorItem}${it.logName}${ShiftJis.colorClear}" }

        ChatLog("You sense that [$itemPrompts] is needed...")

        val player = ActorStateManager.player()
        for (item in bossSpawner.definition.requiredItemIds) {
            if (!player.discardNotEquippedItems(item, 1, validateOnly = true)) { return }
        }

        UiStateHelper.openQueryMode(prompt = "Offer the items?", options = getOptions(), callback = this::handleResponse)
    }

    private fun getOptions(): List<QueryMenuOption> {
        val options = ArrayList<QueryMenuOption>()
        options += QueryMenuOption(text = "No", value = -1)
        options += QueryMenuOption(text = "Yes", value = 1)
        return options
    }

    private fun handleResponse(queryMenuOption: QueryMenuOption?): QueryMenuResponse {
        if (queryMenuOption == null || queryMenuOption.value < 0) { return QueryMenuResponse.pop }
        GameClient.submitTargetUpdate(ActorStateManager.playerId, null)
        bossSpawner.spawnBoss()
        return QueryMenuResponse.pop
    }


}