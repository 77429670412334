import kotlinx.browser.document
import kotlinx.browser.window
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement
import web.url.URLSearchParams
import xim.poc.MainTool
import xim.poc.browser.BrowserPlatformDependencies
import xim.poc.browser.PlatformDependencies
import xim.poc.game.GameState
import xim.poc.game.configuration.assetviewer.AssetViewer
import xim.poc.game.configuration.v0.GameV0
import xim.poc.tools.DebugToolsManager

private var started = false
private lateinit var platform: PlatformDependencies

fun main() {
    platform = BrowserPlatformDependencies.get(canvasId = "canvas")

    if (isLocalHost()) {
        onStart()
    }

    val startButton = (document.getElementById("start-container") as HTMLElement)
    startButton.onclick = { onStart() }
}

fun onStart() {
    if (started) { return }
    started = true

    val startContainer = document.getElementById("start-container") as HTMLDivElement
    startContainer.remove()

    val params = URLSearchParams(window.location.search)

    val gameMode = if (params["game"] != null) { GameV0 } else { AssetViewer }
    GameState.setGameMode(gameMode)

    if (gameMode.configuration.debugControlsEnabled) { DebugToolsManager.showDebugOnlyTools() }

    MainTool.run(platform)
}

fun isLocalHost(): Boolean {
    return window.location.hostname == "localhost"
}