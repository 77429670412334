package xim.poc.ui

import xim.math.Vector2f
import xim.poc.ActorManager
import xim.poc.UiElementHelper
import xim.poc.game.*
import xim.resource.EquipSlot

object EquipScreenUi {

    private class EquipUiSlot(val slot: EquipSlot, val x: Int, val y: Int)

    private val equipOrder = listOf(
        EquipUiSlot(EquipSlot.Main, 0, 0),
        EquipUiSlot(EquipSlot.Head, 0, 1),
        EquipUiSlot(EquipSlot.Body, 0, 2),
        EquipUiSlot(EquipSlot.Back, 0, 3),
        EquipUiSlot(EquipSlot.Sub, 1, 0),
        EquipUiSlot(EquipSlot.Neck, 1, 1),
        EquipUiSlot(EquipSlot.Hands, 1, 2),
        EquipUiSlot(EquipSlot.Waist, 1, 3),
        EquipUiSlot(EquipSlot.Range, 2, 0),
        EquipUiSlot(EquipSlot.LEar, 2, 1),
        EquipUiSlot(EquipSlot.LRing, 2, 2),
        EquipUiSlot(EquipSlot.Legs, 2, 3),
        EquipUiSlot(EquipSlot.Ammo, 3, 0),
        EquipUiSlot(EquipSlot.REar, 3, 1),
        EquipUiSlot(EquipSlot.RRing, 3, 2),
        EquipUiSlot(EquipSlot.Feet, 3, 3),
    )

    fun draw(uiState: UiState) {
        drawEquippedItems(uiState)

        val hoveredItem = getHoveredEquipItem()
        if (hoveredItem != null && UiStateHelper.isFocus(uiState)) {
            InventoryUi.drawSelectedInventoryItem(hoveredItem, UiStateHelper.equipContext)
        }

        val statusPosition = UiElementHelper.drawMenu(menuName = "menu    persona ")
        if (statusPosition != null) { StatusWindowUi.populateStatusWindow(statusPosition) }

        UiElementHelper.drawMenu(menuName = "menu    money   ")
    }

    fun getHoveredEquipItem(): InventoryItem? {
        val hoveredIndex = UiStateHelper.equipContext.cursorIndex
        return ActorStateManager.player().getEquipment(equipOrder[hoveredIndex].slot)
    }

    fun getInventoryItemsByEquipSlot(cursorIndex: Int) : List<InventoryItem> {
        return Inventory.player().getInventoryItemsByEquipSlot(equipOrder[cursorIndex].slot)
    }

    fun equipHoveredItem(uiState: UiState) {
        val equipIndex = UiStateHelper.equipContext.cursorIndex
        val slot = equipOrder[equipIndex].slot

        val filteredView = getInventoryItemsByEquipSlot(UiStateHelper.equipContext.cursorIndex)
        val index = uiState.cursorIndex + uiState.scrollSettings!!.lowestViewableItemIndex

        val item = filteredView[index]

        val current = ActorStateManager.player().getEquipment(slot)
        val itemId = if (current?.internalId == item.internalId) { null } else { item.internalId }

        GameEngine.equipItem(ActorStateManager.playerId, slot, itemId)
    }

    private fun drawEquippedItems(uiState: UiState) {
        val stackPos = uiState.latestPosition ?: return
        val baseOffset = Vector2f(24f, 10f)

        val player = ActorManager.player()
        for (slot in equipOrder) {
            val item = player.getEquipment(slot.slot) ?: continue

            val offset = baseOffset + Vector2f(slot.x * 34f, slot.y * 34f)
            UiElementHelper.drawInventoryItem(itemInfo = item.info(), position = offset + stackPos)
        }
    }

}